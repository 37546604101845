/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
	Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { validationSchema } from './validationSchema';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useCreateClaimMutation } from '@/features/claims/ticketsApi';
import { PolicySelect } from '@/components/selects/PolicySelect';
import { PolicyTransactionSelect } from '@/components/selects/PolicyTransactionSelect';
import { InstantClientSelect } from '@/components/selects/ClientSelect';
import FileUpload from '@/components/FileUpload';
import { PrioritySelect } from '@/components/selects/PrioritySelect';
import { PageHeader } from '@/components/layouts/PageHeader';
import { showToast } from '@/features/toast/toastSlice';
import { useLocation } from 'react-router-dom';
import { ClaimTypeSelect } from '@/components/selects/ClaimTypeSelect';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { OwnerSelect } from '@/components/selects/OwnerSelect';

const CreateClaimForm = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const account = useSelector((state) => state.msalAccount.account);

	const existingClient = location?.state?.client ?? {};
	const existingPolicy = location?.state?.policy;
	const existingTransaction = location?.state?.transaction;

	const isExistingClient = useMemo(
		() => Object.keys(existingClient).length > 0,
		[existingClient]
	);

	const email = account?.username;
	const userQuery = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userQuery.data?.hubspotId;

	const [formLoading, setFormLoading] = useState(false);

	const initialValues = useMemo(() => {
		return {
			policy: existingPolicy ?? {},
			policyId: existingPolicy?.Id,
			transaction: existingTransaction,
			policyTransactionId: existingTransaction?.Id,
			files: [],
			describeClaim: '',
			notes: '',
			clientName: existingClient?.name ?? '',
			clientOwner: existingClient?.hubspot_owner_id ?? existingClient?.ownerId,
			successMessage: '',
			errorMessage: '',
			dealName: '',
			dateOfLoss: null,
			dateReported: null,
			priority: '',
			claimType: '',
			insurer: existingPolicy?.InsurerName ?? '',
			clientId: existingClient?.id ?? existingClient?.hs_object_id,
			ownerId: hubspotId,
		};
	}, [existingPolicy, existingTransaction, existingClient, hubspotId]);

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			setFormLoading(true);
			console.log('📋 ~ Formik values', values);
			const createError = (message) => {
				setFieldValue('errorMessage', message ?? 'Error creating claim');
				setFieldValue('successMessage', '');
				setSubmitting(false);
				setFormLoading(false);
			};
			const res = await createNewClaim();
			if (res?.error) {
				console.log('🙅 ~ Error creating claim', res?.error);
				createError(res?.error?.message);
				return;
			}
			const response = await createClaimFolders(res.dealId, res.noteId);
			if (response?.error) {
				createError(response?.error?.message);
				dispatch(
					showToast({
						message: response?.error?.message ?? 'Claim created',
						action: {
							path: `/claims/${res.dealId}`,
							label: 'GO TO CLAIM',
						},
						autohide: false,
					})
				);
				return;
			}
			console.log('🚀 ~ Successfully created new claim', res);
			dispatch(
				showToast({
					message: 'New claim created!',
					action: {
						path: `/claims/${res.dealId}`,
						label: 'GO TO CLAIM',
					},
					autohide: false,
				})
			);
			window.history.replaceState({}, '');
			resetForm();
			setFieldValue('successMessage', 'Successfully created claim');
			setFieldValue('errorMessage', '');
			setSubmitting(false);
			setFormLoading(false);
		},
	});

	const { data: ownerData } = useGetOwnerQuery(
		{ ownerId: formik.values.clientOwner },
		{ skip: !formik.values.clientOwner }
	);

	const { data: ownerDetails } = useGetUserDetailsQuery(
		{ hubspotId: ownerData?.id },
		{ skip: !ownerData }
	);

	const { setUpDealFilesAndFolders } = useClientStorageHooks(ownerDetails ?? userQuery.data);

	const [createClaim] = useCreateClaimMutation();

	const createNewClaim = async () => {
		const body = {
			hubspotId: formik.values.ownerId,
			dealName: formik.values.dealName.trim(),
			description: formik.values.describeClaim,
			clientId: formik.values.clientId,
			policyId: formik.values.policyId,
			policyTransId: formik.values.policyTransactionId,
			insurer: formik.values.insurer,
			claimType: formik.values.claimType,
			...(formik.values.priority.length > 0 && { priority: formik.values.priority }),
			...(formik.values.notes && { notes: formik.values.notes }),
			completedBy: hubspotId,
		};

		if (formik.values.dateOfLoss) {
			const dateOfLoss = formik.values.dateOfLoss;
			// Get the local time zone offset in minutes
			const timezoneOffset = dateOfLoss.getTimezoneOffset();
			// Adjust the date to UTC by adding the time zone offset
			dateOfLoss.setMinutes(dateOfLoss.getMinutes() - timezoneOffset);
			// Set the time to midnight UTC
			const timestamp = dateOfLoss.setUTCHours(0, 0, 0, 0);
			body.dateOfLoss = timestamp;
		}

		if (formik.values.dateReported) {
			const dateOfLoss = formik.values.dateOfLoss;
			// Get the local time zone offset in minutes
			const timezoneOffset = dateOfLoss.getTimezoneOffset();
			// Adjust the date to UTC by adding the time zone offset
			dateOfLoss.setMinutes(dateOfLoss.getMinutes() - timezoneOffset);
			// Set the time to midnight UTC
			const timestamp = dateOfLoss.setUTCHours(0, 0, 0, 0);
			body.dateReported = timestamp;
		}
		console.log('Create Claim BODY', body);
		return await createClaim(body)
			.unwrap()
			.catch((err) => {
				console.log('🚀 ~ Error creating claim', err);
				return { error: { message: 'Error creating claim' } };
			});
	};

	const createClaimFolders = async (dealId, noteId) => {
		try {
			const { documentId } = await setUpDealFilesAndFolders(
				dealId,
				noteId,
				formik.values.clientName,
				formik.values.dealName,
				process.env.REACT_APP_PIPELINE_CLAIMS,
				formik.values.files
			);
			console.log('Here is the doc Id:', documentId);
		} catch (error) {
			console.log('🚀 ~ Error creating claim folders', error);
			return {
				error: {
					message: `Claim ${dealId} created, but there was an error creating claim folder or uploading files`,
				},
			};
		}
	};

	const { getError, getErrorMessage } = useFormikHelper(formik);

	const loading = formik.isSubmitting || formLoading;

	return (
		<Container>
			<Box sx={{ p: 3 }}>
				<form onSubmit={formik.handleSubmit}>
					<CardHeader disableTypography title={<PageHeader title={'Report a Claim'} />} />
					<CardContent>
						{/* ///TODO: add link when available */}
						{/* <Typography variant="body1" paragraph>
              View our user guide on the steps to process a claim{' '}
							<Link>here</Link>
						</Typography> */}
						{userQuery.isLoading || userQuery.isUninitialized ? (
							<CircularProgress />
						) : (
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<FormLabel required>{'Claim name'}</FormLabel>
									<TextField
										fullWidth
										{...formik.getFieldProps('dealName')}
										error={getError('dealName')}
										helperText={getErrorMessage('dealName')}
										disabled={loading}
										required
									/>
								</Grid>
								<Grid item xs={true}>
									<FormLabel required>{'Search client name'}</FormLabel>
									<InstantClientSelect
										clientId={formik.values.clientId}
										clientName={formik.values.clientName}
										setClient={(client) => {
											console.log('client', client);
											formik.setValues({
												...formik.values,
												clientId: client?.id,
												clientName: client?.name,
												clientOwner: client?.ownerId,
												policy: {},
												policyId: null,
												transaction: {},
												policyTransactionId: null,
											});
										}}
										disabled={loading || isExistingClient}
									/>
									<FormHelperText error={getError('clientId')}>
										{getErrorMessage('clientId')}
									</FormHelperText>
								</Grid>
								<Grid item xs={4}>
									<FormLabel>{'Assign Broker'}</FormLabel>
									<OwnerSelect
										initialId={formik.values.ownerId}
										disabled={formik.isSubmitting}
										onChange={(value) => {
											formik.setFieldValue('ownerId', value);
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12}>
									<FormLabel required sx={{ display: 'flex' }}>
										{'Policy'}
									</FormLabel>
									<PolicySelect
										clientId={formik.values.clientId}
										policy={formik.values.policy}
										setPolicy={(newPolicy) => {
											formik.setValues({
												...formik.values,
												policy: newPolicy,
												policyId: newPolicy?.Id,
												transaction: {},
												policyTransactionId: null,
											});
										}}
										disabled={existingPolicy != null || loading}
									/>
									{!formik.values.clientId && (
										<FormHelperText>
											{'Please select a client to view policies'}
										</FormHelperText>
									)}
									<FormHelperText error={getError('policyId')}>
										{getErrorMessage('policyId')}
									</FormHelperText>
								</Grid>
								<Grid item xs={12} md={12}>
									<FormLabel required sx={{ display: 'flex' }}>
										{'Policy Transaction'}
									</FormLabel>
									<PolicyTransactionSelect
										policyId={formik.values.policyId}
										policyTransaction={formik.values.transaction}
										setPolicyTrans={(newPolicy) => {
											formik.setValues({
												...formik.values,
												policyTransactionId: newPolicy?.Id,
												transaction: newPolicy,
												insurer: newPolicy?.InsurerName,
											});
										}}
										disabled={
											Object.keys(existingTransaction ?? {}) > 0 || loading
										}
									/>
									{!formik.values.policyId && (
										<FormHelperText>
											{'Please select a policy to view policy transactions'}
										</FormHelperText>
									)}
									<FormHelperText error={getError('policyTransactionId')}>
										{getErrorMessage('policyTransactionId')}
									</FormHelperText>
								</Grid>
								<Grid item xs={12} md={6}>
									<ClaimTypeSelect
										required={true}
										disabled={formik.isSubmitting}
										claimType={formik.values.claimType}
										setClaimType={(value) =>
											formik.setFieldValue('claimType', value)
										}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormLabel>{'Insurer'}</FormLabel>
									<TextField
										fullWidth
										{...formik.getFieldProps('insurer')}
										disabled={true}
										value={formik.values.insurer}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormLabel required>{'Date of loss'}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											name='dateOfLoss'
											sx={{ width: '100%' }}
											disabled={
												!formik.values.policyId ||
												!formik.values.policyTransactionId ||
												formik.isSubmitting
											}
											format='dd/MM/yyyy'
											disableFuture={true}
											value={formik.values.dateOfLoss}
											{...formik.getFieldProps('dateOfLoss')}
											onChange={(value) => {
												formik.setFieldValue('dateOfLoss', value);
											}}
										/>
									</LocalizationProvider>
									<FormHelperText error={getError('dateOfLoss')}>
										{getErrorMessage('dateOfLoss')}
									</FormHelperText>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormLabel required>{'Date Reported'}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DatePicker
											name='dateReported'
											sx={{ width: '100%' }}
											disabled={formik.isSubmitting}
											format='dd/MM/yyyy'
											disableFuture={true}
											value={formik.values.dateReported}
											{...formik.getFieldProps('dateReported')}
											onChange={(value) => {
												formik.setFieldValue('dateReported', value);
											}}
										/>
									</LocalizationProvider>
									<FormHelperText error={getError('dateReported')}>
										{getErrorMessage('dateReported')}
									</FormHelperText>
								</Grid>
								<Grid item xs={12} md={12}>
									<PrioritySelect
										disabled={formik.isSubmitting}
										priority={formik.values.priority}
										setPriority={(value) =>
											formik.setFieldValue('priority', value)
										}
									/>
								</Grid>
								<Grid item xs={12} md={12}>
									<FormLabel required>
										{'Brief description of the incident'}
									</FormLabel>
									<TextField
										fullWidth
										id='describeClaim'
										{...formik.getFieldProps('describeClaim')}
										disabled={loading}
										error={getError('describeClaim')}
										// helperText={getErrorMessage('describeClaim')}
										inputProps={{ maxLength: 100 }}
									/>
									<Grid container>
										<Grid item xs={10}>
											<FormHelperText error={getError('describeClaim')}>
												{getErrorMessage('describeClaim')}
											</FormHelperText>
										</Grid>
										<Grid item xs={2}>
											<FormHelperText
												sx={{
													textAlign: 'right',
													mr: '14px',
													...(formik.values.describeClaim.length ==
														100 && { fontWeight: 'bold' }),
												}}
											>
												{`${formik.values.describeClaim.length}/100`}
											</FormHelperText>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} md={12}>
									<FormLabel>{'Additional Notes'}</FormLabel>
									<TextField
										fullWidth
										id='notes'
										multiline
										rows={4}
										{...formik.getFieldProps('notes')}
										disabled={loading}
									/>
								</Grid>
								<Grid item xs={12} md={12}>
									<FileUpload
										files={formik.values.files}
										setFiles={(files) =>
											formik.handleChange({
												target: {
													name: 'files',
													value: files,
												},
											})
										}
										loading={loading}
									/>
								</Grid>
								{formik.values.errorMessage.length > 0 && (
									<FormHelperText sx={{ padding: '1em' }} error>
										{formik.values.errorMessage}
									</FormHelperText>
								)}
								{formik.values.successMessage.length > 0 && (
									<FormHelperText sx={{ color: 'green', padding: '1em' }}>
										{formik.values.successMessage}
									</FormHelperText>
								)}
							</Grid>
						)}
					</CardContent>
					<Divider />
					<CardActions
						sx={{
							justifyContent: 'center',
							p: 2,
						}}
					>
						<Button
							color='primary'
							type='submit'
							variant='contained'
							disabled={
								userQuery.isLoading || userQuery.isUninitialized || formLoading
							}
						>
							{formik.isSubmitting ? (
								<CircularProgress size='2em' sx={{ color: '#ffffff' }} />
							) : (
								'Submit'
							)}
						</Button>
					</CardActions>
				</form>
			</Box>
		</Container>
	);
};

export default CreateClaimForm;
