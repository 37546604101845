import React from 'react';
import { TextField, FormHelperText, FormLabel, Grid } from '@mui/material';
import GooglePlacesAutocomplete from '@/components/selects/GoogleAddressSelect';
import { ADDRESS, CITY, COUNTRY, SUBURB, POSTCODE } from './validationSchema';
import { useFormikHelper } from '@/hooks/useFormikHelper';

export const AddressFields = ({ formik, loading }) => {
	const { getError, getErrorMessage } = useFormikHelper(formik);

	return (
		<>
			<FormLabel required sx={{ width: '100%' }}>
				{'Company address'}
			</FormLabel>
			<Grid container direction={'row'} spacing={2}>
				<Grid item sm={12} md={6}>
					<GooglePlacesAutocomplete
						onSelect={(place) => {
							if (place !== null && place?.address_components) {
								const placesComps = place?.address_components;
								
								console.log('places: ', placesComps);
								const streetNumber = placesComps.find((comp) =>
									comp.types.includes('street_number')
								);
								const streetAddress = placesComps.find((comp) =>
									comp.types.includes('route')
								);
								const suburb = placesComps.find((comp) =>
									comp.types.includes('sublocality_level_1')
								);
								const suburbAlt = placesComps.find((comp) =>
									comp.types.includes('administrative_area_level_1')
								);
								const city = placesComps.find((comp) =>
									comp.types.includes('locality')
								);
								const postCode = placesComps.find((comp) =>
									comp.types.includes('postal_code')
								);
								const country = placesComps.find((comp) =>
									comp.types.includes('country')
								);
								if (streetNumber || streetAddress) {
									const address = streetNumber !== null ? `${streetNumber?.long_name ?? ""} ${streetAddress?.long_name}` :  streetAddress?.long_name;
									formik.setFieldValue(ADDRESS, address);
								}
								if (suburb || suburbAlt) {
									formik.setFieldValue(
										SUBURB,
										suburb?.long_name ?? suburbAlt?.long_name
									);
								}
								if (city) {
									formik.setFieldValue(CITY, city?.long_name);
								}
								if (postCode) {
									formik.setFieldValue(POSTCODE, postCode?.long_name);
								}
								if (country) {
									formik.setFieldValue(COUNTRY, country?.long_name);
								}
							}
						}}
					/>
					<FormHelperText>Street address</FormHelperText>
				</Grid>
				<Grid item sm={12} md={4}>
					<TextField
						fullWidth
						required
						{...formik.getFieldProps(SUBURB)}
						error={getError(SUBURB)}
						helperText={getErrorMessage(SUBURB)}
						disabled={loading}
					/>
					<FormHelperText>Suburb</FormHelperText>
				</Grid>
				<Grid item sm={12} md={2}>
					<TextField
						fullWidth
						name={COUNTRY}
						required
						{...formik.getFieldProps(POSTCODE)}
						error={getError(POSTCODE)}
						helperText={getErrorMessage(POSTCODE)}
						disabled={loading}
					/>
					<FormHelperText>Postcode</FormHelperText>
				</Grid>
			</Grid>
			<Grid container direction={'row'} spacing={2}>
				<Grid item sm={12} md={6}>
					<TextField
						fullWidth
						name={CITY}
						required
						{...formik.getFieldProps(CITY)}
						error={getError(CITY)}
						helperText={getErrorMessage(CITY)}
						disabled={loading}
					/>
					<FormHelperText>City</FormHelperText>
				</Grid>
				<Grid item sm={12} md={6}>
					<TextField
						fullWidth
						name={COUNTRY}
						required
						{...formik.getFieldProps(COUNTRY)}
						error={getError(COUNTRY)}
						helperText={getErrorMessage(COUNTRY)}
						disabled={loading}
					/>
					<FormHelperText>Country</FormHelperText>
				</Grid>
			</Grid>
		</>
	);
};
