/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useGetContactPropertiesQuery } from '@/features/contacts/contactsApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useGetEngagementsQuery } from '@/features/engagements/engagementsApi';
import { setLoading } from '@/features/engagements/engagementsSlice';
import { useDispatch } from 'react-redux';
import { useGetClientPropertiesQuery } from '@/features/clients/clientsApi';

const initialState = {
	objectType: 'contact',
	client: {},
	contact: {},
	deals: [],
	claims: [],
	notes: [],
	tasks: [],
	calls: [],
	emails: [],
	activities: [],
	owner: {},
	isLoading: true,
	isError: false,
};

const ContactContext = createContext(initialState);

// ContactContextProvider component that provides the Contact context to its children.
export const ContactContextProvider = ({ children }) => {
	const dispatch = useDispatch();

	const [contactId, setContactId] = useState(() =>
		window.location.pathname.split('/').pop()
	);
	
	useEffect(() => {
		const newId = window.location.pathname.split('/').pop();
		setContactId(newId);
	}, [window.location.pathname]);

	const contactProperties = useGetContactPropertiesQuery(
		contactId,
		{ skip: !contactId }
	);

	// console.log('CONTACT PROPERTIES', contactProperties.data);

	const objectType = 'contact';

	const clientId = contactProperties.data?.associations?.companies?.results?.[0]?.id;
	const clientProperties = useGetClientPropertiesQuery(
		clientId,
		{ skip: !clientId }
	);

	// const dealIds = contactProperties.data?.deals;
	// const dealsProperties = useGetDealsListByIdsQuery(
	// 	{ clientId: contactId, dealIds, includePolicies: true },
	// 	{ skip: !contactId || !dealIds }
	// );

	// console.log('DEAL PROPERTIES', dealsProperties?.data);

	// const ticketIds = contactProperties.data?.claims;
	// const claimsProperties = useGetTicketsListByIdsQuery(
	// 	{ clientId: contactId, ticketIds },
	// 	{ skip: !contactId || !ticketIds }
	// );

	// console.log('CLAIM PROPERTIES', claimsProperties?.data);

	const ownerId = contactProperties?.data?.properties?.hubspot_owner_id;

	// console.log('ownerId', ownerId);
    
	const ownerProperties = useGetOwnerQuery({ ownerId }, {
		skip: !ownerId,
	});


	const engagementsQuery = useGetEngagementsQuery({ dealId: contactId, objectType: objectType }, 
		{ skip: !contactId }
	);

	useEffect(() => {
		dispatch(setLoading(engagementsQuery.isFetching || engagementsQuery.isLoading));
	}, [
		engagementsQuery.isFetching,
		engagementsQuery.isLoading,
	]);

	// const clientRowQuery = useGetClientRowQuery(contactId, { skip: !contactId });
	// const clientRowQuery = useGetClientRowQuery(clientId, { skip: !clientId });

	// console.log('here are some engagmentsQuery: ', engagementsQuery);
	// console.log('OWNER PROPERTIES', ownerProperties?.data);
    
	// Handle loading and error states
	const isLoading = contactProperties.isLoading || ownerProperties.isLoading;
	// engagementsQuery.isLoading;
	const isError = contactProperties.isError || ownerProperties.isError || engagementsQuery.isError;

	// Context value
	const contextValue = {
		objectType,
		client: clientProperties.data?.properties || {},
		// clientRow: clientRowQuery.data || {},
		contact: contactProperties.data?.properties || {},
		deals: [], // unused for now
		claims: [], // unused for now
		notes: engagementsQuery.data?.notes || [],
		tasks: engagementsQuery.data?.tasks || [],
		calls: engagementsQuery.data?.calls || [],
		emails: engagementsQuery.data?.emails || [],
		msgFiles: [],
		activities: [
			// ...(contactProperties.data ? [{
			// 	activityType: 'Contact created',
			// 	data: contactProperties.data?.properties,
			// 	createdDate: new Date(contactProperties.data?.createdAt).toISOString(),
			// }] : []),
			// ...(clientProperties.data ? [{
			// 	activityType: 'Client created',
			// 	data: clientProperties.data?.properties,
			// 	createdDate: new Date(clientProperties.data?.createdAt).toISOString(),
			// }] : [])
		],
		quotes: [],
		owner: ownerProperties.data || {},
		loading: {
			// deals: dealsProperties.isLoading || claimsProperties.isLoading,
			client: clientProperties.isLoading,
			contact: contactProperties.isLoading,
			owner: ownerProperties.isLoading,
		},
	};

	useEffect(() => {
		console.log('📋 ~ CONTACT CONTEXT VALUE', contextValue);
	}, [contextValue]);
	
	return (
	// Provide the context variables to the children components.
		<ContactContext.Provider value={contextValue}>
			{isError ? (
				<Box>
					<Typography textAlign="center">
                    There was an issue loading this contact. Please try again.
					</Typography>
				</Box>
			) : (
				children
			)}
		</ContactContext.Provider>
	);
};

export default ContactContext;