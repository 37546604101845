/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import { TextField, Skeleton, Typography, Stack, IconButton, Box, Button } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { dealsApi, useUpdateDealPropertiesMutation, useUpdateDealRowsMutation } from '@/features/deals/dealsApi';
import { ticketsApi, useUpdateTicketPropertiesMutation } from '@/features/claims/ticketsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSharepoint } from '@/hooks/useSharepoint';
import { clientsApi, useCheckClientExistsMutation, useUpdateClientMutation } from '@/features/clients/clientsApi';
import { setEditing, setSubmited } from '@/features/select/nameChangeToggleSlice';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { folioBlue, specialChars } from '@/utils/constants';
import DoneIcon from '@mui/icons-material/Done';
import { addToCache } from '@/features/sharepoint/sharepointSlice';

export const DealNameUpdateField = ({ title }) => {
	const { deal, objectType, loading, dealRow, client } = determineContext();
	const isDeal = objectType === 'deal';
	const loadingDeal = loading?.deal;
	const dispatch = useDispatch();

	const [newName, setNewName] = useState(deal?.dealname ?? deal?.subject ?? client?.name);
	const [displayClientExits, setDisplayClientExists] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userData = useGetUserDetailsQuery({ email }, { skip: !email });
	const ownerId = useMemo(() => userData?.data.hubspotId, [userData?.data.hubspotId]);

	const [updateDealProperties, { isLoading: isUpdatingDeal }] = useUpdateDealPropertiesMutation();
	const [updateDealRows, { isLoading: isUpdatingDealRows }] = useUpdateDealRowsMutation();
	const [updateTicketProperties, { isLoading: isUpdatingTicket }] = useUpdateTicketPropertiesMutation();
	const [updateClient, { isLoading: isUpdatingClient }] = useUpdateClientMutation();
	const [checkClientExists] = useCheckClientExistsMutation();

	const sharepointSite = userData?.data?.sharepointSite;
	const clientSiteSuffix = userData?.data?.clientSiteSuffix;
	const clientFolder = userData?.data?.clientFolder;
	const clientSite = userData?.data?.clientSite;

	// Used to invalidate the sharepoint folders
	const invalidateTags = () => {
		setTimeout(async () => {
			try {
				if (deal || objectType === 'ticket') {
					dispatch(dealsApi.util.invalidateTags([{ type: 'DEAL_ROW', id: deal.hs_object_id ?? deal.id }]));
				}
				if (client) {
					dispatch(clientsApi.util.invalidateTags([{ type: 'CLIENT_ROW', id: client.hs_object_id ?? client.id }]));
				}
			} catch (error) {
				console.error('Error invalidating API:', error);
			}
		}, 5000);
	};

	const { updateFolderName } = useSharepoint(
		sharepointSite,
		clientSiteSuffix,
		clientFolder,
		clientSite
	);

	const handleUpdate = async () => {
		const trimmedName = newName.trim();
	  
		if (title !== trimmedName && (objectType === 'deal' || objectType === 'ticket')) {
			try {
				await updateFolderName(dealRow.dealFolderPath, clientFolder, `${trimmedName} Deal ${deal.hs_object_id}`);
				const id = deal.hs_object_id;
				dispatch(addToCache({ type: 'deal', id }));
				const params = { [isDeal ? 'dealname' : 'subject']: trimmedName, [isDeal ? 'description' : 'content'] : trimmedName };
				if (isDeal) {
					await updateDealProperties({ dealId: deal.hs_object_id, body: params }).unwrap();
				} else {
					await updateTicketProperties({ ticketId: deal.hs_object_id, body: params }).unwrap();
				}
				invalidateTags();
			} catch (error) {
				console.error('Failed to update:', error);
			}
		} else if (title !== trimmedName && objectType === 'client') {
			try {
				const clientExistsResponse = await checkClientExists({ ownerId, trimmedName });
				if (clientExistsResponse.data === false) {
					const clientId = client.hs_object_id;
					await updateDealRows({ clientId, trimmedName });
					const properties = { name: trimmedName };
					await updateClient({ clientId: client.hs_object_id, properties });
					await updateFolderName(client.name, clientFolder, trimmedName);
					invalidateTags();
					setDisplayClientExists(false);
				} else if (clientExistsResponse.data === true) {
					setDisplayClientExists(true);
				}
			} catch (error) {
				console.error('Failed to update client:', error);
			}
		}
	};

	// useEffect(() => {
	// 	if (!isEditing && newName !== null && newName.length > 0) {
	// 		handleUpdate();
	// 	}
	// }, [isEditing]);

	const isLoading = isUpdatingDeal || isUpdatingDealRows || isUpdatingTicket || isUpdatingClient || loadingDeal;

	const handleSaveClick = () => {
		handleUpdate();
		setIsEditing(false);
	};

	const handleCancelClick = () => {
		setNewName(deal?.dealname ?? deal?.subject ?? client?.name);
		setIsEditing(false);
	};

	return (
		<Stack direction="row" alignItems="center" spacing={1} width="100%">
			{isLoading ? (
				<Skeleton animation="wave" width="100%" height="2em" />
			) : isEditing ? (
				<Stack direction="row" spacing={1} width="100%">
					<TextField
						variant="standard"
						className="minimal-input"
						value={newName}
						disabled={loadingDeal}
						onChange={(e) => setNewName(e.target.value)}
						fullWidth
						multiline
					/>
					
				</Stack>
			) : (
				<Stack direction="column" spacing={0.5} width="100%">
					{displayClientExits && (
						<Typography variant="caption" color="error">
							Client already exists under your brokerage.
						</Typography>
					)}
					<Typography variant="deal_header">
						{title}
					</Typography>
				</Stack>
			)}
			{objectType !== 'client' && (
				<>
					<IconButton
						sx={{ color: isEditing ? null : folioBlue, marginLeft: 5 }}
						aria-label="edit"
						disabled={isLoading}
						edge="start"
						onClick={() => setIsEditing(!isEditing)}
					>
						{isEditing ? <CloseIcon /> : <EditIcon />}
					</IconButton>
					{isEditing && (
						<IconButton 
							sx={{color: folioBlue}}
							onClick={handleSaveClick}
							disabled={loadingDeal}
						>
							<DoneIcon />
						</IconButton>
					)}
				</>
			)}

		</Stack>
	);
};
