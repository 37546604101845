import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
	TextField,
	InputAdornment,
	IconButton,
	CircularProgress,
	Box,
	Chip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { InstantSearch, Configure, useHits, useSearchBox } from 'react-instantsearch';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetClientsQuery } from '@/features/clients/clientsApi';
import { searchClient } from '@/utils/typesense';
import { 
	setSearchText,
	setSearchValue,
	setOpen,
	reset,
	setRows,
} from '@/features/select/clientSelectSlice';
import CheckIcon from '@mui/icons-material/Check';

export const ClientSelect = ({ clientId, clientName, setClient, disabled }) => {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const {
		limit,
		orderBy,
		order,
		open,
		rows,
		searchText,
		searchValue,
		// resetKey,
	} = useSelector((state) => state.clientSelect);

	const [hasSearched, setHasSearched] = useState(false);

	const { data: userDetails } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const hubspotId = userDetails?.hubspotId;

	const clientProperties = useGetClientsQuery({
		hubspotId,
		searchValue,
		limit,
		orderBy,
		order,
		groupView: true,
	},
	{
		skip: !hubspotId || !open || searchValue.length === 0,
	}
	);

	const loading = open && rows.length === 0 && (clientProperties.isFetching);

	useEffect(() => {
		if (clientName) {
			dispatch(setSearchText(clientName));
			dispatch(setSearchValue(clientName));
		} else {
			dispatch(reset());
		}
	}, []);

	useEffect(() => {
		if (clientProperties.data) {
			dispatch(setRows(clientProperties.data.data));
		}
	}, [clientProperties.data]);

	const handleReset = () => {
		setClient(null);
		setHasSearched(false);
		dispatch(reset());
		setTimeout(() => dispatch(setOpen(true)), 0);
	};

	const handleSearch = () => {
		if (searchText != searchValue) {
			setHasSearched(true);
			dispatch(setRows([]));
			dispatch(setSearchValue(searchText));
		}
	};

	console.log('client id: ', clientId);
	console.log('client id: ', clientName);
	

	return <Autocomplete
		// key={resetKey}
		id="client-select"
		loading={loading}
		loadingText="Loading clients..."
		options={rows}
		open={open}
		disabled={disabled}
		onOpen={() => dispatch(setOpen(true))}
		onClose={() => {
			dispatch(setOpen(false));
		}}
		noOptionsText={hasSearched ? 'No clients found' : 'Press enter to search'}
		filterOptions={(x) => x}
		renderOption={(props, option) => (
			<Box 
				display={'flex'}
				sx={{ alignItems: 'center', justifyContent: 'space-between' }}
				{...props} 
				key={`client-select-${option.id}`}
			>
				<Box flexGrow={1} textAlign="left">
					{option.name}
				</Box>
				{option.id === clientId && <CheckIcon sx={{ fontSize: '18px' }}/>}
			</Box>
		)}
		inputValue={searchText}
		getOptionLabel={(option) => option.name}
		onInputChange={(_, value, reason) => { 
			if (reason != 'reset') {
				dispatch(setSearchText(value));
			}
		}}
		renderInput={(params) => (
			<TextField
				placeholder={'Search for client'}
				{...params}
				onKeyDown={e => {
					if (e.key === 'Enter') {
						e.preventDefault(); // To avoid any default behavior
						handleSearch(); // Trigger the search
					}
				}}
				InputProps={{ 
					...params.InputProps, 
					startAdornment: (<InputAdornment position="start">
						<IconButton 
							disabled={clientProperties.isFetching || disabled} 
							sx={{padding: '0'}} 
							onClick={handleSearch}
						>
							<SearchIcon/>
						</IconButton>
					</InputAdornment>),
					endAdornment: (<InputAdornment position="end">
						{clientProperties.isFetching ? <CircularProgress color="inherit" size={20} /> : null}
						{searchText && searchText.length > 0 && <IconButton 
							size="small" 
							disabled={clientProperties.isFetching || disabled} 
							sx={{padding: '0'}} 
							onClick={handleReset}
						>
							<ClearIcon fontSize='20px'/>
						</IconButton>}
					</InputAdornment>)
				}}
			/>
		)}
		isOptionEqualToValue={(option, value) => 
			option.id === value.id
		}
		onChange={(_, value) => {
			setClient(value);
			dispatch(setSearchText(value?.name ?? ''));
		}}
	/>;
};

const AutocompleteInput = ({ 
	query,
	refine,
	clear,
	hits,
	isSearching,
	setClient, 
	clientId, 
	disabled,
	showContact
}) => {
	const [open, setOpen] = useState(false);
	const [selectedContacts, setSelectedContacts] = useState([]);

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleSearch = () => {
		if (query.length > 0) {
			refine();
			if (showContact) {
				if (isValidEmail(query)) {
					const newContact = {
						id: query,
						contactName: query,
						contactEmail: query
					};
					console.log('is valid email: ', isValidEmail(query));
					const newSelectedContacts = [...selectedContacts, newContact];
					setSelectedContacts(newSelectedContacts);
					setClient(newSelectedContacts);
				}
			}
		}
	};

	const handleSelectedContactDelete = (index) => {
		const updatedContacts = [...selectedContacts];
		updatedContacts.splice(index, 1);
		setSelectedContacts(updatedContacts);
		setClient(updatedContacts);
	};

	const handleReset = () => {
		setClient(null);
		setOpen(false);
		clear();
		// setSelectedContacts([]);
	};

	const selection = useMemo(() => {
		return showContact ? selectedContacts : hits.find(hit => hit.id === clientId);
	}, [showContact, selectedContacts, hits, clientId]);
    
	return (
		<>
			{selectedContacts.length > 0  && (<Box mt={2}>
				{selectedContacts.map((contact, index) => (
					<Chip
						key={index}
						label={contact.contactName}
						onDelete={() => handleSelectedContactDelete(index)} 
						variant="contained"
						style={{ margin: '4px' }}
					/>
				))}
			</Box> )}
			<Autocomplete
				id="client-select"
				multiple={showContact}  
				loading={isSearching}
				loadingText={showContact ? 'Loading contacts...' :'Loading clients...'}
				options={query.length > 0 ? hits : []}
				open={open}
				disabled={disabled}
				noOptionsText={query.length > 0 ? showContact ?  'No contacts found' :  'No clients found' : 'Type to search'}
				filterOptions={(x) => x}
				renderOption={(props, option) => (
					<Box
						display={'flex'}
						sx={{ alignItems: 'center', justifyContent: 'space-between' }}
						{...props}
						key={`client-select-${option.id}`}
					>
						<Box flexGrow={1} textAlign="left">
							{showContact ? option.contactName : option.name}
						</Box>
						{option.objectID === clientId && <CheckIcon sx={{ fontSize: '18px' }} />}
					</Box>
				)}
				inputValue={query}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				getOptionLabel={(option) => showContact ? option.contactName : option.name}
				onInputChange={(_, value, reason) => { 
					if (reason !== 'reset') {
						refine(value);
					}
				}}

				
				renderInput={(params) => (
					<TextField
						placeholder={showContact ? 'Search for existing contacts or enter emails' : clientId ? hits.find(hit => hit.id === clientId)?.name :  'Search for client'}
						{...params}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.preventDefault();
								handleSearch();
							}
						}}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									<IconButton
										disabled={isSearching || disabled}
										sx={{ padding: '0' }}
										onClick={handleSearch}
									>
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position="end">
									{isSearching ? <CircularProgress color="inherit" size={20} /> : null}
									{query.length > 0 && (
										<IconButton
											size="small"
											disabled={isSearching || disabled}
											sx={{ padding: '0' }}
											onClick={handleReset}
										>
											<ClearIcon fontSize="20px" />
										</IconButton>
									)}
								</InputAdornment>
							),
						}}
					/>
				)}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(_, value) => {
					if (showContact) {
						setSelectedContacts(value);
						setClient(value);
					} else {
						setClient(value);
						refine(value?.name ?? '');
					}
				}}
				value={selection}
			/>
		</>
	);
};

export const InstantClientSelect = ({ clientId, clientName, setClient, disabled, showContact }) => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	
	const userQuery = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const brokerGroupId = userQuery.data?.brokerId;
	const brokerGroupIds = userQuery.data?.brokerGroupArray ?? [];


	const clientNameFilter = clientName ? `name: "${clientName}"` : '';
	const filterSchema = useMemo(() => {
		const schema = {
			filters: `brokerGroupId: [${brokerGroupIds.join(',')}]${clientNameFilter ? ` && ${clientNameFilter}` : ''}`,
		};
		console.log('Filter schema', schema);
		return schema;
	}, [brokerGroupIds, clientName]);
	

	return (
		<InstantSearch searchClient={searchClient} indexName="clients">
			<Configure hitsPerPage={15} {...filterSchema} />
			<InstantClientSelectWrapper 
				setClient={setClient} 
				clientId={clientId} 
				disabled={disabled || userQuery.isLoading}
				clientName={clientName}
				showContact={showContact}
			/>
		</InstantSearch>
	);
};

const InstantClientSelectWrapper = ({setClient, clientId, disabled, clientName, showContact, ...props}) => {
	const { items } = useHits(props);
	const updatedHits = useMemo(() => items.map(hit => ({...hit, id: hit.hubspotId ?? hit.id, docId: hit.id })));
	const searchBoxApi = useSearchBox(props);

	return <AutocompleteInput 
		hits={updatedHits} 
		setClient={setClient}
		clientId={clientId}
		disabled={disabled}
		clientName={clientName}
		showContact={showContact}
		{...searchBoxApi} 
	/>;
};