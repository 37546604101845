import React from 'react';
import { Box, Grid } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { claimHeaders } from '@/components/ClaimDetailsEntry/claimDetailData';
import { ClaimDetailField } from '@/components/ClaimDetailsEntry/claimDetailEntry';

export const ClaimDetailsTab = () => {
	// DealContext
	const { deal, policies, dealRow, claim } = determineContext();

	return (
		<Box sx={{ p: 3 }}>
			<Grid container spacing={4}>
				{Object.entries(claimHeaders).map(([key, header]) => {
					if (key === 'Description') {
						return (
							<Grid item xs={12} key={`detail-entry-${key}`} sx={{ padding: '1em 0 0.5em' }}>
								<ClaimDetailField title={header} header={key} />
							</Grid>
						);
					}
					return (
						<Grid item xs={6} key={`detail-entry-${key}`} sx={{ padding: '1em 0 0.5em' }}>
							<ClaimDetailField title={header} header={key} />
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
};
