import React from 'react';
import { Typography } from '@mui/material';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';

export const ResultsTable = ({
	rows,
	updateRows,
	setRowSelectionModel,
	rowSelectionModel,
	disabled,
}) => {
	const columns = [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			renderHeader: () => <></>,
			renderCell: (params) =>
				params.row.insurer === '' ? (
					<></>
				) : (
					GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)
				),
		},
		{
			field: 'insurer',
			headerName: 'Insurer',
			width: 300,
			editable: !disabled,
			sortable: false,
			renderCell: (params) =>
				params.row.insurer === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter name of insurer</Typography>
				) : (
					params.row.insurer
				),
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			width: 150,
			editable: false,
			sortable: false,
		},
		{
			field: 'excess',
			headerName: 'Excess ($)',
			width: 150,
			editable: !disabled,
			sortable: false,
			renderCell: (params) =>
				params.row.insurer !== '' && params.row.excess === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter excess</Typography>
				) : (
					params.row.excess
				),
		},
		{
			field: 'premium',
			headerName: 'Premium (incl.GST) ($)',
			width: 200,
			editable: !disabled,
			sortable: false,
			renderCell: (params) =>
				params.row.insurer !== '' && params.row.premium === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter premium</Typography>
				) : (
					params.row.premium
				),
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
			}}
			width='100%'
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => {
				if (disabled) {
					return false;
				}
				if (params.field === 'insurer') {
					return true;
				}
				return (params.row.insurer ?? '').length > 0;
			}}
			isRowSelectable={(params) => {
				const sumInsured = parseInt((params.row?.sumInsured ?? '').toString());
				const excess = parseInt((params.row?.excess ?? '').toString());
				const premium = parseInt((params.row?.premium ?? '').toString());
				return (
					!isNaN(sumInsured) && sumInsured > 0 &&
					!isNaN(excess) && excess > 0 &&
					!isNaN(premium) && premium > 0 &&
					(params.row?.insurer ?? '').toString().length > 0
				);
			}}
            getRowId={(row) => `${row.insurer}`}
			checkboxSelection
			onRowSelectionModelChange={setRowSelectionModel}
			rowSelectionModel={rowSelectionModel}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
