import React, { useEffect, useMemo } from 'react';
import { Card, Typography } from '@mui/material';
import { folioBlue, formatDate } from '@/utils/constants';

export const CreatedObjectCard = ({ activity }) => {
	
	const completedBy = activity?.completedBy?.name ?? 'Unknown';
	const owner = activity?.owner?.name ?? 'Unknown';

	useEffect(() => {
		console.log('Activity', activity);
	}, [activity]);

	const date = useMemo(
		() => (activity ? new Date(activity.createdDate._seconds * 1000) : new Date()),
		[activity?.createdDate?._seconds]
	);

	const activityText =
		completedBy !== owner ? `${completedBy} and assigned to ${owner}` : `${completedBy}`;

	return (
		<Card style={{ marginBottom: '12px', paddingLeft: '12px' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant="task_body" style={{ padding: '10px' }}>
					<span style={{ fontWeight: 'bold' }}>Created by</span> {activityText}{' '}
					<span style={{ margin: '0 0.5em' }}> | </span>
					<span style={{ color: folioBlue }}> {formatDate(date, true)} </span>
				</Typography>
			</div>
		</Card>
	);
};