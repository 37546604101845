import React, { useMemo } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	Typography,
	FormControl,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
	InputAdornment,
	RadioGroup,
	FormControlLabel,
	Radio,
	Select,
	MenuItem,
	Grid,
	Tooltip,
	Link,
	Accordion,
	AccordionSummary,
	FormGroup,
	Checkbox,
} from '@mui/material';
import {
	ADDRESS,
	ADVISER_FEE,
	CITY,
	CLIENT_ID,
	CLIENT_NAME,
	COUNTRY,
	DEAL_NAME,
	EMAIL,
	FILES,
	FIRST_NAME,
	INDUSTRY,
	IS_EXISTING,
	LAST_NAME,
	OTHER_INFO,
	PHONE,
	PRIORITY,
	RENEWAL_DATE,
	validationSchema,
	WEBSITE,
	OWNER,
	COMPLETED_BY,
	SUBURB,
	POSTCODE,
	CLIENT_GOALS,
	CLIENT_CATEGORY,
	CLIENT_CATEGORY_LABELS,
	SCOPE_OF_ADVICE_LABELS,
	SCOPE_OF_ADVICE,
} from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { ExpandMore } from '@mui/icons-material';
import { determineContext } from '@/hooks/determineContext';
import { folioBlue } from '@/utils/constants';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { useAddDocumentPropertyToDealMutation } from '@/features/deals/dealsApi';

export const ClientCategory = () => {
	const { dealRow } = determineContext();
	const clientCategory = useMemo(() => dealRow[CLIENT_CATEGORY] ?? [], [dealRow]);

	const [updateDeal, { isLoading }] = useAddDocumentPropertyToDealMutation();

	const onChange = async (newValues) => {
		await updateDeal({
			dealId: dealRow.dealId,
			docId: dealRow.id,
			property: CLIENT_CATEGORY,
			value: newValues,
		}).unwrap();
	};

	return (
	// <Accordion
	// 	elevation={0}
	// 	disableGutters
	// 	square
	// 	sx={{
	// 		backgroundColor: 'transparent',
	// 		'&:not(:last-child)': {
	// 			borderBottom: 0,
	// 		},
	// 		'&::before': {
	// 			display: 'none',
	// 		},
	// 	}}
	// >
	// 	<AccordionSummary
	// 		expandIcon={<ExpandMore sx={{ color: folioBlue }} />}
	// 		sx={{
	// 			// flexDirection: 'row-reverse',
	// 			padding: 0,
	// 		}}
	// 	>
	// 		<Typography variant="form_label">Client category</Typography>
	// 	</AccordionSummary>
			
		<FormGroup row>
			<Typography variant="form_label">Client category</Typography>
			<Grid container>
				{Object.entries(CLIENT_CATEGORY_LABELS).map((entry) => {
					const [value, label] = entry;
					return (
						<Grid item xs={12} sm={6} key={`client-category-${value}`}>
							<FormControlLabel
								size="small"
								control={
									<ClientCheckbox
										value={value}
										valuesArray={clientCategory}
										setValues={onChange}
										disabled={isLoading}
									/>
								}
								label={label}
								sx={{
									'& .MuiTypography-root': {
										fontSize: '12px'
									},
									'& .MuiButtonBase-root': {
										fontSize: '18px'
									},
								}}
							/>
						</Grid>
					);
				})}
			</Grid>
		</FormGroup>
		// </Accordion>
	);
};
