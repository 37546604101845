import React, { useEffect, useState } from 'react';
import { TextField, Skeleton, Box, FormHelperText, Stack } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { theme } from '@/app/theme';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '@/utils/firebase';
import { useDispatch } from 'react-redux';
import { ticketsApi } from '@/features/claims/ticketsApi';
import { dealsApi } from '@/features/deals/dealsApi';

export const ClaimNumberField = ({minimal=true}) => {

	const dispatch = useDispatch();

	const MAX_LENGTH = 50;

	const { deal, dealRow, objectType, loading } = determineContext();
	const loadingDeal = loading?.deal;

	const [claimNumber, setClaimNumber] = useState(dealRow?.claimNumber ?? '');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setClaimNumber(dealRow?.claimNumber ?? '');
	}, [dealRow, loadingDeal]);

	const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const updateClaimNumber = async () => {
		// if (claimNumber.trim().length > 0) {
		setIsLoading(true);
		try {
			const docRef = doc(firestore, 'deals', dealRow.id);
			await updateDoc(docRef, { claimNumber }).then((res) =>
				console.log(`Successfully updated doc ${dealRow.id}`)
			);
			setTimeout(async () => {
				try {
					const uppercaseType = (objectType ?? '').toUpperCase();
					dispatch(ticketsApi.util.invalidateTags([
						{ type: `${uppercaseType}S`, id: 'LIST' },
						{ type: uppercaseType, id: deal.hs_object_id ?? deal.id },
					]));
					dispatch(dealsApi.util.invalidateTags([
						{ type: 'DEAL_ROW', id: deal.hs_object_id ?? deal.id },
					]));
				} catch (error) {
					console.error('Error invalidating API:', error);
				}
			}, 1000);
		} catch (error) {
			console.log('Error updating Firebase document', deal, error);
		} finally {
			setIsLoading(false);
		}
		// }
	};

	return (
		<>
			{/* <Typography sx={minimalLabelTheme}>Claim Number</Typography> */}
			{loadingDeal ? (
				<Skeleton animation="wave" width="55%" height="2em" />
			) : (
				<Stack>
					<TextField
						fullWidth
						disabled={isLoading}
						variant= {minimal === true ? "standard" : "outlined"}  
						sx={minimal === true ? minimalInputTheme : {}} 
						className={minimal === true ? "minimal-input" : null}
						value={claimNumber}
						onChange={(event) => setClaimNumber(event.target.value)}
						onBlur={updateClaimNumber}
						inputProps={{ maxLength: MAX_LENGTH }}
					/>
					<Box>
						<FormHelperText sx={{ 
							textAlign: 'right',
							mr: '14px',
							...(claimNumber.length == MAX_LENGTH && { fontWeight: 'bold' }),
						}}>
							{`${claimNumber.length}/${MAX_LENGTH}`}
						</FormHelperText>
					</Box>
				</Stack>
			)}
		</>
	);
};
