import React, { useMemo } from 'react';
import { Typography, Skeleton, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isAfter, isBefore, isValid, parseISO } from 'date-fns';
import { determineContext } from '@/hooks/determineContext';
import { theme } from '@/app/theme';
import { useUpdateDealPropertiesMutation } from '@/features/deals/dealsApi';
import {
	useUpdateInsightClaimMutation,
	useUpdateTicketPropertiesMutation,
} from '@/features/claims/ticketsApi';

const minDate = new Date();

export const DealCardDateSelect = ({
	title,
	dealKey,
	disableFuture,
	disablePast,
	minimal = true,
}) => {
	const { deal, objectType, loading, claim } = determineContext();
	const loadingDeal = loading?.deal;

	const selectedDate = useMemo(() => {
		if (dealKey === 'ReportedDate') {
			return claim[dealKey]
				? parseISO(claim[dealKey])
				: deal[dealKey]
					? parseISO(deal[dealKey])
					: null;
		}
		return deal[dealKey] ? parseISO(deal[dealKey]) : null;
	}, [dealKey, claim, deal]);

	const isDeal = objectType === 'deal';

	const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const [updateDealProperties, { isLoading }] = isDeal
		? useUpdateDealPropertiesMutation()
		: useUpdateTicketPropertiesMutation();

	const [updateInsightClaim] = useUpdateInsightClaimMutation();

	const handleDateChange = async (value) => {
		const date = value;
		// Get the local time zone offset in minutes
		const timezoneOffset = date.getTimezoneOffset();
		// Adjust the date to UTC by adding the time zone offset
		date.setMinutes(date.getMinutes() - timezoneOffset);
		// Set the time to midnight UTC
		const timestamp = date.setUTCHours(0, 0, 0, 0);

		try {
			const params = {
				[dealKey]: timestamp,
			};

			console.log('here are the properties ', params);

			//Date of reported doesn't get stored in hubspot or firebase?
			if (dealKey !== 'ReportedDate') {
				await updateDealProperties({
					[isDeal ? 'dealId' : 'ticketId']: deal.hs_object_id,
					body: params,
				}).unwrap();
			}
			//only update insight if date of loss or reported date
			if (dealKey === 'date_of_loss' || dealKey === 'ReportedDate') {
				const date = new Date(timestamp);
				const formattedDate = date.toISOString().slice(0, 10);

				await updateInsightClaim({
					claimId: claim.Id,
					properties: {
						...(dealKey === 'ReportedDate' && { ReportedDate: formattedDate }),
						...(dealKey === 'LossDate' && { LossDate: formattedDate }),
					},
				}).unwrap();
			}
		} catch (error) {
			console.error('Error updating deal properties:', error);
		}
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Typography sx={minimalLabelTheme}>{title}</Typography>
			{loadingDeal ? (
				<Skeleton animation='wave' width='100%' height='2em' />
			) : (
				<LocalizationProvider dateAdapter={AdapterDateFns} style={{ width: '100%' }}>
					<DatePicker
						name={dealKey}
						className={minimal === true ? 'minimal-input' : null}
						disablePast={disablePast}
						disableFuture={disableFuture}
						disabled={isLoading}
						format='dd/MM/yyyy'
						value={selectedDate}
						slotProps={{
							textField: {
								size: minimal ? 'small' : 'medium',
								sx: minimal ? minimalInputTheme : {},
								variant: minimal ? 'standard' : 'outlined',
								fullWidth: true,
							},
						}}
						sx={{
							...(minimal ? minimalInputTheme : {}),
							width: '100%',
						}}
						onChange={(value) => {
							let newDate = value;
							if (disablePast) {
								newDate =
									!isValid(value) || isAfter(minDate, value) ? minDate : value;
							} else if (disableFuture) {
								newDate =
									!isValid(value) || isBefore(minDate, value) ? minDate : value;
							}
							handleDateChange(newDate);
						}}
					/>
				</LocalizationProvider>
			)}
		</Box>
	);
};
