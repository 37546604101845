import React, { useMemo } from 'react';
import { Box, Stack, Typography, Grid } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { ComplianceTaskCheckbox } from '../cards/DealCardValues/ComplianceTaskCheckbox';
import { theme } from '@/app/theme';
import { closedPipelineStages } from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';

export const ComplianceTasksDisplay = () => {
	const { complianceTasks, deal } = determineContext();


	const pipelineQuery = useGetPipelinePropertiesQuery(deal.pipeline, {
		skip: !deal.pipeline,
	});
	const stages = pipelineQuery?.data?.stages ?? {};

	const stageOrderMap = useMemo(() =>
		Object.entries(stages).reduce((acc, [stageId, stageData]) => {
			acc[stageId] = { order: stageData.order, label: stageData.label };
			return acc;
		}, {}),
	[stages]
	);

	const dealStageId = useMemo(() => 
		deal.dealstage ?? deal.hs_pipeline_stage,
	[deal]
	);

	const currentStageTasks = useMemo(() => 
		complianceTasks.filter((t) => t.stageId === dealStageId),
	[complianceTasks, dealStageId]
	);

	const inTermDeal = useMemo(() => {
		return closedPipelineStages.includes(dealStageId);
	}, [dealStageId]);


	const groupedComplianceTasks = useMemo(() => {
		const grouped = complianceTasks.reduce((acc, task) => {
			const stageId = task.questionData?.stages[0];
			if (stageId) {
				if (!acc[stageId]) {
					acc[stageId] = [];
				}
				acc[stageId].push(task);
			}
			return acc;
		}, {});

		Object.keys(grouped).forEach(stageId => {
			grouped[stageId].sort((a, b) => a.questionData.order - b.questionData.order);
		});

		return grouped;
	}, [complianceTasks]);

	const sortedStages = useMemo(() => 
		Object.entries(stageOrderMap)
			.sort(([, a], [, b]) => a.order - b.order)
			.map(([stageId, { label }]) => ({ stageId, label })),
	[stageOrderMap]
	);


	const sortedComplianceTasks = useMemo(() => {
		const tasksToDisplay = inTermDeal ? complianceTasks : currentStageTasks;
		console.log('tasks to display: ', tasksToDisplay);
		return [...tasksToDisplay].sort((a, b) => a?.questionData?.order - b?.questionData?.order);
	}, [inTermDeal, complianceTasks, currentStageTasks]);

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	return (
		<Box width={'100%'}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
				<Typography sx={minimalLabelTheme}>
					Steps to complete
				</Typography>
			</Stack>

			<Stack direction="column" spacing={1} sx={{ width: '100%' }}>
				{inTermDeal ? (
					<Stack direction="column" spacing={2}>
						{sortedStages.map(({ stageId, label }) => (
							<React.Fragment key={stageId}>
								<Typography variant='subtitle2'>{label}</Typography>
								{groupedComplianceTasks[stageId]?.map((task) => {
									return (
										<Box key={task.documentId} sx={{ display: 'flex', alignItems: 'center' }}>
											<ComplianceTaskCheckbox task={task} />
										</Box>
									);
								})}
							</React.Fragment>
						))}
					</Stack>
				) : (
					sortedComplianceTasks?.map((task) => (
						<Box key={task.documentId} sx={{ display: 'flex', alignItems: 'center' }}>
							<ComplianceTaskCheckbox task={task} />
						</Box>
					))
				)}
			</Stack>
		</Box>
	);
};
