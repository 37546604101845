import React, {  } from 'react';
import { Box, Typography } from '@mui/material';
import { NoteAccordion } from '@/components/cards/NoteCard';
import { AddNoteDialog } from '@/components/dialogs/AddNoteDialog';
import { determineContext } from '@/hooks/determineContext';

export const NotesTab = () => {
	
	const { notes } = determineContext();

	return (
		<Box px={'0.5em'}>
			{notes.length > 0 ?
				<>
					{notes.map((note, index) => (
						<NoteAccordion key={`note-tab-note-${index}`} note={note} />
					))}
				</> :
				<Typography textAlign={'center'}>No notes created yet.</Typography>
			}
			<AddNoteDialog />
		</Box>
	);
};