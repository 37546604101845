import React, { createContext, useMemo, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { getComparator, stableSort } from '@/components/table/functions';

const initialState = {
	pipelineId: null,
	pipelineName: '',
	stages: [],
	isClaims: false,
	isEndorsement: false,
	dealStages: {},
	refreshStatus: {},
	updateRefreshStatus: (stageId, status) => {},
	updateDealStages: (stageId, deals) => {},
	shiftDeal: (existingStage, newStage, existingId, newId, deal) => {}
};

const KanbanContext = createContext(initialState);

export const KanbanContextProvider = ({ pipelineId, children }) => {
	
	const { 
		data: pipelineData, isError
	} = useGetPipelinePropertiesQuery(pipelineId, {skip: !pipelineId});
	const pipelineName = pipelineData?.pipelines?.label;

	const isClaims = pipelineId == process.env.REACT_APP_PIPELINE_CLAIMS;
	const isEndorsement = pipelineId == process.env.REACT_APP_PIPELINE_ENDORSEMENTS;

	const stages = useMemo(() => {
		const list =  pipelineData?.pipelines?.stages ?? [];
		return stableSort(list, getComparator('asc', 'displayOrder'));
	}, [pipelineData?.pipelines?.stages]);

	const [dealStages, setDealStages] = useState({});
	const [refreshStatus, setRefreshStatus] = useState({});

	const updateDealStages = (stageId, deals) => {
		setDealStages(prev => {
			return {
				...prev,
				[stageId]: deals,
			};
		});
	};

	const shiftDeal = (existingStage, newStage, existingId, newId, deal) => {
		if (newStage === existingStage) {
			// moving deal inside the same column
			const column = dealStages[existingStage];
			column.splice(existingId, 1);
			column.splice(newId ?? column.length + 1, 0, deal);
			updateDealStages(existingId, column);
		} else {
			// moving deal across columns
			const sourceColumn = dealStages[existingStage] ?? [];
			const destinationColumn = dealStages[newStage] ?? [];
			sourceColumn.splice(existingId, 1);
			destinationColumn.splice(
				newId ?? destinationColumn.length + 1,
				0,
				deal
			);
			// setDealStages(prev => ({ ...prev, [deal.id]: { [newStage]: deal } }));
			updateDealStages(existingStage, []);
			updateDealStages(newStage, []);

			setRefreshStatus(prev => ({...prev, [existingStage]: true, [newStage]: true}));
			// setDealStages(prev => {
			// 	return {
			// 		...prev,
			// 		[existingStage]: sourceColumn,
			// 		[newStage]: destinationColumn,
			// 	};
			// });
		}
	};
    
	const updateRefreshStatus = (stageId, status) => {
		setRefreshStatus(prev => ({...prev, [stageId]: status === true}));
	};

	const contextValue = {
		pipelineId,
		pipelineName,
		stages,
		isClaims,
		isEndorsement,
		dealStages,
		refreshStatus,
		updateRefreshStatus,
		updateDealStages,
		shiftDeal,
	};

	return (
		<KanbanContext.Provider value={contextValue}>
			{isError ? (
				<Box>
					<Typography textAlign="center">
						There was an issue loading this pipeline. Please try again.
					</Typography>
				</Box>
			) : (
				children
			)}
		</KanbanContext.Provider>
	);
};

export default KanbanContext;