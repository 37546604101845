import React from 'react';
import { Box, Stack, Skeleton } from '@mui/material';

export const LoadingTaskCard = () => {
	
	return (
		<Stack width='100%' direction='row' alignItems='center' padding="0.5em 0">
			<Box margin='1em'>
				<Skeleton animation="wave" variant="circular" width={'2em'} height={'2em'} />
			</Box>
			<Stack width='100%'>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<Skeleton animation="wave" height="18px" width="75%" />
				</div>
				<Skeleton animation="wave" height="15px" width="100%" />
				<Skeleton animation="wave" height="15px" width="66%" />
			</Stack>
		</Stack>
	);
};