import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';

export const GoogleMaps = () => {
	const { client } = determineContext();


	const clientAddress = client?.address;

	return (
		<Box>
			<Box>
				<iframe
					title="Google Maps"
					style={{ borderRadius: '10px', border: 'none' }}
					width="100%"
					height="80%"
					loading="lazy"
					allowFullScreen
					referrerPolicy="no-referrer-when-downgrade"
					src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}&q=${clientAddress}`}
				></iframe>
			</Box>
		</Box>
	);
};
