import React from 'react';
import { CircularProgress, InputAdornment, IconButton } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export const ResetButton = ({isLoading, onMouseDown}) => {
	return <InputAdornment position="end" >
		{isLoading ? <CircularProgress size="1em" /> :
			<IconButton onMouseDown={onMouseDown}>
				<RestartAltIcon style={{ fontSize: '1.25rem'}} />
			</IconButton>
		}
	</InputAdornment>;
};