/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import {
	TableCell,
	TableRow,
	Typography,
	Button,
	Stack,
	Checkbox,
	Avatar,
	Chip,
} from '@mui/material';
import { formatDate } from '@/utils/constants';

export const PolicyRow = ({ policy, handleCheckboxChange, hideCheckbox }) => {
	const showDisabled =
		policy?.associated ||
		(policy?.policyTransactions === null && (policy?.policyTransactions ?? []).length > 0);

	return (
		<TableRow
			key={`table-row-${policy.Id}`}
			sx={{
				...(showDisabled && { backgroundColor: '#E0E0E0' }),
			}}
		>
			{!hideCheckbox && (
				<TableCell size='small' padding='checkbox'>
					{showDisabled ? (
						<> </>
					) : (
						<Checkbox
							checked={policy.selected}
							onChange={(event) => handleCheckboxChange(event, policy.Id)}
							color='primary'
							inputProps={{
								'aria-label': 'select all desserts',
							}}
						/>
					)}
				</TableCell>
			)}
			<TableCell width={'15%'} sx={{ whiteSpace: 'nowrap' }}>
				{policy.PolicyNumber}
			</TableCell>
			<TableCell width={'20%'} sx={{ whiteSpace: 'nowrap' }}>
				{formatDate(policy?.ToDate)}
			</TableCell>
			<TableCell>{policy.Description ?? policy.BriefDescription}</TableCell>
			<TableCell>{policy.InsurerName}</TableCell>
		</TableRow>
	);
};
