import React from 'react';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import { Box } from '@mui/material';
import { ContactContextProvider } from '@/context/contactContext';
import ContactViewWrapper from '@/components/views/ContactsView/ContactViewWrapper';

const ViewContactPage = () => {
	return (
		<AuthenticatedPageLayout>
			<Box pb={3}>
				<ContactContextProvider>
					<ContactViewWrapper />
				</ContactContextProvider>
			</Box>
		</AuthenticatedPageLayout>
	);
};

export default ViewContactPage;