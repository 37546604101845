import * as Yup from 'yup';
import { CLIENT_NAME, EMAIL, FIRST_NAME, LAST_NAME, OTHER_INFO, PHONE } from '@/components/forms/CreateNewBusinessForm/validationSchema';

const validationSchema = Yup.object().shape({
	[FIRST_NAME]: Yup.string().required('First name is required.'),
	[LAST_NAME]: Yup.string().required('Last name is required.'),
	[PHONE]: Yup.string()
		.nullable()
		.notRequired()
		.test(
			'len', 
			'Phone number should be between 7 and 11 characters', 
			val => !val || (val.length >= 7 && val.length <= 11)
		),
	// [PHONE]: Yup.string().required('Phone number is required.')
	// 	.min(7, 'Phone number should be more than 7 characters')
	// 	.max(11, 'Phone number should be no more than 11 characters'),
	[EMAIL]: Yup.string()
		.email('Email address must be a valid email')
		.required('Email address is required.')
		.test('domain-length', 'Email address must be a valid email', value => {
			if (!value) return false;
			const parts = value.split('@');
			if (parts.length !== 2 || parts[1].length <= 2) {
				return false;
			}
			const domainParts = parts[1].split('.');
			if (domainParts.length < 2 || domainParts.length > 3) {
				return false;
			}
			return domainParts.every(part => part.length >= 2);
		}),
	[CLIENT_NAME]: Yup.string().nullable().notRequired(),
	[OTHER_INFO]: Yup.string().nullable().notRequired(),
});

export default validationSchema;