import React, { useMemo } from 'react';
import { Typography, Skeleton, Select, MenuItem, Stack, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { determineContext } from '@/hooks/determineContext';
import { theme } from '@/app/theme';
import { dealsApi, useUpdateDealPropertiesMutation } from '@/features/deals/dealsApi';
import { ticketsApi, useUpdateTicketPropertiesMutation } from '@/features/claims/ticketsApi';
import { setLoading } from '@/features/engagements/engagementsSlice';
import { engagementsApi } from '@/features/engagements/engagementsApi';

export const DealCardStageChange = ({ showLabel, title, minimal = true }) => {
	const dispatch = useDispatch();
	const { deal, objectType, loading, pipelines } = determineContext();
	const loadingDeal = loading?.deal;

	const isDeal = objectType === 'deal';

	const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const dealStage = useMemo(() => {
		const stageId = deal?.dealstage ?? deal?.hs_pipeline_stage;
		const stage = pipelines?.stages?.find((stage) => stage.id === stageId);
		return stage;
	}, [pipelines, deal?.hs_pipeline_stage, deal?.dealstage, pipelines?.stages]);

	const dealStageId = useMemo(() => dealStage?.id, [dealStage]);

	const isDisabled = useMemo(
		() =>
			dealStage?.metadata?.probability?.toString() === '1.0' ||
			dealStage?.metadata?.ticketState === 'CLOSED',
		[dealStage]
	);

	const [updateDealProperties, { isLoading }] = isDeal
		? useUpdateDealPropertiesMutation()
		: useUpdateTicketPropertiesMutation();

	const invalidateTags = async () => {
		dispatch(setLoading(true));
		setTimeout(async () => {
			try {
				const type = (objectType ?? '').toUpperCase();
				dispatch(
					(objectType == 'deal' ? dealsApi : ticketsApi).util.invalidateTags([
						{ type: `${type}S`, id: 'LIST' },
						{ type: `${type}S`, id: deal?.hs_pipeline_stage ?? deal?.dealstage },
						{ type: type, id: deal.hs_object_id ?? deal.id },
					])
				);
				dispatch(
					dealsApi.util.invalidateTags([
						{ type: 'DEAL_ROW', id: deal.hs_object_id ?? deal.id },
					])
				);
				dispatch(
					engagementsApi.util.invalidateTags([
						{ type: 'ENGAGEMENTS', id: deal.id },
						{ type: 'TASKS', id: 'LIST' },
						{ type: 'TASKS_COUNT', id: 'LIST' },
					])
				);
			} catch (error) {
				console.error('Error invalidating engagements API:', error);
			} finally {
				dispatch(setLoading(false));
			}
		}, 10000);
	};

	const handleDealStageChange = async (event) => {
		const selectedStageId = event.target.value;

		const params = {
			[isDeal ? 'dealstage' : 'hs_pipeline_stage']: selectedStageId,
		};

		const response = await updateDealProperties({
			[isDeal ? 'dealId' : 'ticketId']: deal.hs_object_id,
			body: params,
		}).unwrap();
		invalidateTags();
		console.log('👾 ~ transform update deal response', response);
	};

	// const handleMoveDealNextStage = async (e) => {
	// 	e.preventDefault();

	// 	try {
	// 		const currentStageIndex = pipelines?.stages.findIndex(
	// 			(stage) => stage.id === deal?.hs_pipeline_stage || stage.id === deal?.dealstage
	// 		);
	// 		if (
	// 			currentStageIndex !== -1 &&
	//     currentStageIndex < pipelines?.stages?.length - 1
	// 		) {
	// 			console.log('this is a valid stage');
	// 			const nextStageId =
	//       pipelines?.stages[currentStageIndex + 1].id;
	// 			console.log('here is the next stage id: ', nextStageId);

	// 			const params = {
	// 				[isDeal ? 'dealstage' : 'hs_pipeline_stage']: nextStageId,
	// 			};
	// 			const response = await updateDealProperties({
	// 				[isDeal ? 'dealId' : 'ticketId']: deal.hs_object_id,
	// 				body: params,
	// 			}).unwrap();
	// 			console.log('👾 ~ transform update ticket response', response);
	// 		} else {
	// 			console.log('invalid stage');
	// 		}
	// 	} catch (err) {
	// 		console.log('🙅 ~ Error moving ticket status', err);
	// 	}
	// };

	return (
		<Stack width={'100%'}>
			{showLabel != false && (
				<Typography sx={minimalLabelTheme}>{title ?? 'Deal Stage'}</Typography>
			)}
			{loadingDeal ? (
				<Skeleton animation='wave' width='100%' height='2em' />
			) : (
							
				<Select
					fullWidth
					value={dealStageId}
					onChange={handleDealStageChange}
					disabled={isLoading || isDisabled}
					variant={minimal ? 'standard' : 'outlined'}
					sx={minimal === true ? minimalInputTheme : {}}
				>
					{pipelines?.stages.map((stage, index) => {
						const itemDisabled =
							stage?.metadata?.probability?.toString() === '1.0' ||
							stage?.metadata?.ticketState === 'CLOSED';
						return (
							<MenuItem key={index} value={stage.id} disabled={itemDisabled}>	
								{stage.label}
							</MenuItem>
						);
					})}
				</Select>
			)}
		</Stack>
	);
};
