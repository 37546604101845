import React from 'react';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import { CreateEndorsementForm } from '@/components/forms/CreateEndorsementForm';

export const CreateEndorsementPage = () => {
	return (
		<AuthenticatedPageLayout>
			<CreateEndorsementForm />
		</AuthenticatedPageLayout>
	);
};