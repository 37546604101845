/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableFooter,
	TableRow,
	Typography,
	Paper,
	Button,
    Box,
	Divider,
	Grid,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { stableSort, getComparator } from '@/components/table/functions';
import { TablePaginationActions } from '@/components/table/TablePaginationActions';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import SearchField from '@/components/SearchField';
import { useGetUserTasksQuery } from '@/features/engagements/engagementsApi';
import { TaskRow } from './TaskRow';
import { AddTaskDialog } from '@/components/dialogs/AddTaskDialog';
import { rowLimits } from '@/components/table/data';
import { 
	changeRowsPerPage, 
	resetSearchFields, 
	setSearchText, 
	setSearchValue, 
	changePage,
	requestSort,
	resetAllPages,
	onOrderChange,
	setOrderBy,
	setOrder,
	setRows,
} from '@/features/table/tasksTableSlice';

export const tasksHeadCells = [
	// { id: 'id', numeric: false, label: null, sortable: false },
	{ id: 'hs_task_status', numeric: false, label: 'Status', align: 'left', sortable: false },
	{ id: 'hs_task_subject', numeric: false, label: 'Task name', align: 'left' },
	{ id: 'deal', numeric: false, label: 'Insured entity', align: 'left', sortable: false },
	{ id: 'hs_task_type', numeric: false, label: 'Type', align: 'left', sortable: true },
	{ id: 'deal_type', numeric: false, label: 'Deal type', align: 'left', sortable: false },
	{ id: 'hubspot_owner_id', numeric: false, label: 'Assignee', align: 'right', sortable: false },
	{ id: 'hs_timestamp', numeric: false, label: 'Due date', align: 'right' },
	// { id: 'hs_task_body', numeric: false, label: 'Description', align: 'left' },
];

export default function TaskTable({tab}) {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const {
		pageAfter,
		rowsPerPage,
		searchValue,
		searchText,
		orderBy,
		order,
		page,
		rows,
	} = useSelector((state) => state.taskTable);

	const userData = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const hubspotId = userData?.data?.hubspotId;

	const tasksQuery = useGetUserTasksQuery(
		{ hubspotId, 
		  searchValue, 
		  pageAfter, 
		  limit: rowsPerPage, 
		  property: orderBy, 
		  order, 
		  tabValue: tab 
		},
		{ skip: 
			!hubspotId
		}
	);

	const handleRequestSort = (event, property) => {
		dispatch(requestSort(property));
	};

	const handleChangePage = (event, newPage, lastPageAfter) => {
		dispatch(changePage({ newPage, lastPageAfter }));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(changeRowsPerPage(event.target.value));
	};

	const handleReset = () => {
		dispatch(resetSearchFields());
	};

	const handleSearch = () => {
		if (searchText != searchValue) {
			dispatch(resetAllPages());
			dispatch(setRows([]));
			dispatch(setSearchValue(searchText));
		}
	};

	useEffect(() => {
		dispatch(resetAllPages());
		dispatch(setOrderBy('hs_timestamp'));
		dispatch(setOrder('desc'));
	}, [tab]);

	useEffect(() => {
		dispatch(onOrderChange());
	}, [orderBy, order]);

	useEffect(() => {
		if (tasksQuery?.data) {
			console.log('Here is the tasks data before: ', tasksQuery?.data);
			const newRows = (tasksQuery?.data?.data ?? []).map((task) => {
				const dealData = task.deal?.properties;
				const companyData = task.deal?.companyInformation?.properties ?? task.ticket?.companyInformation?.properties ?? task.client?.properties;
				const contactData = task.contact?.properties;
				const deal = task.deal ? {
					...task.deal,
					insuredName: companyData?.name ?? '-',
					dealName: dealData.dealname,
					description: dealData.description,
				} : null;
				const ticket = task.ticket ? {
					...task.ticket,
					insuredName: companyData?.name ?? '-',
					dealName: task.ticket.properties?.subject,
					description: task.ticket.properties?.content,
				} : null;
				return {
					hs_task_status: task.properties.hs_task_status,
					hs_task_subject: task.properties.hs_task_subject,
					deal: deal,
					client: companyData,
					contact: contactData,
					ticket: ticket,
					hubspot_owner_id: task.properties.hubspot_owner_id,					
					hs_timestamp: task.properties.hs_timestamp,
					hs_task_body: task.properties.hs_task_body ?? '',
					hs_task_priority: task.properties.hs_task_priority,
					hs_task_type: task.properties.hs_task_type ?? 'TODO',
					id: task.id,
					completed: task.properties.hs_task_status !== 'NOT_STARTED'
				};
			});
			dispatch(setRows(newRows));
		}
	}, [tasksQuery.data]);
  
	const loading = tasksQuery?.isLoading || tasksQuery?.isUninitialized;
	const fetching = tasksQuery?.isFetching;

	const error = tasksQuery?.isError;

	return (
		<TableContainer component={Paper}>
			<Grid container alignItems="center" sx={{alignItems: 'flex-end'}} p={1} px={2} spacing={1}>
				<Grid item xs={12} sm={5}> 
					<SearchField
						value={searchText}
						disabled={loading}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSearch();
							}
						}}
						onChange={(e) => dispatch(setSearchText(e.target.value))}
						onClick={handleSearch}
						onReset={handleReset}
					/>
				</Grid>
				<Grid item xs={0} sm={true}></Grid>
			</Grid>
			<Divider />
			<Table
				aria-labelledby="tableTitle"
				size="medium"
				aria-label="enhanced table"
				sx={{ minWidth: 700 }}
			>
				<FolioTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={tasksHeadCells}
				/>
				<TableBody>
					{loading || fetching ? (
						<TableProgressIndicator rowCount={tasksHeadCells.length} />
					) : error ? (
						<TableRow>
							<TableCell colSpan={tasksHeadCells.length} align="center">
								<Typography variant="subtitle1">
                                There was an error. Please try again.
								</Typography>
							</TableCell>
						</TableRow>
					) : rows.length > 0 ? (
						stableSort(rows, getComparator(order, orderBy))
							//.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((task, index) => (
								<TaskRow
									key={`tasks-row-index-${index}-${task.id}`}
									task={task}
								/>
							))
					) : (
						<TableRow>
							<TableCell colSpan={tasksHeadCells.length} align="center">
								<Typography variant="subtitle1">No tasks found</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Box width="100%" justifyItems="end">
				<TablePagination
					rowsPerPageOptions={rowLimits}
					count={tasksQuery?.data?.total ?? 0}
					rowsPerPage={rowsPerPage}
					page={page}
					component={'div'}
					SelectProps={{
						inputProps: {
							'aria-label': 'Rows per page',
						},
						native: true,
					}}
					sx={{
						display: 'block',
						width: '100%'
					}}
					showLastButton
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					ActionsComponent={TablePaginationActions}
				/>
			</Box>
			<AddTaskDialog />
		</TableContainer>
	);
}