import React, { useMemo } from 'react';
import {
	Card,
	Typography,
	Link,
} from '@mui/material';
import '@/styles/index.scss';
import { formatDate } from '@/utils/constants';

export const EmailCard = ({ email, sharepointSite }) => {
	const date = useMemo(
		() => new Date(email.data.TimeCreated),
		[email.data.TimeCreated]
	);
	const lastUpdated = useMemo(
		() => new Date(email.data.TimeLastModified),
		[email.data.TimeLastModified]
	);
	const downloadUrl = `${sharepointSite}${email.data.ServerRelativeUrl}`;

	return (
		<Card style={{ marginBottom: '12px', paddingLeft: '12px' }}>
			{/* <div style={{padding: '0 16px'}}> */}
			<div
				style={{
					display: 'flex',
					marginTop: '12px',
					justifyContent: 'space-between',
				}}
			>
				<Typography variant="task_body" style={{ padding: '10px' }}>
					<span style={{ fontWeight: 'bold' }}>Email</span> by{' '}
					<span>{`${email.data.Author.authorProperties.Title}`}</span>
					<span style={{ margin: '0 0.5em' }}>{' | '}</span>
					<span style={{ color: '#505AFC' }}> {formatDate(date, true)} </span>
				</Typography>
			</div>
			<div style={{ padding: '10px' }}>
				<Link
					href={downloadUrl}
					target="_blank"
					rel="noopener noreferrer"
					sx={{ textDecorationLine: 'none' }}
				>
					<Typography sx={{ color: '#505AFC' }} variant="avatar_title">
						{email.data.Name}
					</Typography>
				</Link>
			</div>
			<div
				style={{ display: 'flex', justifyContent: 'right', paddingTop: '1em' }}
			>
				<Typography variant="task_updated" sx={{padding: 1}}>
					{`Last updated: ${formatDate(lastUpdated, true)}`}
				</Typography>
			</div>
		</Card>
	);
};
