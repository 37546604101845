/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
	useGetDealPoliciesQuery,
	useGetDealPropertiesQuery,
	useGetDealRowQuery,
} from '@/features/deals/dealsApi';
import {
	useGetAIDocsQuery,
	useGetDealNoteSummariesQuery,
	useGetDealThreadIdsQuery,
	useGetLatestDealSOAsQuery,
} from '@/features/factFind/factFindApi';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useGetClientPropertiesQuery } from '@/features/clients/clientsApi';
import {
	useGetAllDealActivitiesQuery,
	useGetDealComplianceTasksQuery,
	useGetEngagementsQuery,
} from '@/features/engagements/engagementsApi';
import { useGetContactPropertiesQuery } from '@/features/contacts/contactsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import {
	useGetTicketInsightClaimQuery,
	useGetTicketPropertiesQuery,
} from '@/features/claims/ticketsApi';
import { useClientStorageHooks } from './useClientStorageHooks';
import {
	setCount,
	setLoading,
	setLoadingActivities,
} from '@/features/engagements/engagementsSlice';
import { getPipelineName } from '@/utils/constants';
import { useGetFormsQuery } from '@/features/forms/formsApi';
import { useSharepoint } from './useSharepoint';
import { addToCache } from '@/features/sharepoint/sharepointSlice';
import { paths } from '@/app/routes';

export const initialState = {
	deal: {},
	dealstage: null,
	client: {},
	forms: [],
	pipeline: '',
	pipelines: {},
	tasks: [],
	complianceTasks: [],
	activities: [],
	notes: [],
	calls: [],
	emails: [],
	noteSummaries: [],
	aiDocs: [],
	quotes: [],
	riskInfo: [],
	soas: [],
	dealRow: {},
	threadIds: {},
	loading: {
		deal: true,
		client: true,
		contact: true,
		aiDocs: true,
		noteSummaries: true,
	},
	sharepoint: {
		sharepointSite: '',
		clientSiteSuffix: '',
		clientFolder: '',
		clientSite: '',
		isValid: false,
	},
	files: [],
	policies: [],
	claim: {},
	dealstageId: '',
	dealsPipelineData: {},
	contact: {},
	ownerId: null,
};

const useDealData = (objectType) => {
	const dispatch = useDispatch();

	const id = useMemo(() => {
		const pathnames = window.location.pathname.split('/');
		return pathnames.find((i) => !isNaN(parseInt(i)));
	}, [window.location.pathname]);

	// const account = useSelector((state) => state.msalAccount.account);
	const { count } = useSelector((state) => state.engagementsSlice);

	const dealRowQuery = useGetDealRowQuery(id, { skip: !id });

	const dealQuery =
		objectType == 'deal' ? useGetDealPropertiesQuery(id) : useGetTicketPropertiesQuery(id);
	const pipelineId = dealQuery.data?.pipeline;

	const dealstage =
		dealQuery.data?.properties?.dealstage ?? dealQuery.data?.properties?.hs_pipeline_stage;
	const pipelineQuery = useGetPipelinePropertiesQuery(pipelineId, { skip: !pipelineId });
    
	const { deal: cache } = useSelector((state) => state.sharepoint.folderCache);
	const folderCache = cache[id];

	const ownerId = dealQuery.data?.properties?.hubspot_owner_id;
	const userQuery = useGetUserDetailsQuery({ hubspotId: ownerId }, { skip: !ownerId });

	const isValidSharepoint =
		userQuery.data?.sharepointSite != null &&
		userQuery.data?.clientSiteSuffix != null &&
		userQuery.data?.clientFolder != null &&
		userQuery.data?.clientSite != null;

	const clientId = dealRowQuery.data?.clientHubspotId;
	const clientQuery = useGetClientPropertiesQuery(clientId, {
		skip: !clientId,
	});

	const clientRow = clientQuery.data?.properties?.clientRow;

	const brokerGroupId = clientRow?.brokerGroupId;

	const contactId = dealQuery.data?.contactId ?? clientQuery.data?.contacts[0];
	const contactEmail = clientRow?.contactEmail;

	//gets the specific forms based on the deals pipeline
	const dealPipeline = getPipelineName(pipelineId);
	const formsQuery = useGetFormsQuery(
		{ dealPipeline: dealPipeline, brokerRef: brokerGroupId },
		{ skip: !dealPipeline || !brokerGroupId }
	);

	const contactQuery = useGetContactPropertiesQuery(
		{ hubspotId: contactId, email: contactEmail },
		{ skip: !contactId || !contactEmail }
	);

	const engagementsQuery = useGetEngagementsQuery({ dealId: id, objectType }, { skip: !id });

	const complianceTasksQuery = useGetDealComplianceTasksQuery({ dealId: id }, { skip: !id });

	const activitiesQuery = useGetAllDealActivitiesQuery({ dealId: id }, { skip: !id });

	const insightClaimQuery = useGetTicketInsightClaimQuery(id, { skip: objectType != 'ticket' });

	const threadIdsQuery = useGetDealThreadIdsQuery(id, { skip: !id });

	const [msgFiles, setMsgFiles] = useState([]);

	const { getSharepointAttachmentsRecursive } = useSharepoint(
		userQuery.data?.sharepointSite,
		userQuery.data?.clientSiteSuffix,
		userQuery.data?.clientFolder,
		userQuery.data?.clientSite
	);

	const latestDealSOAsQuery = useGetLatestDealSOAsQuery({ dealId: id }, { skip: !id });

	const { createClientFolder } = useClientStorageHooks(userQuery.data);

	useEffect(() => {
		const createIfNecessary = async () => {
			try {
				await createClientFolder(id, {
					deal: dealQuery.data?.properties,
					client: clientQuery.data?.properties,
					objectType,
				});
				dispatch(addToCache({ type: 'deal', id }));
			} catch (error) {
				console.log(
					`Error creating folders for deal ${dealRowQuery.data.dealFolderPath}`,
					error
				);
			}
		};
		const getMsgFiles = async () => {
			try {
				dispatch(setLoadingActivities(true));
				const response = await getSharepointAttachmentsRecursive(
					dealRowQuery.data.dealFolderPath,
					['.msg']
				);
				setMsgFiles(response);
			} catch (error) {
				console.log('Error getting files', error);
				setMsgFiles([]);
			} finally {
				dispatch(setLoadingActivities(false));
			}
		};
		console.log('CACHE', cache);
		const sharepointReady = isValidSharepoint && dealRowQuery.data?.dealFolderPath;
		console.log('folder cache: ', folderCache);
		if (isValidSharepoint && dealQuery.data && clientQuery.data && !folderCache) {
			createIfNecessary();
		}
		if (sharepointReady && folderCache) {
			getMsgFiles();
		}
	}, [
		dealRowQuery.data?.dealFolderPath,
		dealQuery.data,
		clientQuery.data,
		id,
		isValidSharepoint,
		folderCache,
	]);

	useEffect(() => {
		dispatch(
			setLoading(
				engagementsQuery.isFetching ||
					activitiesQuery.isFetching ||
					engagementsQuery.isLoading ||
					activitiesQuery.isLoading
			)
		);
		dispatch(setLoadingActivities(activitiesQuery.isFetching || activitiesQuery.isLoading));
	}, [
		engagementsQuery.isFetching,
		activitiesQuery.isFetching,
		engagementsQuery.isLoading,
		activitiesQuery.isLoading,
	]);

	useEffect(() => {
		if (engagementsQuery.data?.tasks) {
			const tasks = engagementsQuery.data?.tasks ?? [];
			const incompleteTasks = tasks.filter((t) => !t.completed);
			dispatch(setCount({ key: 'tasks', count: tasks.length }));
			dispatch(setCount({ key: 'incompleteTasks', count: incompleteTasks.length }));
		}
	}, [engagementsQuery.data]);

	const policiesQuery = useGetDealPoliciesQuery({ dealId: id }, { skip: !id });
	// console.log('deal policies data: ', policiesQuery.data);
	const noteSummariesQuery = useGetDealNoteSummariesQuery({ dealId: id }, { skip: !id });
	const aiDocsQuery = useGetAIDocsQuery({ dealId: id, type: 'ALL' }, { skip: !id });

	const quotes = useMemo(() => {
		const data = (aiDocsQuery.data ?? []).filter((e) => e.type === 'quote');
		const sortedData = data.sort((a, b) => {
			if (a.createdWhen._seconds !== b.createdWhen._seconds) {
				return b.createdWhen._seconds - a.createdWhen._seconds;
			}
			return b.createdWhen._nanoseconds - a.createdWhen._nanoseconds;
		});
		console.log('Sorted quotes', sortedData);
		return sortedData;
	}, [aiDocsQuery.data]);

	const riskInfo = useMemo(() => {
		const data = (aiDocsQuery.data ?? []).filter((e) => e.type === 'wytu');
		const sortedData = data.sort((a, b) => {
			if (a.createdWhen._seconds !== b.createdWhen._seconds) {
				return b.createdWhen._seconds - a.createdWhen._seconds;
			}
			return b.createdWhen._nanoseconds - a.createdWhen._nanoseconds;
		});
		console.log('Sorted risk info', sortedData);
		return sortedData;
	}, [aiDocsQuery.data]);

	// Aggregate data and status from all queries to manage loading and error states
	const isLoading = [
		pipelineQuery.isLoading,
		clientQuery.isLoading,
		contactQuery.isLoading,
		// engagementsQuery.isLoading,
		// activitiesQuery.isLoading,
		policiesQuery.isLoading,
		aiDocsQuery.isLoading,
		dealRowQuery.isLoading,
		formsQuery.isLoading,
	].some((status) => status === true);

	const isError = [
		pipelineQuery.isError,
		clientQuery.isError,
		activitiesQuery.isError,
		contactQuery.isError,
		engagementsQuery.isError,
		policiesQuery.isError,
		aiDocsQuery.isError,
		dealRowQuery.isError,
	].some((status) => status === true);

	const contextValue = {
		objectType,
		deal: {
			...(dealQuery.data && {
				...dealQuery.data?.properties,
				createdAt: dealQuery.data?.createdAt,
			}),
		},
		forms: formsQuery.data?.data ?? [],
		dealRow: dealRowQuery.data ?? {},
		threadIds: threadIdsQuery.data ?? {},
		policies: policiesQuery.data?.policies ?? [],
		claim: insightClaimQuery?.data?.data?.Claim ?? {},
		dealstage: pipelineQuery.data?.stages[dealstage]?.label ?? dealstage,
		dealstageId: dealstage,
		dealsPipelineData: pipelineQuery.data ?? {},
		client: clientQuery.data?.properties ?? {},
		contact: contactQuery.data?.properties ?? {},
		pipeline: pipelineQuery.data?.pipelines?.label ?? '',
		pipelines: pipelineQuery.data?.pipelines ?? {},
		ownerId,
		tasks: engagementsQuery.data?.tasks ?? [],
		notes: engagementsQuery.data?.notes ?? [],
		calls: engagementsQuery.data?.calls ?? [],
		emails: engagementsQuery.data?.emails ?? [],
		complianceTasks: complianceTasksQuery.data ?? [],
		msgFiles,
		activities: activitiesQuery.data?.data ?? [],
		sharepoint: {
			sharepointSite: userQuery.data?.sharepointSite,
			clientSiteSuffix: userQuery.data?.clientSiteSuffix,
			clientFolder: userQuery.data?.clientFolder,
			clientSite: userQuery.data?.clientSite,
			isValid: isValidSharepoint,
		},
		aiDocs: aiDocsQuery.data ?? [],
		noteSummaries: noteSummariesQuery.data ?? [],
		quotes,
		riskInfo,
		soas: latestDealSOAsQuery.data ?? [],
		loading: {
			deal:
				dealQuery.isLoading ||
				pipelineQuery.isLoading ||
				userQuery.isLoading ||
				dealQuery.isUninitialized ||
				pipelineQuery.isUninitialized ||
				userQuery.isUninitialized,
			client: clientQuery.isLoading || clientQuery.isUninitialized,
			contact: contactQuery.isLoading || contactQuery.isUninitialized,
			forms: formsQuery.isLoading || formsQuery.isUninitialized,
			aiDocs: aiDocsQuery.isLoading || aiDocsQuery.isUninitialized,
			noteSummaries: noteSummariesQuery.isLoading || noteSummariesQuery.isUninitialized,
		},
	};

	// const { createClientFolder } = useClientStorageHooks((userQuery.data));

	// useEffect(() => {
	// 	const createIfNecessary = async () => {
	// 		await createClientFolder(id, contextValue);
	// 	};
	// 	if (!isLoading && clientQuery.data && (!dealRowQuery.data || !dealRowQuery.data?.dealFolderPath)) {
	// 		createIfNecessary();
	// 	}
	// }, [dealRowQuery.data, clientQuery.data, id, isLoading]);

	return { id, contextValue, isLoading, isError };
};

export default useDealData;
