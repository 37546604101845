/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableRow,
	Typography,
	TablePagination,
	Box,
	IconButton,
	Skeleton,
	Link,
	Stack,
	Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useGetDealRowQuery } from '@/features/deals/dealsApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSharepoint } from '@/hooks/useSharepoint';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { getComparator, stableSort } from '@/components/table/functions';
import { TablePaginationActions } from '@/components/table/TablePaginationActions';
import { AttachmentsRow } from './AttachmentsRow';
import { rowLimits } from '@/components/table/data';
import {
	setPage,
	requestSort,
	setRows,
	changeRowsPerPage,
	setError,
	setLoading,
	triggerRefresh,
	setSearchValue,
} from '@/features/table/attachmentsTableSlice';
import { DataGrid } from '@mui/x-data-grid';
import {
	folioBlue,
	formatDate,
	replaceFolderName,
	specialChars,
	truncateFilename,
} from '@/utils/constants';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { showToast } from '@/features/toast/toastSlice';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import { attachmentHeadCells } from './data';
import SearchField from '@/components/SearchField';
import PreviewIcon from '@mui/icons-material/Preview';

export const AttachmentsTableOld = ({
	deal,
	client,
	checkedList,
	onChecked,
	canDownload,
	filterTypes,
	metadataFilters,
	metadataEnablers = { disabled: {}, enabled: {} },
	showPolicyId = false,
	editableView = false,
	showAttachmentDescription = false,
}) => {
	const dispatch = useDispatch();

	const { rowsPerPage, orderBy, order, page, rows, loading, error, refreshIndicator } =
		useSelector((state) => state.attachmentsTable);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	let dealRowData;
	if (deal) {
		const { data: dealData } = useGetDealRowQuery(deal.id, { skip: !deal.id });
		dealRowData = dealData;
	}

	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });

	// const sharepointSite = userDetails?.sharepointSite;
	// const clientSiteSuffix = userDetails?.clientSiteSuffix;
	// const clientFolder = userDetails?.clientFolder;
	// const clientSite = userDetails?.clientSite;
	const object = deal ?? client;

	const sharepointSite = object?.sharepoint?.sharepointSite;
	const clientSiteSuffix = object?.sharepoint?.clientSiteSuffix;
	const clientFolder = object?.sharepoint?.clientFolder;
	const clientSite = object?.sharepoint?.clientSite;

	const { getSharepointAttachmentsRecursive, updateFileMetadata } = useSharepoint(
		sharepointSite,
		clientSiteSuffix,
		clientFolder,
		clientSite
	);

	const handleAddMetadataFileDocument = async (metadata, url) => {
		await updateFileMetadata(url, metadata);
		console.log('🚀 ~ Metadata updated for file!');
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch(setLoading(true));
			try {
				let rowData;
				if (deal) {
					rowData = dealRowData?.dealFolderPath;
				} else if (client) {
					rowData = replaceFolderName(client.name);
				}
				const response = await getSharepointAttachmentsRecursive(
					rowData,
					filterTypes,
					metadataFilters
				);
				dispatch(setRows(response));
				dispatch(setError(false));
			} catch (error) {
				console.error('Error getting files:', error);
				dispatch(setError(true));
				dispatch(setRows([]));
			} finally {
				dispatch(setLoading(false));
			}
		};

		if ((deal && dealRowData) || client) {
			fetchData();
		} else {
			dispatch(setRows([]));
		}
	}, [deal, dealRowData, client, refreshIndicator]);

	const displayedFiles = useMemo(
		() => rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
		[rows, page, rowsPerPage]
	).filter((file) => file.UniqueId);

	const handleRequestSort = (event, property) => {
		dispatch(requestSort(property));
	};

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 3) : 0;

	const handleChangePage = (event, newPage) => {
		dispatch(setPage(newPage));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(changeRowsPerPage(event.target.value));
	};

	const attachmentHeadCells = [
		{ id: 'id', numeric: false, label: null, sortable: false },
		{
			id: 'Name',
			numeric: false,
			label: 'DOCUMENTS',
			align: 'left',
			width: 295,
		},
		{ id: 'PolicyId', numeric: true, label: 'POLICY ID', align: 'right' },
		{
			id: 'TimeCreated',
			numeric: false,
			label: 'DATE ADDED',
			align: 'right',
			width: 150,
		},
		{
			id: 'download',
			numeric: false,
			label: '',
			align: 'left',
			sortable: false,
		},
	];

	const headCells = useMemo(() => {
		let cells = attachmentHeadCells;
		if (!checkedList) {
			cells = cells.filter((c) => c.id !== 'id');
		}
		if (canDownload === false) {
			cells = cells.filter((c) => c.id !== 'download');
		}
		if (!showPolicyId) {
			cells = cells.filter((c) => c.id !== 'PolicyId');
		}
		if (editableView) {
			cells = cells.filter((c) => c.id !== 'download');
		}
		return cells;
	}, [attachmentHeadCells, checkedList, canDownload, showPolicyId, editableView]);

	return (
		<TableContainer>
			<Table
				aria-labelledby='tableTitle'
				size='small'
				aria-label='enhanced table'
				sx={{ minWidth: 500 }}
			>
				<FolioTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={headCells}
					onSelectAllCheck={() => onChecked(rows)}
				/>
				<TableBody>
					{loading ? (
						<TableProgressIndicator rowCount={headCells.length} />
					) : error ? (
						<TableRow>
							<TableCell colSpan={3} align='center'>
								<Typography variant='subtitle1'>
									There was an error. Please make sure you are signed in and try
									again.
								</Typography>
							</TableCell>
						</TableRow>
					) : displayedFiles.length > 0 ? (
						stableSort(displayedFiles, getComparator(order, orderBy)).map(
							(file, fileIndex) => (
								<AttachmentsRow
									key={`attachments-table-${file.UniqueId}`}
									file={file}
									downloadUrl={
										canDownload !== false
											? `${sharepointSite}${file.ServerRelativeUrl}`
											: null
									}
									checkedList={checkedList}
									onChecked={onChecked}
									showPolicyId={showPolicyId}
									metadataEnablers={metadataEnablers}
								/>
							)
						)
					) : (
						<TableRow>
							<TableCell colSpan={3} align='center'>
								<Typography>No attachments have been added.</Typography>
							</TableCell>
						</TableRow>
					)}
					{emptyRows > 0 && (
						<TableRow style={{ height: 30.7 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Box width='100%' justifyItems='end'>
				<TablePagination
					rowsPerPageOptions={rowLimits}
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					component={'div'}
					SelectProps={{
						inputProps: {
							'aria-label': 'Rows per page',
						},
						native: true,
					}}
					sx={{
						display: 'block',
						width: '100%',
					}}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					ActionsComponent={TablePaginationActions}
				/>
			</Box>
		</TableContainer>
	);
};

export const AttachmentsTable = ({
	checkedList,
	onChecked,
	canDownload,
	filterTypes,
	metadataFilters,
	metadataEnablers = { disabled: {}, enabled: {} },
	showPolicyId = false,
	editableView = false,
	showAttachmentDescription = false,
}) => {
	const dispatch = useDispatch();

	const { deal, client, sharepoint, isLoading, isError, dealRow } = useAttachmentsContext();

	const [refresh, setRefresh] = useState(0);

	const rowData = useMemo(
		() =>
			deal && dealRow ? dealRow.dealFolderPath : client ? replaceFolderName(client.name) : '',
		[deal, client, dealRow]
	);

	const {
		rowsPerPage,
		// orderBy,
		// order,
		page,
		rows,
		loading,
		error,
		refreshIndicator,
		searchValue,
	} = useSelector((state) => state.attachmentsTable);

	const allLoading = loading || isLoading;
	const allError = error || isError;

	const headCells = useMemo(() => {
		let cells = attachmentHeadCells;
		if (!checkedList) {
			cells = cells.filter((c) => c.id !== 'id');
		}
		if (canDownload === false || allLoading) {
			cells = cells.filter((c) => c.id !== 'download');
		}
		if (!showPolicyId) {
			cells = cells.filter((c) => c.id !== 'PolicyId');
		}
		// if (editableView) {
		// 	cells = cells.filter((c) => c.id !== 'download');
		// }
		return cells;
	}, [attachmentHeadCells, checkedList, canDownload, showPolicyId, editableView, allLoading]);

	const gridViewCells = [
		...headCells.map((cell) => ({
			field: cell.id,
			headerName: cell.label || '',
			align: 'left',
			sortable: cell.sortable !== false,
			editable: cell.id === 'Name',
			width: cell.width || 200,
			renderCell: (params) => {
				if (params.id === 'no-attachments-message' && cell.id === 'Name') {
					return (
						<Typography noWrap variant='body1'>
							{params.row.Name}
						</Typography>
					);
				}
				if (cell.id === 'download') {
					return (
						<IconButton
							color='primary'
							href={params.row.downloadUrl}
							target='_blank'
							rel='noopener noreferrer'
							// download
							sx={{ color: folioBlue }}
						>
							<PreviewIcon />
						</IconButton>
					);
				}
				if (cell.id === 'type') {
					return allLoading ? (
						<Skeleton width={'100%'} height={40} />
					) : (
						<Typography noWrap variant='body1'>
							{`${params.row.suffix}`}
						</Typography>
					);
				}
				if (cell.id === 'Name') {
					const name = truncateFilename(params?.row?.Name ?? '', 50);
					console.log('here are the params: ', params);
					return allLoading ? (
						<Skeleton width={'100%'} height={40} />
					) : (
						<Tooltip title={'Double-click to edit file name'} placement='top' arrow>
							<Stack direction='column' spacing={0.2}>
								<Typography noWrap variant='body1'>
									{`${name}.${params.row.suffix}`}
								</Typography>
								<Typography variant='avatar_subtitle'>
									{params.row.Directory}
								</Typography>
								{params.row.Name && specialChars.test(params.row.Name) && (
									<Typography variant='avatar_subtitle' sx={{ color: 'red' }}>
										{'File name contains characters that could cause an error'}
									</Typography>
								)}
							</Stack>
						</Tooltip>
					);
				} else {
					return allLoading ? <Skeleton width={'100%'} height={40} /> : undefined;
				}
			},
		})),
		{
			field: 'Description',
			headerName: 'DESCRIPTION',
			width: 300,
			editable: true,
			renderCell: (params) => {
				const empty = (params.row.Description ?? '').length === 0;
				return allLoading ? (
					<Skeleton width={'100%'} height={40} />
				) : (
					<span style={{ ...(empty && { color: 'rgba(0,0,0,0.25)' }) }}>
						{empty ? 'Please enter a description' : params.row.Description}
					</span>
				);
			},
		},
	];

	const { getSharepointAttachmentsRecursive, updateFileMetadata } = useSharepoint(
		sharepoint?.sharepointSite,
		sharepoint?.clientSiteSuffix,
		sharepoint?.clientFolder,
		sharepoint?.clientSite
	);

	const handleAddMetadataFileDocument = async (metadata, url) => {
		if (sharepoint?.isValid) {
			await updateFileMetadata(url, metadata);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			dispatch(setLoading(true));
			try {
				const response = await getSharepointAttachmentsRecursive(
					rowData,
					filterTypes,
					metadataFilters
				);
				dispatch(setRows(response));
				dispatch(setError(false));
			} catch (error) {
				console.error('Error getting files:', error);
				dispatch(setError(true));
				dispatch(setRows([]));
			} finally {
				dispatch(setLoading(false));
			}
		};

		console.log('Refresh indicator', refreshIndicator);
		if (sharepoint?.isValid && ((deal && dealRow) || client) && !loading) {
			fetchData();
		} else {
			if (rows.length != 0) {
				dispatch(setRows([]));
			}
		}
	}, [deal, dealRow, client, refreshIndicator, sharepoint]);

	const displayedFiles = useMemo(() => {
		const files = rows
			.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
			.filter((file) => file.UniqueId);
		// console.log('DISPLAYED FILES', files);
		const display = files.map((file, fileIndex) => {
			const filename = (file.Name ?? '').split('.');
			const id = file.UniqueId;
			const Name = filename[0];
			const suffix = filename[1];
			const Description = file.Description ?? '';
			const PolicyId = file.fileMetadata?.PolicyId ?? '';
			const Directory = file.Directory;
			const TimeCreated = formatDate(file.TimeCreated, true);
			const downloadUrl =
				canDownload !== false
					? `${sharepoint?.sharepointSite}${file.ServerRelativeUrl}`
					: null;
			return {
				id,
				serverUrl: file.ServerRelativeUrl,
				Name,
				Description,
				PolicyId,
				Directory,
				TimeCreated,
				downloadUrl,
				suffix,
			};
		});
		const search = searchValue.toLowerCase().trim();
		return search.length > 0
			? display.filter(
					(file) =>
						file.Name.toLowerCase().includes(search) ||
						file.Description.toLowerCase().includes(search)
			  )
			: display;
	}, [rows, page, rowsPerPage, searchValue]);

	const updateDescription = async (newRow, oldRow) => {
		const metadata = {
			OData__ExtendedDescription: newRow.Description,
		};
		await handleAddMetadataFileDocument(metadata, oldRow.serverUrl);
	};

	const updateFileName = async (newRow, oldRow) => {
		const cleanedName = newRow.Name.replace(specialChars, '');
		const metadata = {
			FileLeafRef: `${cleanedName}.${oldRow.suffix}`,
		};
		await handleAddMetadataFileDocument(metadata, oldRow.serverUrl);
	};

	const processRowUpdate = async (newRow, oldRow) => {
		if (oldRow.Description !== newRow.Description) {
			try {
				await updateDescription(newRow, oldRow);
				dispatch(
					showToast({
						message: 'Description updated!',
						success: true,
					})
				);
			} catch (error) {
				const errorMessage = error?.response?.data?.error?.message?.value ?? '';
				console.log('🙅 ~ ERROR UPDATING METADATA', error);
				dispatch(
					showToast({
						message: `Failed to update description: ${errorMessage}`,
						error: true,
					})
				);
			}
			dispatch(triggerRefresh());
		}
		if (oldRow.Name !== newRow.Name) {
			try {
				await updateFileName(newRow, oldRow);
				dispatch(
					showToast({
						message: 'File name updated!',
						success: true,
					})
				);
			} catch (error) {
				const errorMessage = error?.response?.data?.error?.message?.value ?? '';
				console.log('🙅 ~ ERROR UPDATING METADATA', error);
				dispatch(
					showToast({
						message: `Failed to update file name: ${errorMessage}`,
						error: true,
					})
				);
			}
			dispatch(triggerRefresh());
		}
		return newRow;
	};

	const handleProcessRowUpdateError = (error) => {
		console.log('Error updating row', error);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box width={{ sm: '100%', md: '60%' }} pb='1em'>
				<SearchField
					value={searchValue}
					onChange={(event) => dispatch(setSearchValue(event.target.value))}
					onReset={() => dispatch(setSearchValue(''))}
					helperText={''}
				/>
			</Box>
			{allLoading ? (
				<DataGrid
					sx={{ backgroundColor: 'white' }}
					rows={[{ id: 'loading-skeleton-row' }]}
					columns={gridViewCells}
					disableColumnMenu
					disableSelectionOnClick
				/>
			) : !sharepoint?.isValid ? (
				<Typography variant='subtitle1'>
					{`Could not find valid Sharepoint site for ${
						deal ? 'deal' : 'client'
					} owner. Please contact support@folio.insure.`}
				</Typography>
			) : allError ? (
				<Typography variant='subtitle1'>
					There was an error. Please make sure you are signed in and try again.
				</Typography>
			) : displayedFiles.length > 0 && !loading ? (
				<DataGrid
					sx={{
						backgroundColor: 'white',
						'& .MuiDataGrid-editInputCell': {
							backgroundColor: 'transparent',
						},
					}}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={handleProcessRowUpdateError}
					rows={displayedFiles}
					columns={gridViewCells}
					disableRowSelectionOnClick
					disableSelectionOnClick
					disableColumnFilter
					disableColumnSelector
					disableColumnMenu
				/>
			) : (
				<DataGrid
					sx={{ backgroundColor: 'white' }}
					rows={[
						{
							id: 'no-attachments-message',
							Name: 'No attachments added.',
						},
					]}
					columns={[
						{
							field: 'no-attachments-message',
							headerName: 'DOCUMENTS',
							align: 'left',
							sortable: false,
							editable: false,
							width: 400,
							renderCell: (params) => (
								<span style={{ color: 'rgba(0,0,0,0.5)' }}>{params.row.Name}</span>
							),
						},
					]}
					disableColumnMenu
					disableRowSelectionOnClick
					disableSelectionOnClick
					disableColumnFilter
					disableColumnSelector
				/>
			)}
		</Box>
	);
};
