import React, { useEffect, useMemo } from 'react';
import {
	Box,
	FormLabel,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Chip,
	Tooltip,
} from '@mui/material';
import { useRefinementList } from 'react-instantsearch';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { theme } from '@/app/theme';
import { folioBlue } from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { getComparator, stableSort } from '@/components/table/functions';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '@/features/table/instantSearchSlice';

export const RefinementListSelect = ({
	title,
	disabled,
	multiple,
	width = '150px',
	pipeline,
	...props
}) => {
	const dispatch = useDispatch();

	const { filters } = useSelector(state => state.instantSearch);
    
	const currentFilters = filters[props.attribute] ?? [];

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline, {
		skip: !pipeline,
	});

	const stages = pipelineQuery?.data?.pipelines?.stages ?? [];

	const { items, refine } = useRefinementList(props);

	const refactoredItems = useMemo(() => {
		// console.log('STAGES', stages);
		const itemsWithStages = items.map((item) => {
			const stage = stages.find((s) => s.id == item.value);
			return {
				...item,
				...(stage && { label: stage.label, order: stage.displayOrder }),
			};
		});
		return Object.keys(stages).length > 0
			? stableSort(itemsWithStages, getComparator('asc', 'order'))
			: items;
	}, [items, stages]);

	const all = useMemo(
		() => ({
			label: 'All',
			value: 'All',
			isRefined: refactoredItems.every((item) => item.isRefined),
		}),
		[refactoredItems]
	);

	const none = useMemo(
		() => ({
			label: 'None',
			value: 'None',
			isRefined: refactoredItems.every((item) => !item.isRefined),
		}),
		[refactoredItems]
	);

	const allItems = useMemo(
		() =>
			multiple
				? [all.isRefined ? none : all, ...refactoredItems]
				: [none, ...refactoredItems],
		[refactoredItems]
	);

	useEffect(() => {
		const refined = items.filter((i) => i.isRefined).map((v) => v.value);
		console.log('CURRENT FILTERS', currentFilters, 'refined', refined);

		const isEmpty = currentFilters.length === 0;
		const changes = currentFilters.filter(i => !refined.includes(i));
		const deletions = refined.filter(i => !currentFilters.includes(i));
		console.log('CHANGES', changes, 'DELETIONS', deletions);
		if (isEmpty && refined.length > 0) {
			refined.forEach(i => refine(i));
		} else if (changes.length > 0) {
			changes.forEach(i => refine(i));
		} else if (deletions.length > 0) {
			deletions.forEach(i => refine(i));
		}
	}, [currentFilters, items]);

	const handleChange = (event) => {
		let values = event.target.value;
		console.log('NEW SELECTION', values);
		// const refined = items.filter((i) => i.isRefined).map((v) => v.value);
		if (multiple) {
			values = values.map((v) => (typeof v === 'string' ? v : v.value));
			if (values.includes('All')) {
				// items.filter((i) => !i.isRefined).forEach((item) => refine(item.value));
				dispatch(setFilters({ type: props.attribute, values: [] }));
			} else if (values.includes('None')) {
				// refined.forEach((item) => refine(item));
				dispatch(setFilters({ type: props.attribute, values: [] }));
			} else {
				var multi = values.reduce((o, n) => {
					n in o ? o[n] += 1 : o[n] = 1;
					return o;
				}, {});
                  
				var res = Object.keys(multi).filter(k => multi[k] == 1);
				// const newValues = [
				// 	...new Set([
				// 		// ...values.filter((v) => !currentFilters.includes(v)),
				// 		...values.filter((v, i, a) => a.indexOf(v) !== i),
				// 	]),
				// ];
				// newValues.forEach((item) => refine(item));
				dispatch(setFilters({ type: props.attribute, values: res }));
			}
		} else {
			const value = typeof values === 'string' ? values : values.value;
			if (value == 'None') {
				// refined.forEach((item) => refine(item));
				dispatch(setFilters({ type: props.attribute, values: [] }));
			} else {
				dispatch(setFilters({ 
					type: props.attribute, 
					values: currentFilters.includes(value) ? [] : [value] 
				}));
				// refined.forEach((item) => refine(item));
				// if (!refined.includes(value)) {
				// 	// refine(value);
				// }
			}
		}
	};

	return (
		<Box>
			<FormLabel sx={{ minimalLabelTheme, color: folioBlue }}>{title}</FormLabel>
			<Box width={width}>
				<Tooltip
					disableFocusListener
					title={refactoredItems
						.filter((i) => i.isRefined)
						.map((i) => i.label)
						.join(', ')}
					placement="top"
				>
					<Select
						multiple={multiple}
						variant="standard"
						fullWidth={true}
						disabled={disabled}
						value={
							multiple
								? refactoredItems.filter((i) => i.isRefined)
								: refactoredItems.filter((i) => i.isRefined)[0]?.label ?? ''
						}
						onChange={handleChange}
						renderValue={(selected) => {
							return (
								<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
									{multiple ? (
										<span>
											{selected.slice(0, 1).map((value) => (
												<Chip
													size="small"
													key={`${title}-chip-${value.label}`}
													label={value.label}
												/>
											))}
											{selected.length > 1 && (
												<Chip size="small" label={' ... '} />
											)}
										</span>
									) : (
										selected
									)}
								</Box>
							);
						}}
					>
						{allItems.map((item) => {
							return (
								<MenuItem key={`${title}-select-${item.label}`} value={item.value}>
									{multiple &&
										(item.isRefined ? <CheckBox /> : <CheckBoxOutlineBlank />)}
									<ListItemText primary={item.label} />
								</MenuItem>
							);
						})}
					</Select>
				</Tooltip>
			</Box>
		</Box>
	);
};