import { ReactComponent as RefreshIconSVG } from '@/assets/icons/refresh_icon.svg';

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const RefreshIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<RefreshIconSVG />
		</SvgIcon>
	);
};
