import React, { useMemo } from 'react';
import { determineContext } from '@/hooks/determineContext';
import { TasksTab } from '@/components/objectTabs/TasksTab';
import { ActivityTab } from '@/components/objectTabs/ActivityTab';
import { NotesTab } from '@/components/objectTabs/NotesTab';
import { CreateNoteButton } from '@/components/buttons/viewHeaderButtons/CreateNoteButton';
import { CreateTaskButton } from '@/components/buttons/viewHeaderButtons/CreateTaskButton';
import { TabView } from '@/components/layouts/TabView';

export const ContactTabView = () => {
	const { contact, objectType } = determineContext();

	const tabs = useMemo(() => [
		{
			label: 'Activity',
			value: 'activity',
			showComponent: true,
			ContentComponent: ActivityTab,
		},
		{
			label: 'Notes',
			value: 'notes',
			showComponent: true,
			ContentComponent: NotesTab,
			HeaderComponent: CreateNoteButton,
		},
		{
			label: 'Tasks',
			value: 'tasks',
			showComponent: true,
			ContentComponent: TasksTab,
			HeaderComponent: CreateTaskButton,
		},
	], [objectType, contact]);

	const activeTabs = useMemo(() => tabs.filter((tab) => tab.showComponent), [tabs]);

	const fullname = useMemo(() => {
		return `${contact?.firstname ?? 'Unknown'} ${contact?.lastname ?? ''}`;
	}, [contact]);

	return <TabView 
		tabs={activeTabs} 
		title={fullname} 
		subtitle={''}
	/>;
};
