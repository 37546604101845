import React, {  } from 'react';
import { 
	TableCell,
	TableRow, 
	CircularProgress,
	Skeleton,
} from '@mui/material';

export const TableProgressIndicator = ({ rowCount }) => {

	return <TableRow>
		{Array.from({length: rowCount}, (_, index) => {
			return <TableCell key={`loading-${index}`}>
				<Skeleton />
			</TableCell>;
		})}
	</TableRow>;
};