import { FormLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { taskPriorities } from '@/utils/constants';
import { theme } from '@/app/theme';

export const PrioritySelect = ({ priority, disabled, setPriority, minimal, ...props }) => {

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	return <>
		<FormLabel sx={minimal ? minimalLabelTheme : undefined}>{'Priority'}</FormLabel>
		<Select
			name="priority"
			value={priority}
			disabled={disabled}
			onChange={(e) => setPriority(e.target.value)}
			fullWidth={true}
			{...props}
		>
			{taskPriorities.map((value) => {
				return (
					<MenuItem
						value={value}
						key={`priority-selection-${value}`}
					>
						{value}
					</MenuItem>
				);
			})}
		</Select>
	</>;
};