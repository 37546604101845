import React, { useState } from 'react';
import { Typography, Accordion, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { folioBlue } from '@/utils/constants';

export const DetailsAccordion = ({ title, children, titleProps, ...props }) => {
	const [expanded, setExpanded] = useState(true);

	return (
		<Accordion
			elevation={0}
			expanded={expanded}
			onChange={() => setExpanded((prev) => !prev)}
			disableGutters
			square
			sx={{
				backgroundColor: 'transparent',
				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
				paddingBottom: expanded ? '2em' : 0,
			}}
            {...props}
		>
			<AccordionSummary
				expandIcon={<ExpandMore sx={{ color: folioBlue }} />}
				sx={{
					padding: '0 0 1em',
				}}
			>
				<Typography variant='form_label' fontSize={'18px'} {...titleProps}>
					{title}
				</Typography>
			</AccordionSummary>
			{children}
		</Accordion>
	);
};
