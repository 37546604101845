import React, { useMemo, useState, useEffect } from 'react';
import { Select, MenuItem, Skeleton } from '@mui/material';
import { theme } from '@/app/theme';
import { useGetIndustriesQuery } from '@/features/properties/propertiesApi';

export const IndustrySelect = ({ initialValue, disabled, onChange, minimal }) => {
	const industryData = useGetIndustriesQuery();
	const nullEntry = { value: 'NONE', label: 'None' };

	const industries = useMemo(() => {
		return industryData?.data?.options ? [nullEntry, ...(industryData?.data?.options ?? {})] : [nullEntry];
	}, [industryData?.data]);

	const [industry, setIndustry] = useState(() => {
		return industries.find(o => o.value == initialValue || o.label == initialValue) ?? nullEntry;
	});

	const updateIndustry = (industryString) => {
		console.log('here is the selected industry: ', industryString);
		const selected = industries.find(option => option.value == industryString || option.label == industryString);
		setIndustry(selected ?? nullEntry);
	};
    
	const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;

	useEffect(() => {
		updateIndustry(initialValue);
	}, [initialValue, industries]);

	useEffect(() => {
		if (industry.value != 'NONE' && (industry.value != initialValue || industry.label != initialValue)) {
			console.log(`I will update in the backend: initialValue= ${initialValue}, industry.value= ${industry.value}, industry.label= ${industry.label}`);
			onChange && onChange(industry.value);
		}
	}, [industry]);

	return <>
		{industryData?.isLoading || industryData?.isUninitialized 
			? 
			<Skeleton width="50%" animation="wave" /> 
			: 
			<Select
				className={minimal && 'minimal-input'}
				value={industry.value}
				size={minimal && 'small'}
				disabled={disabled}
				onChange={(e) => {
					updateIndustry(e.target.value);
				}}
				displayEmpty
				fullWidth={true}
				sx={minimal ? minimalInputTheme : {}}
				variant={minimal && 'standard'}
			>
				{industries.map((option) => {
					return (
						<MenuItem
							value={option.value}
							key={`client-industry-selection-${option.value}`}
						>
							{option.label}
						</MenuItem>
					);
				})}
			</Select>}
	</>;
};