/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	Typography,
	FormControl,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
	InputAdornment,
	RadioGroup,
	FormControlLabel,
	Radio,
	Select,
	MenuItem,
	Grid,
	Tooltip,
	Link,
	Accordion,
	AccordionSummary,
	FormGroup,
	Checkbox,
} from '@mui/material';
import { BUSINESS_INTERRUPTION, DOMESTIC, EMPLOYERS_LIABILITY, GENERAL_LIABILITY, MANAGEMENT_LIABILITY, MATERIAL_DAMAGE, SCOPE_OF_ADVICE, SCOPE_OF_ADVICE_LABELS_DOMESTIC, STATUTORY_LIABILITY, SCOPE_OF_ADVICE_LABELS_COMMERCIAL, COMMERCIAL } from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { ExpandMore } from '@mui/icons-material';
import { determineContext } from '@/hooks/determineContext';
import { folioBlue } from '@/utils/constants';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { useAddDocumentPropertyToDealMutation } from '@/features/deals/dealsApi';

export const RiskClasses = () => {
	//LABELS FOR SCOPE HAVE BEEN CHANGED NEED TO ADJUST WHEN COMES TO DEAL VIEW
	const { dealRow } = determineContext();
	
	const scopeOfAdvice = useMemo(
		() => dealRow[SCOPE_OF_ADVICE] ?? [],
		[dealRow]
	);

	const [updateDeal, { isLoading }] = useAddDocumentPropertyToDealMutation();
	
	const getScopeOfAdviceLabels = () => {
		if (dealRow?.clientType === COMMERCIAL) {
			return SCOPE_OF_ADVICE_LABELS_COMMERCIAL;
		}
		if (dealRow?.clientType === DOMESTIC) {
			return SCOPE_OF_ADVICE_LABELS_DOMESTIC;
		}
		return {};
	};

	const scopeOfAdviceLabels = getScopeOfAdviceLabels();

	const onChange = async (newValues) => {
		await updateDeal({
			dealId: dealRow.dealId,
			docId: dealRow.id,
			property: SCOPE_OF_ADVICE,
			value: newValues,
		}).unwrap();
	};

	return (
		<Grid container>
			{Object.entries(scopeOfAdviceLabels)
				.filter(([value, label]) => {
					// Include EL and SL if Management Liability is in scopeOfAdvice
					if (scopeOfAdvice.includes(MANAGEMENT_LIABILITY)) {
						if (value === EMPLOYERS_LIABILITY || value === STATUTORY_LIABILITY) {
							return true;
						}
					}
	
					// Exclude Business Interruption if Material Damage is not included
					if (value === BUSINESS_INTERRUPTION && !scopeOfAdvice.includes(MATERIAL_DAMAGE)) {
						return false;
					}
	
					// Exclude Statutory Liability and Employers Liability if neither
					// General Liability nor Management Liability is included
					if (
						(value === STATUTORY_LIABILITY || value === EMPLOYERS_LIABILITY) &&
						!scopeOfAdvice.includes(GENERAL_LIABILITY) &&
						!scopeOfAdvice.includes(MANAGEMENT_LIABILITY)
					) {
						return false;
					}
	
	
					return true;
				})
				.map((entry) => {
					const [value, label] = entry;
					return (
						<Grid item xs={12} sm={6} key={`risk-classes-${value}`}>
							<FormControlLabel
								size="small"
								control={
									<ClientCheckbox
										value={value}
										valuesArray={scopeOfAdvice}
										setValues={onChange}
										disabled={isLoading}
									/>
								}
								label={label}
								sx={{
									'& .MuiTypography-root': {
										fontSize: '12px',
									},
									'& .MuiButtonBase-root': {
										fontSize: '18px',
									},
								}}
							/>
						</Grid>
					);
				})}
		</Grid>
	);
	
};
