/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Table,
	TableCell,
	TableContainer,
	TableFooter,
	TableRow,
	Typography,
	Paper,
	Divider,
	Box,
	Grid,
} from '@mui/material';
import { InstantSearch, Configure } from 'react-instantsearch';
import { dealClient } from '@/utils/typesense';
import { HitsTableBody } from '@/components/table/HitsTableBody';
import { HitsTablePagination } from '@/components/table/TablePaginationActions';
import { DealRow } from './DealRow';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { SearchFolioHead } from '@/components/table/FolioTableHead';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { InstantSearchFieldAdaptor } from '@/components/SearchField';
import { rowLimits } from '@/components/table/data';
import {
	changeRowsPerPage,
	setOrder,
	setOrderBy,
	requestSort,
	onOrderChange,
} from '@/features/table/dealsTableSlice';
import { KanbanToggle } from '@/components/buttons/KanbanToggle';
import { features } from '@/utils/features';
import { TicketRow } from '@/components/tables/ClaimsTable/TicketRow';
import { RefinementListSelect } from '@/components/selects/RefinementListSelect';


export const dealHeadCells = [
	// { id: 'id', numeric: false, label: null, sortable: false },
	//insuredName
	{ id: 'clientName', numeric: false, label: 'Insured name', align: 'left' },
	{ id: 'contactName', numeric: false, label: 'Contact', align: 'left' },
	{ id: 'dealStage', numeric: false, label: 'Stage', align: 'left' },
	{ id: 'ownerId', numeric: false, label: 'Assignee', align: 'left' },
	{ id: 'createDate', numeric: false, date: true, label: 'Created date', align: 'right' },
	// {
	// 	id: 'hs_lastmodifieddate',
	// 	numeric: false,
	// 	date: true,
	// 	label: 'Last Updated',
	// 	align: 'right',
	// },
	{ id: 'renewalDate', numeric: false, date: true, label: 'Renewal date', align: 'right' },
	// { id: 'hs_priority', numeric: false, label: 'Priority', align: 'center' },
	// { id: 'id', numeric: false, label: '', sortable: false, align: 'center' },
];

export const ticketHeadCells = [
	// { id: 'id', numeric: false, label: null, sortable: false },
	//insuredName
	{ id: 'clientName', numeric: false, label: 'Insured name', align: 'left' },
	{ id: 'dealName', numeric: false, label: 'Claim name', align: 'left' },
	{ id: 'claimType', numeric: false, label: 'Claim Type', align: 'left' },
	{ id: 'claimNumber', numeric: false, label: 'Claim Number', align: 'left' },
	{ id: 'insurer', numeric: false, label: 'Insurer', align: 'left' },
	{ id: 'dealStage', numeric: false, label: 'Stage', align: 'left' },
	{ id: 'hubspot_owner_id', numeric: false, label: 'Assignee', align: 'left' },
	{ id: 'dateOfLoss', numeric: false, label: 'Date of loss', align: 'left' },
	{
		id: 'next_task_date',
		numeric: false,
		label: 'Next Task Date',
		align: 'left',
		frontEndSort: true,
		// sortable: false,
	},
	{
		id: 'createDate',
		numeric: false,
		date: true,
		label: 'Created date',
		align: 'right',
	},
	// {
	// 	id: 'hs_lastmodifieddate',
	// 	numeric: false,
	// 	date: true,
	// 	label: 'Last Updated',
	// 	align: 'right',
	// },
];

export default function DealTable({ pipeline, tab }) {
	const dispatch = useDispatch();

	const isClaims = pipeline == process.env.REACT_APP_PIPELINE_CLAIMS;

	const headCells = isClaims ? ticketHeadCells : dealHeadCells;

	const { 
		groupView, 
		hubspotId: groupViewHubspotId
	} = useSelector((state) => state.userPrefs);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const {
		rowsPerPage,
		orderBy,
		order,
		refresh
	} = useSelector((state) => state.dealTable);

	const { data: userDetails } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});

	const userDetailsHubspotId = userDetails?.hubspotId;
	const hubspotId = groupViewHubspotId || userDetailsHubspotId;
    
	const brokerGroupId = userDetails?.brokerId;
	const brokerGroupIds = userDetails?.brokerGroupArray ?? [];

	useEffect(() => {
		const renewalsPipeline = process.env.REACT_APP_PIPELINE_RENEWALS;
		dispatch(setOrderBy(pipeline == renewalsPipeline ? 'renewalDate' : 'createdate'));
		dispatch(setOrder(pipeline == renewalsPipeline ? 'asc' : 'desc'));
	}, [pipeline]);
   
	useEffect(() => {
		dispatch(onOrderChange());
	}, [orderBy, order]);

	const sortItems = headCells.reduce((acc, cell, index) => {
		if (index === 0) {
			acc.push({ value: 'deals', label: cell.label });
		}
		if (cell.sortable !== false && !cell.frontEndSort) {
			acc.push({ value: `deals/sort/${cell.id}:asc`, label: `${cell.label} (asc)` });
			acc.push({ value: `deals/sort/${cell.id}:desc`, label: `${cell.label} (desc)` });
		}
		return acc;
	}, []);

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline, {
		skip: !pipeline,
	});

	const { active, inactive } = useMemo(() => {
		const pipelineStages = pipelineQuery.data?.pipelines?.stages ?? [];
		return { 
			active: pipelineStages.filter(stage => stage.metadata?.isClosed != 'true').map(s => s.id), 
			inactive: pipelineStages.filter(stage => stage.metadata?.isClosed == 'true').map(s => s.id)
		};
	}, [pipelineQuery.data]);

	const handleRequestSort = (event, property) => {
		dispatch(requestSort(property));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(changeRowsPerPage(event.target.value));
	};

	const filterSchema = useMemo(() => {
		const stageIds = (tab == 'active' ? active : inactive).join(',');
		const ownerFilter = groupView ? `brokerGroupId: [${brokerGroupIds.join(',')}]` : `ownerId := ${hubspotId}`;
		const schema = {
			filters: `dealStage: [${stageIds}] && ${ownerFilter}`
		};
		return schema;
	}, [tab, active, inactive, hubspotId, brokerGroupIds, groupView]);

	return (
		<InstantSearch indexName="deals" searchClient={dealClient}>
			<Configure {...filterSchema} />
			<Grid container sx={{ alignItems: 'flex-start' }} p={1} px={2} spacing={1}>
				<Grid item xs={12} sm={5}>
					<InstantSearchFieldAdaptor />
				</Grid>
				<Grid item xs={0} sm={true}></Grid>
				{isClaims && (
					<Grid item xs={12} sm={6} container justifyContent={'flex-end'} display={'flex'}>
						<Grid item>
							<Box pr={1}>
								<RefinementListSelect
									attribute="dealStage"
									title="Stage"
									multiple={true}
									pipeline={pipeline}
								/>
							</Box>
						</Grid>
						<Grid item>
							<Box pr={1}>
								<RefinementListSelect
									attribute="claimType"
									title="Claim Type"
									multiple={true}
								/>
							</Box>
						</Grid>
						<Grid>
							<Box pr={1}>
								<RefinementListSelect attribute="insurer" title="Insurer" multiple={true} />
							</Box>
						</Grid>
					</Grid>
				)}
			</Grid>
			{features.showKanban && (
				<Box p={1} px={2}>
					<KanbanToggle />
				</Box>
			)}
			<Divider />
			<Box sx={{ overflowX: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
				<TableContainer component={Paper}>
					<Table
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table"
						sx={{ minWidth: 700 }}
					>
						<SearchFolioHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={headCells}
							items={sortItems}
						/>
						<HitsTableBody
							TableRowComponent={isClaims ? TicketRow : DealRow}
							objectType={isClaims ? 'ticket' : 'deal'}
							includeTasks={isClaims}
							headCells={headCells}
							order={order}
							orderBy={orderBy}
							ErrorRowComponent={() => (
								<TableRow>
									<TableCell colSpan={headCells.length} align="center">
										<Typography variant="subtitle1">
											There was an error. Please try again.
										</Typography>
									</TableCell>
								</TableRow>
							)}
							EmptyRowComponent={() => (
								<TableRow>
									<TableCell colSpan={headCells.length} align="center">
										<Typography variant="subtitle1">
											{`No ${isClaims ? 'claims' : 'deals'} found`}
										</Typography>
									</TableCell>
								</TableRow>
							)}
						/>
					</Table>
				</TableContainer>
			</Box>
			<HitsTablePagination
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				items={rowLimits.map((item) => ({
					label: `${item} rows per page`,
					value: item,
					default: item == rowsPerPage,
				}))}
			/>
		</InstantSearch>
	);
}