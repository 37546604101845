/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import {
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	Grid,
	TextField,
	Select,
	MenuItem,
	FormLabel,
	ListItemIcon,
	Box,
	ToggleButtonGroup,
	ToggleButton,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import { FormikContext, useFormik } from 'formik';
import * as Yup from 'yup';
import { addDays, getUnixTime, isAfter, isValid } from 'date-fns';
import {
	taskTypes,
	getUrlFromPipeline,
	formatDate,
	getTimeFromDate,
	formatTimeToHourMinutes,
} from '@/utils/constants';
import { useCreateTaskMutation } from '@/features/engagements/engagementsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { InstantDealSelect } from '@/components/selects/DealSelect';
import { InstantClientSelect } from '@/components/selects/ClientSelect';
import { PrioritySelect } from '@/components/selects/PrioritySelect';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import { showToast } from '@/features/toast/toastSlice';
import { determineContext } from '@/hooks/determineContext';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { useMsGraph } from '@/hooks/useMsGraph';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { useGetBrokerOwnersQuery } from '@/features/user/ownerApi';

const minDate = new Date();

export const AddTaskDialog = () => {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);

	const { createOnlineMeetingEvent, createEventTask, getUserIdByEmail } = useMsGraph();

	const open = useSelector((state) => state.dialog.open['task']);

	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const hubspotId = userDetails?.hubspotId;
	const brokers = useGetBrokerOwnersQuery(hubspotId, {
		skip: !hubspotId,
	});

	const name = account?.name;
	const company = userDetails?.broker?.name;

	const validationSchema = Yup.object().shape({
		title: Yup.string().required('Title is required.'),
		dueDate: Yup.date().required('Due date is required.'),
		priority: Yup.string().required('Priority is required.'),
		notes: Yup.string().required('Task info is required.'),
		taskType: Yup.string().required('Task type is required.'),
		location: Yup.string()
			.test(
				'is-location-required',
				'Location is required for meetings',
				function (value) {
					const { taskType, isOnlineMeeting } = this.parent;
					if (taskType === 'MEETING' && !isOnlineMeeting) {
						return !!value;
					}
					return true;
				}
			)
			.nullable(),
		startTime: Yup.date()
			.nullable()
			.when('taskType', {
				is: (taskType) => taskType === 'MEETING' || taskType === 'CALL',
				then: (schema) => schema.required('Start time is required.'),
				otherwise: (schema) => schema.nullable(),
			}),
		endTime: Yup.date()
			.nullable()
			.when('taskType', {
				is: (taskType) => taskType === 'MEETING' || taskType === 'CALL',
				then: (schema) =>
					schema
						.required('End time is required.')
						.test(
							'is-greater',
							'End time must be later than start time',
							function (value) {
								const { startTime } = this.parent;
								return !startTime || !value || isAfter(value, startTime);
							}
						),
				otherwise: (schema) => schema.nullable(),
			}),
		ownerId: Yup.string().required('Assignee is required.'),
	});

	const initialValues = {
		title: '',
		dueDate: addDays(minDate, 3),
		priority: 'LOW',
		notes: '',
		taskType: taskTypes[0],
		errorMessage: '',
		deal: null,
		client: null,
		contact: null,
		objectType: null,
		ownerId: userDetails.hubspotId,
		attendees: [],
		userId: null,
		isOnlineMeeting: false,
		startTime: null,
		location: '',
		endTime: null,
	};

	const { deal, client, contact, objectType } =
    determineContext() ?? initialValues;

	const [createTask, { isLoading }] = useCreateTaskMutation();

	const getDefaultDateTime = (date) => {
		date.setHours(12, 0, 0, 0);
		return date;
	};

	const handleClose = () => {
		dispatch(closeDialog('task'));
	};

	const handleChange = (event, newValue) => {
		if (newValue !== null) {
			formik.setFieldValue('isOnlineMeeting', newValue);
		}
	};



	const handleCreateTask = async () => {
		// If task type is Meeting adjust to do task type

		const dealId = formik.values.deal?.hs_object_id ?? formik.values.deal?.id;
		const clientId =
      formik.values.client?.id ?? formik.values.client?.hs_object_id;
		const contactId =
      formik.values.contact?.id ?? formik.values.contact?.hs_object_id;

		const pipeline = formik.values.deal?.pipeline;
		const type = objectType ?? formik.values.deal?.objectType;

		const updatedTitle =
      formik.values.taskType === 'MEETING' || formik.values.taskType === 'CALL'
      	? `${formik.values.title} (${formatTimeToHourMinutes(
      		formik.values.startTime
      	)} - ${formatTimeToHourMinutes(formik.values.endTime)})`
      	: formik.values.title;

		const body = {
			title: updatedTitle,
			dueDate: getUnixTime(formik.values.dueDate) * 1000,
			priority: formik.values.priority,
			notes: formik.values.notes,
			hubspotId: formik.values.ownerId,
			type: formik.values.taskType,
			...(type && { objectType: type }),
			...(dealId && { dealId }),
			...(clientId && { clientId }),
			...(contactId && { contactId }),
		};

		console.log('📋 ~ Create task object', body);
		const response = await createTask(body).unwrap();
		return { response, body: { ...body, pipeline } };
	};

	const formik = useFormik({
		initialValues: {
			...initialValues,
			...((objectType === 'deal' || objectType === 'ticket') &&
        deal && { deal }),
			...(objectType === 'client' && client && { client }),
			...(objectType === 'contact' && contact && { contact }),
			...(objectType && { objectType }),
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		validateOnBlur: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);
			const endTime = formik.values.endTime ? formik.values.endTime.toISOString() : getDefaultDateTime(formik.values.dueDate).toISOString();
			const startTime = formik.values.startTime ? formik.values.startTime.toISOString() : getDefaultDateTime(formik.values.dueDate).toISOString();
			const taskWithClient = (formik.values?.client?.name?.length > 0 || client?.name) ? `${client?.name ?? formik.values?.client?.name} - ${formik.values.title}` : formik.values.title;
			try {
				//Event basic
				const eventBody = {
					subject: taskWithClient,
					body: {
						contentType: 'HTML',
						content: formik.values.notes,
					},
					start: {
						dateTime: startTime,
						timeZone: 'New Zealand Standard Time',
					},
					end: {
						dateTime: endTime,
						timeZone: 'New Zealand Standard Time',
					},
					location: {
						displayName: formik.values.location,
					},
				};
				if (formik.values.isOnlineMeeting === true) {
					eventBody.isOnlineMeeting = true;
				} else {
					eventBody.isOnlineMeeting = false;
				}
				eventBody.attendees = formik.values.attendees.map((contact) => ({
					emailAddress: {
						address: contact.contactEmail ?? contact.email,
						name: contact?.contactEmail 
							? contact.contactName 
							: `${contact.firstName} ${contact?.lastName ?? ''}`
					},
					type: 'required',
				}));
				if (formik.values.taskType === 'MEETING') {
					// eventBody.attendees = formik.values.attendees.map((contact) => ({
					// 	emailAddress: {
					// 		address: contact.contactEmail ?? contact.email,
					// 		name: contact?.contactEmail 
					// 			? contact.contactName 
					// 			: `${contact.firstName} ${contact?.lastName ?? ''}`
					// 	},
					// 	type: 'required',
					// }));
					const meetingEventRes = await createOnlineMeetingEvent(eventBody);
					console.log('meeting event res: ', meetingEventRes);
				} else {
					eventBody.isOnlineMeeting = false;
					const toDoEventRes = await createEventTask(eventBody);
					console.log('event to do res: ', toDoEventRes);
				}
				const updatedTaskType =
			  formik.values.taskType === 'MEETING'
			  	? 'TODO'
			  	: formik.values.taskType;
				formik.setFieldValue('taskType', updatedTaskType);

				const res = await handleCreateTask();
				if (res) {
					console.log('🚀 ~ Successfully created task', res);
					const taskId = res.response?.id;
					const path = window.location.pathname;
					const { clientId, dealId, pipeline } = res.body;
					let newPath, params;
					if (dealId) {
						const pathName = getUrlFromPipeline(pipeline);
						newPath = `${pathName}/${dealId}`;
						params = { state: { task: taskId } };
					} else if (clientId) {
						newPath = `/clients/${clientId}`;
						params = { state: { task: taskId } };
					}
					console.log('Body', res.body);
					dispatch(
						showToast({
							message: 'New task created!',
							autohide: false,
							...(path.startsWith('/my-tasks') && {
								autohide: false,
								action: {
									path: newPath,
									params,
									label: 'GO TO TASK',
								},
							}),
						})
					);
					resetForm();
					handleClose();
				} else {
					console.log('🙅 ~ Error creating task');
					setFieldValue(
						'errorMessage',
						'Error creating task. Please try again'
					);
				}
			} catch (err) {
				console.log('🙅 ~ Error creating task', err);
				setFieldValue('errorMessage', 'Error creating task. Please try again');
			}
			setSubmitting(false);
		},
	});

	const { getError, getErrorMessage } = useFormikHelper(formik);

	useEffect(() => {
		console.log('here is the selected client: ', formik.values.client);
	}, [formik.values]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			sx={{ minWidth: '50%', padding: '1em' }}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>Create Task</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<FormLabel>{'Task Type'}</FormLabel>
							<Select
								name="taskType"
								value={formik.values.taskType}
								disabled={formik.isSubmitting}
								onChange={(e) => {
									const selectedValue = e.target.value;
									formik.setFieldValue('taskType', selectedValue);
								}}
								fullWidth={true}
								renderValue={(value) => (
									<Box display="flex" alignItems="center">
										{value === 'CALL' ? (
											<PhoneIcon fontSize="small" /> 
										) : value === 'EMAIL' ? (
											<EmailIcon fontSize="small" />
										) : value === 'MEETING' ? (
											<GroupsIcon fontSize="small" />
										) : (
											<ChecklistIcon fontSize="small" />
										)}
										<Typography sx={{ paddingLeft: '0.5em' }} variant="body1" noWrap>
											{value}
										</Typography>
									</Box>

								)}
							>
								{taskTypes.map((value) => {
									return (
										<MenuItem
											value={value}
											key={`task-type-selection-${value}`}
										>
											<ListItemIcon>
												{value === 'CALL' ? (
													<PhoneIcon size="small" />
												) : value === 'EMAIL' ? (
													<EmailIcon size="small" />
												) : value === 'MEETING' ? (
													<GroupsIcon size="small" />
												) : (
													<ChecklistIcon size="small" />
												)}
											</ListItemIcon>

											<Typography variant="body1" noWrap>
												{value}
											</Typography>
										</MenuItem>
									);
								})}
							</Select>
						</Grid>
						<Grid item xs={6}>
							<PrioritySelect
								disabled={formik.isSubmitting}
								priority={formik.values.priority}
								setPriority={(value) => formik.setFieldValue('priority', value)}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormLabel required>{'Date'}</FormLabel>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									name="dueDate"
									disablePast={true}
									disabled={formik.isSubmitting}
									format="dd/MM/yyyy"
									value={formik.values.dueDate}
									sx={{ width: '100%' }}
									onChange={(value) => {
										const newDate =
                      !isValid(value) || isAfter(minDate, value)
                      	? minDate
                      	: value;
										formik.setFieldValue('dueDate', newDate);
									}}
								/>
							</LocalizationProvider>
						</Grid>
						{(formik.values.taskType === 'MEETING' ||
              formik.values.taskType === 'CALL') && (
							<>
								<Grid item xs={3}>
									<FormLabel required>{'Start Time'}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											value={formik.values.startTime}
											onChange={(value) => {
												const dueDate = new Date(formik.values.dueDate);
												const newStartTime = new Date(dueDate);
												newStartTime.setHours(
													value.getHours(),
													value.getMinutes(),
													value.getSeconds(),
													value.getMilliseconds()
												);
												formik.setFieldValue('startTime', newStartTime);
											}}
										/>
									</LocalizationProvider>
									{formik.touched.startTime && formik.errors.startTime && (
										<FormHelperText error>
											{formik.errors.startTime}
										</FormHelperText>
									)}
								</Grid>
								<Grid item xs={3}>
									<FormLabel required>{'End Time'}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											value={formik.values.endTime}
											onChange={(value) => {
												const dueDate = new Date(formik.values.dueDate);
												const newEndTime = new Date(dueDate);
												newEndTime.setHours(
													value.getHours(),
													value.getMinutes(),
													value.getSeconds(),
													value.getMilliseconds()
												);
												formik.setFieldValue('endTime', newEndTime);
											}}
										/>
									</LocalizationProvider>
									{formik.touched.endTime && formik.errors.endTime && (
										<FormHelperText error>
											{formik.errors.endTime}
										</FormHelperText>
									)}
								</Grid>
							</>
						)}
						{formik.values.taskType === 'MEETING' && (
							<>
								{' '}
								<Grid item xs={12}>
									<FormLabel>{'Attendees'}</FormLabel>
									<InstantClientSelect
										// clientId={formik.values.client?.id}
										// clientName={formik.values[CLIENT_NAME]}
										showContact={true}
										setClient={(contacts) => {
											if (contacts) {
												console.log('here is the selected client: ', contacts);
												formik.setFieldValue('attendees', contacts);
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormLabel>{'Meeting type'}</FormLabel>
									<ToggleButtonGroup
										color="primary"
										value={formik.values.isOnlineMeeting}
										size="small"
										exclusive
										onChange={handleChange}
										sx={{
											marginLeft: 2,
											boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
											'& .MuiToggleButtonGroup-grouped': {
												border: 'none',
												margin: '4px',
												padding: '3px 12px',
												'&:not(:first-of-type)': {
													borderRadius: '5px',
												},
												'&:first-of-type': {
													borderRadius: '5px',
												},
												color: 'rgba(189, 189, 189, 1)',
												backgroundColor: 'transparent',
												'&.Mui-selected': {
													color: 'rgba(80, 90, 252, 1)',
													backgroundColor: 'rgba(80, 90, 252, 0.33)',
												},
												'&:hover': {
													backgroundColor: 'rgba(80, 90, 252, 0.1)',
												},
											},
										}}
									>
										<ToggleButton value={false}>In person</ToggleButton>
										<ToggleButton value={true}>Online</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
								{formik.values.isOnlineMeeting === false && (
									<Grid item xs={12}>
										<FormLabel required>{'Location'}</FormLabel>
										<TextField
											fullWidth={true}
											name="location"
											{...formik.getFieldProps('location')}
											disabled={formik.isSubmitting}
										/>
										{formik.touched.location && formik.errors.location && (
											<FormHelperText error>
												{formik.errors.location}
											</FormHelperText>
										)}
									</Grid>
								)}
							</>
						)}
						<Grid item xs={12}>
							<FormLabel required>{'Title'}</FormLabel>
							<TextField
								fullWidth={true}
								name="title"
								{...formik.getFieldProps('title')}
								error={formik.touched.title && Boolean(formik.errors.title)}
								helperText={formik.touched.title && formik.errors.title}
								disabled={formik.isSubmitting}
								required
							/>
						</Grid>
						{!deal && !client && !contact && (
							<Grid item xs={12}>
								<FormLabel>{'Associated client'}</FormLabel>
								<InstantClientSelect
									clientId={formik.values.client?.id}
									disabled={formik.values.deal != null || formik.isSubmitting}
									setClient={(newClient) => {
										formik.setFieldValue('client', newClient);
										console.log('NEW CLIENT', newClient);
										if (newClient) {
											formik.setFieldValue('deal', null);
										}
									}}
								/>
							</Grid>
						)}
						{!deal && !client && !contact && (
							<Grid item xs={12}>
								<FormLabel>{'Associated deal or claim'}</FormLabel>
								<InstantDealSelect
									disabled={formik.isSubmitting}
									clientId={formik.values.client?.id}
									dealId={formik.values.deal?.id}
									setDeal={(newDeal) => {
										formik.setFieldValue('deal', newDeal);
										if (newDeal) {
											formik.setFieldValue('objectType', newDeal.objectType);
											// formik.setFieldValue('client', null);
										}
									}}
									autoPopulate={true}
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<FormLabel>{'Assignee'}</FormLabel>
							<OwnerSelect
								initialId={formik.values.ownerId}
								disabled={formik.isSubmitting}
								onChange={(value) => {
									formik.setFieldValue('ownerId', value);
									const owner =
                    brokers?.data?.filter((b) => b.id === value)[0] ??
                    null;
									const updatedAttendees = [...formik.values.attendees];
									if (owner && !updatedAttendees.includes(owner)) {
										updatedAttendees.push(owner);
									}
									formik.setFieldValue('attendees', updatedAttendees);
								}}
								showEmail={true}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormLabel required>{'Task Info'}</FormLabel>
							<TextField
								fullWidth={true}
								name="notes"
								multiline
								rows={4}
								{...formik.getFieldProps('notes')}
								error={formik.touched.notes && Boolean(formik.errors.notes)}
								helperText={formik.touched.notes && formik.errors.notes}
								disabled={formik.isSubmitting}
								required
							/>
						</Grid>
					</Grid>
					{formik.values.errorMessage.length > 0 && (
						<FormHelperText error>{formik.values.errorMessage}</FormHelperText>
					)}
					{/* <pre>{JSON.stringify(formik.values, undefined, 2)}</pre> */}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => {
							formik.resetForm();
							handleClose();
						}}
						color="primary"
					>
            Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						variant="contained"
						disabled={isLoading || formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<CircularProgress size="2em" sx={{ color: '#ffffff' }} />
						) : (
							'Create'
						)}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
