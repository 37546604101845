import React from 'react';
import { Button, Grid } from '@mui/material';

export const AddProductButton = ({ onClick, disabled }) => {
	return (
		<Grid
			item
			xs={12}
			sx={{
				paddingTop: '1em !important',
			}}
		>
			<Button
				variant='text'
				onClick={onClick}
				sx={{
					textTransform: 'none',
				}}
				disabled={disabled}
			>
				Add new Product
			</Button>
		</Grid>
	);
};
