import React from 'react';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import CreateClaimForm from '@/components/forms/CreateClaimForm';

export const CreateClaimPage = () => {
	return (
		<AuthenticatedPageLayout>
			<CreateClaimForm />
		</AuthenticatedPageLayout>
	);
};