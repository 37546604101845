/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { Avatar, Box, Button, Card, Skeleton, Stack, Typography } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/app/routes';

export const ContactAboutCard = () => {
	const navigate = useNavigate();

	const { client, contact, loading } = determineContext();

	const getContactInitials = () => {
		if (contact.firstname && contact.lastname) {
			return (
				contact.firstname.charAt(0).toUpperCase() + contact.lastname.charAt(0).toUpperCase()
			);
		} else {
			return 'NA';
		}
	};

	const createEntry = (header, value) => {
		return (
			<Stack>
				<Typography variant='form_label'>{header}</Typography>
				<Typography variant='blue_text'>{value}</Typography>
			</Stack>
		);
	};

	const fullName = contact ? `${contact.firstname} ${contact.lastname}` : 'Unknown';

	return (
		<Card sx={{ p: 3, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
			<Stack spacing={3}>
				<Box display={'flex'} justifyContent='center'>
					<Avatar
						sx={{
							width: 200,
							height: 200,
							borderRadius: 100,
							backgroundColor: '#34495E',
							fontSize: '4em',
						}}
					>
						{getContactInitials()}
					</Avatar>
				</Box>
				<Typography variant='subtitle1'>CONTACT DETAILS</Typography>
				{loading.contact ? (
					<Skeleton variant='text' animation='wave' sx={{ width: '66%' }} />
				) : (
					createEntry('Contact name', fullName ?? '-')
				)}
				{loading.contact ? (
					<Skeleton variant='text' animation='wave' sx={{ width: '66%' }} />
				) : (
					createEntry('Contact phone', contact.phone ?? '-')
				)}
				{loading.contact ? (
					<Skeleton variant='text' animation='wave' sx={{ width: '66%' }} />
				) : (
					createEntry('Contact email', contact.email ?? '-')
				)}
				{loading.contact ? (
					<Skeleton variant='text' animation='wave' sx={{ width: '66%' }} />
				) : (
					createEntry('Company name', contact?.company ?? 'No company')
				)}
				{/* {loading.client ? <Skeleton variant="text" animation="wave" sx={{width: '66%'}} /> : createEntry('Company address', client.address ?? '-')} */}
				{loading.client || loading.contact ? (
					<Skeleton
						variant='text'
						animation='wave'
						sx={{ width: '100%', height: '3em' }}
					/>
				) : (
					<Button
						color='primary'
						onClick={() => {
							navigate(paths.createNewBusiness, { state: { client, contact } });
						}}
						variant='contained'
					>
						Convert to Client
					</Button>
				)}
			</Stack>
		</Card>
	);
};
