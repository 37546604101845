/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useState } from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	CircularProgress,
	IconButton,
	useTheme,
	Stack,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DOMPurify from 'dompurify';
import { folioBlue, formatDate } from '@/utils/constants';
import '@/styles/index.scss';
import { BlinkingCircle } from '@/components/icons/BlinkingCircle';
import AttachFile from '@mui/icons-material/AttachFile';

export const EmailActivityAccordion = ({ email, index }) => {
	const [expanded, setExpanded] = useState(false);
	const theme = useTheme();

	const date = useMemo(() => {
		const timestamp = email?.created;
		return timestamp
			? new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000)
			: new Date();
	}, [email?.created]);

	const lastUpdated = useMemo(() => {
		const timestamp = email?.lastUpdated;
		return timestamp
			? new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000)
			: new Date();
	}, [email?.lastUpdated]);

	const sanitizedHtml = useMemo(() => {
		const htmlContent = email?.html || '';
		const cleanHtml = DOMPurify.sanitize(htmlContent);
		const parser = new DOMParser();
		const doc = parser.parseFromString(cleanHtml, 'text/html');
	
		doc.querySelectorAll('img').forEach(img => {
			img.style.maxWidth = '100%'; 
			img.style.height = '100%';  
		});
		return doc.body.innerHTML;
	}, [email?.html]);
	const baseTheme = useTheme();

	const handleChange = () => {
		setExpanded((prev) => !prev);
	};
    

	return (
		<Accordion
			key={`activity-tab-task-${index}`}
			expanded={expanded}
			onChange={handleChange}
			sx={{
				marginBottom: '12px',
				borderRadius: '4px',
				boxShadow: baseTheme.shadows[1],
			}}
		>
			<Stack>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<AccordionSummary
						expandIcon={
							<ArrowForwardIosSharpIcon
								sx={{ fontSize: '0.9rem', color: folioBlue }}
							/>
						}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
						sx={{
							flexDirection: 'row-reverse',
							width: '100%',
							marginBottom: '-1rem',
							'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
								transform: 'rotate(90deg)',
							},
						}}
					>
						<Typography variant="task_body" sx={{ marginLeft: '0.5em' }}>
							<span style={{ fontWeight: 'bold' }}>Email - {email?.subject}</span> from{' '}
							<span>{`${email.from}`}</span>
							<span style={{ margin: '0 0.5em' }}>{' | '}</span>
							<span style={{ color: '#505AFC' }}>{formatDate(date, true)}</span>
						</Typography>
					</AccordionSummary>
				</div>
				<Stack
					direction="column"
					spacing={2}
					sx={{ marginLeft: '1.5em' }}
				>

					<Typography>to {email?.to}</Typography>  
					<span style={{alignItems: 'center'}}>
						<span style={{ paddingRight: '5px' }}>
							<BlinkingCircle fontSize='6px' color={folioBlue} />
						</span>
						{email?.status}
					</span>
					{/* <Typography
						onClick={() => handleTaskOpened(index)}
						variant="task_body"
						sx={{ marginLeft: '3px', marginRight: '1.5em', cursor: 'pointer' }}
						style={{
							textDecoration: task.completed ? 'line-through' : 'none',
							width: '100%',
						}}
					>
						{task.subject}
					</Typography> */}
				</Stack>
				<div
					style={{ display: 'flex', justifyContent: 'right', padding: '10px' }}
				>
					<Typography variant="task_updated">
						{`Last updated: ${formatDate(lastUpdated, true)}`}
					</Typography>
				</div>
			</Stack>
			<AccordionDetails sx={{ borderTop: '1px solid rgba(0, 0, 0, .125)', margin: '0 1.5em 1.5em' }}>
				<Stack width="100%">
					<div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
					{email?.attachments.length > 0 && (
						<List
							dense
							sx={{
								width: '100%',
								'& .MuiListItemIcon-root': {
									minWidth: 0,
									marginRight: '12px',
								},
								'& .MuiSvgIcon-root': {
									fontSize: 18,
								},
							}}
						>
							{email?.attachments.map((file, i) => (
								<ListItem disablePadding key={`${file.name}-${i}`}>
									<ListItemButton
										target="_blank"
										rel="noopener noreferrer"
										href={file.url}
									>
										<ListItemIcon>
											<AttachFile />
										</ListItemIcon>
										<ListItemText sx={{ color: '#505AFC' }} primary={`${file.name}.${file.extension}`} />
									</ListItemButton>
								</ListItem>
							))}
						</List>
					)}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};
