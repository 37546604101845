import { ticketsApi } from './claims/ticketsApi';
import { clientsApi } from './clients/clientsApi';
import { contactsApi } from './contacts/contactsApi';
import { dealsApi } from './deals/dealsApi';

export const getApi = (objectType) => {
	switch ((objectType ?? '').toUpperCase()) {
	case 'DEAL': {
		return dealsApi;  
	}
	case 'CLIENT': {
		return clientsApi;
	}
	case 'CONTACT': {
		return contactsApi;
	}
	case 'TICKET': {
		return ticketsApi;
	}
	default: {
		return null;
	}
	}
};