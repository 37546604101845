import { createSlice } from '@reduxjs/toolkit';

export const userPrefsSlice = createSlice({
	name: 'userPrefs',
	initialState: {
		groupView: false,
		hubspotId: null,
		kanbanView: false,
	},
	reducers: {
		setGroupView: (state, action) => {
			console.log('👾 ~ setGroupView: action.payload', action.payload);
			state.groupView = action.payload;
		},
		setHubspotId: (state, action) => {
			console.log('👾 ~ setHubspotId: action.payload', action.payload);
			state.hubspotId = action.payload;
		},
		setKanbanView: (state, action) => {
			console.log('👾 ~ setKanbanView: action.payload', action.payload);
			state.kanbanView = action.payload;
		}
	},
});

export const { setGroupView, setHubspotId, setKanbanView } = userPrefsSlice.actions;
export default userPrefsSlice.reducer;