import React, { useEffect, useState } from 'react';
import { Typography, TextField, Skeleton } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { determineContext } from '@/hooks/determineContext';
import { theme } from '@/app/theme';
import { useUpdateDealPropertiesMutation } from '@/features/deals/dealsApi';
import { useUpdateTicketPropertiesMutation } from '@/features/claims/ticketsApi';

export const DealCardAdviserFee = () => {

	const { deal, objectType, loading } = determineContext();
	const loadingDeal = loading?.deal;

	const [adviserFeeValue, setAdviserFeeValue] = useState(deal?.adviser_fee ?? 0);
    
	useEffect(() => {
		setAdviserFeeValue(deal?.adviser_fee ?? 0);
	}, [loadingDeal]);

	const isDeal = objectType === 'deal';

	const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const [updateDealProperties, { isLoading }] = isDeal ? useUpdateDealPropertiesMutation() : useUpdateTicketPropertiesMutation();

	const updateAdviserFee = async () => {
		const params = {
			adviser_fee: adviserFeeValue,
		};

		await updateDealProperties({
			[isDeal ? 'dealId' : 'ticketId']: deal.hs_object_id,
			body: params,
		}).unwrap();
	};

	return <>
		<Typography sx={minimalLabelTheme}>Current Premium</Typography>
		{loadingDeal ? <Skeleton animation='wave' width='55%' height='2em' /> : <NumericFormat
			fullWidth
			disabled={isLoading}
			customInput={TextField}
			thousandSeparator
			decimalScale={0}
			fixedDecimalScale
			allowNegative={false}
			prefix="$"
			variant="standard"
			sx={minimalInputTheme}
			className="minimal-input"
			value={adviserFeeValue}
			onValueChange={({floatValue}) => setAdviserFeeValue(floatValue)}
			onBlur={updateAdviserFee}
		/>}
	</>;
};