import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Box,
	Card,
	Tab,
	Tabs,
	Typography,
	Stack,
	Skeleton,
	Grid,
	IconButton,
	Container,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { firstTwoCapsOrNumbers, folioBlue } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { AssociatedClient } from '@/components/cards/DealCardValues/AssociatedClient';

import { DealNameUpdateField } from '@/components/cards/DealCardValues/DealChangeNameField';
import { useDispatch, useSelector } from 'react-redux';

export const TabView = ({ tabs, title }) => {
	const location = useLocation();

	const { loading } = determineContext();
	const isLoading = loading.client || loading.deal;
	const dispatch = useDispatch();

	const [currentTab, setCurrentTab] = useState(() => {
		if (location.state?.task) {
			return 'tasks';
		}
		return tabs[0].value;
	});

	const handleTabChange = (event, value) => {
		event.preventDefault();
		setCurrentTab(value);
	};

	useEffect(() => window.scrollTo(0, 0), [currentTab]);

	const activeTabComponents = useMemo(() => {
		return tabs.map((tab) => {
			const tabComponent = (
				<Tab
					sx={{ fontWeight: 'bold', color: 'rgba(108, 115, 127, 1)' }}
					key={tab.value}
					label={
						['factfind', 'quotes', 'risk'].includes(tab.value) ? (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									position: 'relative',
									height: '100%',
								}}
							>
								{tab.label}
								<Box
									sx={{
										position: 'absolute',
										top: '30%',
										right: '-3.25em',
										transform: 'translateY(-75%) rotate(45deg)',
										backgroundColor: folioBlue,
										color: 'white', // Text color
										padding: '2px 20px',
										fontSize: '0.7em',
										whiteSpace: 'nowrap',
									}}
								>
									{'BETA'}
								</Box>
							</Box>
						) : (
							tab.label
						)
					}
					value={tab.value}
				/>
			);
			return tabComponent;
		});
	}, [tabs]);

	const CurrentTabContent = tabs.find((tab) => tab.value === currentTab)?.ContentComponent;
	const CurrentHeaderComponent = tabs.find((tab) => tab.value === currentTab)?.HeaderComponent;

	const LoadingComponentHeader = () => (
		<Box display='flex' sx={{ justifyContent: 'right' }}>
			<Skeleton animation='wave' variant='rounded' height='3em' width='8em' />
		</Box>
	);

	const LoadingHeader = () => (
		<Box display='flex' width="100%" sx={{ alignItems: 'center' }}>
			<Skeleton
				animation='wave'
				variant='rounded'
				height='3em'
				width='3em'
				sx={{ mr: '10px' }}
			/>
			<Stack width='100%'>
				<Skeleton animation='wave' variant='text' width='70%' height='2em' />
				<Skeleton animation='wave' variant='text' width='32%' />
			</Stack>
		</Box>
	);

	return (
		<Box
			className='tab-view'
			sx={{
				backgroundColor: (theme) =>
					theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100',
				mt: '1em',
			}}
		>
			<Box display='flex' px={3} width='100%' sx={{ alignItems: 'center' }}>
				{isLoading ? (
					<LoadingHeader />
				) : (
					<>
						<Avatar
							sx={{ backgroundColor: '#1C2536', marginRight: '10px' }}
							variant='rounded'
						>
							{firstTwoCapsOrNumbers(title ?? 'NA')}
						</Avatar>
						<Stack width='100%'>
							<Stack direction='row' alignItems='center' width='100%' spacing={1}>
								<DealNameUpdateField title={title ?? 'Unknown'} />
							</Stack>
							<AssociatedClient hideHeader={true} />
						</Stack>
					</>
				)}
			</Box>
			<Tabs
				indicatorColor='primary'
				scrollButtons='auto'
				textColor='primary'
				value={currentTab}
				sx={{ mt: 3, mb: 2, borderBottom: 'solid 1px #d3d3d3' }}
				variant='scrollable'
				onChange={handleTabChange}
			>
				{activeTabComponents}
			</Tabs>
			<Box px={3}>
				{CurrentHeaderComponent &&
					(isLoading ? <LoadingComponentHeader /> : <CurrentHeaderComponent />)}
				{CurrentTabContent && <CurrentTabContent />}
			</Box>
		</Box>
	);
};
