import React from 'react';
import { useRouteError } from 'react-router-dom';
import { Typography, Box, Container } from '@mui/material';
import { PageLayout } from '@/components/layouts/PageLayout';

export const ErrorPage = () => {
	const error = useRouteError();
	console.error(error);

	return (
		<PageLayout>
			<Container>
				<Box sx={{paddingTop: '4em'}}>
					<Typography variant="h4">
                Sorry, an unexpected error has occurred.
					</Typography>
					<p><i>{error.statusText || error.message}</i></p>
				</Box>
			</Container>
		</PageLayout>
	);
};