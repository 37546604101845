import React from 'react';
import {
	Card,
	Skeleton,
} from '@mui/material';

export const LoadingActivityCard = () => {
	
	return (
		<Card sx={{ marginBottom: '12px', px: '16px', pb: '16px' }} >
			<div style={{ display: 'flex', marginTop: '12px', paddingBottom: '12px' }}>
				<Skeleton
					variant="text"
					animation="wave"
					width="60%"
				/>
			</div>
			<Skeleton
				animation="wave"
				height="18px"
				width="100%"
			/>
			<Skeleton
				animation="wave"
				height="18px"
				width="70%"
			/>
			<div style={{ display: 'flex', justifyContent: 'right', paddingTop: '1em' }}>
				<Skeleton
					animation="wave"
					height="16px"
					width="20%"
				/>
			</div>
		</Card>
	);
};
