import React, { useState, useEffect, useCallback } from 'react';
import { 
	Box, 
	Card, 
	Divider, 
	Button, 
	Tab, 
	Tabs,
	Typography,
	CardHeader,
	Grid,
} from '@mui/material';
import 'pdfjs-dist/build/pdf.worker.mjs';
import { InvoiceTable } from '@/components/tables/InvoiceTable';
import { IS_DEV } from '@/utils/environment';
import { PageHeader } from '@/components/layouts/PageHeader';

export const InvoicesTableView = () => {

	const [currentTab, setCurrentTab] = useState('active');

	const handleTabChange = (event, value) => {
		event.preventDefault();
		setCurrentTab(value);
	};

	const tabs = [
		{
			label: 'Active Invoices',
			value: 'active',
		},
		{
			label: 'Archived',
			value: 'archived',
		}
	];

	return (
		<Box
			sx={{
				backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100'),
				p: 3,
			}}
		>
			<CardHeader disableTypography title={
				<PageHeader title={'Invoices'} showGroupView/>
			}
			/>
			{IS_DEV ? <Card>
				<Tabs
					indicatorColor="primary"
					scrollButtons="auto"
					textColor="primary"
					value={currentTab}
					sx={{ px: 3 }}
					variant="scrollable"
					onChange={handleTabChange}
				>
					{tabs.map((tab) => (
						<Tab
							sx={{ fontWeight: 'bold' }}
							key={tab.value}
							label={tab.label}
							value={tab.value}
						/>
					))}
				</Tabs>
				{/* <SearchComponent />  */}
				<Divider />
				<InvoiceTable tab={currentTab}/>
			</Card> : <Card>
				<Typography>
					{'Invoices will be implemented in a future version of Folio Broker Portal'}
				</Typography>
			</Card>}
		</Box>
	);
};