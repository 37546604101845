/* eslint-disable no-mixed-spaces-and-tabs */
import axios from 'axios';
import { useMsal } from '@azure/msal-react';

// Custom hook to interact with Microsoft graph
export const useMsGraph = () => {
	// Using MSAL hook to get auth instance and accounts
	const { instance, accounts } = useMsal();

	const scopes = [
		'User.Read',
		'Calendars.ReadWrite',
		'OnlineMeetings.ReadWrite',
		'Calendars.Read',
		'User.Read.All'
	];

	const getGraphAccessToken = async () => {
		const request = {
			scopes: scopes,
			account: accounts[0],
		};

		return await instance.acquireTokenSilent(request)
			.then(response => {
				console.log('🔓 ~ Access token retrieved silently');
				return response.accessToken;
			})
			.catch(async error => {
				console.error('🙅 ~ Error retrieving access token silently', error);
				// Redirect user to login screen if there is an issue getting access token
				return instance.acquireTokenRedirect(request)
					.then(response => {
						console.log('🔓 ~ Access token retrieved via redirect');
						return response.accessToken;
					})
					.catch(e => {
						console.error('🙅 ~ Token redirect error', e);
						return Promise.reject(e);
					});
			});
	};

	const createOnlineMeetingEvent = async (eventDetails) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				'Authorization': `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			};
			const response = await axios.post('https://graph.microsoft.com/v1.0/me/events', eventDetails, { headers });
			console.log('🚀 ~ Event created successfully', response.data);
			return response.data;
		} catch (error) {
			console.error('🙅 ~ Error creating event', error);
			throw error;
		}
	};


	const createEventTask = async (eventDetails) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				'Authorization': `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			};
			const response = await axios.post('https://graph.microsoft.com/v1.0/me/calendar/events', eventDetails, { headers });
			console.log('🚀 ~ Event created successfully', response.data);
			return response.data;
		} catch (error) {
			console.error('🙅 ~ Error creating event', error);
			throw error;
		}
	};


	const getUserCalendars = async (email) => {
		const userId = await getUserIdByEmail(email);
		const accessToken = await getGraphAccessToken();
		const headers = {
			'Authorization': `Bearer ${accessToken}`,
			'Content-Type': 'application/json'
		};
		try {
			const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${userId}/calendars`, { headers });
			console.log('Calendars retrieved successfully:', response.data.value);
			return response.data.value;
		} catch (error) {
			console.error('Error retrieving calendars:', error);
			throw error;
		}
	};
	const getUserIdByEmail = async (email) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				'Authorization': `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			};
			const response = await axios.get(`https://graph.microsoft.com/v1.0/users/${email}`, { headers });
			console.log(':mag: ~ User retrieved successfully', response.data);
			return response.data.id;
		} catch (error) {
			console.error(':no_good: ~ Error retrieving user', error);
			throw error;
		}
	};


	// Returning the functions to be used elsewhere in the app
	return { getGraphAccessToken, createOnlineMeetingEvent, createEventTask, getUserIdByEmail, getUserCalendars };
};