
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useSyncMsalWithStore } from '@/hooks/syncMsalWithStore';
import { theme } from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import routes from './routes';

const router = createBrowserRouter(routes);

export default function App() {
	// Sync MSAL account with Redux store
	useSyncMsalWithStore();

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<RouterProvider router={router}/>
		</ThemeProvider>
	);
}
