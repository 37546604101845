/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import useDealData, { initialState } from '@/hooks/useDealData';

const TicketContext = createContext(initialState);

// Ticket ContextProvider component that provides the Deal context to its children.
export const TicketContextProvider = ({ children }) => {
	const { id, contextValue, isLoading, isError } = useDealData('ticket');
	const ticketId = id;
	
	useEffect(() => console.log('TICKET CONTEXT', contextValue), [contextValue]);
	
	return (
		<TicketContext.Provider value={contextValue}>
			{isError ? (
				<Box>
					<Typography textAlign="center">
            There was an issue loading this ticket. Please try again.
					</Typography>
				</Box>
			) : (
				children
			)}
		</TicketContext.Provider>
	);
};

export default TicketContext;
