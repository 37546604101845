import { FormLabel, MenuItem, Select, Skeleton } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { theme } from '@/app/theme';
import { useRetrieveClaimSubjectOfLossQuery, useUpdateInsightClaimMutation, useUpdateTicketPropertiesMutation } from '@/features/claims/ticketsApi';
import { determineContext } from '@/hooks/determineContext';
import { firestore } from '@/utils/firebase';

export const ClaimTypeSelectWrapper = ({ minimal, noLabel, required }) => {
	const { deal, loading, claim, dealRow } = determineContext();
	
	const { data: claimSubjectDetails } = useRetrieveClaimSubjectOfLossQuery();
	const claimSubjectTypes = useMemo(() => claimSubjectDetails?.data?.Results || [], [claimSubjectDetails]);

	const [claimType, setClaimType] = useState('');

	useEffect(() => {
		if (dealRow?.claimType && claimSubjectTypes.length > 0) {
			const matchingClaimType = claimSubjectTypes.find(type => type.Description === dealRow.claimType);
			setClaimType(matchingClaimType);
		}
	}, [dealRow?.claimType, claimSubjectTypes]);
    
	const [updateTicketProperties, { isLoading }] = useUpdateTicketPropertiesMutation();
	const [
		updateInsightClaim,
		{ isLoading: isLoadingClaimUpdate },
	] = useUpdateInsightClaimMutation();

	const handleClaimTypeChange = async (value) => {
		setClaimType(value);
		try {
			try {
				const docRef = doc(firestore, 'deals', dealRow.id);
				await updateDoc(docRef, { claimType: value.Description }).then(res => console.log(`Successfully updated doc ${dealRow.id}`));
			} catch (error) {
				console.log('Error updating Firebase document', deal, error);
			}

			await updateTicketProperties({
				ticketId: deal.id ?? deal.hs_object_id,
				body: { claimtype: value.Description },
			}).unwrap();

			await updateInsightClaim({
				claimId: claim.Id,
				properties: {
					SubjectId: value.Id
				}
			}).unwrap();
		} catch (error) {
			console.error('Error updating claim:', error);
		}
	};

	return <ClaimTypeSelect
		claimType={claimType}
		setClaimType={handleClaimTypeChange}
		minimal={minimal}
		noLabel={noLabel}
		disabled={loading?.deal || isLoadingClaimUpdate || isLoading}
		loading={isLoadingClaimUpdate || isLoading}
		required={required}
	/>;
};

export const ClaimTypeSelect = ({ claimType, disabled, setClaimType, minimal, noLabel, required, loading }) => {
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;
	const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;

	const {
		data: claimSubjectDetails,
		isLoading,
	} = useRetrieveClaimSubjectOfLossQuery();

	const claimSubjectTypes = useMemo(() => claimSubjectDetails?.data?.Results || [], [claimSubjectDetails]);

	return (
		<>
			{!noLabel && <FormLabel sx={minimal ? minimalLabelTheme : undefined} required={required}>{'Claim Type'}</FormLabel>}
			{loading || isLoading ? <Skeleton height='4em' width='100%'/> : <Select
				name="claimType"
				value={claimType}
				disabled={disabled || isLoading} 
				onChange={(e) => setClaimType(e.target.value)}
				fullWidth={true}
				size={minimal ? 'small' : null}
				displayEmpty
				renderValue={(value) => (
					<span>{value?.Name ?? ''}</span>
				)}
				sx={minimal ? minimalInputTheme : {}}
				variant={minimal === true ? 'standard' : 'outlined'}
			>
				{claimSubjectTypes.map((value) => (
					<MenuItem key={`claim-subject-selection-${value.Id}`} value={value}>
						{value.Name}
					</MenuItem>
				))}
			</Select>}
		</>
	);
};
