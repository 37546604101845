import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Typography,
	Box,
	Card,
	useTheme,
	Link,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	useMediaQuery,
	Skeleton,
} from '@mui/material';
import '@/styles/index.scss';
import { folioBlue, formatDate } from '@/utils/constants';
import {
	useGetDealPoliciesQuery,
	useGetDealRowQuery,
} from '@/features/deals/dealsApi';
import LinkIcon from '@mui/icons-material/Link';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { getComparator, stableSort } from '@/components/table/functions';
import { PolicyRow } from '@/components/tables/PolicyRow';
import { Link as RouterLink } from 'react-router-dom';

export const PolicyLinkedCard = ({ activity }) => {
	const dealId = activity.data.dealId;

	const baseTheme = useTheme();

	const policiesQuery = useGetDealPoliciesQuery({ dealId }, { skip: !dealId });

	const { data: dealRow } = useGetDealRowQuery(dealId, { skip: !dealId });

	const ownerId = useMemo(() => dealRow?.ownerId, [dealRow?.ownerId]);
	const { data: owner } = useGetOwnerQuery(
		{ ownerId },
		{
			skip: !ownerId,
		}
	);

	const order = 'desc';
	const orderBy = 'createdWhen';

	const policyHeadCells = [
		{
			id: 'policyNumber',
			numeric: false,
			label: 'Policy number',
			align: 'left',
			sortable: false,
		},
		{
			id: 'renewalDate',
			numeric: false,
			label: 'Renewal date',
			align: 'left',
			sortable: false,
		},
		{ id: 'description', numeric: false, label: 'Description', align: 'left', sortable: false },
		{ id: 'insurer', numeric: false, label: 'Insurer', align: 'left', sortable: false },
	];

	const policies = useMemo(() => policiesQuery.data?.policies ?? [], [policiesQuery.data]);

	const getFullName = useCallback(() => {
		return owner ? `${owner?.firstName} ${owner?.lastName}` : 'Unknown';
	}, [owner]);

	const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
	const displayCells = useMemo(() => {
		return isLargeScreen ? policyHeadCells : policyHeadCells.slice(0, 3);
	}, [isLargeScreen]);

	return (
		<Card
			key={`activity-linked-card-${dealId}`}
			sx={{
				marginBottom: '12px',
				borderRadius: '4px',
				padding: 2,
				boxShadow: baseTheme.shadows[1],
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
					<LinkIcon sx={{ color: folioBlue }} />
					<Typography variant='task_body' style={{ padding: '10px' }}>
						<span style={{ fontWeight: 'bold' }}>Policies linked</span> by{' '}
						<span>{getFullName()}</span>
						<span style={{ margin: '0 0.5em' }}>{' | '}</span>
						<span style={{ marginRight: 5, color: '#505AFC' }}>
							{' '}
							{formatDate(activity.data.createdDate._seconds * 1000)}{' '}
						</span>
					</Typography>
				</Box>
				<Link
					to={`/clients/${activity.data.clientId}`}
					component={RouterLink}
					sx={{
						textDecorationLine: 'none',
						alignSelf: 'center', // Align link to the center vertically
						marginLeft: '10px',
					}}
				>
					View Client
				</Link>
			</Box>

			<TableContainer>
				<Table
					aria-labelledby='tableTitle'
					size='small'
					aria-label='enhanced table'
					sx={{ border: 'none', '& td, & th': { border: 'none' } }}
				>
					<FolioTableHead
						order={order}
						orderBy={orderBy}
						rowCount={policies?.length}
						headCells={displayCells}
					/>
					<TableBody>
						{policies.length > 0 ? (
							stableSort(policies, getComparator(order, orderBy)).map(
								(policy, index) => (
									<PolicyRow
										hideCheckbox={true}
										key={`policy-row-index-${index}-${policy.Id}`}
										policy={policy}
									/>
								)
							)
						) : (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align='center'>
									{policiesQuery.isLoading ? (
										<Skeleton width={'100%'} />
									) : (
										<Typography variant='subtitle1'>
											No policies linked
										</Typography>
									)}
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Card>
	);
};
