import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as InsightIconSVG }  from '@/assets/icons/insight_icon.svg';

export const InsightIcon = (props) => {
	
	return (
		<SvgIcon {...props}>
			<InsightIconSVG />
		</SvgIcon>
	);
};
