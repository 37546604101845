import React, {  } from 'react';
import { 
	Box, 
	CardHeader,
	Typography,
	CircularProgress,
} from '@mui/material';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { PageHeader } from '@/components/layouts/PageHeader';
import { useSelector } from 'react-redux';
import { DealTableView } from '../DealTableView';
import { KanbanView } from '../KanbanView';

export const DealPipelineView = ({pipelineId }) => {
	// const [currentTab, setCurrentTab] = useState('active');

	const { kanbanView } = useSelector((state) => state.userPrefs);
    
	// Fetching pipeline properties using a custom hook
	const { 
		data: pipelineData, 
		isLoading, 
		isError, 
		isUninitialized 
	} = useGetPipelinePropertiesQuery(pipelineId, {skip: !pipelineId});
	const pipelineName = pipelineData?.pipelines?.label;

	return (
		<Box
			sx={{
				backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100'),
				p: 3,
			}}
		>
			{isLoading || isUninitialized
				? 
				<div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div> 
				: 
				isError 
					? 
					<Box>
						<Typography textAlign='center'>
						There was an issue loading this pipeline. Please try again.
						</Typography>
					</Box> 
					: <>
						<CardHeader 
							disableTypography 
							title={<PageHeader title={pipelineName} showGroupView/>}
						/>
						{kanbanView ? 
							<KanbanView pipelineId={pipelineId} /> : 
							<DealTableView pipelineId={pipelineId} />}
					</>}
		</Box>
	);
};