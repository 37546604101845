import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
	TextField,
	InputAdornment,
	IconButton,
	CircularProgress,
	Grid,
	Chip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useGetTicketsQuery } from '@/features/claims/ticketsApi';
import { useGetDealsQuery } from '@/features/deals/dealsApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { folioBlue } from '@/utils/constants';
import { IS_DEV } from '@/utils/environment';
import { InstantSearch, Configure, useHits, useSearchBox } from 'react-instantsearch';
import { 
	setSearchText,
	setSearchValue,
	setOpen,
	reset,
	setRows,
	setFilters,
} from '@/features/select/dealSelectSlice';
import { dealClient } from '@/utils/typesense';

export const DealSelect = ({setDeal, disabled, client}) => {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const {
		limit,
		orderBy,
		order,
		open,
		rows,
		searchText,
		searchValue,
		resetKey,
		filters,
	} = useSelector((state) => state.dealSelect);

	const [hasSearched, setHasSearched] = useState(false);

	useEffect(() => {
		const newFilter = client ? [
			{
				propertyName: 'associations.company',
				value: client?.hs_object_id ?? client?.id,
			}
		] : [];
		dispatch(setFilters(newFilter));
	}, [client]);



	const { data: userDetails } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const hubspotId = userDetails?.hubspotId;

	const tabValue = 'active';

	const dealProperties = useGetDealsQuery({
		hubspotId,
		// pipeline: null,
		searchValue,
		pageAfter: null,
		limit,
		dealTabValue: tabValue,
		propertyName: orderBy,
		orderDir: order,
		groupView: true,
		filters,
	},
	{
		skip: !hubspotId || !open || (searchValue.length === 0 && filters.length === 0),
	}
	);

	const ticketProperties = useGetTicketsQuery({
		hubspotId,
		pipeline: process.env.REACT_APP_PIPELINE_CLAIMS,
		searchValue,
		pageAfter: null,
		limit,
		claimsTabValue: tabValue,
		propertyName: orderBy,
		orderDir: order,
		groupView: true,
		filters,
	},
	{
		skip: !hubspotId || !open || (searchValue.length === 0 && filters.length === 0),
	}
	);

	const loading = open && rows.length === 0 && (dealProperties.isFetching || ticketProperties.isFetching);

	useEffect(() => {
		dispatch(reset());
	}, []);

	useEffect(() => {
		if (dealProperties.data && ticketProperties.data) {
			console.log('Data', dealProperties.data, ticketProperties.data);
			
			const data = dealProperties.data.data.concat(ticketProperties.data.data);

			const newRows = data.map((deal) => {
				const dealData = deal.properties;
				const objectType = deal.properties.subject ? 'ticket' : 'deal';
				const contactData = deal.contactInformation?.properties;
				const companyData = deal.companyInformation?.properties;
				
				return {
					insuredName: companyData?.name ?? '-',
					contactName: `${contactData?.firstname ?? '-'} ${
						contactData?.lastname ?? ''
					}`,
					name: dealData.subject ?? dealData.dealname ?? '-',
					createdate: new Date(dealData.createdate).toISOString(),
					description: dealData.content ?? dealData.description ?? '',
					id: deal.id,
					key: `${objectType}-${deal.id}`,
					pipeline: dealData.pipeline ?? dealData.hs_pipeline,
					contact: {
						firstName: contactData?.firstname ?? '-',
						lastName: contactData?.lastname ?? '',
					},
					objectType
				};
			});
			dispatch(setRows(newRows));
		}
	}, [dealProperties.data, ticketProperties.data]);

	const handleReset = () => {
		setDeal(null);
		setHasSearched(false);
		dispatch(reset());
		setTimeout(() => dispatch(setOpen(true)), 0);
	};

	const handleSearch = () => {
		if (searchText != searchValue) {
			setHasSearched(true);
			dispatch(setRows([]));
			dispatch(setSearchValue(searchText));
		}
	};

	return <Autocomplete
		key={resetKey}
		id="deal-select"
		loading={loading}
		loadingText="Loading deals / claims ..."
		options={rows}
		open={open}
		disabled={disabled}
		onOpen={() => dispatch(setOpen(true))}
		onClose={() => dispatch(setOpen(false))}
		filterOptions={(x) => x}
		noOptionsText={hasSearched ? 'No deals / claims found' : 'Press enter to search'}
		renderOption={(props, option) => (
			<Grid container {...props} key={option.key}>
				<Grid item xs={2}>
					<Chip size='small' variant='outlined' style={{
						borderColor: option.objectType == 'deal' ? folioBlue : '#fbca04',
						color: option.objectType == 'deal' ? folioBlue : '#fbca04',
						// color: '#fff'
					}} label={option.objectType == 'ticket' ? 'claim' : option.objectType} />
				</Grid>
				<Grid item xs={10}>
					{option.name}
				</Grid>
			</Grid>
		)}
		getOptionLabel={(option) => option.name}
		onInputChange={(_, value) => dispatch(setSearchText(value))}
		renderInput={(params) => (
			<TextField
				placeholder={'Search for deal or claim'}
				{...params}
				onKeyDown={e => {
					if (e.key === 'Enter') {
						e.preventDefault(); // To avoid any default behavior
						handleSearch(); // Trigger the search
					}
				}}
				onChange={(_, e) => console.log('change text', e)}
				value={searchText}
				InputProps={{ 
					...params.InputProps, 
					startAdornment: (<InputAdornment position="start">
						<IconButton 
							disabled={dealProperties.isFetching || ticketProperties.isFetching} 
							sx={{padding: '0'}} 
							onClick={handleSearch}
						>
							<SearchIcon/>
						</IconButton>
					</InputAdornment>),
					endAdornment: (<InputAdornment position="end">
						{dealProperties.isFetching || ticketProperties.isFetching ? <CircularProgress color="inherit" size={20} /> : null}
						{searchText && searchText.length > 0 && <IconButton 
							size="small" 
							disabled={dealProperties.isFetching || ticketProperties.isFetching} 
							sx={{padding: '0'}} 
							onClick={handleReset}
						>
							<ClearIcon fontSize='20px'/>
						</IconButton>}
					</InputAdornment>)
				}}
			/>
		)}
		isOptionEqualToValue={(option, value) => 
			option.id === value.id
		}
		onChange={(_, value) => {
			// console.log('Value', value);
			setDeal(value);
			dispatch(setSearchText(value?.name ?? ''));
		}}
	/>;
};

const AutocompleteInput = ({ 
	query,
	refine,
	clear,
	hits,
	isSearching,
	setDeal, 
	dealId, 
	disabled,
	autoPopulate = false
}) => {

	const [open, setOpen] = useState(false);

	const handleSearch = () => {
		if (query.length > 0) {
			refine();
		}
	};


	const handleReset = () => {
		setDeal(null);
		setOpen(false);
		clear();
	};

	return (
		<Autocomplete
			id="deal-select"
			loading={isSearching}
			loadingText="Loading deals..."
			options={autoPopulate ? hits : (query.length > 0 ? hits : [])}
			open={open}
			disabled={disabled}
			noOptionsText={query.length > 0 ? 'No deals / claims found' : 'Type to search'}
			filterOptions={(x) => x}
			renderOption={(props, option) => (
				<Grid container {...props} key={option.id}>
					<Grid item xs={2}>
						<Chip 
							size='small' 
							variant='outlined' 
							style={{
								borderColor: option.pipeline == process.env.REACT_APP_PIPELINE_CLAIMS ? '#fbca04' : folioBlue,
								color: option.pipeline == process.env.REACT_APP_PIPELINE_CLAIMS ? '#fbca04' : folioBlue
							}} 
							label={option.pipeline == process.env.REACT_APP_PIPELINE_CLAIMS ? 'claim' : 'deal'}
						/>
					</Grid>
					<Grid item xs={9}>
						{option.dealName}
					</Grid>
					<Grid item xs={1}>
						{option.id === dealId && <CheckIcon sx={{ fontSize: '18px' }} />}
					</Grid>
				</Grid>
			)}
			inputValue={query}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			getOptionLabel={(option) => option.dealName}
			onInputChange={(_, value, reason) => { 
				if (reason != 'reset') {
					refine(value);
				}
			}}
			renderInput={(params) => (
				<TextField
					placeholder="Search for deal"
					{...params}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSearch();
						}
					}}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start">
								<IconButton
									disabled={isSearching || disabled}
									sx={{ padding: '0' }}
									onClick={handleSearch}
								>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								{isSearching ? <CircularProgress color="inherit" size={20} /> : null}
								{query.length > 0 && (
									<IconButton
										size="small"
										disabled={isSearching || disabled}
										sx={{ padding: '0' }}
										onClick={handleReset}
									>
										<ClearIcon fontSize="20px" />
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
				/>
			)}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			onChange={(_, value) => {
				setDeal(value);
				refine(value?.dealName ?? '');
			}}
		/>
	);
};

export const InstantDealSelect = ({setDeal, dealId, disabled, clientId, autoPopulate}) => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	// const pipelineQuery = useGetPipelinePropertiesQuery();

	// const { active, inactive } = useMemo(() => {
	// 	const pipelineStages = pipelineQuery.data?.pipelines?.stages ?? [];
	// 	return { 
	// 		active: pipelineStages.filter(stage => stage.metadata?.isClosed != 'true').map(s => s.id), 
	// 		inactive: pipelineStages.filter(stage => stage.metadata?.isClosed == 'true').map(s => s.id)
	// 	};
	// }, [pipelineQuery.data]);

	const userQuery = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const brokerGroupId = userQuery.data?.brokerId;
	const brokerGroupIds = userQuery.data?.brokerGroupArray ?? [];

	const filterSchema = useMemo(() => {
		// const stageIds = active.join(',');
		const clientIdFilter = `clientHubspotId := ${clientId}`;
		const ownerFilter = `brokerGroupId: [${brokerGroupIds.join(',')}]`;
		const schema = {
			filters: clientId ? `${clientIdFilter} && ${ownerFilter}` : ownerFilter
		};
		console.log('Filter schema', schema);
		return schema;
	}, [clientId, brokerGroupIds]);

	return (
		<InstantSearch searchClient={dealClient} indexName="deals">
			<Configure hitsPerPage={15} {...filterSchema} />
			<InstantDealSelectWrapper
				autoPopulate={autoPopulate}
				setDeal={setDeal}
				dealId={dealId}
				disabled={disabled || userQuery.isLoading}
			/>
		</InstantSearch>
	);
};

const InstantDealSelectWrapper = ({setDeal, dealId, disabled, ...props}) => {
	const { hits } = useHits(props);
	const updatedHits = useMemo(() => hits.map(hit => ({
		...hit, 
		id: hit.hubspotId ?? hit.dealId ?? hit.id, 
		docId: hit.id,
		objectType: (hit.pipeline == process.env.REACT_APP_PIPELINE_CLAIMS || (IS_DEV && hit.pipeline == process.env.REACT_APP_PIPELINE_ENDORSEMENTS)) ? 'ticket' : 'deal'
	})));
	const searchBoxApi = useSearchBox(props);

	return <AutocompleteInput 
		hits={updatedHits} 
		setDeal={setDeal}
		dealId={dealId}
		disabled={disabled}
		autoPopulate={props.autoPopulate}
		{...searchBoxApi} 
	/>;
};