import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CustomNavLink from './CustomNavLink';
import { useSelector } from 'react-redux';
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';

export const SideBarListItem = ({ item }) => {
	const { isCollapsed } = useSelector((state) => state.sideNavSlice);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const collapsed = isCollapsed && !isMobile;

	return (
		//Made the selected Color change when on page
		<ListItem 
			key={item.text} 
			disableGutters={collapsed} 
			disablePadding
		>
			<Tooltip title={collapsed ? item.text : ''} placement='right'>
				<ListItemButton
					sx={{
						borderRadius: '20px',
						'&:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.1)',
						},
						'&.Mui-selected': {
							backgroundColor: '#505AFC',
						},
						...(collapsed &&
						{
							paddingLeft: 0,
							paddingRight: 0,
							justifyContent: 'center'
						}),
					}}
					component={CustomNavLink}
					to={item.link}
				>
					<ListItemIcon
						sx={{
							color: '#FAFDFF',
							...(collapsed && {
								minWidth: '24px',
								display: 'flex',
								justifyContent: 'center',
							}),
						}}
					>
						{item.icon}
					</ListItemIcon>
					{!collapsed && (
						<ListItemText
							primary={item.text}
							sx={{ fontWeight: 400, marginLeft: '-12px', color: '#FAFDFF' }}
						/>
					)}
				</ListItemButton>
			</Tooltip>
		</ListItem>
	);
};
