import { ReactComponent as ClaimsIconSVG } from '@/assets/icons/claims_icon.svg';

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const ClaimsIcon = (props) => {
	return (
		<SvgIcon {...props}>
			<ClaimsIconSVG />
		</SvgIcon>
	);
};
