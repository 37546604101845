import React from 'react';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

// Styled component that applies the animation
export const BlinkingCircle = styled(CircleIcon, {
	shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
	color: color, // Use the color prop for the icon color
	animation: `${blink} 1s infinite alternate ease-in-out`,
	filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.3))'
}));