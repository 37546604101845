import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '@/app/routes';

export const StatementOfAdviceButton = () => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Button
			component='label'
			variant='tab_header'
			startIcon={<SendIcon />}
			onClick={() => navigate(`${location.pathname}${paths.generateSOA}`)}
		>
			{'Generate Statement of Advice'}
		</Button>
	);
};
