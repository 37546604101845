// features/deal/dealSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isEditing:  false,
	hasSubmited: false
};

const nameChangeSlice = createSlice({
	name: 'nameChangeSlice',
	initialState,
	reducers: {
		setEditing: (state, action) => {
			state.isEditing = action.payload;
		},
		setSubmited: (state, action) => {
			state.hasSubmited = action.payload;
		},
	},
});

export const { setEditing, setSubmited } = nameChangeSlice.actions;
export default nameChangeSlice.reducer;
