/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useState } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	Typography,
	FormControl,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
	InputAdornment,
	RadioGroup,
	FormControlLabel,
	Radio,
	Select,
	MenuItem,
	Grid,
	Tooltip,
	Link,
	Accordion,
	AccordionSummary,
	FormGroup,
	Checkbox,
} from '@mui/material';
import { SCOPE_OF_ADVICE } from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { ExpandMore } from '@mui/icons-material';
import { determineContext } from '@/hooks/determineContext';
import { folioBlue } from '@/utils/constants';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { useAddDocumentPropertyToDealMutation } from '@/features/deals/dealsApi';
import { RiskClasses } from '@/components/cards/DealCardValues/RiskClasses';
import { NeedsAnalysisDropdownContainer } from '@/components/cards/DealCardValues/NeedsAnalysis';
import { AdditionalProducts } from '@/components/cards/DealCardValues/AdditionalProducts';

export const NeedsAnalysisAccordian = () => {
	//LABELS FOR SCOPE HAVE BEEN CHANGED NEED TO ADJUST WHEN COMES TO DEAL VIEW
	const { dealRow } = determineContext();

	const [expanded, setExpanded] = useState(true);

	return (
		<Accordion
			elevation={0}
			disableGutters
			square
			expanded={expanded}
			onChange={() => setExpanded(prev => !prev)}
			sx={{
				backgroundColor: 'transparent',
				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMore sx={{ color: folioBlue }} />}
				sx={{
					borderBottom: expanded ? '1px solid #d3d3d3' : 'none',
					padding: 0,
					marginBottom: '1em'
				}}
			>
				<Typography variant='form_label'>Needs Analysis</Typography>
			</AccordionSummary>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<RiskClasses />
				</Grid>
				<Grid item xs={12}>
					<NeedsAnalysisDropdownContainer />
				</Grid>
				<Grid item xs={12}>
					<AdditionalProducts />
				</Grid>
			</Grid>
		</Accordion>
	);
};
