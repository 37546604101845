import React, { useContext } from 'react';
import ClientContext from '@/context/clientContext';
import { DetailsTab } from './ClientTabs/DetailsTab';
import { ClaimsTab } from './ClientTabs/ClaimsTab';
import {  PolicyTransactionTab } from './ClientTabs/PolicyTransactionTab';
import { ActivityTab } from '@/components/objectTabs/ActivityTab';
import { NotesTab } from '@/components/objectTabs/NotesTab';
import { CreateNoteButton } from '@/components/buttons/viewHeaderButtons/CreateNoteButton';
import ObjectViewFileUpload from '@/components/ObjectViewFileUpload';
import { TabView } from '@/components/layouts/TabView';
import { TasksTab } from '@/components/objectTabs/TasksTab';
import { CreateTaskButton } from '@/components/buttons/viewHeaderButtons/CreateTaskButton';
import { AttachmentsTab } from '@/components/objectTabs/AttachmentsTab';
import { FormsTab } from '@/components/objectTabs/FormsTab';

export const ClientTabView = () => {
	const { client, contact } = useContext(ClientContext);

	const tabs = [
		{
			label: 'Policy Transactions',
			value: 'policyTransactions',
			ContentComponent: PolicyTransactionTab,
		},
		{
			label: 'Activity',
			value: 'activity',
			ContentComponent: ActivityTab,
		},
		{
			label: 'Claims',
			value: 'claims',
			ContentComponent: ClaimsTab,
		},
		{
			label: 'Notes',
			value: 'notes',
			ContentComponent: NotesTab,
			HeaderComponent: CreateNoteButton,
		},
		{
			label: 'Tasks',
			value: 'tasks',
			ContentComponent: TasksTab,
			HeaderComponent: CreateTaskButton,
		},
		{
			label: 'Attachments',
			value: 'attachments',
			ContentComponent: AttachmentsTab,
			HeaderComponent: ObjectViewFileUpload,
		},
		{
			label: 'Forms',
			value: 'forms',
			ContentComponent: FormsTab,
		},
		{
			label: 'Client details',
			value: 'details',
			ContentComponent: DetailsTab,
		},
	];

	return <TabView 
		tabs={tabs} 
		title={client.name} 
		subtitle={contact.firstname ? `${contact.firstname} ${contact.lastname}` : 'Unknown'}
	/>;
};