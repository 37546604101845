import React, { useState } from 'react';
import { Box, Typography, IconButton, Skeleton } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import CloseIcon from '@mui/icons-material/Close';
import { useGetAnnouncementsQuery } from '@/features/announcements/announcementsApi';

const AnnouncementBanner = () => {
	const announcementsQuery = useGetAnnouncementsQuery({});

	const [closed, setClosed] = useState(false);

	const textColour = '#7B83FD';

	return (
		announcementsQuery?.data?.subject &&
		!closed && (
			<Box px='1.5em'>
				<Box
					sx={{
						position: 'relative', // Needed for absolute positioning of the IconButton
						width: '100%',
						padding: '20px',
						marginTop: '1em',
						backgroundColor: '#D9DCFE',
						borderRadius: '4px',
						display: 'flex',
						alignItems: 'center',
						border: '2px solid #7B83FD',
					}}
				>
					<CampaignIcon sx={{ fontSize: 50, color: textColour }} />
					<Box sx={{ ml: 2, flexGrow: 1 }}>
						{' '}
						{/* flexGrow: 1 ensures this Box takes up the maximum width */}
						<Typography sx={{ color: textColour }} variant='h6'>
							Announcements
						</Typography>
						<Typography sx={{ color: textColour }} variant='body1'>
							{' '}
							{announcementsQuery.isFetching ? (
								<Skeleton variant='text' animation='wave' />
							) : (
								announcementsQuery?.data?.subject
							)}
						</Typography>
					</Box>
					<IconButton
						onClick={() => setClosed(true)}
						sx={{
							color: textColour,
							position: 'absolute', // Position the button absolutely...
							top: 8, // ...from the top of the parent Box
							right: 8, // ...and from the right of the parent Box
						}}
					>
						<CloseIcon sx={{ fontSize: '18px' }} />
					</IconButton>
				</Box>
			</Box>
		)
	);
};

export default AnnouncementBanner;
