import { useCallback } from 'react';
import { storage } from '@/utils/firebase';
import {
	ref,
	uploadBytes,
} from 'firebase/storage';
import { useCreateDealRowMutation } from '@/features/deals/dealsApi';
import { getFolderFromPipeline, getFolderStructure, getPipelineName, replaceFolderName } from '@/utils/constants';
import { useSharepoint } from './useSharepoint';


export const useClientStorageHooks = (userDetails) => {

	const sharepointSite = userDetails?.sharepointSite;
	const clientSiteSuffix = userDetails?.clientSiteSuffix;
	const clientFolder = userDetails?.clientFolder;
	const clientSite = userDetails?.clientSite;

	// Hooks are called at the top level of the custom hook
	const [createDealRow] = useCreateDealRowMutation();
	const { createFolder, addFileToSharepoint } = useSharepoint(
		sharepointSite,
		clientSiteSuffix,
		clientFolder,
		clientSite
	);

	const isValidSharepoint = userDetails?.sharepointSite != null && 
    userDetails?.clientSiteSuffix != null && 
    userDetails?.clientFolder != null && 
    userDetails?.clientSite != null;

	const uploadFilesToStorage = async (dealId, noteId, dealFolder, pipelineId, files) => {

		const dealType = getPipelineName(pipelineId);
		for (const file of files) {
			const filePath = `/${dealType}Files/${file.name}`;
			const storageRef = ref(storage, filePath);
			const metadata = {
				customMetadata: {
					'Deal ID': dealId.toString(),
					'Note ID': noteId.toString(),
				},
			};
			await uploadBytes(storageRef, file, metadata);
			if (isValidSharepoint) {
				await addFileToSharepoint(dealFolder.url, file);
			} else {
				console.log('Errror, invalid Sharepoint', userDetails);
			}
		}
	};

	const getClientFolders = (clientName, dealName, dealId, pipelineFolder, date) => {
		// Normalise and remove special characters
		const replacedCompanyName = replaceFolderName(clientName);

		const { folders, dealFolder } = getFolderStructure(
			replacedCompanyName,
			dealName,
			dealId,
			pipelineFolder,
			date,
		);
		return { folders, dealFolder };
	};

	const createDealFolders = async (dealId, dealName, clientName, pipelineId, date) => {
		const pipelineFolder = getFolderFromPipeline(pipelineId);
		const { folders, dealFolder } = getClientFolders(clientName, dealName.trim(), dealId, pipelineFolder, date);

		if (isValidSharepoint) {
			for (var path of folders) {
				await createFolder(path);
			}
			const dealFolderUrl = await createFolder(dealFolder.url);
    
			const body = {
				dealId,
				dealFolderUrl,
				dealFolderPath: dealFolder.url,
				yearRoot: dealFolder.yearRoot,
				dealName: dealFolder.dealName,
				pipelineFolder: dealFolder.pipelineFolder,
			};
    
			const dealRowResponseId = await createDealRow(body).unwrap();
			const docId = dealRowResponseId.doc_id;
    
			return { success: 'Created client folders', dealFolder, dealFolderUrl, documentId: docId };
		} else {
			console.log('Could not create client folders, invalid Sharepoint site', userDetails);
			return { error: 'Could not create client folders, invalid Sharepoint site', userDetails };
		}
		
	};

	const setUpDealFilesAndFolders = async (dealId, noteId, clientName, dealName, pipelineId, files, date) => {
		const { success, dealFolder, dealFolderUrl, documentId } = await createDealFolders(dealId, dealName.trim(), clientName, pipelineId, date);
		await uploadFilesToStorage(dealId, noteId, dealFolder, pipelineId, files);
		return { success, dealFolder, dealFolderUrl, documentId,  };
	};

	const createClientFolder = useCallback(async (dealId, contextValue) => {
		const { deal, client, objectType } = contextValue;
		const date = deal.createdAt;
        
		console.log('Create client folder hook');

		if (!client || !deal) {
			console.log('Could not create folder: could not locate client/deal', dealId);
			return;
		}
		
		const dealName = deal.dealname ?? deal.description ?? deal.subject ?? deal.content;
		await setUpDealFilesAndFolders(dealId, null, client?.name, dealName.trim(), deal.pipeline ?? deal.hs_pipeline, [], date);
	}, [setUpDealFilesAndFolders]);

	return { createClientFolder, createDealFolders, uploadFilesToStorage, setUpDealFilesAndFolders };
};