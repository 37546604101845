import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, Card, Collapse, IconButton, List, Stack, Typography } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Markdown from 'react-markdown';
import { ResetButton } from '@/components/ClientDetailEntry/resetButton';
import { formatDate } from '@/utils/constants';

export const CollapsibleChatCards = ({ messages, update }) => {

	const messageList = messages.filter(m => m.role == 'assistant');

	const shownMessages = useMemo(() => messageList.slice(0, 1), [messageList]);
	const hiddenMessages = useMemo(() => messageList.slice(1), [messageList]);

	const [showAll, setShowAll] = useState(false);

	const onReset = async (value) => {
		const message = `Please revert to this: ${value}`;
		if (update) {
			await update(message);
		}
	};

	const renderMessages = (collection, showReset) => {
		return collection.map((message, index) => {
			const content = message.content?.[0]?.text?.value;
			return <Collapse key={`chat-gpt-messages-${message.id}`} sx={{ padding: '0.5em'}}>
				<Card>
					<Stack padding="1em">
						{showReset && <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '0.5em' }}>
							<ResetButton onMouseDown={() => onReset(content)} />
						</div>}
						<Typography variant="details_body">
							<Markdown>
								{content ?? ''}
							</Markdown>
						</Typography>
						<div style={{ display: 'flex', justifyContent: 'right', paddingTop: '0.5em' }}>
							<Typography variant="client_subheader">
								{formatDate(message.created_at * 1000, true)}
							</Typography>
						</div>
					</Stack>
				</Card>
			</Collapse>;
		});
	};

	return <Stack>
		<TransitionGroup>
			{renderMessages(shownMessages)}
			{showAll && renderMessages(hiddenMessages, true)}
		</TransitionGroup>
		<Stack direction="row" justifyContent="flex-end">
			{hiddenMessages.length > 0 && (
				<Button
					onClick={() => setShowAll(!showAll)}
					size="small"
					sx={{ minWidth: 'auto' }}
					endIcon={!showAll ? <MoreHorizIcon /> : <ExpandLessIcon />}
				>
					{!showAll ? 'MORE' : 'LESS'}
				</Button>
			)}
		</Stack>
	</Stack>;
};