import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { setAccount, clearAccount } from '@/features/msalAccount/msalAccountSlice';

// Custom hook to synchronise MSAL account state with Redux state
export const useSyncMsalWithStore = () => {
	// Using MSAL hook to get accounts
	const { accounts } = useMsal();
	// Using useDispatch hook to dispatch actions to Redux store
	const dispatch = useDispatch();

	useEffect(() => {
		if (accounts && accounts.length > 0) {
			console.log('📋 ~ set MSAL account');
			dispatch(setAccount(accounts[0]));
		} else { 
			console.log('📋 ~ clear MSAL account');
			dispatch(clearAccount());
		}
	}, [accounts, dispatch]);
};
