import React, { useEffect } from 'react';
import { 
	setHubspotId
} from '@/features/groupView/groupViewSlice';
import { useDispatch, useSelector } from 'react-redux';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { Typography } from '@mui/material';
import { folioBlue } from '@/utils/constants';

export const BrokerSelect = () => {
	const dispatch = useDispatch();
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const groupViewHubspotId = useSelector((state) => state.userPrefs.hubspotId);
	const userQuery = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});

	const userDetailsHubspotId = userQuery?.hubspotId;
	const hubspotId = groupViewHubspotId || userDetailsHubspotId;

	useEffect(() => {
		if (hubspotId != null && groupViewHubspotId == null) {
			dispatch(setHubspotId(hubspotId));
		}
	}, [hubspotId]);

	const handleChange = (value) => {
		console.log('here is the selected value: ', value);
		if (value !== hubspotId) {
			dispatch(setHubspotId(value));
		}
	};


	return (
		<div style={{ display: 'inline-flex', alignItems: 'center' }}>
			<Typography variant="body1" style={{ marginRight: '8px', color: folioBlue, fontWeight: 'bold' }}>
				Viewing:
			</Typography>
			<OwnerSelect
				initialId={hubspotId} 
				onChange={(value) => {
					handleChange(value);
				}}
				minimal={true}
			/>
		</div>
	);
};