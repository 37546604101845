// features/table/dealTableSlice.js
import { generateInitialState, createTableSlice } from './tableSlice';

const dealsTableInitialState = generateInitialState();
export const dealsTableSlice = createTableSlice('dealTable', dealsTableInitialState);

export const { 
	setPageAfter,
	setRowsPerPage,
	setOpenDialog,
	setSearchValue,
	setSearchText,
	setOrderBy,
	setOrder,
	setPage,
	setRows,
	resetSearchFields,
	changeRowsPerPage,
	requestSort,
	changePage,
	resetAllPages,
	onOrderChange,
} = dealsTableSlice.actions;

export default dealsTableSlice.reducer;