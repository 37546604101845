import React, { useMemo } from 'react';
import {
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { claimStatusColour, firstTwoCapsOrNumbers } from '@/utils/constants';
import { formatDate } from '@/utils/constants';
import { ClientNameAndAvatar } from '@/components/table/ClientNameAndAvatar';
import { BlinkingCircle } from '@/components/icons/BlinkingCircle';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { isAfter } from 'date-fns';
import { useGetOwnerQuery } from '@/features/user/ownerApi';

const today = new Date();

export const TicketRow = ({ hit: ticket }) => {
	const navigate = useNavigate();

	const pipelineQuery = useGetPipelinePropertiesQuery(ticket.pipeline, {
		skip: !ticket.pipeline,
	});
	const stages = pipelineQuery?.data?.stages ?? {};

	const stage = useMemo(() => {
		return ticket.dealStage in stages 
			? stages[ticket.dealStage] 
			: { label: ticket.dealStage, order: 99 };
	}, [stages]);

	const statusColour = claimStatusColour(stages[ticket.dealStage]?.order ?? 0);

	const due = useMemo(() => (
		ticket?.earliestTask ? new Date(ticket?.earliestTask?.hs_timestamp) : null
	), [ticket?.earliestTask]);

	const overdue = useMemo(() => due ? isAfter(today, due) : false, [due]);

	const ownerQueryParams = { ownerId: ticket.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});
	

	return (
		<TableRow
			onMouseDown={() => navigate(`${ticket.id}`)}
			hover
			key={`table-row-${ticket.id}`}
			style={{ cursor: 'pointer' }}
		>
			<TableCell>
				<ClientNameAndAvatar
					title={ticket.clientName}
					subtitle={ticket.contactName}
					initials={firstTwoCapsOrNumbers(ticket?.dealName ?? 'NA')}
					width={300}
				/>
			</TableCell>
			<TableCell>
				<Typography color="text.primary">{ticket.dealName}</Typography>
			</TableCell>
			<TableCell>
				<Typography color="text.primary">{ticket.claimType}</Typography>
			</TableCell>
			<TableCell>
				<Typography color="text.primary">{ticket.claimNumber ?? ''}</Typography>
			</TableCell>
			<TableCell>
				<Typography color="text.primary">{ticket.insurer}</Typography>
			</TableCell>
			<TableCell>
				<><span style={{ paddingRight: '5px' }}>
					<BlinkingCircle fontSize='6px' color={statusColour} />
				</span><span> {stage?.label ?? ''} </span></>
			</TableCell>
			<TableCell>
				<Typography color="text.primary">{`${owner?.firstName ?? '-'} ${owner?.lastName ?? ''}`}</Typography>
			</TableCell>
			<TableCell>
				<Typography>{formatDate(ticket.dateOfLoss)}</Typography>
			</TableCell>
			<TableCell>
				<Typography sx={{ ...(overdue && { color: 'red', fontWeight: 600 })}}>
					{due ? formatDate(due) : '-'}
				</Typography>
			</TableCell>
			<TableCell align="right">
				<Typography>{formatDate(ticket.createDate)}</Typography>
			</TableCell>
			{/* <TableCell align="right">
				<Typography>{formatDate(ticket.hs_lastmodifieddate, true)}</Typography>
			</TableCell> */}
			{/* <TableCell align="center">
                {ticket.hs_priority === null ? '-' : <PriorityChip priority={ticket.hs_priority} />}
            </TableCell> */}

		</TableRow>
	);
};
