import React from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';

export const CreateNoteButton = () => {
	const dispatch = useDispatch();

	const handleOpenDialog = () => {
		dispatch(openDialog('note'));
	};
    
	return <div style={{display: 'flex', justifyContent: 'right', paddingBottom: '1em' }}>
		<Button component="label" variant="tab_header" startIcon={<Add />}
			onClick={handleOpenDialog}>
			{'Create note'}
		</Button>
	</div>;
};