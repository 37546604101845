import React, { useMemo } from 'react';
import '@/styles/navbar.scss';
import {
	Box,
	Drawer,
	Toolbar,
	List,
	Typography,
	ListItem,
	Divider,
	IconButton,
	ListItemText,
	ListSubheader,
	Stack,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import { useIsAuthenticated } from '@azure/msal-react';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ContactsIcon from '@mui/icons-material/Contacts';
import FolderIcon from '@mui/icons-material/Folder';
import { SignInButton } from '@/components/buttons/SignInButton';
import { SignOutButton } from '@/components/buttons/SignOutButton';
import { PlayCircle } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { IS_DEV } from '@/utils/environment';
import { paths } from '@/app/routes';

import { useDispatch, useSelector } from 'react-redux';
import { toggleOpen, toggleWidth } from '@/features/sideNav/sideNavSlice';
import { SideBarListItem } from './SideBarListItem';
import { folioBlue } from '@/utils/constants';

export const SideNavBar = () => {
	const dispatch = useDispatch();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { open, width, isCollapsed } = useSelector((state) => state.sideNavSlice);

	const collapsed = isCollapsed && !isMobile;

	const handleDrawerToggle = () => dispatch(toggleOpen());
	const handleDrawerWidthToggle = () => dispatch(toggleWidth());

	const isAuthenticated = useIsAuthenticated();

	const homeList = useMemo(() => {
		const list = [
			{ text: 'Clients', icon: <PersonIcon />, link: paths.home },
			{ text: 'Prospects', icon: <ContactsIcon />, link: paths.contacts },
			{ text: 'My Tasks', icon: <CheckCircleOutlineRoundedIcon />, link: paths.tasks },
		];
		return isAuthenticated ? list : list.slice(0, -2);
	}, [isAuthenticated]);

	const pipelinesList = useMemo(() => {
		const list = [
			{
				text: 'New Business',
				icon: <InsertChartOutlinedRoundedIcon />,
				link: paths.newBusiness,
			},
			{ text: 'Claims', icon: <ArticleRoundedIcon />, link: paths.claims },
			{ text: 'Endorsements', icon: <FolderIcon />, link: paths.endorsements },
			{ text: 'Renewals', icon: <PlayCircle />, link: paths.renewals },
			// { text: 'Invoices', icon: <MonetizationOnOutlinedIcon />, link: paths.invoices },
		];
		// Remove invoices for PROD for now
		return IS_DEV ? list : list.filter((l) => l.text != 'Invoices');
	}, [IS_DEV]);

	const myAccountList = [];

	const displayList = (list, title) => {
		return list.map((item, index) => (
			<SideBarListItem
				key={`side-bar-list-item-${title}-${index}-${item.text}`}
				item={item}
			/>
		));
	};

	const header = (text) => {
		return (
			<Typography
				variant="subtitle2"
				sx={{
					color: 'whitesmoke',
					marginLeft: '35px',
					paddingBottom: '8px',
				}}
			>
				{text}
			</Typography>
		);
	};

	return (
		<Drawer
			className="side_nav_bar"
			sx={{
				width: width,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: width,
					boxSizing: 'border-box',
					border: 'none',
					backgroundColor: '#1C2536',
					transition: theme.transitions.create(['width'], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
				},
				'& .MuiListItem-root': {
					padding: collapsed ? 0 : '0 16px',
				},
			}}
			variant={isMobile ? 'temporary' : 'permanent'}
			open={isMobile ? open : true}
			onClose={handleDrawerToggle}
			anchor="left"
		>
			<Toolbar />
			{collapsed ? (
				<IconButton 
					variant="outlined"
					onClick={handleDrawerWidthToggle}
					sx={{
						mb: '1em'
					}}
				>
					<MenuIcon sx={{ color: 'whitesmoke' }} />
				</IconButton>
			) : (
				<Box sx={{ ml: '20px' }}>
					<Stack direction="row" justifyContent={'space-between'} alignContent={'start'}>
						<Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white' }}>
							Folio
						</Typography>
						<IconButton
							variant="outlined"
							onClick={handleDrawerWidthToggle}
							sx={{ pt: '0.1em' }}
						>
							<KeyboardArrowLeftIcon sx={{ color: 'whitesmoke' }} />
						</IconButton>
					</Stack>
					<Typography variant="caption" sx={{ color: 'whitesmoke' }}>
						Broker Portal
					</Typography>
				</Box>
			)}
			<List 
				disablePadding={collapsed}
				sx={{
					...(collapsed && {
						pb: '1.5em'
					})
				}}
			>
				{displayList(homeList, 'home')}
			</List>
			{isAuthenticated && (
				<List
					disablePadding={collapsed}
					sx={{
						...(collapsed && {
							pb: '1.5em'
						})
					}}
				>
					{!collapsed && header('Your Pipelines')}
					{displayList(pipelinesList, 'pipelines')}
				</List>
			)}
			<List
				disablePadding={collapsed}
				sx={{
					...(collapsed && {
						pb: '1.5em'
					})
				}}
			>
				{!collapsed && header('My Account')}
				{isAuthenticated && displayList(myAccountList, 'my-account')}
				<ListItem 
					disablePadding={collapsed} 
					disableGutters={collapsed}
				>
					{isAuthenticated ? <SignOutButton /> : <SignInButton showListItem />}
				</ListItem>
			</List>
		</Drawer>
	);
};
