import React, { useMemo, useState, useEffect } from 'react';
import {
	Typography,
	Grid,
	Card,
	Box,
} from '@mui/material';
import { DealsDisplayCard } from '@/components/cards/DealsDIsplayCard';
import ClientTable from '@/components/tables/ClientTable';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useBatchGetPipelineDealCountsQuery } from '@/features/pipelines/pipelineApi';
import { IS_DEV } from '@/utils/environment';
import { PageHeader } from '@/components/layouts/PageHeader';

export const ClientsView = () => {
	const account = useSelector((state) => state.msalAccount.account);
	const name = useMemo(() => account?.name.split(' ')[0], [account]);

	const { 
		groupView, 
		hubspotId: groupViewHubspotId 
	} = useSelector((state) => state.userPrefs );

	const dealPipelines = useMemo(() => {
		console.log('IS DEV', IS_DEV, process.env.REACT_APP_REACT_ENV);
		const dev = [
			process.env.REACT_APP_PIPELINE_NEW_BUSINESS, 
			process.env.REACT_APP_PIPELINE_RENEWALS
		];
		const prod = dev.concat([process.env.REACT_APP_PIPELINE_ENDORSEMENTS]);
		return IS_DEV ? dev : prod;
	}, [IS_DEV]);

	const ticketPipelines = useMemo(() => { 
		const prod = [process.env.REACT_APP_PIPELINE_CLAIMS];
		const dev = prod.concat([process.env.REACT_APP_PIPELINE_ENDORSEMENTS]);
		return IS_DEV ? dev : prod;
	}, [IS_DEV]);

	const [totals, setTotals] = useState(() => {
		return [...dealPipelines, ...ticketPipelines].map(id => {
			return { [id]: 0 };
		}).reduce((acc, obj) => {
			return { ...acc, ...obj };
		}, {});
	});

	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	
	const hubspotId = groupViewHubspotId ?? userDetails?.hubspotId;
	

	const totalsQuery = useBatchGetPipelineDealCountsQuery({
		hubspotId, 
		dealPipelines, 
		ticketPipelines,
		dealStage: 'active',
		groupView,
	}, 
	{ skip: !hubspotId }
	);

	useEffect(() => {
		if (totalsQuery.data) {
			console.log(totalsQuery.data);
			setTotals(totalsQuery.data);
		}
	}, [totalsQuery.data]);

	const loading = totalsQuery.isFetching;

	return (
		<Box
			sx={{
				backgroundColor: (theme) =>
					theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100',
				p: 3,
			}}
		>
			<PageHeader title={`Kia ora ${name}`} showGroupView padding={'1em 0'}/>
			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<DealsDisplayCard
						title="New Business Leads"
						total={totals[process.env.REACT_APP_PIPELINE_NEW_BUSINESS] ?? 0}
						loading={loading}
						url="new-business"
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<DealsDisplayCard
						title="Renewals"
						total={totals[process.env.REACT_APP_PIPELINE_RENEWALS] ?? 0}
						loading={loading}
						url="renewals"
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<DealsDisplayCard
						title="Endorsements"
						total={totals[process.env.REACT_APP_PIPELINE_ENDORSEMENTS] ?? 0}
						loading={loading}
						url="endorsements"
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<DealsDisplayCard
						title="Claims"
						total={totals[process.env.REACT_APP_PIPELINE_CLAIMS] ?? 0}
						loading={loading}
						url="claims"
					/>
				</Grid>
			</Grid>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: '3em 0 1em',
				}}
			>
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item>
						<Typography variant="h5" style={{ fontWeight: 'bold' }}>
                        Insured Entities
						</Typography>
					</Grid>
				</Grid>
			</Box>
			<Card>
				<ClientTable />
			</Card>
		</Box>
	);
};
