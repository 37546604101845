import React from 'react';
import { Typography, Box, Container, Link, Stack } from '@mui/material';
import { PageLayout } from '@/components/layouts/PageLayout';

export const PageNotFoundPage = () => {

	return (
		<PageLayout>
			<Container>
				<Stack spacing={2} pt='4em'>
					<Typography variant='h4'>Page not found.</Typography>
					<Typography variant='body'>
						{'Sorry, we couldn\'t locate that page. For assistance, please contact '}
						<span>
							<Link
								href={'mailto:support@folio.insure'}
								color={'inherit'}
								target='_blank'
								rel='noopener noreferrer'
							>
							support@folio.insure
							</Link>
						</span>
					</Typography>
                    
				</Stack>
			</Container>
		</PageLayout>
	);
};
