import React from 'react';
import { Snackbar, Alert, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { hideToast } from '@/features/toast/toastSlice';
import { useNavigate } from 'react-router-dom';

const Toast = () => {
	const { open, message, autohide, duration, action, error, success } = useSelector((state) => state.toast);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		dispatch(hideToast());
	};

	return error ? 
		<Snackbar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			open={open}
			autoHideDuration={duration > 0 && autohide ? duration : null}
			onClose={handleClose}
		>
			<Alert 
				severity="error"
				onClose={handleClose}
			>
				{message}
			</Alert>
		</Snackbar>
		: success ? 
		
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={open}
				autoHideDuration={duration > 0 && autohide ? duration : null}
				onClose={handleClose}
			>
				<Alert 
					severity="success"
					onClose={handleClose}
				>
					{message}
				</Alert>
			</Snackbar>
			:

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={open}
				autoHideDuration={duration > 0 && autohide ? duration : null}
				onClose={handleClose}
				message={message}
				action={
					<React.Fragment>
						{action && action.path && <Button 
							color="primary" 
							size="small" 
							onClick={action.path ? () => {
								navigate(action.path, action.params);
								dispatch(hideToast());
							} : null}
						>
							{action.label}
						</Button>}
						<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</React.Fragment>}
			
			/>;
};

export default Toast;