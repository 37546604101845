import { FormLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { theme } from '@/app/theme';

export const NeedsAnalysisSelect = ({ 
	analysisValue, 
	label, 
	disabled, 
	setAnalysisValue, 
	minimal, 
	needsValues, 
	...props 
}) => {
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	return (
		<>

			{/* no link currently <span style={{ color: '#505AFC' }}>View guidance</span> */}
			<FormLabel sx={minimal ? minimalLabelTheme : undefined}>
				{label} 
			</FormLabel>
			<Select
				name="needsAnalysis"
				value={analysisValue}
				disabled={disabled}
				onChange={(e) => setAnalysisValue(e.target.value, e.target.name)}
				fullWidth
				{...props}
			>
				{Object.entries(needsValues).map(([value, displayLabel]) => (
					<MenuItem
						value={value}
						key={`needs-analysis-selection-${value}`}
					>
						{displayLabel}
					</MenuItem>
				))}
			</Select>
		</>
	);
};
