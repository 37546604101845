import React, { useState } from 'react';
import {
	Typography,
	Box,
	Card,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Checkbox,
} from '@mui/material';
import '@/styles/index.scss';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { getComparator, stableSort } from '@/components/table/functions';
import { folioBlue } from '@/utils/constants';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { DealPolicyRow } from './DealPolicyRow';

export const DealSelectCard = ({ deal, handleCheckboxChange, selected, disabled, loading }) => {
    
	const policies = deal.policies;
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('CreatedWhen');

	const policyHeadCells = [
		{ id: 'policy', numeric: false, label: 'Policy', align: 'left', sortable: false },
		{ id: 'policyNumber', numeric: false, label: 'policy Number', align: 'left', sortable: false },
		{ id: 'renewalDate', numeric: false, label: 'Renewal date', align: 'right', sortable: false },
	];

	const handleCardClick = () => handleCheckboxChange(deal);

	return (
		<Card
			key={`deal-select-card-${deal.id}`}
			sx={{
				marginBottom: '12px',
				borderRadius: '4px',
				border: `2px solid ${selected ? folioBlue : null}`, 
				...(!disabled && { cursor: 'pointer' }),
				transition: 'background-color 0.3s ease',
				backgroundColor: disabled ? 'rgba(240, 240, 240, 0.5)' : 'transparent',
				'&:hover': {
					backgroundColor: disabled ? 'rgba(240, 240, 240, 0.5)' : 'rgba(240, 240, 240, 0.8)',
				},
				'& .MuiTypography-root': {
					color: disabled ? 'text.disabled' : 'inherit',
				},
				'& .MuiTableCell-root': {
					color: disabled ? 'text.disabled' : 'inherit',
				}
			}}
			onClick={() => disabled ? {} : handleCardClick()}
		>
			<Box sx={{ paddingTop: 1, paddingRight: 4, paddingBottom: 1, paddingLeft: 4, justifyContent: 'left' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Checkbox
						sx={{ marginRight: 1, size: 10, color:  '#E0E0E0'}}
						checked={selected}
						disabled={disabled}
						onChange={handleCardClick}
					/>
					<Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>{deal.description}</Typography>
				</Box>
				<TableContainer sx={{ borderRadius: 2 }}>
					<Table
						aria-labelledby="tableTitle"
						size="small"
						sx={{ border: 'none', '& td, & th': { border: 'none' } }}
						aria-label="enhanced table"
					>
						<FolioTableHead
							order={order}
							orderBy={orderBy}
							rowCount={policies.length}
							headCells={policyHeadCells}
							size='small'
						/>
						<TableBody>
							{loading ? <TableProgressIndicator rowCount={policyHeadCells.length} /> 
								: policies.length > 0 ? (
									stableSort(policies, getComparator(order, orderBy)).map(
										(policy, index) => (
											<DealPolicyRow
												key={`policy-row-index-${index}-${policy.Id}`}
												policy={policy}
											/>
										)
									)
								) : (
									<TableRow>
										<TableCell colSpan={policyHeadCells.length} align="center">
											<Typography variant="subtitle1">No policies linked</Typography>
										</TableCell>
									</TableRow>
								)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Card>
	);
};
