import React from 'react';
import { BlinkingCircle } from '@/components/icons/BlinkingCircle';
import { Chip, Typography } from '@mui/material';


export const StatusChip = ({ status }) => {
	const unpaid = status.toUpperCase().slice(0, 2) === 'UN';

	const style = { 
		borderRadius: '8px',
		backgroundColor: unpaid ? 'rgba(254, 220, 223)'  : 'rgba(216, 246, 229)',
		color: unpaid ? 'rgba(251, 66, 80)' : 'rgba(63, 205, 104)',
		fontWeight: 'bold',
	};
	return <Chip size="small" style={style} label={status}/>;
};

export const StatusBlink = ({ status }) => {
	const unpaid = status.toUpperCase().slice(0, 2) === 'UN';
	const titleCaseStatus = `${status.slice(0, 1)}${status.slice(1).toLowerCase()}`;
	const backgroundColour = unpaid ? 'rgba(251, 66, 80)' : 'rgba(63, 205, 104)';//unpaid ? 'rgba(254, 220, 223)'  : 'rgba(216, 246, 229)';
	
	return (
		<Typography fontSize={'12px'}>
			<span style={{ paddingRight: '5px' }}>
				<BlinkingCircle sx={{
					width: '0.65em',
					height: '0.5em',
				}} fontSize='4px' color={backgroundColour} />
			</span>
			{titleCaseStatus}
		</Typography>
	);
};