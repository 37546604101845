import React, { useState } from 'react';
import {
	Box,
	Card,
	Button,
	Tab,
	CardHeader,
	Tabs,
	Grid,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import TaskTable from '@/components/tables/TaskTable';
import { PageHeader } from '@/components/layouts/PageHeader';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';

export const TasksTableView = () => {
	const dispatch = useDispatch();
	const [currentTab, setCurrentTab] = useState('overdue');

	const tabs = [
		{
			label: 'Overdue',
			value: 'overdue',
		},
		{
			label: 'Upcoming',
			value: 'upcoming',
		},
		{
			label: 'Completed',
			value: 'completed',
		},
	];

	const handleTabChange = (event, value) => {
		event.preventDefault();
		setCurrentTab(value);
	};

	return (
		<Box
			sx={{
				backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100'),
				p: 3,
			}}
		>
			<CardHeader
				disableTypography
				title={
					<PageHeader title={'My Tasks'} />
				}
			/>
			<Card>
				<Grid container>
					<Grid item xs={true}>
						<Tabs
							indicatorColor="primary"
							scrollButtons="auto"
							textColor="primary"
							value={currentTab}
							sx={{ px: 2 }}
							variant="scrollable"
							onChange={handleTabChange}
						>
							{tabs.map((tab) => (
								<Tab
									sx={{ fontWeight: 'bold' }}
									key={tab.value}
									label={tab.label}
									value={tab.value}
								/>
							))}
						</Tabs>
					</Grid>
					<Grid item xs={3} container justifyContent={'flex-end'} mr={2} py={1}>
						<Button 
							component="label" 
							variant="contained"
							onClick={() => dispatch(openDialog('task'))}
							startIcon={<Add />}>
                    New Task
						</Button>
					</Grid>
				</Grid>
				<TaskTable tab={currentTab} />
			</Card>
		</Box>
	);
};
