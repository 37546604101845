import React, { useContext, useEffect, useMemo } from 'react';
import { 
	Typography, 
	TableContainer, 
	Paper, 
	Table, 
	TableRow, 
	TableCell, 
	Box, 
} from '@mui/material';
import ClientContext from '@/context/clientContext';
import { ClaimRow } from './ClaimRow';
import { HitsTablePagination } from '@/components/table/TablePaginationActions';
import { rowLimits } from '@/components/table/data';
import { HitsTableBody } from '@/components/table/HitsTableBody';
import { SearchFolioHead } from '@/components/table/FolioTableHead';
import { Configure, InstantSearch } from 'react-instantsearch';
import { dealClient } from '@/utils/typesense';
import { useDispatch, useSelector } from 'react-redux';
import { onOrderChange } from '@/features/table/dealsTableSlice';
import { DealRow } from '../DealTable/DealRow';

// let Claim = {
// 	Id: 39,
// 	CreatedWhen: '2024-02-13T19:55:36.916871Z',
// 	CreatedWho: 'Folio.Developer',
// 	RowVersion: '00000000007CF5B7',
// 	ParentId: 1700,
// 	IsOpen: true,
// 	StatusId: 13,
// 	LossDate: '2024-02-13',
// 	ClaimsTeamId: null,
// 	ClaimsTeamName: null,
// 	ReportedDate: '2024-02-12',
// 	PolicyTransactionId: 2070,
// 	Description: 'Broke a glass',
// 	ClientId: 0,
// 	InsurerId: 0,
// 	AuditCategory: 'Claim',
// 	AuditId: 39
// }

const headCells = [
	{ id: 'dealName', numeric: false, label: 'Ticket name', align: 'left' },
	{ id: 'createDate', numeric: false, label: 'Created date', align: 'right' },
	{ id: 'dealId', numeric: false, label: 'Policy number', align: 'right', sortable: false },
	{ id: 'dealStage', numeric: false, label: 'Stage', align: 'left', sortable: false },
	// { id: 'id', numeric: false, label: '', sortable: false, align: 'center' },
];

export const ClientDealTable = ({ pipeline }) => {
	
	const dispatch = useDispatch();

	const { client } = useContext(ClientContext);

	const isClaims = pipeline == process.env.REACT_APP_PIPELINE_CLAIMS;

	const {
		rowsPerPage,
		orderBy,
		order,
		refresh
	} = useSelector((state) => state.dealTable);

	useEffect(() => {
		dispatch(onOrderChange());
	}, [orderBy, order]);

	const sortItems = headCells.reduce((acc, cell, index) => {
		if (index === 0) {
			acc.push({ value: 'deals', label: cell.label });
		}
		if (cell.sortable !== false && !cell.frontEndSort) {
			acc.push({ value: `deals/sort/${cell.id}:asc`, label: `${cell.label} (asc)` });
			acc.push({ value: `deals/sort/${cell.id}:desc`, label: `${cell.label} (desc)` });
		}
		return acc;
	}, []);

	const handleRequestSort = (event, property) => {
		// dispatch(requestSort(property));
	};

	const handleChangeRowsPerPage = (event) => {
		// dispatch(changeRowsPerPage(event.target.value));
	};

	const filterSchema = useMemo(() => {
		const schema = {
			filters: `clientHubspotId := ${client?.clientRow?.hubspotId} && pipeline := ${pipeline}`
		};
		return schema;
	}, [pipeline, client?.clientRow?.hubspotId]);

	return (
		<InstantSearch indexName="deals" searchClient={dealClient}>
			<Configure {...filterSchema} />
			
			<Box sx={{ overflowX: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
				<TableContainer component={Paper}>
					<Table
						aria-labelledby="tableTitle"
						size="medium"
						aria-label="enhanced table"
						sx={{ minWidth: 700 }}
					>
						<SearchFolioHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={headCells}
							items={sortItems}
						/>
						<HitsTableBody
							TableRowComponent={isClaims ? ClaimRow : DealRow}
							objectType={isClaims ? 'ticket' : 'deal'}
							includeTasks={false}
							headCells={headCells}
							order={order}
							orderBy={orderBy}
							ErrorRowComponent={() => (
								<TableRow>
									<TableCell colSpan={headCells.length} align="center">
										<Typography variant="subtitle1">
											There was an error. Please try again.
										</Typography>
									</TableCell>
								</TableRow>
							)}
							EmptyRowComponent={() => (
								<TableRow>
									<TableCell colSpan={headCells.length} align="center">
										<Typography variant="subtitle1">
											{`No ${isClaims ? 'claims' : 'deals'} found`}
										</Typography>
									</TableCell>
								</TableRow>
							)}
						/>
					</Table>
				</TableContainer>
			</Box>
			<HitsTablePagination
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				items={rowLimits.map((item) => ({
					label: `${item} rows per page`,
					value: item,
					default: item == rowsPerPage,
				}))}
			/>
		</InstantSearch>
	);
};