/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { formatDate } from '@/utils/constants';

export const DealPolicyRow = ({ policy }) => {
	return (
		<TableRow key={`table-row-${policy.Id}`}>
			<TableCell>{policy?.Description}</TableCell>
			<TableCell>{policy?.PolicyNumber}</TableCell>
			<TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
				{formatDate(policy?.ToDate)}
			</TableCell>
		</TableRow>
	);
};
