import React, { useEffect } from 'react';
import { SideNavBar } from '@/components/layouts/SideNavBar';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/system';
import { backgroundColour } from '@/app/theme';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AccountDropdownMenu } from '@/components/layouts/AccountDropdownMenu';
import { useSelector } from 'react-redux';
import { loginRequest } from '@/app/authConfig';
import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useLocation, useNavigate } from 'react-router-dom';
import Toast from '@/components/layouts/Toast';
import { paths } from '@/app/routes';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import UnderMaintenancePage from '@/pages/UnderMaintenancePage';
// import { HubspotConversationsProvider } from '@/context/hubspotContext';
/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */

const LayoutRoot = styled('div')(({ theme }) => {
	const { width, isCollapsed } = useSelector(state => state.sideNavSlice);

	return {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		// [theme.breakpoints.up('xs')]: {
		[theme.breakpoints.up('sm')]: {
			paddingLeft: width
		},
		transition: theme.transitions.create(['padding-left', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	};
});
 
const LayoutContainer = styled('div')({
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'column',
	width: '100%',
	height: '100%',
	backgroundColor: backgroundColour,
});

const MainContent = styled('main')({
	flex: '1 0 auto', // Allow this to grow and shrink as needed, but not to base size
	width: '100%', // Fill the width
});
  
const Footer = styled('footer')({
	flexShrink: '0',
	minHeight: '4em',
	marginTop: '2em',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	borderTop: '1px solid rgb(217, 220, 254)'
});

const AuthenticatedWrapper = ({ children }) => {
	const isAuthenticated = useIsAuthenticated();
	const { instance, accounts } = useMsal();
	const account = useSelector((state) => state.msalAccount.account);

	const navigate = useNavigate();
	const location = useLocation();

	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	
	useEffect(() => {
		console.log('Effect setup', isAuthenticated);
	
		const checkAuthState = async () => {
			console.log('Checking auth state...');
			if (accounts.length > 0) {
				let activeAccount = instance.getActiveAccount();
				console.log('Active account before setting:', activeAccount);
				if (!activeAccount) {
					instance.setActiveAccount(accounts[0]);
					activeAccount = instance.getActiveAccount(); // Log the active account after setting
					console.log('Active account set to:', activeAccount);
				}
			}
			
			try {
				console.log('Start refreshing token');
				const response = await instance.acquireTokenSilent(loginRequest);
				console.log('Successfully refreshed token:', response);
			} catch (error) {
				console.error('Error refreshing token', error);
				if (error instanceof InteractionRequiredAuthError) {
					console.error('Redirecting for token due to interaction required');
					await instance.acquireTokenRedirect(loginRequest);
				}
			}
		};
	
		if (isAuthenticated) {
			console.log('User is authenticated, checking state immediately and setting interval for future checks.');
			checkAuthState();
			const intervalId = setInterval(checkAuthState, 300000); // Check every 5 minutes
			return () => {
				console.log('Clearing interval on effect cleanup');
				clearInterval(intervalId);
			};
		} else {
			console.log('User is not authenticated, redirecting to login');
			if (location.pathname !== '/') {
				navigate(paths.home);
			}
		}
	}, [isAuthenticated, instance, accounts, loginRequest]);
	
	
	const error = userQuery.isError || 
		!userQuery.data || 
		!userQuery.data?.hubspotId;
	
	return <LayoutContainer className='page-layout-container'>
		{userQuery.isLoading || userQuery.isUninitialized
			? 
			<div style={{display: 'flex', justifyContent: 'center', paddingTop: '4em'}}>
				<CircularProgress />
			</div> 
			: 
			error
				? 
				<Box
					sx={{
						backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100'),
						p: 3,
					}}
				>
					<Typography textAlign='center'>
					There was an issue loading your profile. Please contact Folio support for access.
					</Typography>
				</Box> 
				: 
				children}
	</LayoutContainer>;
};

const today = new Date().getFullYear();

export const PageLayout = (props) => {
	const {
		data: siteActive,
		isLoading,
		isError,
		isUninitialized,
	} = useGetFeatureFlagQuery({ feature: 'siteActive' });

	return (
		<>
			<Toast />
			{isLoading || isUninitialized ? (
				<Box marginTop="1em" width="100vw" display="flex" justifyContent={'center'}>
					<CircularProgress />
				</Box>
			) : isError || siteActive ? (
				<>
					<AuthenticatedTemplate>
						<LayoutRoot className='page-layout-root'>
							<SideNavBar />
							<MainContent>
								<AuthenticatedWrapper>
									<AccountDropdownMenu />
									{/* <Container maxWidth='xl'> */}
									{props.children}
									{/* </Container> */}
								</AuthenticatedWrapper>
							</MainContent>
							<Footer>
								<Typography 
									variant="client_subheader" 
									color="rgba(112, 112, 112, 0.75)"
								>
									{'Folio.insure '}{today}
									<span style={{ padding: '0 1em'}}>{'|'}</span>
									<span>
										<Link href={'mailto:support@folio.insure'}
											sx={{ color: 'rgba(112, 112, 112, 0.75)' }}
											target="_blank"
											rel="noopener noreferrer">
									support@folio.insure
										</Link>
									</span>
								</Typography>
							</Footer>
						</LayoutRoot>
					</AuthenticatedTemplate>
					<UnauthenticatedTemplate >
						{props.children}
					</UnauthenticatedTemplate>
				</>
			) : (
				<UnderMaintenancePage />
			)}
		</>
	);

};