import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ClientContext from '@/context/clientContext';
import ContactContext from '@/context/contactContext';
import DealContext from '@/context/dealContext';
import TicketContext from '@/context/ticketContext';
import { IS_DEV } from '@/utils/environment';

export const determineContext = () => {
	const location = useLocation();
	const routes = {
		newBusiness: '/new-business', 
		renewals: '/renewals',
		claims: '/claims',
		endorsements: '/endorsements',
		clients: '/clients',
		tasks: '/my-tasks',
		contacts: '/prospects',
	};

	if (location.pathname.startsWith(routes.clients)) {
		return useContext(ClientContext);
	} else if (location.pathname.startsWith(routes.contacts)) {
		return useContext(ContactContext);
	} else if (location.pathname.startsWith(routes.tasks)) {
		return null;
	} else if (location.pathname.startsWith(routes.claims) || (IS_DEV && location.pathname.startsWith(routes.endorsements))) {
		return useContext(TicketContext);
	} else {
		return useContext(DealContext);
	}
};