import { createSlice } from '@reduxjs/toolkit';

export const msalAccountSlice = createSlice({
	name: 'msalAccount',
	initialState: {
		account: null,
	},
	reducers: {
		setAccount: (state, action) => {
			console.log('👾 ~ action.payload', action.payload);
			state.account = {
				...action.payload,
				...(action.payload.username && { username: action.payload.username.trim().toString() })
			};
		},
		clearAccount: (state) => {
			console.log('👾 ~ clear account', state);
			state.account = null;
		},
	},
});

export const { setAccount, clearAccount } = msalAccountSlice.actions;
export default msalAccountSlice.reducer;