/* eslint-disable no-mixed-spaces-and-tabs */
import { format, addYears } from 'date-fns';
import * as pdfjs from 'pdfjs-dist';
import { paths } from '@/app/routes';

export const currentYear = new Date().getFullYear() % 100;
export const nextYear = addYears(new Date(), 1).getFullYear() % 100;


export const policyHeadCells = [
	{ id: 'checkbox', numeric: false, label: null, sortable: false },
	{
		id: 'policyNumber',
		numeric: false,
		label: 'Policy number',
		align: 'left',
		sortable: false,
	},
	{
		id: 'CreatedWhen',
		numeric: false,
		label: 'Create date',
		align: 'left',
		sortable: false,
	},
	{
		id: 'description',
		numeric: false,
		label: 'Description',
		align: 'left',
		sortable: false,
	},
	{
		id: 'insurer',
		numeric: false,
		label: 'Insurer',
		align: 'left',
		sortable: false,
	},
];



export function getNextStageId(dealStage, dealsPipelineData) {
	if (dealsPipelineData?.stages) {
		const stages = dealsPipelineData.stages;

		const currentStageOrder = stages[dealStage]?.order;
		if (currentStageOrder !== null) {
			let nextStageId;
			for (const stageId in stages) {
				const stage = stages[stageId];
				if (stage.order > currentStageOrder) {
					if (!nextStageId || stage.order < stages[nextStageId].order) {
						nextStageId = stageId;
					}
				}
			}
			if (nextStageId) {
				console.log('Next stage ID:', nextStageId);
				return nextStageId;
			} else {
				console.log('No next stage found.');
				return null;
			}
		} else {
			console.log('Invalid deal stage:', dealStage);
			return null;
		}
	} else {
		// console.log('No pipeline data available.');
		return null;
	}
}

export function getTimeFromDate(date) {
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');
	return `${hours}:${minutes}:${seconds}`;
}
  


export async function setDealStage(objectType, updateFunc, nextStageId, dealId) {
	const paramType = objectType === 'deal' ? 'dealstage' : 'hs_pipeline_stage';
	const params = {
		[paramType]: nextStageId,
	};
	try {
		const response = await updateFunc({
			[`${objectType}Id`]: dealId,
			body: params,
		}).unwrap();
		console.log('👾 ~ transform update object response', response);
		return response; 
	} catch (error) {
		console.error('Error setting deal stage:', error);
		throw error; 
	}
}

export function formatTimeToHourMinutes(date) {
	const hours = date.getHours();
	const minutes = date.getMinutes().toString().padStart(2, '0');
    
	const ampm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 || 12; 

	return `${formattedHours}:${minutes} ${ampm}`;
}


export const folderStructure = {
	year: `${currentYear}-${nextYear}`,
	endorsements: 'Endorsements',
	renewal: 'Renewal',
	cover: 'Cover%20Information',
	claims: 'Claims',
	notes: 'Notes',
	clientAttachments: 'Client%20Attachments',
	clientNotes: 'Client%20Generated%20Notes',
};

export const replaceFolderName = (folderName) => {
	// Normalize and remove special characters
	return String(folderName)
		.normalize('NFD') // Normalize to decompose combined graphemes
		.replace(/[\u0300-\u036f]/g, '') // Remove accents
		.replace(/[\\/]/g, '-')
		.replace(/[^a-zA-Z0-9 _&-]/g, ''); // Remove remaining special characters
};

export const getFolderFromPipeline = (pipelineId) => {
	switch (pipelineId) {
	case process.env.REACT_APP_PIPELINE_NEW_BUSINESS:
		return folderStructure.cover;
	case process.env.REACT_APP_PIPELINE_RENEWALS:
		return folderStructure.renewal;
	case process.env.REACT_APP_PIPELINE_ENDORSEMENTS:
		return folderStructure.endorsements;
	case process.env.REACT_APP_PIPELINE_CLAIMS:
		return folderStructure.claims;
	default:
		console.log('no pipeline id detected: ', pipelineId);
		break;
	}
};

export const getUrlFromPipeline = (pipelineId) => {
	switch (pipelineId) {
	case process.env.REACT_APP_PIPELINE_NEW_BUSINESS:
		return paths.newBusiness;
	case process.env.REACT_APP_PIPELINE_RENEWALS:
		return paths.renewals;
	case process.env.REACT_APP_PIPELINE_ENDORSEMENTS:
		return paths.endorsements;
	case process.env.REACT_APP_PIPELINE_CLAIMS:
		return paths.claims;
	default:
		console.log('no pipeline id detected: ', pipelineId);
		break;
	}
};

export const getCreateUrlFromPipeline = (pipelineId) => {
	switch (pipelineId) {
	case process.env.REACT_APP_PIPELINE_NEW_BUSINESS:
		return paths.createNewBusiness;
	case process.env.REACT_APP_PIPELINE_RENEWALS:
		return paths.createRenewal;
	case process.env.REACT_APP_PIPELINE_ENDORSEMENTS:
		return paths.createEndorsement;
	case process.env.REACT_APP_PIPELINE_CLAIMS:
		return paths.createClaim;
	default :
		console.log('no pipeline id detected: ', pipelineId);
		break;
	}
};

export const getFolderStructure = (clientName, dealName, dealId, pipelineFolder, date) => {
	const replacedCompanyName = replaceFolderName(clientName);
	const dealFolderName = replaceFolderName(`${dealName} Deal ${dealId}`);

	const structure = {
		base: replacedCompanyName,
		dealName: dealFolderName,
		...folderStructure,
	};

	let yearRoot = `${structure.base}/${structure.year}`;
	if (date) {
		const newDate = new Date(date);
		const currentYear = newDate.getFullYear() % 100;
		const nextYear = addYears(newDate, 1).getFullYear() % 100;
		yearRoot = `${structure.base}/${currentYear}-${nextYear}`;
	}

	const baseFolders = [
		structure.base,
		`${structure.base}/${structure.clientAttachments}`,
		`${structure.base}/${structure.clientNotes}`,
		`${yearRoot}`,
		`${yearRoot}/${structure.cover}`,
		`${yearRoot}/${structure.endorsements}`,
		`${yearRoot}/${structure.renewal}`,
		`${yearRoot}/${structure.claims}`,
	];

	const dealFolders = [
		`${yearRoot}/${pipelineFolder}/${structure.dealName}`,
		`${yearRoot}/${pipelineFolder}/${structure.dealName}/${structure.notes}`,
	];

	return {
		folders: [...baseFolders, ...dealFolders], dealFolder: {
			url: dealFolders[0],
			yearRoot, pipelineFolder, dealName: structure.dealName,
		}
	};
};


export const formatCurrency = (num) => {
	// Use the Intl.NumberFormat object to format the 'num' as currency.
	return Intl.NumberFormat('en-US', {
		style: 'currency',                  // Format as currency.
		currency: 'NZD',                    // Use NZD (NZ Dollars).
		currencyDisplay: 'narrowSymbol'     // Display the currency symbol (NZD) in a narrow format.
	}).format(parseFloat(num));
};

export const folioBlue = '#505AFC';

export const firstTwoCapsOrNumbers = str => /^\d/.test(str) ? (str.match(/\d/g) || []).slice(0, 2).join('') : (str.match(/[A-Z]/g) || []).slice(0, 2).join('');

export const formatDate = (date, includeTime) => {
	if (date == null) {
		return 'Date not found';
	}
	const dateFormat = includeTime ? 'd MMM yyyy \'at\' h:mm a' : 'd MMM yyyy';
	return format(new Date(date), dateFormat);
};

export const getFilePageCount = async (file) => {
	const arrayBuffer = await file.arrayBuffer();
	const pdf = await pdfjs.getDocument(arrayBuffer).promise;
	return pdf.numPages;
};

export const formatDateDayToYear = (dateValue) =>  {
	const date = new Date(dateValue);
	return format(new Date(date), 'dd/MM/yyyy');
};

export const taskPriorities = ['LOW', 'MEDIUM', 'HIGH'];

export const closedPipelineStages = process.env.REACT_APP_CLOSED_PIPELINE_STAGES.split(',');
export const pipelineStagesBeforeInterm = process.env.REACT_APP_BEFORE_IN_TERM_STAGES.split(',');
export const claimFinalisedStatus = 12;

export const adjustSharepointUrl = (url) => {
	// console.log('adjusting url: ', url);
	const dotIndex = url.lastIndexOf('.');
	const firstPart = url.substring(0, dotIndex);
	const secondPart = url.substring(dotIndex + 1);
	const encodedFirstPart = encodeURIComponent(firstPart);
	const adjustedServerRelativeUrl = `${encodedFirstPart}.${secondPart}`;
	// console.log('here is the adjusted url: ', adjustedServerRelativeUrl);
	return adjustedServerRelativeUrl;
};

export const prioritySort = (a, b) => {
	const priorityA = taskPriorities.indexOf(a.priority);
	const priorityB = taskPriorities.indexOf(b.priority);

	if (priorityA === priorityB) {
		return a.created - b.created;
	}
	return priorityB - priorityA;
};

// Location of note attachments, etc., in Firebase Storage
export const attachmentsFolderPath = 'dealAttachments';
export const attachmentsClientFolderPath = 'clientAttachments';

export const stabilisedTaskSort = (array) => {
	const arrayCopy = [...array];
	arrayCopy.sort(prioritySort);
	return arrayCopy;
};

export const getPipelineName = (pipelineId) => {
	switch (pipelineId) {
	case process.env.REACT_APP_PIPELINE_NEW_BUSINESS: {
		return 'newBusiness';
	}
	case process.env.REACT_APP_PIPELINE_CLAIMS: {
		return 'claims';
	}
	case process.env.REACT_APP_PIPELINE_RENEWALS: {
		return 'renewals';
	}
	case process.env.REACT_APP_PIPELINE_ENDORSEMENTS: {
		return 'endorsements';
	}
	default: {
		return '';
	}
	}
};

export const createFormUrlQuery = (broker, insured, contactFname, contactLname, dealId, clientId) => {
	return `?broker=${encodeURIComponent(broker)}&insured=${encodeURIComponent(insured)}&firstName=${encodeURIComponent(contactFname)}&lastName=${encodeURIComponent(contactLname)}&deal=${encodeURIComponent(dealId)}&client=${encodeURIComponent(clientId)}`;
};



export const formatDateString = (dateString) => {
	if (!dateString) return '-';
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0'); 
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
  };


export const truncateFilename = (filename, maxLength) => {
	if ((filename ?? '').length <= maxLength) {
		return filename; // Return the original filename if it's short enough
	}
	const extensionIndex = filename.lastIndexOf('.'); // Find the last '.' to identify the extension
	const extension = extensionIndex !== -1 ? filename.substring(extensionIndex) : ''; // Extract the extension
	const baseName = extensionIndex !== -1 ? filename.substring(0, extensionIndex) : filename; // Extract the base filename
	const truncatedBaseName = baseName.slice(0, maxLength - extension.length - 3) + '... '; // Truncate and add ellipsis
	return truncatedBaseName + extension; // Reassemble the truncated filename with its extension
};

export const removeHtml = (htmlString) => {
	// First, replace </p> tags with a newline break
	const updatedHtmlString = htmlString.replace(/<\/p>/gi, '\n');
	const regex = /(<([^>]+)>)/gi;
	return updatedHtmlString.replace(regex, '');
};

// export const specialChars = /[!@#$%^&*()+=\\[\]{};:"\\|<>\\/?~]/;
export const specialChars = /[<>:"'/\\|?%*]/;

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const claimStatusColour = (displayOrder) => {
	return ['#1ABCFE', '#505AFC', '#9747FF', '#F3BA2F', '#00C650' ][displayOrder] ?? folioBlue;
};

export const taskTypes = ['TODO', 'CALL', 'EMAIL', 'MEETING'];

const isPrimitive = (obj) => {
	return obj !== Object(obj);
};
  
export const isEqual = (first, second) => {
	if (first === second) {
		return true;
	}
  
	if (isPrimitive(first) || isPrimitive(second) ||
      typeof first === 'function' || typeof second === 'function') {
		return first === second;
	}
  
	if (Object.keys(first).length !== Object.keys(second).length) {
		return false;
	}
  
	for (const key of Object.keys(first)) {
		if (!(key in second)) {
			return false;
		}
  
		if (!isEqual(first[key], second[key])) {
			return false;
		}
	}
  
	return true;
};

export const getNameInitials = (name) => {
	const names = name.split(' ');
	if (/^[A-Z]{2}/.test(name)) {
		return name.substring(0, 2).toUpperCase();
	}
	if (names.length >= 2) {
		return (
			names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase()
		);
	} else {
		return name.length >= 2
			? name.substring(0, 2).toUpperCase()
			: name.toUpperCase();
	}
};

export const formatKey = (key) => {
	return key
		.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camel case words
		.replace(/([a-z])([0-9])/g, '$1 $2') // Add space between lowercase and number
		.replace(/_/g, ' ') // Replace underscores with spaces
		.replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

export const toCamelCase = (string) => {
	return string
		.replace(/[^a-zA-Z0-9\s]/g, '') // Remove symbols
		.trim() // Trim leading/trailing spaces
		.split(/\s+/) // Split by spaces (one or more)
		.map((word, index) => 
			index === 0 
				? word.toLowerCase() 
				: word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)
		.join('');
};

export const getNumberFromKey = (key) => {
	const match = key.match(/\d+/);
	return match ? match[0] : null;
};

export const generateDetailsObject = (details, parentKey = '') => {
	const result = {};

	if (!details) return result;

	Object.entries(details).forEach(([key, value], index) => {
		const uniqueKey = parentKey ? `${parentKey}_${key}` : key;
		const arrayKey = parentKey ? `${parentKey}_${index}` : key;

		if (!isNaN(Number(key)) && typeof key === 'string') {
			if (Array.isArray(value)) {
				value.forEach((item, idx) => {
					Object.assign(result, generateDetailsObject(item, `${arrayKey}_${idx}`));
				});
			} else if (typeof value === 'object') {
				Object.assign(result, generateDetailsObject(value, uniqueKey));
			}
		} else if (Array.isArray(value)) {
			value.forEach((item, idx) => {
				Object.assign(result, generateDetailsObject(item, `${arrayKey}_${idx}`));
			});
		} else if (typeof value === 'object') {
			if (value === null) {
				result[uniqueKey] = [key, '-'];
			} else {
				Object.assign(result, generateDetailsObject(value, uniqueKey));
			}
		} else {
			result[uniqueKey] = [key, value?.toString() || '-'];
		}
	});

	return result;
};

export const flattenToNested = (flattenedData) => {
	const nestedData = {};

	for (const [flatKey, value] of Object.entries(flattenedData)) {
		const keys = flatKey.split('_');
		let currentLevel = nestedData;

		keys.forEach((key, index) => {
			if (!currentLevel[key]) {
				currentLevel[key] = index === keys.length - 1 ? value : {};
			}
			currentLevel = currentLevel[key];
		});
	}

	return nestedData;
};