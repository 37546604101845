import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { PageLayout } from '@/components/layouts/PageLayout';
import LoginPage from '@/pages/LoginPage';

/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */
export const AuthenticatedPageLayout = ({ children }) => {

	return (
		<PageLayout>
			<AuthenticatedTemplate>
				{children}
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<LoginPage />
			</UnauthenticatedTemplate>
		</PageLayout>
	);
};
