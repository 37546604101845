/* eslint-disable no-mixed-spaces-and-tabs */
import React, {  } from 'react';
import {
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Paper,
} from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import {
	policyHeadCells,
} from '@/utils/constants';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { InsightClaimRow } from './InsightClaimRow';

export const InsightClaimTable = () => {
	const { claim } = determineContext();

	const headerCells = policyHeadCells.filter((header) => header.id !== 'checkbox');

	// const handleSubmit = async () => {
	// 	try {
	// 		await updateDealStage();
	// 		await updateInsightClaim({
	// 			claimId: claim.Id,
	// 			policyId: policyId,
	// 			ReportedDate: claim.ReportedDate,
	// 			LossDate: claim.LossDate,
	// 			PolicyTransactionId: policyTransId,
	// 			Description: claim.Description,
	// 			CreatedWhen: claim.CreatedWhen,
	// 			CreatedWho: claim.CreatedWho,
	// 			RowVersion: claim.RowVersion,
	// 			Status: claimFinalisedStatus,
	// 		});
	// 		if (additionalContent.length > 0) {
	// 			const body = {
	// 				dealId: deal.hs_object_id,
	// 				noteBody: additionalContent,
	// 				hubspotId: hubspotId,
	// 				objectType: objectType,
	// 			};
	// 			console.log('📋 ~ Create note object', body);
	// 			const responseNote = await createNote(body).unwrap();
	// 		}
	// 		dispatch(closeDialog('completeDeal'));
	// 		dispatch(
	// 			showToast({
	// 				message: `${pipelineName} completed!`,
	// 				action: {
	// 					path: `/clients/${client.hs_object_id}`,
	// 					label: 'GO TO CLIENT',
	// 				},
	// 			})
	// 		);
	// 	} catch (err) {
	// 		console.log(':no_good: ~ Error updating claim', err);
	// 	}
	// };

	return (
		<>
			<Typography variant='subtitle2' sx={{ fontWeight: 'bold', marginTop: 3 }}>
				Completing claim:
			</Typography>
			<TableContainer component={Paper}>
				<Table
					aria-labelledby='tableTitle'
					size='medium'
					aria-label='enhanced table'
					sx={{ minWidth: 700 }}
				>
					<FolioTableHead order={'desc'} orderBy={'createdate'} headCells={headerCells} />
					<TableBody>
						{claim ? (
							<InsightClaimRow claim={claim} />
						) : (
							<TableRow>
								<TableCell colSpan={headerCells.length} align='center'>
									<Typography variant='subtitle1'>No Claims found</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
