// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
//'firebase/firestore/lite'
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

// const firebaseConfig = {
//   projectId: "folio-app-3cd6d",
//   storageBucket: "gs://folio-app-3cd6d.appspot.com",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
export { storage, firestore, app as firebase };
