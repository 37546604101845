import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { BrokerSelect } from '@/components/selects/BrokerSelect';
import { TeamViewToggle } from '@/components/buttons/TeamViewToggle';

export const PageHeader = ({ title, showGroupView, showBrokerSelect, ...props }) => {
	const account = useSelector((state) => state.msalAccount.account);
	
	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
    
	return <Grid container justifyContent="space-between" alignItems="center" {...props}>
		<Grid item>
			<Typography variant="h4" style={{ fontWeight: 'bold' }}>
				{title}
			</Typography>
		</Grid>
		{(showGroupView || showBrokerSelect) && userDetails?.canViewGroup && <Grid item>
			{showGroupView ? <TeamViewToggle /> : <BrokerSelect />}
		</Grid>}
	</Grid>;
};