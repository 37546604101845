/**
 * Compares two objects for sorting in descending order based on a specified property.
 * 
 * @param {Object} a - The first object to compare.
 * @param {Object} b - The second object to compare.
 * @param {string} orderBy - The property name to be used for comparison.
 * @returns {number} - Returns -1, 0, or 1 as per the comparison logic for sorting.
 */
export function descendingComparator(a, b, orderBy) {
	// Determine the value from objects 'a' and 'b' for comparison:
	// - If the property is a string, convert it to lowercase for case-insensitive comparison.
	// - If the property is an object and has a 'label' property, use the 'label' for comparison.
	// - Otherwise, use the property value as is.
	const aOrderBy = typeof a[orderBy] === 'string' ? `${a[orderBy]}`.toLowerCase() : a[orderBy] != null && typeof a[orderBy] === 'object' && 'label' in a[orderBy] ? a[orderBy].label : a[orderBy];
	const bOrderBy = typeof b[orderBy] === 'string' ? `${b[orderBy]}`.toLowerCase() : b[orderBy] != null && typeof b[orderBy] === 'object' && 'label' in b[orderBy] ? b[orderBy].label : b[orderBy];
    
	if (bOrderBy < aOrderBy) {
		return -1;
	}
	if (bOrderBy > aOrderBy) {
		return 1;
	}
	return 0;
}

export function getComparator(order, orderBy, nullAlwaysLast = false) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => {
			if (nullAlwaysLast) {
				// Null values should always be last
				if (a[orderBy] === null) {
					return 1;
				}
				if (b[orderBy] === null) {
					return -1;
				}
			}
			
			return -descendingComparator(a, b, orderBy);
		};
}

export function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}