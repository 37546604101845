const BASE_QUERY = {
	baseUrl: '/', // Set your base URL here
	prepareHeaders: (headers, { getState }) => {
		headers.set('Content-Type', 'application/json');
		headers.set('Accept', 'application/json');
		const state = getState();
		const email = state?.msalAccount?.account?.username;
		const data = state?.userApi?.queries[`getUserDetails({"email":"${email}"})`]?.data;
		if (data?.brokerId && data?.broker?.ledger) {
			headers.set('broker-id', data.brokerId);
			headers.set('broker-ledger', data.broker.ledger);
		}
		return headers;
	},
};

export default BASE_QUERY;