import React, {  } from 'react';
import { 
	Box, 
	Card, 
} from '@mui/material';
import { ClientDealTable } from '@/components/tables/ClientClaimsTable';

export const ClaimsTab = () => {

	return (<Card>
		<ClientDealTable pipeline={process.env.REACT_APP_PIPELINE_CLAIMS} />
	</Card>
	);
};