import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

const CustomNavLink = forwardRef((props, ref) => (
	<NavLink
		ref={ref}
		{...props}
		className={({ isActive }) => (isActive ? props.className + ' Mui-selected' : props.className)}
		end
	/>
));

CustomNavLink.displayName = 'CustomNavLink';

export default CustomNavLink;