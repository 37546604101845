import React, { createContext } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import useDealData, { initialState } from '@/hooks/useDealData';

const DealContext = createContext(initialState);

export const DealContextProvider = ({ children }) => {
	const { id, contextValue, isLoading, isError } = useDealData('deal');
	const dealId = id;
	
	console.log('DEAL CONTEXT', contextValue);
	return (
		<DealContext.Provider value={contextValue}>
			{isError ? (
				<Box>
					<Typography textAlign="center">
						There was an issue loading this deal. Please try again.
					</Typography>
				</Box>
			) : (
				children
			)}
			{/* {isLoading ? (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress />
				</div>
			) : isError ? (
				<Box>
					<Typography textAlign="center">
						There was an issue loading this deal. Please try again.
					</Typography>
				</Box>
			) : (
				children
			)} */}
		</DealContext.Provider>
	);
};

export default DealContext;
