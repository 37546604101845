/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	Stack,
	LinearProgress,
	Box,
	Grid,
	List,
	ListItem,
	TextField,
	DialogActions,
	Button,
	FormHelperText,
} from '@mui/material';
import { theme } from '@/app/theme';
import {
	claimFinalisedStatus,
	getNextStageId,
	pipelineStagesBeforeInterm,
	setDealStage,
	stabilisedTaskSort,
} from '@/utils/constants';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import {
	useAddPoliciesToDealMutation,
	useGetDealPolicyTransactionsQuery,
	useGetDealRowQuery,
	useUpdateDealPropertiesMutation,
} from '@/features/deals/dealsApi';
import { useSharepoint } from '@/hooks/useSharepoint';
import { CompleteDealPolicySelection } from './CompletePipeline/CompleteDealPolicySelection';
import { CompleteDealPolicyTransactionTable } from './CompletePipeline/CompleteDealPolicyTransactionTable';
import { determineContext } from '@/hooks/determineContext';
import {
	setEmailFiles,
	setDocxFiles,
	setPdfFiles,
	setXlsxFiles,
} from '@/features/completeDeal/completeDealSlice';
import { InsightClaimTable } from '@/components/tables/InsightClaimTable';
import { ComplianceTaskCheckbox } from '@/components/cards/DealCardValues/ComplianceTaskCheckbox';
import { LoadingButton } from '@mui/lab';
import {
	useUpdateInsightClaimMutation,
	useUpdateTicketPropertiesMutation,
} from '@/features/claims/ticketsApi';
import {
	useAddDocumentPropertiesToCollectionMutation,
	useCreateNoteMutation,
} from '@/features/engagements/engagementsApi';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { showToast } from '@/features/toast/toastSlice';
import { Timestamp } from 'firebase/firestore';
import { setError } from '@/features/table/attachmentsTableSlice';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { CheckBox } from '@mui/icons-material';

const CompleteDealTasks = () => {
	const { complianceTasks } = determineContext();

	const completedTasks = useMemo(
		() => complianceTasks.filter((t) => t.completed),
		[complianceTasks]
	);

	const incompleteTasks = useMemo(
		() => stabilisedTaskSort(complianceTasks.filter((t) => !t.completed)),
		[complianceTasks]
	);

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	return (
		<>
			<Stack direction='column' spacing={1}>
				<Typography sx={minimalLabelTheme}>Total Tasks Complete</Typography>
				<Typography
					variant='h6'
					sx={{ fontWeight: 'bold', marginLeft: '8px' }}
				>{`${completedTasks.length}/${complianceTasks.length}`}</Typography>
			</Stack>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ minWidth: 35 }}>
					<Typography sx={minimalLabelTheme}>COMPLETE</Typography>
				</Box>
				<Box sx={{ width: '100%', ml: 3, mr: 1 }}>
					<LinearProgress
						variant='determinate'
						value={
							complianceTasks.length === 0
								? 100
								: (completedTasks.length / complianceTasks.length) * 100
						}
						sx={{
							height: 10,
							borderRadius: 5,
							'& .MuiLinearProgress-bar': {
								borderRadius: 5,
								height: 10,
								backgroundColor: '#00C759',
							},
						}}
					/>
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography variant='body2' color='text.secondary'>
						{complianceTasks.length === 0
							? '100%'
							: `${Math.round(
								(completedTasks.length / complianceTasks.length) * 100
							  )}%`}
					</Typography>
				</Box>
			</Box>
		</>
	);
};

const CompleteDealRecordKeeping = () => {
	const dispatch = useDispatch();

	const { sharepoint, dealRow } = determineContext();

	const { emailFiles, docxFiles, pdfFiles, xlsxFiles } = useSelector(
		(state) => state.completeDeal
	);

	useEffect(() => {
		dispatch(setEmailFiles([]));
		dispatch(setDocxFiles([]));
		dispatch(setPdfFiles([]));
		dispatch(setXlsxFiles([]));
	}, []);

	const { getSharepointAttachmentsRecursive } = useSharepoint(
		sharepoint?.sharepointSite,
		sharepoint?.clientSiteSuffix,
		sharepoint?.clientFolder,
		sharepoint?.clientSite
	);

	useEffect(() => {
		const getDealProperties = async () => {
			try {
				const response = await getSharepointAttachmentsRecursive(dealRow.dealFolderPath);

				const allEmailFiles = response.filter((file) =>
					file.Name.toLowerCase().endsWith('.msg')
				);

				const allDocxFiles = response.filter((file) =>
					file.Name.toLowerCase().endsWith('.docx')
				);

				const allPdfFiles = response.filter((file) =>
					file.Name.toLowerCase().endsWith('.pdf')
				);

				const allXlsxFiles = response.filter((file) =>
					file.Name.toLowerCase().endsWith('.xlsx')
				);

				dispatch(setEmailFiles(allEmailFiles));
				dispatch(setDocxFiles(allDocxFiles));
				dispatch(setPdfFiles(allPdfFiles));
				dispatch(setXlsxFiles(allXlsxFiles));
			} catch (error) {
				console.error('🙅 ~ Error getting files:', error);
				dispatch(setEmailFiles([]));
				dispatch(setDocxFiles([]));
				dispatch(setPdfFiles([]));
				dispatch(setXlsxFiles([]));
			}
		};
		if (dealRow?.dealFolderPath && sharepoint?.isValid) {
			getDealProperties();
		}
	}, [dealRow, sharepoint]);

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	return (
		<Stack direction='column' spacing={1}>
			<Typography sx={minimalLabelTheme}>Record Keeping</Typography>
			<Stack direction='row' sx={{ alignItems: 'center' }} spacing={1.5}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{emailFiles.length}
				</Typography>
				<Typography>Emails saved</Typography>
			</Stack>

			<Stack direction='row' sx={{ alignItems: 'center' }} spacing={1.5}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{docxFiles.length}
				</Typography>
				<Typography>Word .docx saved</Typography>
			</Stack>

			<Stack direction='row' sx={{ alignItems: 'center' }} spacing={1.5}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{pdfFiles.length}
				</Typography>
				<Typography>Application .Pdfs saved</Typography>
			</Stack>

			<Stack direction='row' sx={{ alignItems: 'center' }} spacing={1.5}>
				<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
					{xlsxFiles.length}
				</Typography>
				<Typography>Excel .Xlsx saved</Typography>
			</Stack>
		</Stack>
	);
};

const CompleteDealStepsToComplete = () => {
	const { complianceTasks, deal } = determineContext();
	const [complianceTasksList, setComplianceTasksList] = useState([]);

	const pipelineQuery = useGetPipelinePropertiesQuery(deal.pipeline, {
		skip: !deal.pipeline,
	});
	const { data: pipelineData, isLoading, isError } = pipelineQuery;

	const stages = pipelineData?.stages ?? {};

	const stageOrderMap = useMemo(() => {
		return Object.entries(stages).reduce((acc, [stageId, stageData]) => {
			acc[stageId] = { order: stageData.order, label: stageData.label };
			return acc;
		}, {});
	}, [stages]);

	const groupedComplianceTasks = useMemo(() => {
		if (!complianceTasks) return {};

		const grouped = complianceTasks.reduce((acc, task) => {
			const stageId = task.questionData?.stages[0];
			if (stageId) {
				if (!acc[stageId]) {
					acc[stageId] = [];
				}
				acc[stageId].push(task);
			}
			return acc;
		}, {});

		Object.keys(grouped).forEach((stageId) => {
			grouped[stageId].sort((a, b) => a.questionData.order - b.questionData.order);
		});

		return grouped;
	}, [complianceTasks, stageOrderMap]);

	const sortedStages = useMemo(() => {
		return Object.entries(stageOrderMap)
			.sort(([, a], [, b]) => a.order - b.order)
			.map(([stageId, { label }]) => ({ stageId, label }));
	}, [stageOrderMap]);

	const handleSelectAllCheckbox = (event) => {
		const { checked } = event.target;
		const updatedTasksRows = complianceTasksList.map((task) => ({
			...task,
			completed: checked,
		}));
		setComplianceTasksList(updatedTasksRows);
	};

	return (
		<Stack direction='column' spacing={2}>
			<Stack direction='row' spacing={1} alignItems='center'>
				<Typography variant='subtitle2'>Steps to complete:</Typography>
			</Stack>
			{sortedStages.map(({ stageId, label }) => (
				<React.Fragment key={stageId}>
					<Typography variant='subtitle2'>{label}</Typography>
					<Grid container spacing={1}>
						{groupedComplianceTasks[stageId]?.map((task) => {
							const itemSize =
								12 / Math.min(3, groupedComplianceTasks[stageId].length);
							return (
								<Grid item key={task.documentId} xs={12} sm={itemSize}>
									<ComplianceTaskCheckbox task={task} />
								</Grid>
							);
						})}
					</Grid>
				</React.Fragment>
			))}
		</Stack>
	);
};

export const CompleteDealView = () => {
	const dispatch = useDispatch();

	const dialogOpen = useSelector((state) => state.dialog.open['completeDeal']);

	const [additionalContent, setAdditionalContent] = useState('');

	const [confirmationOpen, setConfirmationOpen] = useState();

	const [selectedPolicies, setSelectedPolicies] = useState([]);

	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const { deal, claim, pipelines, client, dealRow, objectType, dealsPipelineData, policies } =
		determineContext();

	const isNewBusiness =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_NEW_BUSINESS;
	const isEndorsement =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_ENDORSEMENTS;

	useEffect(() => {
		if (isNewBusiness || isEndorsement) {
			setError(selectedPolicies.length === 0);
		}
	}, [selectedPolicies]);

	const pipelineName = isNewBusiness
		? 'New Business'
		: dealsPipelineData?.pipelines?.label?.slice(0, -1);

	const finalStage = useMemo(() => {
		const stage = pipelines?.stages?.find(
			(stage) =>
				stage?.metadata?.probability?.toString() === '1.0' ||
				stage?.metadata?.ticketState === 'CLOSED'
		);
		return stage;
	}, [deal?.hs_pipeline_stage, deal?.dealstage, pipelines?.stages]);

	const account = useSelector((state) => state.msalAccount.account);

	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userDetails?.hubspotId;

	const [updateDealProperties] = useUpdateDealPropertiesMutation();

	const policyId = useMemo(() => policies?.[0]?.Policy?.Id, [policies]);

	const { data: policyTransData } = useGetDealPolicyTransactionsQuery(
		{ policyId: policyId },
		{ skip: !policyId }
	);
	const policyTransId = useMemo(() => policyTransData?.[0]?.Id, [policyTransData]);

	const [updateInsightClaim] = useUpdateInsightClaimMutation();

	const [addPoliciesToDeal] = useAddPoliciesToDealMutation();

	const [updateTicketProperties] = useUpdateTicketPropertiesMutation();

	const [addDocumentPropertiesToCollection] = useAddDocumentPropertiesToCollectionMutation();

	const [createNote] = useCreateNoteMutation();

	const updateDealStage = async () => {
		const updateFunc = objectType === 'deal' ? updateDealProperties : updateTicketProperties;
		const updateRes = await setDealStage(
			objectType,
			updateFunc,
			finalStage.id,
			deal.hs_object_id
		);
		console.log('updated deal response: ', updateRes);
	};

	const updateDealPolicies = async (policiesToAdd) => {
		const response = await addPoliciesToDeal({
			dealId: deal.hs_object_id,
			policies: policiesToAdd,
		});
		const todaysDate = new Date();
		const timestamp = Timestamp.fromDate(todaysDate);
		const responseDocProperties = await addDocumentPropertiesToCollection({
			dealId: deal.hs_object_id,
			docId: dealRow.id,
			objectType: objectType,
			collectionName: 'activities',
			properties: {
				dealId: deal.hs_object_id,
				activityType: 'policies linked',
				createdDate: timestamp,
				clientId: client.hs_object_id,
			},
		});
	};

	const completeDeal = async () => {
		setLoading(true);
		try {
			switch (deal?.pipeline ?? deal?.hs_pipeline) {
			case process.env.REACT_APP_PIPELINE_NEW_BUSINESS: {
				const policiesToAdd = selectedPolicies.map((policy) => ({
					policyId: policy.Id,
					policyTransId: policy.policyTransactions?.[0]?.Id,
				}));
				await updateDealPolicies(policiesToAdd);
				await updateDealStage();
				break;
			}
			case process.env.REACT_APP_PIPELINE_CLAIMS: {
				await completeClaim();
				break;
			}
			case process.env.REACT_APP_PIPELINE_ENDORSEMENTS: {
				const policiesToAdd = selectedPolicies.map((policyTransaction) => ({
					policyId: policies?.[0]?.Id,
					policyTransId: policyTransaction.Id,
				}));
				await updateDealPolicies(policiesToAdd);
				await updateDealStage();
				break;
			}
			default: {
				await updateDealStage();
				break;
			}
			}
			if (additionalContent.length > 0) {
				const body = {
					dealId: deal.hs_object_id,
					noteBody: additionalContent,
					hubspotId: hubspotId,
					objectType: objectType,
				};
				console.log('📋 ~ Create note object', body);
				await createNote(body).unwrap();
			}
			dispatch(closeDialog('completeDeal'));
			dispatch(
				showToast({
					message: `${pipelineName} completed!`,
					action: {
						path: `/clients/${client.hs_object_id}`,
						label: 'GO TO CLIENT',
					},
				})
			);
		} catch (err) {
			console.log(':no_good: ~ Error adding policies', err);
			dispatch(
				showToast({
					message: 'Error adding policies',
					error: true,
				})
			);
		} finally {
			setLoading(false);
		}
	};

	const completeClaim = async () => {
		await updateDealStage();
		await updateInsightClaim({
			claimId: claim.Id,
			properties: {
				StatusId: claimFinalisedStatus,
			},
		});
	};

	const PolicySelectionContent = useCallback(() => {
		switch (deal?.hs_pipeline ?? deal?.pipeline) {
		case process.env.REACT_APP_PIPELINE_NEW_BUSINESS:
			return (
				<CompleteDealPolicySelection
					setSelectedPolicies={setSelectedPolicies}
					setLoading={setLoading}
				/>
			);
		case process.env.REACT_APP_PIPELINE_CLAIMS:
			return <InsightClaimTable setLoading={setLoading} />;
		case process.env.REACT_APP_PIPELINE_ENDORSEMENTS:
			return (
				<CompleteDealPolicySelection
					setSelectedPolicies={setSelectedPolicies}
					setLoading={setLoading}
				/>
			);
		case process.env.REACT_APP_PIPELINE_RENEWALS:
			return <CompleteDealPolicyTransactionTable setLoading={setLoading} />;
		default:
			return <></>;
		}
	}, [deal?.hs_pipeline, deal?.pipeline, setSelectedPolicies, setLoading]);

	return (
		<>
			<Dialog
				open={dialogOpen}
				onClose={(_, reason) => {
					if (reason === 'backdropClick') {
						return false;
					} else if (!loading) {
						dispatch(closeDialog('completeDeal'));
					}
				}}
				hideBackdrop={true}
				fullWidth={true}
				maxWidth='lg'
				sx={{ py: '2em', px: '3em' }}
			>
				<DialogTitle sx={{ fontWeight: 'bold' }} component='div'>
					{`Complete ${pipelineName}`}
					<Typography variant='subtitle2'>Your Deal Summary</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={5} pt='1em'>
							<CompleteDealTasks />
						</Grid>
						<Grid item xs={true} />
						<Grid item xs={4} sm={5}>
							<CompleteDealRecordKeeping />
						</Grid>
						<Grid item xs={12}>
							<CompleteDealStepsToComplete />
						</Grid>

						<Grid item xs={12}>
							<Typography variant='subtitle2'>Note</Typography>
							<TextField
								fullWidth
								multiline
								rows={3}
								value={additionalContent}
								onChange={(e) => setAdditionalContent(e.target.value)}
							/>
						</Grid>
					</Grid>
					<PolicySelectionContent />
					{error && (
						<FormHelperText error>
							{`Please select the ${
								isEndorsement ? 'endorsement' : 'policies'
							} related to this deal`}
						</FormHelperText>
					)}
				</DialogContent>

				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => dispatch(closeDialog('completeDeal'))}
						color='primary'
						disabled={loading}
					>
						Cancel
					</Button>
					<LoadingButton
						loading={loading || finalStage?.id === null}
						color='primary'
						onClick={() => {
							if (selectedPolicies.length === 0 && (isEndorsement || isNewBusiness)) {
								setError(true);
								return;
							} else {
								setError(false);
							}
							setConfirmationOpen(true);
						}}
						variant='contained'
						disabled={loading}
					>
						Complete
					</LoadingButton>
				</DialogActions>
			</Dialog>

			{confirmationOpen && (
				<Dialog
					open={confirmationOpen}
					onClose={() => setConfirmationOpen(false)}
					maxWidth='xs'
					fullWidth={true}
				>
					<DialogTitle>Confirmation</DialogTitle>
					<DialogContent>
						<Typography>
							{`Are you sure you want to complete this ${pipelineName}?`}
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setConfirmationOpen(false)} color='primary'>
							Cancel
						</Button>
						<Button
							onClick={() => {
								setConfirmationOpen(false);
								completeDeal();
							}}
							color='primary'
							variant='contained'
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};
