import React from 'react';
import { Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useSendScopeOfAdviceEmailMutation } from '@/features/deals/dealsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { determineContext } from '@/hooks/determineContext';
import { showToast } from '@/features/toast/toastSlice';

export const SendScopeOfAdviceButton = () => {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });

	const { deal } = determineContext();

	const [sendScopeOfAdviceEmail, { isLoading }] = useSendScopeOfAdviceEmailMutation();

	const handleSendScopeEmail = async () => {
		await sendScopeOfAdviceEmail({
			dealId: deal?.hs_object_id,
			hubspotId: userQuery?.data?.hubspotId,
		}).unwrap().then((res) => {
			console.log('Result of sending email', res);
			dispatch(
				showToast({
					message: 'Email sent!',
					success: true,
				})
			);
			// resetForm();
			// resetFormFields();
		});
		// .catch((err) => {
		//     console.log('Error generating proposal', err);
		//     dispatch(
		//         showToast({
		//             message: 'Error generating proposal; please try again.',
		//             error: true,
		//         })
		//     );
		// });
	};

	return (
		<div style={{ display: 'flex', justifyContent: 'right', paddingBottom: '1em' }}>
			<Button
				disabled={isLoading || !deal || !userQuery.data}
				component='label'
				variant='tab_header'
				startIcon={<EmailIcon />}
				onClick={handleSendScopeEmail}
			>
				{'Email Scope of Advice'}
			</Button>
		</div>
	);
};
