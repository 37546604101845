import React from 'react';
import { Box, IconButton, TablePagination, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { usePagination, useHitsPerPage } from 'react-instantsearch';

export function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange, lastPageAfter, showLastButton } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		const totalPages = Math.ceil(count / rowsPerPage);
		const lastPage = totalPages - 1;
		const lastPageAfter = lastPage * rowsPerPage;

		onPageChange(event, lastPage, lastPageAfter);
	};

	return (
		<div style={{ display: 'flex' }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
				size="large"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
				size="large"
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
				size="large"
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			{showLastButton && <IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
				size="large"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>}
		</div>
	);
}

export const HitsTablePagination = ({rowsPerPage, onRowsPerPageChange, ...props}) => {
	const {
		// pages,
		currentRefinement,
		nbHits,
		// nbPages,
		// isFirstPage,
		// isLastPage,
		// canRefine,
		refine,
		// createURL,
	} = usePagination(props);

	const {
		items,
		refine: refineRows,
		// createURL,
		canRefine: canRefineRows,
	} = useHitsPerPage(props);

	const handleChangePage = (event, page) => {
		refine(page);
	};

	const handleRowsPerPageChange = (event) => {
		onRowsPerPageChange(event);
		refineRows(event.target.value);
	};

	return (
		<Box width="100%" justifyItems="end">
			<TablePagination
				rowsPerPageOptions={items.map(i => i.value)}
				count={nbHits}
				rowsPerPage={rowsPerPage}
				page={currentRefinement}
				component={'div'}
				SelectProps={{
					inputProps: {
						'aria-label': 'Rows per page',
					},
					native: true,
				}}
				sx={{
					display: 'block',
					width: '100%'
				}}
				showLastButton
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleRowsPerPageChange}
				ActionsComponent={TablePaginationActions}
			/>
		</Box>
	);
};