// // features/table/invoicesTableSlice.js
import { createSlice, current } from '@reduxjs/toolkit';

export const invoicesTableSlice = createSlice({
	name: 'invoicesTable',
	initialState: {
		pageAfter: null,
		endAt: null,
		searchValue: '',
		searchText: '',
		rowsPerPage: 10,
		orderBy: 'createdWhen',
		order: 'asc',
		page: 0,
		rows: [],
	},
	reducers: {
		setSearchValue: (state, action) => {
			state.searchValue = action.payload;
		},
		setSearchText: (state, action) => {
			state.searchText = action.payload;
		},
		setRows: (state, action) => {
			state.rows = action.payload;
		},
		resetSearchFields: (state) => {
			state.searchValue = '';
			state.searchText = '';
			state.pageAfter = null;
			state.endAt = null;
			state.page = 0;
			state.rows = [];
		},
		resetPages: (state) => {
			state.pageAfter = null;
			state.endAt = null;
			state.page = 0;
		},
		changeRowsPerPage: (state, action) => {
			state.rowsPerPage = parseInt(action.payload, 10);
			state.pageAfter = null;
			state.endAt = null;
			state.page = 0;
		},
		requestSort: (state, action) => {
			const property = action.payload;
			const isAsc = state.orderBy === property && state.order === 'asc';
			state.order = isAsc ? 'desc' : 'asc';
			state.orderBy = property;
		},
		changePage: (state, action) => {
			const { newPage } = action.payload;
			const currentPage = state.page;
			state.page = newPage;
			const last = current(state.rows)[current(state.rows).length - 1]?.docId;
			const first = current(state.rows)[0]?.docId;
			console.log(`first ${first} last ${last} newPage ${newPage} currentPage ${currentPage}`);
			if (newPage > currentPage) {
				state.pageAfter = last;
				state.endAt = null;
			} else if (newPage == 0) {
				state.pageAfter = null;
				state.endAt = null;
			} else {
				state.endAt = first;
				state.pageAfter = null;
			}
		},
		resetAllPages: (state) => {
			state.pageAfter = null;
			state.endAt = null;
			state.page = 0;
		},
		onOrderChange: (state) => {
			state.rows = [];
			state.pageAfter = null;
			state.endAt = null;
			state.page = 0;
		}
	},
});

export const { 
	setRows,
	setSearchText,
	setSearchValue,
	changePage,
	changeRowsPerPage,
	requestSort,
	resetAllPages,
	resetPages,
	resetSearchFields,
	onOrderChange,

} = invoicesTableSlice.actions;

export default invoicesTableSlice.reducer;