import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Login } from '@mui/icons-material';
import { loginRequest } from '@/app/authConfig';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = ({ showListItem }) => {
	const { instance } = useMsal();

	const handleLogin = (loginType) => {
		if (loginType === 'popup') {
			instance.loginPopup(loginRequest).catch(e => {
				console.log('🙅 ~ ', e);
			});
		} else if (loginType === 'redirect') {
			instance.loginRedirect(loginRequest).catch(e => {
				console.log('🙅 ~ ', e);
			});
		}
	};

	return (
		<>
			{showListItem ? (
				<ListItemButton onClick={() => handleLogin('redirect')} sx={{ borderRadius: '20px', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}>
					<ListItemIcon sx={{ color: 'white' }}><Login /></ListItemIcon>
					<ListItemText primary="Sign in" sx={{ fontWeight: 'bold', marginLeft: '-12px', color: 'whitesmoke' }} />
				</ListItemButton>
			) : (
				<Button
					onClick={() => handleLogin('redirect')}
					sx={{
						display: 'flex',
						alignItems: 'center',
						backgroundColor: '#F5F5F5',
						color: '#787878',
						padding: '30px 70px',
						border: '2px solid #DFE1E5',
					}}
				>
					<img
						src="/Microsoft_logo.png"
						alt="Microsoft Logo"
						style={{ marginRight: '8px', height: '30px', width: '30px' }}
					/>
					<Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Login with Microsoft
					</Typography>
				</Button>
			)}
		</>
	);
};
