/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import {
	Box,
	Button,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	TextField,
	FormHelperText,
	CircularProgress,
	FormLabel,
	Container,
	Grid,
} from '@mui/material';
import '@/styles/fileuploader.scss';
import { useFormik } from 'formik';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';

import { validationSchema } from './validationSchema';
import {
	useAddDocumentPropertyToDealMutation,
	useAddPoliciesToDealMutation,
	useCreateRenewalMutation,
} from '@/features/deals/dealsApi';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { InstantClientSelect } from '@/components/selects/ClientSelect';
import FileUpload from '@/components/FileUpload';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { PageHeader } from '@/components/layouts/PageHeader';
import { showToast } from '@/features/toast/toastSlice';
import { reset } from '@/features/select/clientSelectSlice';
import { DealPolicySelect } from '@/components/selects/DealPolicySelect';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { OwnerSelect } from '@/components/selects/OwnerSelect';

const CreateRenewalForm = () => {
	const dispatch = useDispatch();
	const account = useSelector((state) => state.msalAccount.account);

	const email = account?.username;
	const {
		data: userDetails,
		isLoading,
		isUninitialized,
		isError,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userDetails?.hubspotId;

	const [formLoading, setFormLoading] = useState(false);

	const [createRenewal] = useCreateRenewalMutation();

	const [addPoliciesToDeal] = useAddPoliciesToDealMutation();
	const [addDocumentPropertyToDeal] = useAddDocumentPropertyToDealMutation();

	const createNewRenewal = async () => {
		const body = {
			hubspotId: formik.values.assignOwnerId ?? hubspotId,
			completedBy: hubspotId,
			dealName: formik.values.dealName,
			notes: formik.values.notes,
			dealId: formik.values.deal.id,
			clientId: formik.values.clientId ?? null,
			insuredName: formik.values.clientName,
		};

		return await createRenewal(body)
			.unwrap()
			.then(async (res) => {
				console.log('🚀 ~ Created user', res);
				const { documentId } = await setUpDealFilesAndFolders(
					res.deal_id,
					res.note_id,
					formik.values.clientName,
					formik.values.dealName,
					process.env.REACT_APP_PIPELINE_RENEWALS,
					formik.values.files
				);

				console.log('here is the doc Id:', documentId);
				const policies = formik.values.deal.policies;
				const policyData = policies.map((policy) => ({ policyId: policy.Id }));
				const response = await addPoliciesToDeal({
					dealId: res.deal_id,
					policies: policyData,
				});
				const responseDoc = await addDocumentPropertyToDeal({
					dealId: res.deal_id,
					docId: documentId,
					property: 'referenceOf',
					value: formik.values.deal.id,
				});
				console.log('added policies: ', response);
				console.log('added reference of: ', responseDoc);
				dispatch(
					showToast({
						message: 'New renewal created!',
						action: {
							path: `/renewals/${res.deal_id}`,
							label: 'GO TO RENEWAL',
						},
						autohide: false,
					})
				);
			})
			.catch((err) => {
				console.log('🙅 ~ Error creating user', err);
				return Promise.reject(err);
			});
	};

	const formik = useFormik({
		initialValues: {
			files: [],
			deal: null,
			notes: '',
			clientName: '',
			clientOwner: null,
			successMessage: '',
			errorMessage: '',
			dealName: '',
			clientId: null,
			assignOwnerId: hubspotId,
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			setFormLoading(true);
			console.log('📋 ~ Formik values', values);
			await createNewRenewal()
				.then((res) => {
					resetForm();
					setFieldValue('successMessage', 'Successfully created Renewal');
					setFieldValue('errorMessage', '');
					dispatch(reset());
				})
				.catch((err) => {
					console.log('🙅 ~ Error creating Renewal', err);
					const errorMessage = 'Error creating Renewal';
					setFieldValue('errorMessage', errorMessage);
					setFieldValue('successMessage', '');
				});
			setSubmitting(false);
			setFormLoading(false);
		},
	});

	const { data: ownerData } = useGetOwnerQuery(
		{ ownerId: formik.values.clientOwner },
		{ skip: !formik.values.clientOwner }
	);

	const {
		data: ownerDetails,
		isLoading: isLoadingOwner,
		isUninitialized: isUninitOwner,
	} = useGetUserDetailsQuery({ hubspotId: ownerData?.id }, { skip: !ownerData });

	const { setUpDealFilesAndFolders } = useClientStorageHooks(ownerDetails ?? userDetails);

	const { getError, getErrorMessage } = useFormikHelper(formik);

	const loading = formik.isSubmitting || formLoading;

	const handleCheckboxChange = (deal) => {
		console.log('Here is the selected deal: ', deal, deal.isChecked);

		const checked = formik.values.deal?.id == deal?.id;
		formik.setFieldValue('deal', checked ? null : deal);
		formik.setFieldValue('dealName', checked ? '' : deal.description);
	};

	return (
		<Container>
			<Box sx={{ p: 3 }}>
				<form onSubmit={formik.handleSubmit}>
					<CardHeader disableTypography title={<PageHeader title={'Add Renewal'} />} />
					<CardContent>
						{/* <Typography variant="body1" paragraph>
              View our user guide on the steps to to add a Renewal{' '}
							<Link>here</Link>
						</Typography> */}
						{isLoading || isUninitialized ? (
							<CircularProgress />
						) : (
							<Grid container spacing={4}>
								<Grid item xs={true}>
									<FormLabel required>{'Search client name'}</FormLabel>
									<InstantClientSelect
										clientId={formik.values.clientId}
										disabled={formik.isSubmitting}
										setClient={(client) => {
											console.log('here is the selected client: ', client);
											if (client) {
												formik.setFieldValue('clientId', client.id);
												formik.setFieldValue('clientName', client.name);
												formik.setFieldValue('clientOwner', client.ownerId);
											}
											//When the user removes the selected client
											else {
												formik.setFieldValue('clientId', null);
												formik.setFieldValue('clientName', null);
												formik.setFieldValue('clientOwner', null);
												formik.setFieldValue('deal', null);
												formik.setFieldValue('dealName', '');
											}
										}}
									/>
									<FormHelperText error={getError('clientId')}>
										{getErrorMessage('clientId')}
									</FormHelperText>
								</Grid>
								<Grid item xs={4}>
									<FormLabel>{'Client owner'}</FormLabel>
									<TextField
										fullWidth
										value={
											isLoadingOwner || isUninitOwner
												? ''
												: formik.values.clientId && ownerData?.firstName
													? `${ownerData.firstName} ${ownerData.lastName}`
													: formik.values.clientId
														? 'Unknown'
														: ''
										}
										disabled={loading || !formik.values.clientId}
										InputProps={{ readOnly: true }}
									/>
								</Grid>
								<Grid item xs={12} md={12}>
									<FormLabel required sx={{ display: 'flex' }}>
										{'Select Policy'}
									</FormLabel>
									<DealPolicySelect
										dealId={formik.values.deal?.id}
										clientId={formik.values.clientId}
										handleCheckboxChange={handleCheckboxChange}
										disabled={formik.isSubmitting}
									/>
									<FormHelperText error={getError('deal')}>
										{getErrorMessage('deal')}
									</FormHelperText>
								</Grid>
								<Grid item xs={12}>
									<FormLabel required>{'Renewal name'}</FormLabel>
									<TextField
										fullWidth
										{...formik.getFieldProps('dealName')}
										error={getError('dealName')}
										helperText={getErrorMessage('dealName')}
										disabled={loading}
										required
										name='dealName'
									/>
								</Grid>
								<Grid item xs={12}>
									<FormLabel>{'Assign Broker'}</FormLabel>
									<OwnerSelect
										initialId={formik.values.assignOwnerId}
										disabled={formik.isSubmitting}
										onChange={(value) => {
											formik.setFieldValue('assignOwnerId', value);
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12}>
									<FormLabel>{'Notes'}</FormLabel>
									<TextField
										fullWidth
										id='notes'
										multiline
										rows={4}
										{...formik.getFieldProps('notes')}
										disabled={loading}
									/>
								</Grid>

								<Grid item xs={12} md={12}>
									<FileUpload
										files={formik.values.files}
										setFiles={(files) =>
											formik.handleChange({
												target: {
													name: 'files',
													value: files,
												},
											})
										}
										loading={loading}
									/>
								</Grid>
								{formik.values.errorMessage.length > 0 && (
									<FormHelperText sx={{ padding: '1em' }} error>
										{formik.values.errorMessage}
									</FormHelperText>
								)}
								{formik.values.successMessage.length > 0 && (
									<FormHelperText sx={{ color: 'green', padding: '1em' }}>
										{formik.values.successMessage}
									</FormHelperText>
								)}
							</Grid>
						)}
					</CardContent>
					<Divider />
					<CardActions
						sx={{
							justifyContent: 'center',
							p: 2,
						}}
					>
						<Button
							color='primary'
							type='submit'
							variant='contained'
							disabled={isLoading || isUninitialized || formLoading}
						>
							{formik.isSubmitting ? (
								<CircularProgress size='2em' sx={{ color: '#ffffff' }} />
							) : (
								'Submit'
							)}
						</Button>
					</CardActions>
				</form>
			</Box>
		</Container>
	);
};

export default CreateRenewalForm;
