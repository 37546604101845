import React from 'react';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import { DealContextProvider } from '@/context/dealContext';
import DealViewWrapper from '@/components/views/DealView/DealViewWrapper';
import { Box } from '@mui/material';

const ViewDealPage = () => {
	return (
		<AuthenticatedPageLayout>
			<Box pb={3}>
				<DealContextProvider>
					<DealViewWrapper />
				</DealContextProvider>
			</Box>
		</AuthenticatedPageLayout>
	);
};

export default ViewDealPage;
