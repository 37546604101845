
import React from 'react';
import { InvoicesTableView } from '@/components/views/InvoicesTableView';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';

export const InvoicesPage = () => {
	return (
		<AuthenticatedPageLayout>
			<InvoicesTableView />
		</AuthenticatedPageLayout>
	);
};