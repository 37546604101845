/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import {
	Link,
	Typography,
	Box,
	Skeleton,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { determineContext } from '@/hooks/determineContext';
import { theme } from '@/app/theme';

export const AssociatedClient = ({hideHeader = false }) => {
	const { client, loading, contact } = determineContext();
	const isLoading = loading?.client || loading?.contact;
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;


	return <Box>
		{!hideHeader && (
			<Typography sx={minimalLabelTheme}>
        Associated Client
			</Typography> )}
		{isLoading 
			? 
			<Skeleton width='100%' /> 
			: 
			(() => {
				const data = Object.keys(client || {}).length > 0 ? client : (Object.keys(contact || {}).length > 0 ? contact : null);

				if (data) {
					return (
						data?.name ? (
							<Link
								to={`/clients/${data.hs_object_id}`}
								component={RouterLink}
								sx={{ textDecorationLine: 'none', paddingTop: '3px' }}
							>
								{data.name ?? data.company}
							</Link>
						) : (
							<Typography variant="body1">{data.name ?? data.company}</Typography>
						)
					);
				} else {
					return <Typography variant="body1">No client found</Typography>;
				}

			})()
		}

	</Box>;
};