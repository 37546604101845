/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import {
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { formatDate } from '@/utils/constants';
import { ClientNameAndAvatar } from '@/components/table/ClientNameAndAvatar';
import { firstTwoCapsOrNumbers } from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';

export const DealRow = ({ hit: deal }) => {
	const navigate = useNavigate();
  
	const pipelineQuery = useGetPipelinePropertiesQuery(deal.pipeline, {
		skip: !deal.pipeline,
	});
	const stages = pipelineQuery?.data?.stages ?? {};
	
	const ownerQueryParams = { ownerId: deal.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});
	

	const stage = useMemo(() => {
		return deal.dealStage in stages 
			? stages[deal.dealStage] 
			: { label: deal.dealStage, order: 99 };
	}, [stages]);

	return (
		<TableRow
			onMouseDown={() => navigate(`${deal.id}`)}
			hover
			key={`table-row-${deal.id}`}
			style={{ cursor: 'pointer' }}
		>
			{/* <TableCell>
        <Checkbox />
    </TableCell> */}
			<TableCell>
				<ClientNameAndAvatar
					title={deal.clientName ?? 'Client not found'}
					subtitle={deal.description ?? deal.dealName}
					initials={firstTwoCapsOrNumbers(deal?.description ?? deal.dealName ?? 'NA')}
					width={300}
				/>
			</TableCell>
			<TableCell>
				<Typography color="text.primary">{deal.contactName ?? 'Unknown'}</Typography>
			</TableCell>
			<TableCell>
				<Typography color="text.primary">{stage?.label ?? ''}</Typography>
			</TableCell>
			<TableCell>
				<Typography color="text.primary">{`${owner?.firstName ?? '-'} ${owner?.lastName ?? ''}`}</Typography>
			</TableCell>
			<TableCell align="right">
				<Typography>{formatDate(deal.createDate)}</Typography>
			</TableCell>
			<TableCell align="right">
				<Typography>{formatDate(deal.renewalDate)}</Typography>
			</TableCell>
			{/* <TableCell align="center">
				{ deal.hs_priority === null ? '-' : <PriorityChip priority={deal.hs_priority}  /> }
			</TableCell> */}
			{/* <TableCell align="center">
			<Button onClick={() => navigate(`${deal.id}`)} variant="table_action" >Open</Button>
		</TableCell> */}
		</TableRow>
	);
};
