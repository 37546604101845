import { Box, Skeleton, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Configure, InstantSearch, useHits } from 'react-instantsearch';
import { useSelector } from 'react-redux';
import { useBatchGetDealListPoliciesQuery } from '@/features/deals/dealsApi';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { dealClient } from '@/utils/typesense';
import { DealSelectCard } from '@/components/selects/DealSelect/DealSelectCard';

const PolicySelectBody = ({
	dealId,
	ItemComponent,
	EmptyComponent,
	handleCheckboxChange,
	disabled,
	...props
}) => {
	const { hits } = useHits(props);

	const dealIds = hits.map((hit) => hit?.dealId);
	const policiesQuery = useBatchGetDealListPoliciesQuery(
		{ dealIds },
		{ skip: dealIds.length == 0 }
	);

	const updatedHits = useMemo(() => {
		const data = policiesQuery.data ?? [];
		console.log('DATA', data);
		return hits.map((hit) => ({
			...hit,
			id: hit.dealId ?? hit.hubspotId ?? hit.id,
			docId: hit.id,
			policies: (data[hit.dealId] ?? []).map((r) => r.policy),
		}));
	}, [hits, policiesQuery.data]);

	return (
		<Box>
			{hits.length > 0 ? (
				updatedHits.map((hit) => (
					<ItemComponent
						key={hit.id}
						deal={hit}
						selected={dealId == hit.id}
						handleCheckboxChange={handleCheckboxChange}
						disabled={disabled}
						loading={policiesQuery.isLoading}
					/>
				))
			) : (
				<EmptyComponent />
			)}
		</Box>
	);
};

export const DealPolicySelect = ({ clientId, dealId, handleCheckboxChange, disabled }) => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const brokerGroupId = userDetails?.brokerId;
	const brokerGroupIds = userDetails?.brokerGroupArray ?? [];

	const pipelineQuery = useGetPipelinePropertiesQuery();
	const inTerm = useMemo(() => {
		const data = pipelineQuery.data?.pipelines?.results?.filter(
			(p) => p.id != process.env.REACT_APP_PIPELINE_CLAIMS
		);
		if (!data) {
			return [];
		}
		const pipelineStages = data.reduce((obj, item) => [...obj, ...item.stages], []);
		return pipelineStages
			.filter(
				(stage) =>
					stage.metadata?.probability?.toString() == '1.0' ||
					stage.label.includes('in term')
			)
			.map((s) => s.id);
	}, [pipelineQuery.data]);

	const filterSchema = useMemo(() => {
		const stageIds = inTerm.join(',');
		const ownerFilter = `brokerGroupId: [${brokerGroupIds.join(',')}]`;
		// const ownerFilter = `brokerGroupId := ${brokerGroupId}`;
		// return {
		// 	filters: `clientHubspotId := ${clientId}`
		// };
		const schema = {
			filters: `dealStage: [${stageIds}] && ${ownerFilter} && clientHubspotId := ${clientId}`,
		};
		console.log('Filter schema', schema);
		return schema;
	}, [clientId, inTerm, brokerGroupIds]);

	return (
		<InstantSearch searchClient={dealClient} indexName='deals'>
			<Configure {...filterSchema} />
			{pipelineQuery.isFetching ? (
				<Skeleton variant='text' animation='wave' />
			) : (
				<PolicySelectBody
					dealId={dealId}
					disabled={disabled}
					handleCheckboxChange={handleCheckboxChange}
					ItemComponent={DealSelectCard}
					EmptyComponent={() =>
						clientId != null ? (
							<Typography variant='body1' mt={1}>
								No policies found for the selected client.
							</Typography>
						) : (
							<Typography variant='body1' mt={1}>
								Please select a client to view policies.
							</Typography>
						)
					}
				/>
			)}
			{/* <>
				{(allInTermDeals ?? []).length > 0 ? (
					allInTermDeals.map((deal) => (
						<DealSelectCard
							handleCheckboxChange={handleCheckboxChange}
							key={`deal-select-card-${deal.id}`}
							disabled={disabled}
							deal={deal}
						/>
					))
				) : clientId != null ? (
					<Typography variant="body1" mt={1}>
                No policies found for the selected client.
					</Typography>
				) : <Typography variant="body1" mt={1}>
                Please select a client to view policies.
				</Typography>
				}
			</> */}
		</InstantSearch>
	);
};
