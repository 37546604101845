import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setKanbanView } from '@/features/groupView/groupViewSlice';
import { folioBlue } from '@/utils/constants';

export const KanbanToggle = () => {
	const dispatch = useDispatch();
	
	const { kanbanView } = useSelector((state) => state.userPrefs);
	
	const handleChange = (event, newView) => {
		if (newView !== null) {
			dispatch(setKanbanView(newView));
		}
	};

	return (
		<ToggleButtonGroup
			color="primary"
			value={kanbanView}
			size="small"
			exclusive
			onChange={handleChange}
			sx={{
				'& .MuiToggleButtonGroup-grouped': {
					border: 'none',
					margin: '4px',
					padding: '4px 12px',
					'&:not(:first-of-type)': {
						borderRadius: '5px',
					},
					'&:first-of-type': {
						borderRadius: '5px',
					},
					// Styles for unselected buttons
					color: 'rgba(189, 189, 189, 1)',
					backgroundColor: 'transparent',
					'&.Mui-selected': {
						// color: 'rgba(80, 90, 252, 1)',
						color: '#ffffff',
						backgroundColor: folioBlue,//'rgba(80, 90, 252, 0.33)',
						'&:hover': {
							backgroundColor: 'rgba(80, 90, 252, 0.5)',
						},
					},
					'&:hover': {
						backgroundColor: 'rgba(80, 90, 252, 0.33)',
						color: '#ffffff',
					},
				},
			}}
		>
			<ToggleButton value={false}>View as list</ToggleButton>
			<ToggleButton value={true}>View as Pipeline</ToggleButton>
		</ToggleButtonGroup>
	);
};