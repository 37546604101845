import React, { useContext, useMemo, useState } from 'react';
import {
	TableCell,
	TableRow,
	Typography,
	Stack,
	IconButton,
	Menu,
	MenuItem,
	Link,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BlinkingCircle } from '../icons/BlinkingCircle';
import {
	closedPipelineStages,
	folioBlue,
	formatDateString,
	getUrlFromPipeline,
} from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { ClaimsIcon } from '../icons/ClaimsIcon';
import { paths } from '@/app/routes';
import ClientContext from '@/context/clientContext';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { RefreshIcon } from '@/components/icons/RefreshIcon';
import { formatCurrency } from '@/utils/constants';

export const PolicyTransactionRow = ({ transaction, deal, policy }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const { client, contact } = useContext(ClientContext);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();
	const pipeline = deal?.properties?.pipeline ?? deal?.properties?.hs_pipeline;

	const handleClick = (event) => setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline, {
		skip: !pipeline,
	});
	const stages = pipelineQuery?.data?.stages ?? {};

	const handleClaim = () => {
		navigate(paths.createClaim, { state: { client, policy, transaction } });
		handleClose();
	};

	const handleUpdate = () => {
		navigate(paths.createEndorsement, {
			state: { client, policy, transaction },
		});
		handleClose();
	};

	const dealLink = useMemo(() => {
		const dealId = deal?.id ?? deal?.properties?.hs_object_id;
		const path = getUrlFromPipeline(pipeline);
		return `${path}/${dealId}`;
	}, [deal, pipeline]);

	const getMenuLink = () => {
		return (
			<>
				<IconButton aria-label='edit' onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
					<MenuItem>
						<Link
							to={dealLink}
							component={RouterLink}
							sx={{ textDecoration: 'none', color: null }}
						>
							<Stack direction='row' alignItems='center' spacing={1}>
								<ArticleIcon sx={{ fontSize: '1.2rem', color: folioBlue }} />
								<Typography>View record keeping</Typography>
							</Stack>
						</Link>
					</MenuItem>
					{transaction != null && (
						<MenuItem onClick={handleClaim}>
							<Stack direction='row' alignItems='center' spacing={1}>
								<ClaimsIcon sx={{ fontSize: '1.2rem', color: folioBlue }} />
								<Typography sx={{ color: folioBlue }}>Claim</Typography>
							</Stack>
						</MenuItem>
					)}
					{transaction != null && (
						<MenuItem onClick={handleUpdate}>
							<Stack direction='row' alignItems='center' spacing={1}>
								<RefreshIcon sx={{ fontSize: '1.2rem', color: folioBlue }} />
								<Typography sx={{ color: folioBlue }}>Endorse</Typography>
							</Stack>
						</MenuItem>
					)}
					{transaction != null && (
						<MenuItem>
							<Typography
								color='gray'
								fontSize='12px'
							>{`Transaction ID: ${transaction?.Id}`}</Typography>
						</MenuItem>
					)}
					{policy != null && (
						<MenuItem>
							<Typography
								color='gray'
								fontSize='12px'
							>{`Policy ID: ${policy?.Id}`}</Typography>
						</MenuItem>
					)}
				</Menu>
			</>
		);
	};

	return (
		<TableRow key={`table-row-${deal?.Id}`}>
			<TableCell>
				<Stack direction='column' spacing={0.5} width='100%'>
					<Typography variant='subtitle2'>
						{policy?.Description ??
							deal?.properties?.dealname ??
							deal.properties?.subject ??
							'-'}
					</Typography>
					<Typography>
						{transaction?.PolicyNumber ??
							deal?.properties?.description ??
							deal?.properties.content ??
							'-'}
					</Typography>
				</Stack>
			</TableCell>
			<TableCell width={'20%'} sx={{ whiteSpace: 'nowrap' }}>
				<Stack direction='column' spacing={1} width='100%'>
					<Typography variant='subtitle2'>
						{transaction?.FromDate ? formatDateString(transaction?.FromDate) : '-'}
					</Typography>
					<Typography variant='subtitle2'>
						{transaction?.ToDate ? formatDateString(transaction?.ToDate) : '-'}
					</Typography>
				</Stack>
			</TableCell>
			<TableCell>
				<Stack direction='column' spacing={1} width='100%'>
					<Typography variant='subtitle2'>
						{transaction?.TransactionType ??
							pipelineQuery?.data?.pipelines?.label ??
							'-'}
					</Typography>
					<Typography
						variant={transaction ? 'subtitle2' : 'body1'}
						{...(!transaction && { fontSize: '12px', color: 'gray' })}
					>
						{transaction
							? formatCurrency(transaction?.AnnualTotalGrossPremium ?? 0)
							: 'No transaction linked'}
					</Typography>
				</Stack>
			</TableCell>
			<TableCell>
				<Stack direction='row' alignItems='center'>
					<BlinkingCircle fontSize='6px' color={transaction ? '#00C650' : '#1ABCFE'} />
					<Typography style={{ paddingLeft: '5px' }}>
						{transaction ? 'In term' : 'In progress'}
					</Typography>
				</Stack>
			</TableCell>
			<TableCell>{getMenuLink()}</TableCell>
		</TableRow>
	);
};
