import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { specialChars } from '@/utils/constants';

// Custom hook to interact with Sharepoint
export const useSharepoint = (sharepointSite, clientSiteSuffix, clientFolder, clientSite) => {
	// Using MSAL hook to get auth instance and accounts
	const { instance, accounts } = useMsal();

	const getFormDigestValueFromResponse = async (response) => {
		const headers = {
			'Accept': 'application/json;odata=verbose',
			'Content-Type': 'application/json;odata=verbose',
			Authorization: `Bearer ${response.accessToken}`
		};
		const accessToken = response.accessToken;
		return axios.post(`${clientSite}/_api/contextinfo`, null, {
			headers,
		}).then(res => {
			// Extracting form digest value from response
			const formDigestValue = res.data.d.GetContextWebInformation.FormDigestValue;
			return { headers, formDigestValue, accessToken };
		}).catch(err => {
			console.log('🙅 ~ Error getting form digest value', err);
			return Promise.reject(err);
		});
	};

	// Function to get form digest value for Sharepoint authentication
	const getFormDigestValue = async () => {
		const request = {
			scopes: [`${sharepointSite}/.default`],
			account: accounts[0]
		};
		return await instance.acquireTokenSilent(request)
			.then(async r => getFormDigestValueFromResponse(r))
			.catch(async error => {
				console.error('🙅 ~ Error retrieving access token', error);
				return instance.acquireTokenRedirect(request)
					.then(async r => getFormDigestValueFromResponse(r))
					.catch(e => {
						console.log('🙅 ~ Token redirect error', e);
						return Promise.reject(e);
					});
			});
	};

	// Function to create a new folder in Sharepoint
	const createFolder = async (path) => {
		const { headers, formDigestValue } = await getFormDigestValue();

		console.log('Trying to create Sharepoint folder ...', path);
		return await axios.post(`${clientSite}/_api/web/folders`, {
			'__metadata': {
				'type': 'SP.Folder'
			},
			'ServerRelativeUrl': `${clientSiteSuffix}/${clientFolder}/${path}`
		}, {
			headers: {
				...headers,
				'X-RequestDigest': formDigestValue,
			}
		}).then(r => {
			console.log(`🚀 ~ ${clientSiteSuffix}/${clientFolder}/${path} folder created successfully`, r);
			return `${sharepointSite}${r.data.d.ServerRelativeUrl}`;
		}).catch((err) => {
			console.log(`🙅 ~ Error creating folder ${clientSiteSuffix}/${clientFolder}/${path}`, err);
			return Promise.reject(err);
		});
	};

	const getFolderContents = async (folderPath, retrieveFiles, authProps) => {
		let { headers, formDigestValue } = authProps;
		if (!authProps || !authProps.headers || !authProps.formDigestValue) {
			const res = await getFormDigestValue();
			headers = res.headers;
			formDigestValue = res.formDigestValue;
		}
		const retrievalType = retrieveFiles ? 'Files' : 'Folders';
		const encodedPath = `${clientSite}/_api/web/GetFolderByServerRelativeUrl('${clientFolder}/${folderPath}')/${retrievalType}`;
		// console.log('ENCODED PATH', encodedPath);
		return axios.get(encodedPath, {
			headers: {
				...headers,
				'X-RequestDigest': formDigestValue,
			}
		});
	};

	const getProperties = async (authorUri, authProps) => {
		try {
			const response = await axios.get(authorUri, {
				headers: {
					...authProps.headers,
					'X-RequestDigest': authProps.formDigestValue,
				}
			});
			return response.data.d;
		} catch (error) {
			console.log('🙅 ~ Error retrieving properties', error);
			return {};
		}
	};

	const getSharepointAttachmentsRecursive = async (folder, filterTypes = [], metaDataFilters = {}) => {
		const { headers, formDigestValue } = await getFormDigestValue();

		const processFolder = async (currentFolderPath) => {
			const filesResponse = await getFolderContents(currentFolderPath, true, { headers, formDigestValue });
			const files = filesResponse.data.d;

			const filesWithAuthorProperties = await Promise.all(files.results.map(async (file) => {
				const lastUpdatedTimestamp = Date.parse(file.TimeLastModified);
				try {
					const authorUri = file.Author.__deferred.uri;
					const authorProperties = await getProperties(authorUri, { headers, formDigestValue });
					return {
						...file,
						lastUpdated: lastUpdatedTimestamp,
						Author: {
							...file.Author,
							authorProperties
						},
					};
				} catch (error) {
					console.log('🙅 ~ Error getting author properties', error);
					return {
						...file,
						lastUpdated: lastUpdatedTimestamp,
					};
				}
			}));

			const subfoldersResponse = await getFolderContents(currentFolderPath, false, { headers, formDigestValue });
			const subfolders = subfoldersResponse.data.d;

			const filesInSubfolders = await Promise.all(subfolders.results.map(async (subfolder) => {
				const subfolderPath = `${currentFolderPath}/${subfolder.Name}`;

				if (subfolder.Name.toLowerCase() === 'notes' || subfolder.Name.toLowerCase() === 'client generated notes') {
					return [];
				}

				const filesInSubfolder = await processFolder(subfolderPath);
				const filePath = decodeURIComponent(subfolderPath);

				return filesInSubfolder.map(file => ({
					...file,
					Directory: filePath,
				}));
			}));
			let allFiles = filesInSubfolders.flat().concat(filesWithAuthorProperties.map(file => ({
				...file,
				Directory: decodeURIComponent(currentFolderPath),
			})));
			allFiles = await Promise.all(allFiles.map(async file => {
				try {
					// const adjustedServerRelativeUrl = adjustSharepointUrl(file.ServerRelativeUrl);
					const fileMetadataUrl = `${file.__metadata.uri}'/ListItemAllFields/FieldValuesAsText`;
					// const fileMetadataUrl = `${clientSite}/_api/web/GetFileByServerRelativeUrl('${adjustedServerRelativeUrl}')/ListItemAllFields/FieldValuesAsText`;
					const fileMetadataResponse = await axios.get(fileMetadataUrl, { headers });
					// console.log('File metadata response', fileMetadataResponse);
					const fileMetadata = fileMetadataResponse.data.d;

					const propertiesUrl = `${file.Properties.__deferred.uri}'/ListItemAllFields/Properties`;
					const propertiesResponse = await axios.get(propertiesUrl, { headers });
					const description = propertiesResponse?.data?.d?.OData__x005f_ExtendedDescription ?? '';
					// console.log('Desc', description);
					// console.log('Properties', propertiesResponse);
					return {...file, fileMetadata, Description: description };
				} catch (error) {
					console.log('🙅 ~ Error getting file metadata', error);
					return file;
				}
			}));
			
			// Filter files by extension if filterTypes is provided
			if (filterTypes.length > 0) {
				allFiles = allFiles.filter(file => {
					const extension = `.${file.Name.split('.').pop()}`.toLowerCase();
					return filterTypes.includes(extension);
				});
			}

			if (Object.keys(metaDataFilters).length > 0) {
				allFiles = allFiles.filter(file => {
					return Object.entries(metaDataFilters).every(([key, value]) => {
						return file.fileMetadata[key] == value;
					});
				});
			}

			return allFiles ?? [];
		};
		try {
			return await processFolder(`${folder}`);
		} catch (err) {
			console.error(`🙅 ~ Error getting client ${folder} folder`, err);
			return [];
		}
	};

	const createUniqueFileName = (originalName) => {
		const timestamp = new Date().getTime();
		const [name, extension] = originalName.split('.');
		return `${name}_${timestamp}.${extension}`;
	};

	const addFileToSharepoint = async (folderPath, file) => {
		const { accessToken, headers, formDigestValue } = await getFormDigestValue();
		// console.log('here is the file name: ', file.name);
		// console.log('here is the file content: ', file.content);
		var fileContent;

		if (file?.content instanceof Blob) {
			// console.log('file is of type blob');
			fileContent = file.content;
		} else {
			// console.log('file is not a blob');
			fileContent = await file.arrayBuffer();
		}

		try {
			const filesResponse = await getFolderContents(folderPath, true, { headers, formDigestValue });
			const files = filesResponse.data.d;
            
			let fileName = file.name.replace(specialChars, '');
			const results = files?.results ?? [];

			// console.log('some', results.some((f) => f.Name.normalize() == fileName.normalize()));
			if (results.some((f) => f.Name.normalize() == fileName.normalize())) {
				fileName = createUniqueFileName(fileName);
				// console.log('New file name', fileName);
			}
			const escapeAndEncode = (str) => encodeURIComponent(str.replace(/'/g, '\\\''));
			// const sharePointApiUrl = `${clientSite}/_api/web/GetFolderByServerRelativeUrl('${clientFolder}/${folderPath}')/Files/add(url='${file.name}',overwrite=true)`;
			const sharePointApiUrl = `${clientSite}/_api/web/GetFolderByServerRelativeUrl('${clientFolder}/${folderPath}')/Files/add(url='${escapeAndEncode(fileName)}',overwrite=true)`;
			// console.log('here is the sharepoint Url: ', sharePointApiUrl);

			try {
				const response = await axios.post(sharePointApiUrl, fileContent, { headers });
				console.log(`🚀 ~ File added successfully: ${file} `, response.data);
				return response.data;
			} catch (error) {
				console.error(`🙅 ~ Error creating file: ${file} in ${folderPath}`, error);
			}

		} catch (error) {
			console.error('🙅 ~ Error adding to SharePoint:', error);
		}
	};

	// const createCustomFieldInLibrary = async (fieldName, fieldType) => {
	// 	const { headers, formDigestValue } = await getFormDigestValue();

	// 	const apiUrl = `${clientSite}/_api/web/lists/GetByTitle('${decodeURIComponent(clientFolder)}')/fields`;

	// 	try {
	// 		const response = await axios.post(apiUrl, JSON.stringify({
	// 			'__metadata': { 'type': 'SP.Field' },
	// 			'Title': fieldName,
	// 			'FieldTypeKind': fieldType, // FieldTypeKind should be an integer corresponding to the field type
	// 			'StaticName': fieldName
	// 		}), {
	// 			headers: {
	// 				...headers,
	// 				'X-RequestDigest': formDigestValue
	// 			}
	// 		});

	// 		console.log("Custom field created successfully", response.data);
	// 		return response.data;
	// 	} catch (error) {
	// 		console.log('error', error);
	// 		console.error("Error creating custom field in library:", error.response.data.error.message.value);
	// 		return null;
	// 	}
	// };

	const getListsAndLibraries = async () => {
		const { headers, formDigestValue } = await getFormDigestValue();
		// Assuming getFormDigestValue correctly sets up headers and provides a valid formDigestValue

		const apiUrl = `${clientSite}/_api/web/lists?$filter=BaseTemplate eq 101 and Title eq '${clientFolder}'`; // 101 is the code for document libraries

		try {
			const response = await axios.get(apiUrl, {
				headers: {
					...headers,
					'X-RequestDigest': formDigestValue
				}
			});
			console.log('Document Libraries:', response.data.d.results);
			return response.data.d.results; // This will be an array of document libraries
		} catch (error) {
			console.error('Error fetching document libraries:', error);
			return []; // Return an empty array or handle the error as needed
		}
	};


	const updateFileMetadata = async (serverRelativeUrl, metadata) => {
		const { headers, formDigestValue } = await getFormDigestValue();

		// Retrieve the list item associated with the file
		const fileListItemUrl = `${clientSite}/_api/web/GetFileByServerRelativeUrl('${encodeURIComponent(serverRelativeUrl)}')/ListItemAllFields`;
		const response = await axios.get(fileListItemUrl, { headers });
		const listItemEntityTypeFullName = response.data.d.__metadata.type;

		// Construct the metadata update payload
		const metadataUpdatePayload = JSON.stringify({
			'__metadata': { 'type': listItemEntityTypeFullName },
			...metadata
		});

		console.log('Metadata payload: ', metadataUpdatePayload);

		// Update the list item with new metadata
		const updateResponse = await axios.post(fileListItemUrl, metadataUpdatePayload, {
			headers: {
				...headers,
				'X-RequestDigest': formDigestValue,
				'X-HTTP-Method': 'MERGE',
				'IF-MATCH': '*'
			}
		});

		return updateResponse.data;
	};


	const updateFolderName = async (dealFolderPath, clientFolderPath, replacedNewName) => {
		const { headers, formDigestValue } = await getFormDigestValue();

		// Construct SharePoint folder URL based on dealFolderPath and replacedExistingName
		const folderUrl = `${clientSite}/_api/web/GetFolderByServerRelativeUrl('${encodeURIComponent(clientFolderPath)}/${encodeURIComponent(dealFolderPath)}')/ListItemAllFields`;

		axios.get(
			folderUrl,
			{
				headers: {
					...headers,
					Accept: 'application/json;odata=verbose',
				},
			}
		).then((response) => {
			console.log('Successfully retrieved folder name', response);
			axios.post(
				folderUrl,
				{
					Title: replacedNewName,
					FileLeafRef: replacedNewName,
				},
				{
					headers: {
						...headers,
						Accept: 'application/json;odata=verbose',
						'X-RequestDigest': formDigestValue,
						'X-HTTP-Method': 'MERGE',
						'Content-Type': 'application/json',
						'If-Match': '*',
					},
				}
			).then((response) => {
				console.log('Successfully changed folder name', response);
			}).catch((error) => {
				console.error('Error updating folder name', error);
			});
		}).catch((error) => {
			console.error('Error retrieving folder name', error);
		});
	};
	

	// Returning the functions to be used elsewhere in the app
	return { createFolder, updateFileMetadata, getSharepointAttachmentsRecursive, addFileToSharepoint, getFormDigestValue, updateFolderName };
};