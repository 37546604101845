import { Button, Card, Grid, Tab, Tabs } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Add } from '@mui/icons-material';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { getCreateUrlFromPipeline } from '@/utils/constants';
import DealTable from '@/components/tables/DealTable';
import { useNavigate } from 'react-router-dom';

export const DealTableView = ({ pipelineId }) => {
	const navigate = useNavigate();

	const [currentTab, setCurrentTab] = useState('active');
    
	// Fetching pipeline properties using a custom hook
	const { 
		data: pipelineData
	} = useGetPipelinePropertiesQuery(pipelineId, {skip: !pipelineId});
	const pipelineName = pipelineData?.pipelines?.label;

	const isClaims = pipelineId == process.env.REACT_APP_PIPELINE_CLAIMS;
	const isEndorsement = pipelineId == process.env.REACT_APP_PIPELINE_ENDORSEMENTS;

	const createPipelineName = useMemo(() => {
		if (pipelineId == process.env.REACT_APP_PIPELINE_NEW_BUSINESS) {
			return pipelineName;
		} else {
			return `New ${pipelineName.substring(0, pipelineName.length - 1)}`;
		}
	}, [pipelineId, pipelineName]);

	const handleNavigation = () => {
		const createUrl = getCreateUrlFromPipeline(pipelineId);
		if (createUrl) {
			navigate(createUrl);
		}
	};

	const tabs = [
		{
			label: `Active ${pipelineName}`,
			value: 'active',
		},
		{
			label: isClaims ? 'Closed' : 'In Term',
			value: 'closed',
		}
	];

	const handleTabChange = (event, value) => {
		event.preventDefault();
		setCurrentTab(value);
	};

	return <Card>
		<Grid container>
			<Grid item xs={true}>
				<Tabs
					indicatorColor="primary"
					scrollButtons="auto"
					textColor="primary"
					value={currentTab}
					sx={{ px: 2 }}
					variant="scrollable"
					onChange={handleTabChange}
				>
					{tabs.map((tab) => (
						<Tab
							sx={{ fontWeight: 'bold' }}
							key={tab.value}
							label={tab.label}
							value={tab.value}
						/>
					))}
				</Tabs>
			</Grid>
			<Grid item xs={3} container justifyContent={'flex-end'} mr={2} py={1}>
				<Button
					component="label"
					variant="contained"
					startIcon={<Add />}
					onClick={handleNavigation}
				>
					{createPipelineName}
				</Button>
			</Grid>
		</Grid>
		<DealTable 
			pipeline={pipelineId} 
			tab={currentTab} 
		/>
		{/* {isClaims || (IS_DEV && isEndorsement) ? */}
		{/* {isClaims ? 
			<ClaimsTable 
				isEndorsement={isEndorsement} 
				pipeline={pipelineId} 
				tab={currentTab} 
			/> 
			:
			<DealTable 
				pipeline={pipelineId} 
				tab={currentTab} 
			/>} */}
	</Card>;
}