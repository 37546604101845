import React, { useMemo } from 'react';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import { DealContextProvider } from '@/context/dealContext';
import { Box, Typography } from '@mui/material';
import CreateSOAForm from '@/components/forms/CreateSOAForm';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { features } from '@/utils/features';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSelector } from 'react-redux';

const DealSOAFormPage = () => {
    const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userData = useGetUserDetailsQuery({ email }, { skip: !email });

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'statementOfAdvice' }, { skip: !userData.data });
	const showSOA = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	return (
		<AuthenticatedPageLayout>
			<Box padding={3}>
				{showSOA ? (
					<DealContextProvider>
						<CreateSOAForm />
					</DealContextProvider>
				) : (
					<Typography variant='h4'>
						{'Statement of Advice generator coming soon.'}
					</Typography>
				)}
			</Box>
		</AuthenticatedPageLayout>
	);
};

export default DealSOAFormPage;
