import React from 'react';
import {
	Box,
	Button,
	Skeleton,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { determineContext } from '@/hooks/determineContext';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/app/routes';

export const AddNewBusinessButton = () => {
	const {client, contact, loading} = determineContext();
	const navigate = useNavigate();

	return (
		<Box >
			{loading.client || loading.contact ? (
				<Skeleton
					variant='text'
					animation='wave'
					sx={{ width: '10em', height: '3em' }}
				/>
			) : (
				<Button 
					component="label" variant="tab_header"
					startIcon={<Add />}
					onClick={() => {
						navigate(paths.createNewBusiness, { state: { client, contact } });
					}}>
            New Business
				</Button>
			)}
		</Box>
	);
};
