import { createSlice } from '@reduxjs/toolkit';

export const dialogSlice = createSlice({
	name: 'dialog',
	initialState: {
		open: {
			note: false,
			proposal: false,
			contact: false,
			task: false,
			summary: false,
			quote: false,
			wytu: false,
			completeDeal: false,
		},
	},
	reducers: {
		openDialog: (state, action) => {
			state.open[action.payload] = true;
		},
		closeDialog: (state, action) => {
            console.log(`${action.payload}`, false);
			state.open[action.payload] = false;
		},
	},
});

export const { 
	openDialog,
	closeDialog,
} = dialogSlice.actions;
export default dialogSlice.reducer;