import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { CircularProgress, useTheme } from '@mui/material';
import { folioBlue } from '@/utils/constants';
import { useNavigate } from 'react-router-dom';

export const DealsDisplayCard = ({ title, loading, total, url }) => {
	const navigate = useNavigate();

	const theme = useTheme();

	return (
		<Card 
			onClick={() => navigate(url)}
			sx={{ 
				borderRadius: '15px',
				height: '12em',
				[theme.breakpoints.down('md')]: {
					height: '13.5em',
				},
				padding: '1.25em',  
				transition: 'background-color 0.3s', 
				'&:hover': { 
					backgroundColor: '#f0f0f0',
					cursor: 'pointer'
				} 
			}}>
			<Grid
				container
				direction="column"
				justifyContent="space-between"
				alignItems="stretch"
				height="100%"
			>
				<Grid item>
					<Typography variant="card_header">{`Active ${title}`}</Typography>
				</Grid>
				<Grid item>
					{loading ? (
						<CircularProgress />
					) : (
						<Typography variant="card_h4">{total}</Typography>
					)}
				</Grid>
				<Grid item sx={{color: folioBlue}}>
						
					{`View all ${title}`}
				</Grid>
			</Grid>
		</Card>
	);
};
