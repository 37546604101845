import { createSlice } from '@reduxjs/toolkit';

export const WIDTHS = [48, 240];

export const sideNavSlice = createSlice({
	name: 'sideNav',
	initialState: {
		open: false,
		width: WIDTHS[1],
		isCollapsed: false,
	},
	reducers: {
		setOpen: (state) => {
			state.open = true;
		},
		setClose: (state) => {
			state.open = false;
		},
		toggleOpen: (state) => {
			state.open = !state.open;
		},
		toggleWidth: (state) => {
			const newWidth = WIDTHS.find(w => w !== state.width);
			state.width = newWidth;
			state.isCollapsed = newWidth === WIDTHS[0];
		}
	},
});

export const { 
	setOpen,
	setClose,
	toggleOpen,
	toggleWidth,
} = sideNavSlice.actions;
export default sideNavSlice.reducer;