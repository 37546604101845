import React, { useMemo } from 'react';
import { Avatar, Box, Button, Card, CardActions, CardContent, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { firstTwoCapsOrNumbers, folioBlue, formatDate } from '@/utils/constants';
import { Draggable } from '@hello-pangea/dnd';
import { formatDistanceToNow, isAfter } from 'date-fns';

const today = new Date();

export const KanbanCard = ({ hit: deal, index }) => {
	const navigate = useNavigate();

	const due = useMemo(() => (
		deal?.earliestTask ? new Date(deal?.earliestTask?.hs_timestamp) : null
	), [deal?.earliestTask]);

	const overdue = useMemo(() => due ? isAfter(today, due) : false, [due]);

	const nextTaskString = useMemo(() => {
		if (!due) {
			return 'No upcoming tasks';
		}
		const dueTime = formatDistanceToNow(due);
		if (overdue) {
			return `Next task was due ${dueTime} ago`;
		} else {
			return `Next task due in ${dueTime}`;
		}
	}, [due]);

	return (
		<Draggable draggableId={`${deal.id}`} index={index}>
			{(provided, snapshot) => (
				<Box 
					mb={1}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
				>
					<Card
						style={{
							opacity: snapshot.isDragging ? 0.9 : 1,
							transform: snapshot.isDragging ? 'rotate(-2deg)' : '',
						}}
						elevation={snapshot.isDragging ? 3 : 1}
					>
						<CardContent>
							<Stack spacing={1}>
								<Typography variant="avatar_title" sx={{ color: folioBlue }}>
									{deal.dealName}
								</Typography>
								<Typography variant="avatar_subtitle">
									{deal.clientName ?? 'Client not found'}
								</Typography>
								{deal.claimNumber && (
									<Tooltip title={deal.claimNumber}>
										<Typography noWrap variant="avatar_subtitle" >
											{deal.claimNumber}
										</Typography>
									</Tooltip>
								)}
                                
								{/* <Typography variant="avatar_subtitle">
									{deal.clientName ?? 'Client not found'}
									{deal.claimNumber && (
										<span>
											<span style={{ padding: '0 0.5em'}}>|</span>
											{deal.claimNumber}
										</span>
									)}
								</Typography> */}
								<Avatar 
									sx={{ 
										width: 30, 
										height: 30, 
										backgroundColor: '#34495E', 
										fontSize: '14px',
									}}
								>
									{firstTwoCapsOrNumbers(deal.dealName ?? 'NA')}
								</Avatar>
								<Typography variant="task_updated" sx={{ ...(overdue && { color: 'red', fontWeight: 600 })}}>
									{nextTaskString}
								</Typography>
							</Stack>
						</CardContent>
						<CardActions sx={{ justifyContent: 'end'}}>
							<Button onClick={() => navigate(`${deal.id}`)}>View</Button>
						</CardActions>
					</Card>
				</Box>)}
		</Draggable>
	);
};