/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { FormCard } from '@/components/cards/FormCard';

export const FormsTab = () => {
	const { forms, loading } = determineContext();
	const isLoading = loading?.forms;


	function sortFormsByName(forms) {
		return forms.slice().sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
	}

	const sortedForms = useMemo(() => sortFormsByName(forms), [forms]);


	return (
		<Box px={'0.5em'}>
			{isLoading ? (
             
				<>
					<Skeleton variant="text" width={'100%'} height={40} />
				</>
			) : sortedForms.length > 0 ? (
            
				sortedForms.map((form, index) => (
					<FormCard key={`forms-tab-form-${index}`} form={form} />
				))
			) : (
				<Typography textAlign={'center'}>No forms are available.</Typography>
			)}
		</Box>
	);
};
