import React, { useState } from 'react';
import {
	TextField,
	FormHelperText,
	FormLabel,
	Grid,
	CircularProgress,
	InputAdornment,
} from '@mui/material';
import { CLIENT_ERROR, CLIENT_NAME, OWNER } from './validationSchema';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { useCheckClientExistsInInsightMutation } from '@/features/clients/clientsApi';

export const ClientNameField = ({ formik, loading }) => {
	const { getError, getErrorMessage } = useFormikHelper(formik);
	const hubspotId = formik.values[OWNER];

	const [errorMessage, setErrorMessage] = useState('');

	const [checkClientExists, { isLoading }] = useCheckClientExistsInInsightMutation();

	return (
		<>
			<FormLabel required>{'Insured Entity'}</FormLabel>
			<TextField
				fullWidth
				{...formik.getFieldProps(CLIENT_NAME)}
				error={getError(CLIENT_NAME) || errorMessage.length > 0}
				helperText={errorMessage.length > 0 ? errorMessage : getErrorMessage(CLIENT_NAME)}
				disabled={loading}
				required
				name={CLIENT_NAME}
				
				InputProps={{
					...(isLoading && {endAdornment: (
						<InputAdornment position='end'>
							<CircularProgress size='1em' />
						</InputAdornment>
					)}),
				}}
				
				onBlur={async () => {
					if (formik.values[CLIENT_NAME].length > 0) {
						const res = await checkClientExists({
							hubspotId,
							companyName: formik.values[CLIENT_NAME],
						}).unwrap();
						formik.setFieldValue(CLIENT_ERROR, res.length > 0);
						setErrorMessage(
							res.length > 0
								? `${formik.values[CLIENT_NAME]} already exists as a client in your brokerage`
								: ''
						);
					}
				}}
			/>
		</>
	);
};
