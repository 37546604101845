// features/select/clientSelectSlice.js
import { generateInitialState, createSelectSlice } from './selectSlice';

const clientSelectInitialState = generateInitialState();
export const clientSelectSlice = createSelectSlice('clientSelect', clientSelectInitialState);

export const { 
	setSearchValue,
	setSearchText,
	incrementResetKey,
	setOpen,
	setRows,
	updateRows,
	reset,
} = clientSelectSlice.actions;

export default clientSelectSlice.reducer;