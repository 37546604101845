/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import {
	Avatar,
	Box,
	Button,
	Card,
	Modal,
	Skeleton,
	Slider,
	Stack,
	Typography,
} from '@mui/material';
import ClientContext from '@/context/clientContext';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { clientsApi, useAddCompanyLogoMutation } from '@/features/clients/clientsApi';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { NavigateInsightButton } from '@/components/buttons/NavigateInsightButton';
import { GoogleMaps } from '@/components/GoogleMapsLocation';

export const AdviserAboutCard = () => {
	const { client, owner, contact, objectType } = useContext(ClientContext);
	const [avatar, setAvatar] = useState(null);
	const [scale, setScale] = useState(1);
	const [rotate, setRotate] = useState(0);
	const [uploadingImage, setUploadingImage] = useState(false);
	const [cropperOpen, setCropperOpen] = useState(false);
	const [croppedAvatar, setCroppedAvatar] = useState(null);
	const dispatch = useDispatch();

	const [addCompanyLogo] = useAddCompanyLogoMutation();

	// console.log('here is the client row: ', clientRow);
	const userData = useGetUserDetailsQuery(
		{ email: owner.email },
		{
			skip: !owner.email,
		}
	);

	useEffect(() => {
		setCroppedAvatar(
			client.companyImg ??
				'https://firebasestorage.googleapis.com/v0/b/folio-test-b4c31.appspot.com/o/Default%20building%20image.jpg?alt=media&token=e6564380-bb61-4c20-87a1-7c64487410a0'
		);
		setAvatar(
			client.companyImg ??
				'https://firebasestorage.googleapis.com/v0/b/folio-test-b4c31.appspot.com/o/Default%20building%20image.jpg?alt=media&token=e6564380-bb61-4c20-87a1-7c64487410a0'
		);
	}, [client.companyImg]);

	const createAdviserEntry = (header, value) => {
		return (
			<Stack>
				<Typography variant='form_label'>{header}</Typography>
				<Typography variant='blue_text'>{value}</Typography>
			</Stack>
		);
	};

	const fullName = contact ? `${contact.firstname} ${contact.lastname}` : 'Unknown';

	const handleAvatarChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setAvatar(reader.result);
				setCropperOpen(true);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleSlider = (event, newValue) => {
		setScale(newValue);
	};

	const handleCancel = () => {
		setCropperOpen(false);
	};

	const handleSave = async () => {
		if (editorRef.current) {
			setUploadingImage(true);
			const canvas = editorRef.current.getImageScaledToCanvas();
			const dataURL = canvas.toDataURL();
			setCroppedAvatar(dataURL);
			setAvatar(dataURL);
			setCropperOpen(false);
			const body = {
				clientId: client.hs_object_id,
				docId: client.docId,
				buffer: dataURL,
				companyName: client.name,
			};
			console.log('here is the body: ', body);
			await addCompanyLogo(body)
				.unwrap()
				.then(
					setTimeout(async () => {
						try {
							const response = dispatch(
								clientsApi.util.invalidateTags([
									{
										type: (objectType ?? '').toUpperCase(),
										id: client.hs_object_id,
									},
									{ type: 'CLIENTS', id: 'LIST' },
								])
							);
							console.log('invalidated client: ', response);
						} catch (error) {
							console.error('Error invalidating clients API:', error);
						}
					}, 10000)
				);
			setUploadingImage(false);
		}
	};
	const editorRef = useRef(null);

	return (
		<Card sx={{ p: 3, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
			<Stack spacing={3}>
				<GoogleMaps />
				{/* <Box display={'flex'} justifyContent='center'>
					<label htmlFor='avatar-upload' style={{ width: 200 }}>
						{croppedAvatar ? (
							<Avatar
								src={croppedAvatar}
								style={{
									width: 200,
									height: 200,
									borderRadius: 100,
									cursor: 'pointer',
								}}
								onClick={() => setCropperOpen(true)}
							/>
						) : (
							<Box sx={{ width: 200, height: 200 }}>
								<Skeleton variant='circular' width={200} height={200} />
							</Box>
						)}
					</label>
					<input
						accept='image/*'
						id='avatar-upload'
						type='file'
						style={{ display: 'none' }}
						onChange={handleAvatarChange}
					/>
				</Box> */}
				<Typography variant='subtitle1'>CLIENT DETAILS</Typography>
				{createAdviserEntry('Company name', client.name)}
				{createAdviserEntry('Company address', client.address ?? '-')}
				{createAdviserEntry('Primary Contact ', fullName ?? '-')}
				{createAdviserEntry('Primary Contact Phone', contact.phone ?? '-')}
				{createAdviserEntry('Primary Contact Email', contact.email ?? '-')}
				<NavigateInsightButton
					ledgerName={client?.broker?.ledger}
					insightId={client?.clientRow?.insightId}
				/>
			</Stack>

			<Modal open={cropperOpen} onClose={handleCancel}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						borderRadius: 2,
					}}
				>

					<label htmlFor='avatar-upload'>
						<AvatarEditor
							ref={editorRef}
							image={avatar}
							width={200}
							height={200}
							color={[255, 255, 255, 0.6]}
							scale={scale}
							border={50}
							rotate={rotate}
							borderRadius={100}
							backgroundColor='#f1f1f1'
						/>
					</label>
					<input
						accept='image/*'
						id='avatar-upload'
						type='file'
						style={{ display: 'none' }}
						onChange={handleAvatarChange}
					/>
					<Slider
						aria-label='Zoom Slider'
						value={scale}
						min={1}
						max={10}
						step={0.1}
						onChange={handleSlider}
					/>
					<Box sx={{ mt: 2 }}>
						<Button
							sx={{ borderRadius: '10px', mr: 2 }}
							variant='contained'
							onClick={handleCancel}
						>
							Cancel
						</Button>
						<LoadingButton
							component='label'
							sx={{ borderRadius: '10px' }}
							loading={uploadingImage}
							onClick={handleSave}
						>
							Save
						</LoadingButton>
					</Box>
				</Box>
			</Modal>
		</Card>
	);
};
