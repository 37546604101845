import React, { useMemo } from 'react';
import {
	Typography,
	FormControlLabel,
	Radio,
	RadioGroup,
	Grid,
	FormGroup,
} from '@mui/material';
import {
	CLIENT_CATEGORY,
	CLIENT_CATEGORY_LABELS,
	CLIENT_TYPE,
	DOMESTIC,
	COMMERCIAL,
} from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { determineContext } from '@/hooks/determineContext';
import { useAddDocumentPropertyToDealMutation } from '@/features/deals/dealsApi';

export const ClientType = () => {
	const { dealRow } = determineContext();
	const clientType = useMemo(() => dealRow[CLIENT_TYPE] ?? '', [dealRow]);

	const [updateDeal, { isLoading }] = useAddDocumentPropertyToDealMutation();

	const onChange = async (event) => {
		const newValue = event.target.value;
		try {
			await updateDeal({
				dealId: dealRow.dealId,
				docId: dealRow.id,
				property: CLIENT_TYPE,
				value: newValue,
				
			}).unwrap();
		} catch (error) {
			console.error('Error updating client type:', error);
		}
	};

	return (
		<FormGroup row>
			<Typography variant="form_label">Client type</Typography>
			<Grid container>
				<RadioGroup
					row
					name={CLIENT_TYPE}
					value={clientType}
					onChange={onChange}
				>
					<FormControlLabel
						value={COMMERCIAL}
						control={<Radio />}
						label="Commercial"
					/>
					<FormControlLabel
						value={DOMESTIC}
						control={<Radio />}
						label="Domestic"
					/>
				</RadioGroup>
			</Grid>
		</FormGroup>
	);
};
