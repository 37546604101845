import { createSlice } from '@reduxjs/toolkit';

export const editButtonSlice = createSlice({
	name: 'editButton',
	initialState: {
		edit: {
			risk: false,
			quote: false,
		},
		submit: {
			risk: false,
			quote: false
		}
	},
	reducers: {
		toggleEdit: (state, action) => {
			const key = action.payload;
			state.edit[key] = !state.edit[key];
		},
		toggleSubmit: (state, action) => {
			const key = action.payload;
			state.submit[key] = true;
		},
		setSubmit: (state, action) => {
			const { key, value } = action.payload;
			state.submit[key] = value;
		},
		setEdit: (state, action) => {
			const { key, value } = action.payload;
			state.edit[key] = value;
		}
	},
});

export const { toggleEdit, toggleSubmit, setSubmit, setEdit } = editButtonSlice.actions;
export default editButtonSlice.reducer;
