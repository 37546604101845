import React, { useState } from 'react';
import {
	Stack,
	Box,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Divider,
} from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { DetailsTab } from '../../ClientView/ClientTabs/DetailsTab';
import { ClientCategory } from '@/components/cards/DealCardValues/ClientCategory';
import { NeedsAnalysisAccordian } from '../../NeedsAnalysisView';
import { ClientType } from '@/components/cards/DealCardValues/ClientType';
import { GoalsOfCover } from '@/components/cards/DealCardValues/GoalsOfCover';
import { ExpandMore } from '@mui/icons-material';
import { folioBlue } from '@/utils/constants';

export const ScopeOfAdviceTab = () => {
	//DealContext
	const { deal, policies, dealRow } = determineContext();

	const [expanded, setExpanded] = useState(true);

	return (
		<Stack spacing={3} width='100%' direction='column' alignItems='flex-start'>
			<Accordion
				elevation={0}
				disableGutters
				square
				expanded={expanded}
				onChange={() => setExpanded(prev => !prev)}
				sx={{
					backgroundColor: 'transparent',
					width: '100%',
					'&:not(:last-child)': {
						borderBottom: 0,
					},
					'&::before': {
						display: 'none',
					},
				}}
			>
				<AccordionSummary
					expandIcon={<ExpandMore sx={{ color: folioBlue }} />}
					sx={{
						borderBottom: expanded ? '1px solid #d3d3d3' : 'none',
						padding: 0,
						marginBottom: '1em'
					}}
				>
					<Typography variant='form_label'>Insured entity details</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px' }}>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<DetailsTab displayContactDetails={false} />
						</Grid>
						<Grid item xs={6}>
							<ClientCategory />
						</Grid>
						<Grid item xs={6}>
							<ClientType />
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<GoalsOfCover />
					</Grid>
				</AccordionDetails>
			</Accordion>
			<NeedsAnalysisAccordian />
		</Stack>
	);
};
