/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
	Typography,
	Grid,
	Stack,
	TextField,
	CircularProgress,
	Box,
	Skeleton,
} from '@mui/material';
import { flattenToNested, formatKey, generateDetailsObject, getNumberFromKey } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { factFindApi, useCreateDealProposalMutation } from '@/features/factFind/factFindApi';
import { useDispatch, useSelector } from 'react-redux';
import { setEdit, setSubmit } from '@/features/editButton/editButtonSlice';
import Robot from '@/assets/images/ai-assistant.gif';

const RISK_INFO_HEADERS = {
	applicantDetails: 'Applicant details',
	businessDetails: 'Business details',
	coverages: 'Coverage details',
	disclosure: 'Disclosure details',
	hotWork: 'Hotwork details',
	liabilities: 'Liabilities details',
	materialDamageAndBusinessInterruption: 'Damage and business interruption',
	periodOfInsurance: 'Period of insurance',
	policySelection: 'Policy Selection',
	productDetails: 'Product Details',
	vehicles: 'Vehicle',
};

export const RiskTab = () => {
	const { deal, riskInfo, loading, soas } = determineContext();
	const [editedValues, setEditedValues] = useState({});
	
	const dispatch = useDispatch();

	const editState = useSelector((state) => state.editButton.edit);
	const hasSubmitted = useSelector((state) => state.editButton.submit.risk);
	const isEditing = editState.risk;

	const latestRiskInfo = useMemo(() => riskInfo[0] ?? {}, [riskInfo]);

    const isGeneratingSOA = useMemo(() => soas.length > 0 && soas[0]?.downloadUrl == null, [soas]);

	const isGenerating = latestRiskInfo?.generating || isGeneratingSOA;

	const riskDetails = useMemo(() => {
		console.log('Latest risk info', latestRiskInfo?.jsonData);
		const generatedObject = Object.keys(RISK_INFO_HEADERS).reduce((acc, key) => {
			acc[key] = generateDetailsObject(latestRiskInfo?.jsonData?.[key], key);
			return acc;
		}, {});
		return generatedObject;
	}, [latestRiskInfo?.jsonData]);

	useEffect(() => {
		let intervalId;
    
		const checkRunStatus = async () => {
			dispatch(factFindApi.util.invalidateTags([
				{ type: 'DEAL_NOTE_SUMMARY', id: deal?.hs_object_id },
			]));
		};
    
		if (isGenerating) {
			intervalId = setInterval(checkRunStatus, 10000); // Check every 10 seconds
		}
    
		return () => clearInterval(intervalId);
	}, [isGenerating, deal?.hs_object_id]);

	useEffect(() => {
		if (!isEditing) {
			const combinedValues = Object.keys(riskDetails).reduce((acc, key) => {
				const entries = Object.entries(riskDetails[key]);
				entries.forEach(([entryKey, value]) => {
					acc[entryKey] = value[1];
				});
				return acc;
			}, {});
			setEditedValues(combinedValues);
		}
	}, [riskDetails, isEditing]);

	const [createDealProposal, { isLoading }] = useCreateDealProposalMutation();

	const loadingInProgress = isLoading || loading.deal || loading.aiDocs;

	const handleSaveClick = async () => {
		const flattened = flattenToNested(editedValues);

		const body = {
			dealId: deal.hs_object_id,
			threadId: latestRiskInfo.threadId,
			jsonData: flattened,
			type: latestRiskInfo.type,
			docId: latestRiskInfo.id,
		};

		await createDealProposal(body)
			.unwrap()
			.then(async (res) => {
				console.log('🚀 ~ Created Proposal', res);
			})
			.catch((err) => {
				console.log('🙅 ~ Error creating Proposal', err);
			})
			.finally(() => {
				dispatch(setEdit({ key: 'risk', value: false }));
				dispatch(setSubmit({ key: 'risk', value: false }));
			});
	};

	useEffect(() => {
		if (hasSubmitted) {
			handleSaveClick();
		}
	}, [hasSubmitted]);

	const handleValueChange = (key, value) => {
		setEditedValues((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const generateSummaryEntry = useCallback(
		(currentObject, key, value, index) => {
			const [name, val] = value;
			const currentNumber = getNumberFromKey(key);
			const previousNumber =
				index > 0 ? getNumberFromKey(Object.keys(currentObject)[index - 1]) : null;

			const hasDifferentNumber =
				previousNumber && currentNumber && previousNumber !== currentNumber;

			return (
				<Grid container key={key} sx={{ ...(hasDifferentNumber && { pt: '1.5em' }) }}>
					<Grid item sm={12} md={4}>
						<Typography variant="subtitle2">{formatKey(name)}</Typography>
					</Grid>
					<Grid item sm={12} md={8}>
						{loadingInProgress || isGenerating ? 
							<Skeleton width="100%" />
							: <TextField
								variant="standard"
								className="minimal-input"
								value={editedValues[key] ?? val}
								onChange={(e) => {
									const newValue = e.target.value;
									handleValueChange(key, newValue);
								}}
								fullWidth
								multiline
								InputProps={{
									readOnly: !isEditing,
								}}
							/>}
					</Grid>
				</Grid>
			);
		},
		[isEditing, loadingInProgress, isGenerating, editedValues, handleValueChange]
	);

	if (loadingInProgress && !latestRiskInfo) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
					alignItems: 'center',
					pt: '0.5em',
				}}
			>
				<Skeleton animation="wave" height="15px" width="100%" />
			</Box>
		);
	}

	if (!latestRiskInfo?.jsonData && !isGenerating) {
		return (
			<Typography variant="subtitle2" mt="1em">
			No risk information available. Upload a document to generate.
			</Typography>
		);
	}
	return (
		<Box pr="0.5em">
			<Typography 
				variant="subtitle2" 
				sx={{ fontWeight: 'bold', fontSize: '20px' }}
			>
			Risk details
			</Typography>
			<Stack spacing={1}>
				{isGenerating && (
                    <>
                    <Box
							sx={{
								position: 'absolute',
								top: '50%',
								left: '41%',
								borderRadius: '50%',
								zIndex: 5,
								height: '150px',
								width: '150px',
								backgroundImage: `url(${Robot})`,
								backgroundPosition: 'left',
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
								display: 'flex',
								flexDirection: 'column',
							}}
						/>
					<Stack direction='row' spacing={1} alignItems="center">
						<CircularProgress size={'1.25em'}/>
						<Typography>
                        Generating risk information data. Please check back soon.
						</Typography>
					</Stack>
                    </>
				)}
				{Object.entries(RISK_INFO_HEADERS).map(([headerKey, header]) => {
					return <Stack 
						key={`risk-info-header-${headerKey}`} 
						spacing={1}
						sx={{ minWidth: '100%' }}
					>
						<Typography
							variant="subtitle2"
							sx={{ fontWeight: 'bold', fontSize: '15px' }}
						>
							{header}
						</Typography>
						{riskDetails[headerKey] ? Object.entries(riskDetails[headerKey]).map(([key, value], index) => {
							return generateSummaryEntry(
								riskDetails[headerKey],
								key,
								value,
								index
							);
						}) : <></>}
					</Stack>;
				})}
			</Stack>
		</Box>
	);
};
