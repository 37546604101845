import { createSlice } from '@reduxjs/toolkit';

const dealViewSlice = createSlice({
	name: 'dealView',
	initialState: {
		open: false,
		emailFiles: [],
		docxFiles: [],
		pdfFiles: [],
		xlsxFiles: [],
	},
	reducers: {
		setEmailFiles: (state, action) => {
			state.emailFiles = action.payload;
		},
		setDocxFiles: (state, action) => {
			state.docxFiles = action.payload;
		},
		setPdfFiles: (state, action) => {
			state.pdfFiles = action.payload;
		},
		setXlsxFiles: (state, action) => {
			state.xlsxFiles = action.payload;
		},
	},
});

export const { setEmailFiles, setDocxFiles, setPdfFiles, setXlsxFiles } = dealViewSlice.actions;
export default dealViewSlice.reducer;
