/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetDealRowQuery } from '@/features/deals/dealsApi';

const initialState = {
	deal: {},
	client: {},
	sharepoint: {},
	isLoading: true,
	isError: false,
	dealRow: {},
};

const AttachmentsContext = createContext(initialState);

export const useAttachmentsContext = () => {
	const context = useContext(AttachmentsContext);
	if (!context) {
		throw new Error('useAttachmentsContext must be used within an AttachmentsContextProvider');
	}
	return context;
};

export const AttachmentsContextProvider = ({ deal, client, children }) => {
	const ownerId = deal?.hubspot_owner_id ?? client?.hubspot_owner_id;
	const userQuery = useGetUserDetailsQuery(
		{ hubspotId: ownerId },
		{ skip: !ownerId }
	);

	const dealRowQuery = useGetDealRowQuery(deal?.hs_object_id ?? deal?.id, { skip: !deal?.hs_object_id && !deal?.id });

	const isLoading = userQuery.isLoading || userQuery.isUninitialized || (deal && dealRowQuery.isLoading);
	const isError = userQuery.isError || !ownerId;

	const sharepoint = useMemo(() => {
		const isValidSharepoint = userQuery.data?.sharepointSite != null && 
        userQuery.data?.clientSiteSuffix != null && 
        userQuery.data?.clientFolder != null && 
        userQuery.data?.clientSite != null;
		return {
			sharepointSite: userQuery.data?.sharepointSite,
	        clientSiteSuffix: userQuery.data?.clientSiteSuffix,
			clientFolder: userQuery.data?.clientFolder,
			clientSite: userQuery.data?.clientSite,
			isValid: isValidSharepoint,
		};
	}, [userQuery]);
    
	const contextValue = {
		deal,
		client,
		dealRow: dealRowQuery.data ?? {},
		isLoading,
		isError,
		sharepoint,
	};

	useEffect(() => {
		console.log('📋 ~ ATTACHMENTS CONTEXT VALUE', contextValue);
	}, [contextValue]);
	
	return (
	// Provide the context variables to the children components.
		<AttachmentsContext.Provider value={contextValue}>
			{ children }
		</AttachmentsContext.Provider>
	);
};

export default AttachmentsContext;