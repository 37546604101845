import { createSlice, current } from '@reduxjs/toolkit';
import { getComparator, stableSort } from '@/components/table/functions';

// A function to generate initialState
export const generateInitialState = (
	initialOrderBy = 'name', 
	initialOrder = 'desc',
) => ({
	limit: 15,
	orderBy: initialOrderBy,
	order: initialOrder,
	open: false,
	rows: [],
	searchText: '',
	searchValue: '',
	resetKey: 0,
	filters: [],
});

// Modify the slice to accept dynamic initial states
export const createSelectSlice = (sliceName, initialState) => createSlice({
	name: sliceName,
	initialState,
	reducers: {
		setSearchValue: (state, action) => {
			state.searchValue = action.payload;
		},
		setSearchText: (state, action) => {
			state.searchText = action.payload;
		},
		incrementResetKey: (state) => {
			state.resetKey += 1;
		},
		setOpen: (state, action) => {
			state.open = action.payload;
		},
		setRows: (state, action) => {
			state.rows = action.payload;
		},
		updateRows: (state, action) => {
			const newRows = action.payload;
			const updatedRows = [...current(state.rows), ...newRows];
			const unique = updatedRows.filter(
				(v, i, arr) => arr.findIndex(item => item.id === v.id) === i
			);
			state.rows = stableSort(unique, getComparator(state.order, state.orderBy));
		},
		reset: (state) => {
			state.searchText = '';
			state.searchValue = '';
			state.rows = [];
			state.filters = [];
			state.open = false;
			state.resetKey += 1;
		},
		setFilters: (state, action) => {
			state.filters = action.payload;
		}
	},
});