/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useMemo } from 'react';
import {
	Box,
	Stack,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	CircularProgress,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
	Checkbox,
	useTheme,
} from '@mui/material';
import AttachFile from '@mui/icons-material/AttachFile';
import Delete from '@mui/icons-material/Delete';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { determineContext } from '@/hooks/determineContext';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useDeleteNoteMutation } from '@/features/engagements/engagementsApi';
import { folioBlue, formatDate } from '@/utils/constants';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { useLocation } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export const NoteAccordion = ({ note, index }) => {
	const { deal, objectType, client } = determineContext();
	const ownerQueryParams = { ownerId: note.createdBy ?? note.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});

	const [deleteNote, { isLoading }] = useDeleteNoteMutation();
	const [dialogOpen, setDialogOpen] = useState(false);
	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);
	const handleConfirmDialog = async () => {
		handleCloseDialog();
		await archiveNote();
	};
	const archiveNote = async () => {
		await deleteNote({ 
			dealId: objectType === 'client' ? client.hs_object_id : deal.hs_object_id, 
			noteId: note.id, 
			objectType: objectType 
		});
	};

	const getFullName = () => {
		return owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown';
	};

	const date = useMemo(() => new Date(note.timestamp), [note.timestamp]);
	const lastUpdated = useMemo(() => new Date(note.lastUpdated), [note.lastUpdated]);
	const baseTheme = useTheme();
	const location = useLocation();
	const [openedNote, setOpenedNote] = useState(() => {
		if (location.state?.note) {
			return location.state.note === note?.id;
		}
		return false;
	});

	const handleNoteOpened = (index) => {
		setOpenedNote((prev) => !prev);
	};

	const getFirst100Characters = (content, openedNote) => {
		if (openedNote) {
		  return content; 
		} else {
		  return content.length > 100 ? content.substring(0, 100) + '...' : content; 
		}
	  };
	  
	  const firstLine = useMemo(() => getFirst100Characters(note.content, openedNote), [note.content, openedNote]);
	  
	  const restOfContent = useMemo(() => {
		if (openedNote) {
		  return ''; 
		} else {
		  return note.content.length > 100 ? note.content.substring(100).trim() : '';
		}
	  }, [note.content, openedNote]);
	  
	return (
		<Accordion
			key={`note-accordion-${index}`}
			expanded={openedNote}
			onChange={() => handleNoteOpened(index)}
			sx={{
				marginBottom: '12px',
				borderRadius: '4px',
				boxShadow: baseTheme.shadows[1],
			}}
		>
			<Stack>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<AccordionSummary
						expandIcon={<ArrowForwardIosSharpIcon  sx={{ fontSize: '0.9rem', color: folioBlue }} />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
						sx={{
							flexDirection: 'row-reverse',
							width: '100%',
							marginBottom: '-1rem',
							'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
								transform: 'rotate(90deg)',
							},
						}}
					>
						<Typography variant="task_body" sx={{ marginLeft: '0.5em' }}>
							<span style={{ fontWeight: 'bold' }}>Note</span> by{' '}
							<span>{getFullName()}</span>
							<span style={{ margin: '0 0.5em' }}>{' | '}</span>
							<span style={{ color: '#505AFC' }}> {formatDate(date, true)} </span>
						</Typography>
					</AccordionSummary>
					<div
						style={{
							marginTop: '12px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'right',
						}}
					>
						{isLoading ? (
							<Box padding="0.76rem">
								<CircularProgress size="1rem" />
							</Box>
						) : (
							<IconButton
								aria-label="delete"
								sx={{ fontSize: '0.5rem' }}
								onClick={handleOpenDialog}
							>
								<Delete />
							</IconButton>
						)}
					</div>
				</div>
				<Stack
					direction="row"
					alignItems="center"
					sx={{ marginLeft: '1.5em' }}
				>
					<Typography
						onClick={() => handleNoteOpened(index)}
						variant="task_body"
						sx={{ marginLeft: '3px', marginRight: '1.5em', cursor: 'pointer' }}
						style={{
							textDecoration: note.completed ? 'line-through' : 'none',
							width: '100%',
						}}
					>
						{firstLine}
					</Typography>
				</Stack>
				{!openedNote && (
					<div
						style={{ display: 'flex', justifyContent: 'right', padding: '10px' }}
					>
						<Typography variant="task_updated">
							{`Last updated: ${formatDate(lastUpdated, true)}`}
						</Typography>
					</div> )}
			</Stack>
			<AccordionDetails sx={{ borderTop: 'none', margin: '0 1.5em 1.5em' }}>
				<Stack width="100%">
					<Typography variant="task_body" sx={{ marginBottom: '1.5em' }}>
						{restOfContent}
					</Typography>
					{note.files.length > 0 && (
						<List
							dense
							sx={{
								width: '100%',
								'& .MuiListItemIcon-root': {
									minWidth: 0,
									marginRight: '12px',
								},
								'& .MuiSvgIcon-root': {
									fontSize: 18,
								},
							}}
						>
							{note.files.map((file, i) => (
								<ListItem disablePadding key={`${file.fileName}-${i}`}>
									<ListItemButton
										target="_blank"
										rel="noopener noreferrer"
										href={file.url}
									>
										<ListItemIcon>
											<AttachFile />
										</ListItemIcon>
										<ListItemText sx={{ color: '#505AFC' }} primary={`${file.fileName}`} />
									</ListItemButton>
								</ListItem>
							))}
						</List>
					)}
				</Stack>
				{openedNote && (
					<div
						style={{ display: 'flex', justifyContent: 'right', padding: '10px' }}
					>
						<Typography variant="task_updated">
							{`Last updated: ${formatDate(lastUpdated, true)}`}
						</Typography>
					</div> )}
				<ConfirmDialog
					openDialog={dialogOpen}
					handleClose={handleCloseDialog}
					handleConfirm={handleConfirmDialog}
					actionName={'delete'}
				/>
			</AccordionDetails>
		</Accordion>
	);
};
