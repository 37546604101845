/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import {
	useGetDealsListByIdsQuery,
} from '@/features/deals/dealsApi';
import { useGetClientPropertiesQuery } from '@/features/clients/clientsApi';
import { useGetContactPropertiesQuery } from '@/features/contacts/contactsApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useGetTicketsListByIdsQuery } from '@/features/claims/ticketsApi';
import { useGetAllDealActivitiesQuery, useGetEngagementsQuery } from '@/features/engagements/engagementsApi';
import { setLoading } from '@/features/engagements/engagementsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetFormsQuery } from '@/features/forms/formsApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSharepoint } from '@/hooks/useSharepoint';
import { folderStructure, getFolderStructure, replaceFolderName } from '@/utils/constants';
import { IS_DEV } from '@/utils/environment';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { addToCache } from '@/features/sharepoint/sharepointSlice';

const initialState = {
	objectType: 'client',
	client: {},
	contact: {},
	deals: [],
	claims: [],
	notes: [],
	tasks: [],
	calls: [],
	emails: [],
	quotes: [],
	owner: {},
	sharepoint: {
		sharepointSite: '',
		clientSiteSuffix: '',
		clientFolder: '',
		clientSite: '',
		isValid: false,
	},
	isLoading: true,
	isError: false,
	// clientRow: {},
	activities: [],
	loading: {
		deals: false,
		client: false,
		contact: false,
		owner: false,
	}
};

const ClientContext = createContext(initialState);

// ClientContextProvider component that provides the Client context to its children.
export const ClientContextProvider = ({ children }) => {
	const dispatch = useDispatch();

	const { client: cache } = useSelector((state) => state.sharepoint.folderCache);

	const clientId = useMemo(() => (
		window.location.pathname.split('/').pop()
	), [window.location.pathname]);

	const folderCache = cache[clientId];

	const clientProperties = useGetClientPropertiesQuery(
		clientId,
		{ skip: !clientId }
	);
    const clientRow = clientProperties.data?.properties?.clientRow;

	const brokerGroupId = clientRow?.brokerGroupId;
	const formsQuery = useGetFormsQuery({ brokerRef: brokerGroupId }, 
		{ skip: !brokerGroupId }
	);

	const objectType = 'client';
	const contactId = clientProperties.data?.contacts[0];
	const contactEmail = clientRow?.contactEmail;

	const contactProperties = useGetContactPropertiesQuery(
		{ hubspotId: contactId, email: contactEmail },
		{ skip: !contactId || !contactEmail }
	);

	const dealIds = clientProperties.data?.deals;
	const dealsProperties = useGetDealsListByIdsQuery(
		{ clientId, dealIds, includePolicies: true },
		{ skip: !clientId || !dealIds }
	);

	const ticketIds = clientProperties.data?.claims;
	const claimsProperties = useGetTicketsListByIdsQuery(
		{ clientId, ticketIds, includePolicies: IS_DEV },
		{ skip: !clientId || !ticketIds }
	);

	const ownerId = clientProperties?.data?.properties?.hubspot_owner_id;

	const ownerProperties = useGetOwnerQuery({ ownerId }, {
		skip: !ownerId,
	});

	const { data: userDetails } = useGetUserDetailsQuery(
		{ hubspotId: ownerId },
		{ skip: !ownerId }
	);


	const activitiesQuery =  useGetAllDealActivitiesQuery({ clientId: clientId,}, 
		{ skip: !clientId }
	);


	const isValidSharepoint = userDetails?.sharepointSite != null && 
    userDetails?.clientSiteSuffix != null && 
    userDetails?.clientFolder != null && 
    userDetails?.clientSite != null;

	const [msgFiles, setMsgFiles] = useState([]);

	const { createFolder, getSharepointAttachmentsRecursive } = useSharepoint(
		userDetails?.sharepointSite,
		userDetails?.clientSiteSuffix,
		userDetails?.clientFolder,
		userDetails?.clientSite
	);
    
	// Handle loading and error states
	const isLoading = clientProperties.isLoading || 
	dealsProperties.isLoading || 
	ownerProperties.isLoading;
    
	useEffect(() => {
		const replacedCompanyName = replaceFolderName(clientProperties.data?.properties?.name ?? '');
		const createIfNecessary = async () => {
			try {
				await createFolder(replacedCompanyName);
				const { clientAttachments, clientNotes } = folderStructure;
				await Promise.all([clientAttachments, clientNotes].map(async name => {
					await createFolder(`${replacedCompanyName}/${name}`);
				}));
				dispatch(addToCache({ type: 'client', id: clientId }));
			} catch (error) {
				console.log(`Error creating client folder for client ${clientProperties.data.properties.name}`, error);
			}
		};
		const getMsgFiles = async () => {
			try {
				dispatch(setLoading(true));
				const response = await getSharepointAttachmentsRecursive(
					replacedCompanyName,
					['.msg']
				);
				console.log('RESPONSE', response);
				setMsgFiles(response);
			} catch (error) {
				console.log('Error getting files', error);
				setMsgFiles([]);
			} finally {
				dispatch(setLoading(false));
			}
		};
		const sharepointReady = isValidSharepoint && clientProperties.data?.properties?.name;
		if (sharepointReady && !folderCache) {
			createIfNecessary();
		}
		if (sharepointReady && folderCache) {
			getMsgFiles();
		}
	}, [
		clientProperties.data,
		clientId, 
		isLoading,
		folderCache,
	]);

	const engagementsQuery = useGetEngagementsQuery({ dealId: clientId, objectType: objectType }, 
		{ skip: !clientId }
	);
	

	useEffect(() => {
		dispatch(setLoading(engagementsQuery.isFetching || engagementsQuery.isLoading || activitiesQuery.isLoading || activitiesQuery.isFetching));
	}, [
		engagementsQuery.isFetching,
		engagementsQuery.isLoading,
		activitiesQuery.isLoading,
		activitiesQuery.isFetching,
	]);
	
	// engagementsQuery.isLoading;
	const isError = clientProperties.isError || dealsProperties.isError || ownerProperties.isError || engagementsQuery.isError;// || clientRowQuery.isError;

	// Context value
	const contextValue = {
		objectType,
		client: clientProperties.data?.properties || {},
		contact: contactProperties.data?.properties || {},
		deals: dealsProperties.data || [],
		claims: claimsProperties.data || [],
		notes: engagementsQuery.data?.notes || [],
		tasks: engagementsQuery.data?.tasks || [],
		calls: engagementsQuery.data?.calls || [],
		emails: engagementsQuery.data?.emails || [],
		msgFiles,
		forms: formsQuery.data?.data || [],
		quotes: [],
		activities: activitiesQuery.data?.data ?? [],
		sharepoint: {
			sharepointSite: userDetails?.sharepointSite,
			clientSiteSuffix: userDetails?.clientSiteSuffix,
			clientFolder: userDetails?.clientFolder,
			clientSite: userDetails?.clientSite,
			isValid: isValidSharepoint,
		},
		owner: ownerProperties.data || {},
		loading: {
			deals: dealsProperties.isLoading || claimsProperties.isLoading || dealsProperties.isUninitialized || claimsProperties.isUninitialized,
			client: clientProperties.isLoading || clientProperties.isUninitialized,
			contact: contactProperties.isLoading || contactProperties.isUninitialized,
			owner: ownerProperties.isLoading || ownerProperties.isUninitialized,
			forms: formsQuery.isLoading || formsQuery.isUninitialized,
		},
	};

	useEffect(() => {
		console.log('📋 ~ CLIENT CONTEXT VALUE', contextValue);
	}, [contextValue]);

	// const { createClientFolder } = useClientStorageHooks((userDetails));

	return (
	// Provide the context variables to the children components.
		<ClientContext.Provider value={contextValue}>
			{isError ? (
				<Box>
					<Typography textAlign="center">
            There was an issue loading this client. Please try again.
					</Typography>
				</Box>
			) : (
				children
			)}
		</ClientContext.Provider>
	);
};

export default ClientContext;