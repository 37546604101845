/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useMemo, useEffect } from 'react';
import {
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Paper,
} from '@mui/material';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { useGetDealPolicyTransactionsQuery } from '@/features/deals/dealsApi';
import { policyHeadCells } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { stableSort } from '@/components/table/functions';
import { PolicyRow } from '@/components/tables/PolicyRow';

export const CompleteDealPolicyTransactionTable = ({ setLoading }) => {
	const { policies } = determineContext();

	const [policyTransRows, setPolicyTransRows] = useState([]);

	const headerCells = policyHeadCells; //.filter((header) => header.id !== 'checkbox');

	//If there is no policies array set id to null
	const policyId = useMemo(() => policies[0]?.Id, [policies]);

	const {
		data: policyTransData,
		isLoading: isLoadingPolicyTrans,
		isError: isPoliciesError,
	} = useGetDealPolicyTransactionsQuery(
		{ policyId },
		{ skip: !policyId }
	);

	const loading = isLoadingPolicyTrans;

	useEffect(() => setLoading(loading), [loading]);

	const error = isPoliciesError;

	useEffect(() => {
		if (policyTransData) {
			console.log('Policy transactions', policyTransData);
			const updatedPolicies = policyTransData.map((policy) => ({ ...policy }));
			console.log('here is the latest updated policy: ', updatedPolicies);
			if (updatedPolicies.length > 0) {
				const latestPolicy = updatedPolicies[updatedPolicies.length - 1];
				setPolicyTransRows([latestPolicy]);
			} else {
				console.log('No policies found in updated data.');
			}
		}
	}, [policyTransData]);

	// const handleSubmit = async () => {
	// 	try {
	// 		await updateDealStage();
	// 		if (additionalContent.length > 0) {
	// 			const body = {
	// 				dealId: deal.hs_object_id,
	// 				noteBody: additionalContent,
	// 				hubspotId: hubspotId,
	// 				objectType: objectType,
	// 			};
	// 			console.log('📋 ~ Create note object', body);
	// 			const responseNote = await createNote(body).unwrap();
	// 		}
	// 		dispatch(closeDialog('completeDeal'));
	// 		dispatch(showToast({
	// 			message: `${pipelineName} completed!`,
	// 			action: {
	// 				path: `/clients/${client.hs_object_id}`,
	// 				label: 'GO TO CLIENT',
	// 			},
	// 			autohide: false,
	// 		}));
	// 	} catch (err) {
	// 		console.log(':no_good: ~ Error adding policies', err);
	// 	}
	// };

	return (
		<>
			<Typography variant='subtitle2' sx={{ fontWeight: 'bold', marginTop: 3 }}>
				Completing policy transaction:
			</Typography>
			<Typography>
				{`${policies[0]?.PolicyNumber ?? ''} - ${policies[0]?.Description ?? ''}`}
			</Typography>
			<TableContainer component={Paper}>
				<Table
					aria-labelledby='tableTitle'
					size='medium'
					aria-label='enhanced table'
					sx={{ minWidth: 700 }}
				>
					<FolioTableHead order={'desc'} orderBy={'createdate'} headCells={headerCells} />
					<TableBody>
						{loading ? (
							<TableProgressIndicator rowCount={headerCells.length} />
						) : error ? (
							<TableRow>
								<TableCell colSpan={headerCells.length} align='center'>
									<Typography variant='subtitle1'>
										There was an error. Please try again.
									</Typography>
								</TableCell>
							</TableRow>
						) : policyTransRows.length > 0 ? (
							stableSort(policyTransRows, (a, b) => a.Id - b.Id).map(
								(policy, index) => (
									<PolicyRow
										hideCheckbox={true}
										key={`policy-row-index-${index}-${policy.Id}`}
										policy={policy}
									/>
								)
							)
						) : (
							<TableRow>
								<TableCell colSpan={headerCells.length} align='center'>
									<Typography variant='subtitle1'>
										No policy transactions found
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
