import React from 'react';
import { Typography } from '@mui/material';
import { FIELDS } from '../validationSchema';
import { DataGrid } from '@mui/x-data-grid';

export const WhatHasBeenSentToMarket = ({ rows, updateRows, removeRow, disabled }) => {
	const columns = [
		// {
		// 	...GRID_CHECKBOX_SELECTION_COL_DEF,
		// 	renderHeader: (params) => <></>,
		// 	renderCell: (params) =>
		// 		Object.keys(FIELDS.whatHasBeenSentToMarket).includes(params.row.id) || params.row.classOfRisk === '' ? (
		// 			<></>
		// 		) : (
		// 			<IconButton
		// 				sx={{ fontSize: '10px', padding: 0 }}
		// 				color='rgba(0,0,0,0)'
		// 				onClick={() => removeRow(params.row.id)}
		// 			>
		// 				<DeleteIcon />
		// 			</IconButton>
		// 		),
		// },
		{
			field: 'classOfRisk',
			headerName: 'Class of Risk',
			width: 300,
			editable: !disabled,
			sortable: false,
			renderCell: (params) =>
				params.row.classOfRisk === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter name of product</Typography>
				) : (
					params.row.insurer
				),
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			width: 300,
			editable: !disabled,
			sortable: false,
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
			}}
			width='100%'
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => {
				if (disabled) {
					return false;
				}
				if (params.field === 'classOfRisk') {
					return !Object.keys(FIELDS.whatHasBeenSentToMarket).includes(params.row.id);
				}
				return true;
			}}
			// isRowSelectable={(params) => {
			// 	return !Object.keys(FIELDS.whatHasBeenSentToMarket).includes(params.row.id);
			// }}
			// checkboxSelection
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
