// features/select/dealSelectSlice.js
import { generateInitialState, createSelectSlice } from './selectSlice';

const dealSelectInitialState = generateInitialState('createdate');
export const dealSelectSlice = createSelectSlice('dealSelect', dealSelectInitialState);

export const { 
	setSearchValue,
	setSearchText,
	incrementResetKey,
	setOpen,
	setRows,
	updateRows,
	reset,
	setFilters,
} = dealSelectSlice.actions;

export default dealSelectSlice.reducer;