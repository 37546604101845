// features/table/contactsTableSlice.js
import { generateInitialState, createTableSlice } from './tableSlice';

const contactsTableInitialState = generateInitialState();
export const contactsTableSlice = createTableSlice('contactsTable', contactsTableInitialState);

export const { 
	setPageAfter,
	setRowsPerPage,
	setOpenDialog,
	setSearchValue,
	setSearchText,
	setOrderBy,
	setOrder,
	setPage,
	setRows,
	resetSearchFields,
	changeRowsPerPage,
	requestSort,
	changePage,
	resetAllPages,
	onOrderChange,
} = contactsTableSlice.actions;

export default contactsTableSlice.reducer;