/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEdit, toggleSubmit } from '@/features/editButton/editButtonSlice';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { determineContext } from '@/hooks/determineContext';

export const AddEditButton = ({ type = 'quote' }) => {
	const dispatch = useDispatch();

	const { quotes, riskInfo } = determineContext();

	const latestQuote = useMemo(() => quotes[0] ?? {}, [quotes]);
	const latestRiskInfo = useMemo(() => riskInfo[0] ?? {}, [riskInfo]);

	const isGenerating = type === 'quote' ? latestQuote?.generating : latestRiskInfo?.generating;

	const editState = useSelector((state) => state.editButton.edit);

	const handleToggle = (key) => {
		dispatch(toggleEdit(key));
	};

	const handleSubmission = (key) => {
		handleToggle(key);
		dispatch(toggleSubmit(key));
	};

	const isQuote = type === 'quote';
	const isEditing = isQuote ? editState.quote : editState.risk;

	return (
		<>
			{isEditing ? (
				<>
					<Button
						variant="tab_header_outlined"
						startIcon={<CloseIcon />}
						onClick={() => handleToggle(type)}
						disabled={isGenerating}
					>
						Cancel
					</Button>
					<Button
						variant="tab_header"
						startIcon={<DoneIcon />}
						onClick={() => handleSubmission(type)}
						disabled={isGenerating}
					>
						Save
					</Button>
				</>
			) : (
				<Button
					variant="tab_header"
					component="label"
					startIcon={<EditNoteIcon />}
					disabled={isGenerating}
					onClick={() => handleToggle(type)}
				>
					Edit
				</Button>
			)}
		</>
	);
};
