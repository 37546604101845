/* eslint-disable no-mixed-spaces-and-tabs */
import React, {useState } from 'react';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	FormHelperText,
	Typography,
} from '@mui/material';
import '@/styles/fileuploader.scss';
import { FileUploader } from 'react-drag-drop-files';
import {
	specialChars,
	folioBlue
} from '@/utils/constants';
import { AttachFile } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const FileUpload = ({ files, setFiles, loading, types, multiple }) => {

	const handleDragAndDrop = (file) => {
		const newFiles = Array.from(file);
		setFiles(multiple != false ? [...files, ...newFiles] : [file]);
	};
	
	const removeFile = (index) => {
		const newFiles = files.filter((_, i) => i !== index);
		setFiles(newFiles);
		console.log('📋 ~ Selected files after delete', newFiles);
	};

	return (
		<>
			{!loading && (
				<FileUploader
					classes="drop_zone"
					handleChange={handleDragAndDrop}
					multiple={multiple != false}
					name="file"
					types={types}
				>
					<div 
						style={{ 
							border: `2px dashed ${folioBlue}`, 
							backgroundColor: 'white',
							borderRadius: '5px', 
							display: 'flex', 
							alignItems: 'center', 
							cursor: 'pointer', 
							padding: '1em'
						}}>
						<UploadFileIcon sx={{ fontSize: '2em', color: folioBlue, marginRight: '0.25em' }} />
						<Typography variant="card_header">
							<span style={{ textDecoration: 'underline'}}>{'Upload'}</span>
							{' or drop a file right here'}
						</Typography>
					</div>
				</FileUploader>
			)}
			{files.length > 0 && (
				<List dense>
					{files.map((file, i) => (
						<ListItem
							disablePadding
							key={`${file.name}`}
							secondaryAction={
								<IconButton
									disabled={loading}
									edge="end"
									aria-label="delete"
									onClick={() => removeFile(i)}
								>
									<DeleteIcon />
								</IconButton>
							}
						>
							<ListItemIcon>
								<AttachFile />
							</ListItemIcon>
							<ListItemText primary={`${file.name}`} />
						</ListItem>
					))}
				</List>
			)}
		</>
	);
};

export default FileUpload;