import React, { useState } from 'react';
import { 
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Stack,
	TableCell, 
	TableRow,
	Tooltip,
	Typography, 
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import DownloadIcon from '@mui/icons-material/Download';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { ClientNameAndAvatar } from '@/components/table/ClientNameAndAvatar';
import { useNavigate } from 'react-router';
import { paths } from '@/app/routes';
import { folioBlue, formatCurrency, formatDate, getNameInitials } from '@/utils/constants';
import { StatusBlink, StatusChip } from './StatusChip';
import { DriveFileMove } from '@mui/icons-material';
import { useUpdateInvoiceStatusMutation } from '@/features/invoices/invoicesApi';
import { useDispatch } from 'react-redux';
import { showToast } from '@/features/toast/toastSlice';
import { useInstantSearch } from 'react-instantsearch';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const InvoiceHitRow = ({hit: invoice}) => {
	const dispatch = useDispatch();

	const { refresh } = useInstantSearch();

	const [loading, setLoading] = useState(false);
	const [updateInvoiceStatus, { isLoading }] = useUpdateInvoiceStatusMutation();

	const changeInvoiceStatus = async () => {
		setLoading(true);
		await updateInvoiceStatus({ policyId: invoice.policyId })
			.unwrap()
			.then(res => {
				setTimeout(async () => {
					refresh();
					setLoading(false);
				}, 2000);
			})
			.catch(err => {
				console.log('🙅 ~ Error updating invoice status', err);
				dispatch(showToast({
					message: 'Error updating invoice. Please try again.',
					error: true,
				}));
				setLoading(false);
			});
		
	};

	const handleDownloadInvoice = async () => {
		try {
			window.location.href = invoice.downloadUrl;
		} catch (err) {
			console.log('🙅 ~ Error downloading invoice', err);
			dispatch(showToast({
				message: 'Error downloading invoice. Please try again.',
				error: true,
			}));
		}
	};

	const createdDate = new Date(invoice.createdWhenUnix * 1000);
	const status = invoice.isPaid ? 'RECEIPTED' : 'UNRECEIPTED';

	return (
		<TableRow hover>
			<TableCell width={'12.5%'}>
				<ClientNameAndAvatar 
					title={invoice.clientName} 
					subtitle={`${invoice.classCode}`} 
					initials={getNameInitials(invoice.clientName)}
				/>
				{/* <Stack direction="column">
					<Typography variant="avatar_title">
						{invoice.clientName}
					</Typography>
					<Typography variant="avatar_subtitle">
						{`${invoice.classCode}`}
					</Typography>
				</Stack> */}
			</TableCell>
			<TableCell width={'12.5%'}>{invoice.transactionType}</TableCell>
			<TableCell width={'12.5%'}>
				{formatDate(createdDate)}
			</TableCell>
			<TableCell width={'12.5%'}>{invoice.invoiceNumber}</TableCell>
			<TableCell align="right" style={{fontWeight: 'bold'}} width={'15%'}>
				{formatCurrency(invoice.balance)}
			</TableCell>
			<TableCell width={'12.5%'}>
				<StatusBlink status={status}/>
			</TableCell>
			<TableCell width={'12.5%'}>
				<Stack direction='row'>
					<Tooltip title={'Download'}>
						<IconButton variant="outlined" onClick={handleDownloadInvoice} disabled={(invoice.downloadUrl ?? '').length === 0}>
							{<DownloadIcon sx={{ color: folioBlue }}/>}
						</IconButton>
					</Tooltip>
					<Tooltip title={!invoice.archived ? 'Archive' : 'Unarchive'}>
						<IconButton variant="outlined" onClick={changeInvoiceStatus} disabled={loading || isLoading}>
							{loading || isLoading ? <CircularProgress size={'1em'} /> : invoice.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
						</IconButton>
					</Tooltip>
				</Stack>
				{/* <InvoiceRowButton
						title="Merge"
						onClick={handleDialogOpen}
						loading={mergeLoading}
					>
						<NoteAddIcon />
					</InvoiceRowButton> */}
			</TableCell>
			<TableCell width={'12.5%'}>
				<CopyToClipboard 
					text={invoice.folioPayLink ?? ''}
					onCopy={() => {
						if (invoice.folioPayLink) {
							dispatch(showToast({
								message: 'Copied FolioPay link!',
								success: true,
							}));
						}
					}}
				>
					<Tooltip title={(invoice.folioPayLink ?? '').length === 0 ? 'No FolioPay link found' : 'Copy FolioPay link'}>
						<span>
							<Button 
								variant="contained" 
								size="small"
								disabled={(invoice.folioPayLink ?? '').length === 0}
								startIcon={<LinkIcon sx={{ transform: 'rotate(135deg)'}} />}
								sx={{
									fontSize: '12px',
									textTransform: 'none'
								}}
							>
                Copy FolioPay link
							</Button>
						</span>
					</Tooltip>
				</CopyToClipboard>
			</TableCell>
		</TableRow>
	);
};
