// features/table/tasksTableSlice.js
import { generateInitialState, createTableSlice } from './tableSlice';

const tasksTableInitialState = generateInitialState('hs_timestamp', 'desc');
export const tasksTableSlice = createTableSlice('taskTable', tasksTableInitialState);

export const { 
	setPageAfter,
	setRowsPerPage,
	setOpenDialog,
	setSearchValue,
	setSearchText,
	setOrderBy,
	setOrder,
	setPage,
	setRows,
	resetSearchFields,
	changeRowsPerPage,
	requestSort,
	changePage,
	resetAllPages,
	onOrderChange,
} = tasksTableSlice.actions;

export default tasksTableSlice.reducer;