import React from 'react';
import { Card, Typography, Box, Button, Stack, Tooltip } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { createFormUrlQuery } from '@/utils/constants';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '@/features/toast/toastSlice';
import LinkIcon from '@mui/icons-material/Link';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

export const FormCard = ({ form }) => {
	const { client, deal } = determineContext();
	const dispatch = useDispatch();



	const queryUrl = `${form.url}${createFormUrlQuery(
		client.broker.name,
		client.name,
		client.clientRow.contactFirstName,
		client.clientRow.contactLastName,
		deal?.id ?? null,
		client?.hs_object_id ?? null,
	)}`;
	const body = `[Please replace with email body]

You can access the form using the following link:
    
${queryUrl}
    `;

	const handleClick = () => {
		window.location.href = `mailto:${
			client?.clientRow?.contactEmail
		}?subject=${encodeURIComponent(`${form.name} for ${client.name}`)}&body=${encodeURIComponent(body)}`;
	};

	return (
		<Card style={{ marginBottom: '12px', padding: '12px' }}>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '10px',
					marginLeft: '2px'
				}}
			>
				<Typography
					variant="task_body"
					style={{ display: 'flex', alignItems: 'center' }}
				>
					<span style={{ fontWeight: 'bold' }}>{form.name}</span>
					<span style={{ margin: '0 0.5em' }}>{' | '}</span>
					<span style={{ color: '#505AFC' }}>{`Version ${form.version}`}</span>
				</Typography>
				<Stack direction="row" spacing={1.5}>
					<CopyToClipboard 
						text={queryUrl}
						onCopy={() => {
							if(queryUrl) {
								dispatch(showToast({
									message: 'Copied Form link!',
									success: true,
								}));
							}
						}}
					>
						<Tooltip title={'Copy form link to clipboard'}>
							<span>
								<Button
									sx={{ borderRadius: '10px' }}
									component="label" 
									variant="tab_header"
									startIcon={<LinkIcon  />}
								>
									{'Form Link'}
								</Button>
							</span>
						</Tooltip>
					</CopyToClipboard>
					<Tooltip title={'Open outlook with attached form link'}>
						<Button
							sx={{ borderRadius: '10px' }}
							component="label" 
							variant="tab_header"
							onClick={handleClick}
							startIcon={<MailOutlineIcon />}
						>
							{'Send Form'}
						</Button>
					</Tooltip>
				</Stack>
			</Box>
			<div style={{ paddingLeft: '2px' }}>
				<Typography variant="task_body">
					{form.description}
				</Typography>
			</div>
		</Card>
	);
};
