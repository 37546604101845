
import React from 'react';
import { ClientsView } from '@/components/views/ClientsView';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import AnnouncementBanner from '@/components/layouts/AnnouncementBanner';

export const HomePage = () => {

	return (<AuthenticatedPageLayout>
		<AnnouncementBanner/>
		<ClientsView />
	</AuthenticatedPageLayout>);
};