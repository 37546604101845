import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';

export const attachmentHeadCells = [
	{
		id: 'download',
		numeric: false,
		label: '',
		align: 'left',
		sortable: false,
		width: GRID_CHECKBOX_SELECTION_COL_DEF.width,
	},
	{ id: 'id', numeric: false, label: null, sortable: false },
	{
		id: 'Name',
		numeric: false,
		label: 'DOCUMENTS',
		align: 'left',
		width: 350,
	},
	{ id: 'type', numeric: true, sortable: false, label: 'TYPE', align: 'right', width: 85 },
	{ id: 'PolicyId', numeric: true, label: 'POLICY ID', align: 'right' },
	{
		id: 'TimeCreated',
		numeric: false,
		label: 'DATE ADDED',
		align: 'right',
		width: 200,
	},
];
