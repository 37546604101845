import { createSlice } from '@reduxjs/toolkit';

export const sharepointSlice = createSlice({
	name: 'sharepoint',
	initialState: {
		folderCache: {
			deal: {},
			client: {},
		},
	},
	reducers: {
		addToCache: (state, action) => {
			const { type, id } = action.payload;
            console.log('ACTION PAYLOAD', action.payload);
			state.folderCache = {
				...state.folderCache,
				[type]: {
					...state.folderCache[type],
					[id]: true,
				}
			};
		}
	},
});

export const { 
	addToCache,
} = sharepointSlice.actions;
export default sharepointSlice.reducer;