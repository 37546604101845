import React from 'react';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import CreateNewBusinessForm from '@/components/forms/CreateNewBusinessForm';

export const CreateNewBusinessPage = () => {
	return (
		<AuthenticatedPageLayout>
			<CreateNewBusinessForm />
		</AuthenticatedPageLayout>
	);
};