import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box, Typography, Grid, Avatar, Button, useTheme, useMediaQuery } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useMsal } from '@azure/msal-react';
import { toggleOpen } from '@/features/sideNav/sideNavSlice';

export const AccountDropdownMenu = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();

	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const brokerDetails = userQuery.data?.broker;

	const handleDrawerToggle = () => {
		dispatch(toggleOpen());
	};

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleSignOut = () => {
		handleMenuClose();
		instance.logoutRedirect({
			postLogoutRedirectUri: '/',
		}).then(() => {
			localStorage.clear();
			sessionStorage.clear();
		});
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static" style={{ background: 'white', boxShadow: 'none', borderBottom: '1px solid #D9DCFE' }}>
				<Toolbar variant="dense" sx={{ justifyContent: isMobile ? 'space-between' : 'flex-end' }}>
					{isMobile && (
						<IconButton
							color="#1C2536"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: 'none' } }}
						>
							<MenuIcon />
						</IconButton>
					)}
					{userQuery.data && (
						<div>
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								{brokerDetails?.imageUrl ? 
									<Avatar 
										sx={{ width: '1em', height: '1em', mr: 2, backgroundColor: '#34495E' }}
										src={brokerDetails?.imageUrl ?? ''}
									>
									</Avatar> 
									: <AccountCircle />}
								<Typography variant='broker_header'>
									{brokerDetails?.name || ' '}
								</Typography>
								<IconButton
									size="small"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleMenuOpen}
								>
									<KeyboardArrowDownIcon />
								</IconButton>
							</Box>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
							>
								<MenuItem onClick={handleSignOut}>Sign out</MenuItem>
							</Menu>
						</div>
					)}
				</Toolbar>
			</AppBar>
		</Box>
	);
};
