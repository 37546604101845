import React from 'react';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import { Box } from '@mui/material';
import { ClientContextProvider } from '@/context/clientContext';
import ClientViewWrapper from '@/components/views/ClientView/ClientViewWrapper';

const ViewClientPage = () => {
	return (
		<AuthenticatedPageLayout>
			<Box pb={3}>
				<ClientContextProvider>
					<ClientViewWrapper />
				</ClientContextProvider>
			</Box>
		</AuthenticatedPageLayout>
	);
};

export default ViewClientPage;