import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { formatKey } from '@/utils/constants';
import { DataGrid } from '@mui/x-data-grid';

export const RecommendationTable = ({ rows }) => {
	const tableRows = useMemo(
		() =>
			rows.length > 0
				? rows
				: [
					{
						id: 0,
						classOfRisk: '',
						insurer: '',
						sumInsured: '',
						excess: '',
						premium: '',
					},
				],
		[rows]
	);

	const columns = useMemo(
		() =>
			rows.length > 0
				? [
					{
						field: 'classOfRisk',
						headerName: 'Class of Risk',
						width: 250,
						editable: false,
						sortable: false,
						renderCell: (params) => formatKey(params.row.classOfRisk ?? ''),
					},
					{
						field: 'insurer',
						headerName: 'Insurer',
						width: 200,
						editable: false,
						sortable: false,
					},
					{
						field: 'sumInsured',
						headerName: 'Sum Insured ($)',
						width: 150,
						editable: false,
						sortable: false,
					},
					{
						field: 'excess',
						headerName: 'Excess ($)',
						width: 150,
						editable: false,
						sortable: false,
					},
					{
						field: 'premium',
						headerName: 'Premium (incl.GST) ($)',
						width: 200,
						editable: false,
						sortable: false,
					},
				]
				: [
					{
						field: 'classOfRisk',
						headerName: 'Class of Risk',
						width: 500,
						editable: false,
						sortable: false,
						renderCell: () => (
							<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
									Select classes of risk from Results tables
							</Typography>
						),
					},
				],
		[rows]
	);

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
			}}
			width='100%'
			rows={tableRows}
			getRowId={(row) => `${row.classOfRisk}-${row.insurer}`}
			columns={columns}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
