import React, { useState, useEffect } from 'react';
import { Typography, TextField, Box } from '@mui/material';
import { 
	useUpdateDealNoteSummaryMutation, 
	useQueryThreadRunMutation, 
	factFindApi
} from '@/features/factFind/factFindApi';
import { useDispatch } from 'react-redux';
import { CollapsibleChatCards } from '@/components/cards/CollapsibleChatCards';
import { LoadingButton } from '@mui/lab';
import { determineContext } from '@/hooks/determineContext';
import { dealsApi } from '@/features/deals/dealsApi';

export const FactFindTab = () => {
	//DealContext
	const { deal, noteSummaries, threadIds } = determineContext();
	const summaries = noteSummaries['summary'] ?? [];

	const dealId = deal?.hs_object_id;

	const threadId = threadIds?.summary;

	const dispatch = useDispatch();

	const [updateDealNoteSummary, updateData] = useUpdateDealNoteSummaryMutation();
	const [queryThreadRun, { data: queryData }] = useQueryThreadRunMutation();

	const [latest, setLatest] = useState(summaries[0]?.content?.[0]?.text?.value ?? '');

	const [text, setText] = useState('');
	const [runId, setRunId] = useState(null);

	useEffect(() => {
		console.log(summaries);
		if (summaries[0]?.content?.[0]?.text?.value !== text) {
			setLatest(summaries[0]?.content?.[0]?.text?.value ?? '');
		}
	}, [summaries]);

	useEffect(() => {
		const checkRunStatus = async () => {
			if (runId) {
				const intervalId = setInterval(async () => {
					const response = await queryThreadRun({ threadId, runId }).unwrap();
					if (response?.status === 'completed') {
						clearInterval(intervalId);
						dispatch(factFindApi.util.invalidateTags([
							{ type: 'DEAL_NOTE_SUMMARY', id: dealId },
						]));
						dispatch(dealsApi.util.invalidateTags([
							{ type: 'DEAL_ROW', id: dealId },
						]));
						setRunId(null);
						setText('');
					}
				}, 5000); // Check every 5 seconds

				return () => clearInterval(intervalId);
			}
		};

		checkRunStatus();
	}, [runId, threadId, dispatch, queryThreadRun]);

	const updateFactFindApi = async (value) => {
		try {
			const response = await updateDealNoteSummary({
				dealId: deal?.hs_object_id,
				content: value,
			}).unwrap();
			setRunId(response?.id);
		} catch (error) {
			console.error('Error updating deal note summary:', error);
		}
	};

	return (
		<Box>
			{summaries.length === 0 ? (
				<Typography textAlign={'center'}>No summaries generated yet.</Typography>
			) : (
				<CollapsibleChatCards messages={summaries} update={updateFactFindApi}/>
			)}
			<Box p={1} mb={2}>
				<Typography variant="task_updated">
					{'This fact find does not constitute financial advice but serves as a starting point'}
				</Typography>
			</Box>
			<TextField 
				value={text}
				placeholder="Enter some information with which to update the Fact Find"
				fullWidth
				multiline
				disabled={updateData.isLoading || runId != null}
				onChange={(e) => setText(e.target.value)}
				sx={{ p: 1 }}
			/>
			<div
				style={{
					marginTop: '12px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'right',
				}}
			>
				<LoadingButton
					color="primary"
					variant="contained"
					onClick={() => updateFactFindApi(text)}
					loading={updateData.isLoading || runId != null}
				>
					{'Update'}
				</LoadingButton>
			</div>
		</Box>
	);
};

// const InsuranceDetailFields = ({ label, initialValue, onUpdate }) => {
// 	const [value, setValue] = useState(initialValue);

// 	const handleBlur = () => {
// 		onUpdate(value);
// 	};

// 	const minimalInputTheme =
//     theme.components.MuiInputBase.styleOverrides.minimal;

// 	return (
// 		<Box display="flex" flexDirection="row" alignItems="center" mb={1}>
// 			<Typography fontWeight="bold" marginRight={1} whiteSpace="nowrap">
// 				{label}:
// 			</Typography>
// 			<TextField
// 				value={value}
// 				fullWidth
// 				sx={{
// 					...minimalInputTheme,
// 					'& .MuiInput-underline:before': {
// 						borderBottom: 'none',
// 					},
// 					'& .MuiInputBase-input': {
// 						color: 'inherit',
// 						fontWeight: 'inherit',
// 					},
// 				}}
// 				variant="standard"
// 				className="minimal-input"
// 				onBlur={handleBlur}
// 				onChange={(e) => setValue(e.target.value)}
// 			/>
// 		</Box>
// 	);
// };

// const CompanyDetailsFields = ({ label, initialValue, onUpdate }) => {
// 	const [value, setValue] = useState(initialValue);

// 	const minimalInputTheme =
//     theme.components.MuiInputBase.styleOverrides.minimal;

// 	const handleChange = () => {
// 		onUpdate(value);
// 	};

// 	return (
// 		<Box>
// 			<Typography fontWeight="bold">{label}:</Typography>
// 			<TextField
// 				value={value}
// 				fullWidth
// 				sx={{
// 					...minimalInputTheme,
// 					'& .MuiInput-underline:before': {
// 						borderBottom: 'none',
// 					},
// 					'& .MuiInputBase-input': {
// 						color: 'inherit',
// 						fontWeight: 'inherit',
// 					},
// 				}}
// 				variant="standard"
// 				className="minimal-input"
// 				onBlur={handleChange}
// 				onChange={(e) => setValue(e.target.value)}
// 			/>
// 		</Box>
// 	);
// };

// export default CompanyDetailsFields;
