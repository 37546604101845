import React from 'react';
import { TextField, FormLabel } from '@mui/material';
import { formatKey } from '@/utils/constants';

export const FormTextField = ({ field, formKey, value, formik, disabled }) => {
	const formikKey = `${field}.${formKey}`;

	return (
		<>
			<FormLabel>{formatKey(formKey)}</FormLabel>
			<TextField
				fullWidth
				{...(value.lines && {
					multiline: true,
					rows: value.lines,
				})}
				name={formikKey}
				{...formik.getFieldProps(formikKey)}
				disabled={disabled}
			/>
		</>
	);
};
