import React from 'react';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import { TicketContextProvider } from '@/context/ticketContext';
import { Box } from '@mui/material';
import DealViewWrapper from '@/components/views/DealView/DealViewWrapper';

const ViewClaimPage = () => {
	return (
		<AuthenticatedPageLayout>
			<Box pb={3}>
				<TicketContextProvider>
					<DealViewWrapper />
				</TicketContextProvider>
			</Box>
		</AuthenticatedPageLayout>
	);
};

export default ViewClaimPage;
