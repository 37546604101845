import React, { useEffect, useMemo } from 'react';
import {
	Table,
	TableContainer,
	TableFooter,
	TableRow,
	Paper,
	Button,
	Grid,
	TableCell,
	Typography,
	Skeleton,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { SearchFolioHead } from '@/components/table/FolioTableHead';
import {
	changeRowsPerPage,
	requestSort,
	changePage,
	setOrderBy,
	setOrder,
} from '@/features/table/clientsTableSlice';
import { InstantSearchFieldAdaptor } from '@/components/SearchField';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/app/routes';

import { InstantSearch, Configure } from 'react-instantsearch';
import { ClientRow } from './ClientRow';
import { HitsTableBody } from '@/components/table/HitsTableBody';
import { HitsTablePagination } from '@/components/table/TablePaginationActions';
import { rowLimits } from '@/components/table/data';
import { searchClient } from '@/utils/typesense';

export const clientHeadCells = [
	// { id: 'id', numeric: false, label: null, sortable: false },
	{ id: 'name', numeric: false, label: 'Insured name', align: 'left', width: '35%' },
	{ id: 'contactName', numeric: false, label: 'Contact', align: 'left', width: '20%' },
	{ id: 'contactEmail', numeric: false, label: 'Email', align: 'left', width: '20%' },
	{ id: 'contactPhone', numeric: true, label: 'Phone', align: 'right', width: '25%' },
	// { id: 'id', numeric: false, label: '', sortable: false, align: 'center' },
];

export default function ClientTable() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const { 
		groupView, 
		hubspotId: groupViewHubspotId 
	} = useSelector((state) => state.userPrefs);

	const {
		// pageAfter,
		rowsPerPage,
		// searchValue,
		// searchText,
		orderBy,
		order,
		// page,
		// rows,
	} = useSelector((state) => state.clientTable);


	const { data: userDetails, isLoading, isUninitialized } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	
	const userDetailsHubspotId = userDetails?.hubspotId;
	const hubspotId = groupViewHubspotId || userDetailsHubspotId;

	const brokerGroupId = userDetails?.brokerId;
	const brokerGroupIds = userDetails?.brokerGroupArray ?? [];

	useEffect(() => {
		dispatch(setOrderBy('name'));
		dispatch(setOrder('asc'));
	}, []);

	const handleRequestSort = (event, property) => {
		console.log('here is the property value: ', property);
		dispatch(requestSort(property));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(changeRowsPerPage(event.target.value));
	};

	const sortItems = clientHeadCells.reduce((acc, cell, index) => {
		if (index === 0) {
			acc.push({ value: 'clients', label: cell.label });
		}
		acc.push({ value: `clients/sort/${cell.id}:asc`, label: `${cell.label} (asc)` });
		acc.push({ value: `clients/sort/${cell.id}:desc`, label: `${cell.label} (desc)` });
		return acc;
	}, []);

	const filterSchema = useMemo(() => {
		const schema = {
			filters: groupView ? `brokerGroupId: [${brokerGroupIds.join(',')}]` : `ownerId := ${hubspotId}`
		};
		console.log('Filter schema', schema);
		return schema;
	}, [brokerGroupIds, groupView, hubspotId]);

	return (
		<InstantSearch 
			indexName="clients" 
			searchClient={searchClient}
		>
			<TableContainer component={Paper}>
				{isLoading || isUninitialized ? <Skeleton /> : 
					<>
						<Configure {...filterSchema} />
						<Grid container alignItems={'center'} p={1}>
							<Grid item xs={12} sm={5}>
								<InstantSearchFieldAdaptor />
							</Grid>
							<Grid item xs={12} sm={true}></Grid>
							<Grid item xs={12} sm={3} container justifyContent={'flex-end'}>
								<Button 
									component="label" 
									variant="contained" 
									startIcon={<Add />}
									onClick={() => navigate(paths.createNewBusiness)}>
				New Client
								</Button>
							</Grid>
						</Grid>
		
						<Table
							aria-labelledby="tableTitle"
							size="medium"
							aria-label="enhanced table"
							sx={{ minWidth: 700 }}
						>
							<SearchFolioHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								headCells={clientHeadCells}
								items={sortItems}
							/>
							<HitsTableBody 
								TableRowComponent={ClientRow}
								ErrorRowComponent={() => <TableRow>
									<TableCell colSpan={clientHeadCells.length} align="center">
										<Typography variant="subtitle1">
                                    There was an error. Please try again.
										</Typography>
									</TableCell>
								</TableRow>}
								EmptyRowComponent={() => <TableRow>
									<TableCell colSpan={clientHeadCells.length} align="center">
										<Typography variant="subtitle1">No clients found</Typography>
									</TableCell>
								</TableRow>}
							/>
						</Table>
						<HitsTablePagination
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							items={rowLimits.map(item => ({
								label: `${item} rows per page`,
								value: item,
								default: item == rowsPerPage
							}))}
						/>
					</>}
			</TableContainer>
		</InstantSearch>
	);
}