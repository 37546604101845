// features/table/attachmentsTableSlice.js
import { createSlice, current } from '@reduxjs/toolkit';

export const instantSearchSlice = createSlice({
	name: 'instantSearch',
	initialState: {
		filters: {},
	},
	reducers: {
		setFilters: (state, action) => {
			const { type, values } = action.payload;
			state.filters[type] = values;
		},
	},
});

export const { 
	setFilters,
} = instantSearchSlice.actions;

export default instantSearchSlice.reducer;