import React, { useEffect, useMemo, useState } from 'react';
import {
	CircularProgress,
	InputAdornment,
	TextField,
	Box,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useGetClientPoliciesQuery, useGetClientRowQuery } from '@/features/clients/clientsApi';

export const PolicySelect = ({ clientId, policy, setPolicy, disabled }) => {
	const [open, setOpen] = useState(false);

	const { data: clientRow } = useGetClientRowQuery(clientId, { skip: !clientId });
	const insightId = useMemo(() => clientRow?.insightId, [clientRow?.insightId]);

	const {
		data: policyData,
		isLoading: isLoadingPolicies,
		isFetchingPolicies,
	} = useGetClientPoliciesQuery(
		{ insightId },
		{ skip: !insightId || Object.keys(policy ?? {}).length > 0 }
	);

	const policyOptions = useMemo(() => policyData ?? [], [policyData]);

	return (
		<Autocomplete
			id="policy-select"
			loading={isLoadingPolicies || isFetchingPolicies}
			loadingText="Loading policies..."
			options={policyOptions}
			getOptionLabel={(policy) =>
				`${policy.Description}, ${policy.TypeDescription}, ${policy.PolicyNumber}`
			}
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			renderOption={(props, policy) => (
				<Box component="li" {...props}>
					{`${policy.Description}, ${policy.TypeDescription}, ${policy.PolicyNumber}`}
				</Box>
			)}
			value={Object.keys(policy ?? {}).length > 0 ? policy : null}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder="Search for policy"
					// sx={{ opacity: !policyData ? '0.5' : '1' }}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start">
								{isLoadingPolicies || isFetchingPolicies ? (
									<CircularProgress color="inherit" size={20} />
								) : (
									null
								)}
							</InputAdornment>
						),
					}}
				/>
			)}
			isOptionEqualToValue={(option, value) => 
				option.Id === value.Id
			}
			onChange={(_, value) => {
				setPolicy(value);
			}}
			disabled={!clientId || disabled}
		/>
	);
};


