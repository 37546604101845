import { createSlice } from '@reduxjs/toolkit';

export const engagementsSlice = createSlice({
	name: 'engagementsSlice',
	initialState: {
		loading: false,
		loadingActivities: false,
		count: {
			tasks: 0,
			incompleteTasks: 0,
			activities: 0,
		}
	},
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setLoadingActivities: (state, action) => {
			state.loading = action.payload;
		},
		setCount: (state, action) => {
			const { key, count } = action.payload;
			state.count[key] = count;
		}
	},
});

export const { 
	setLoading,
	setLoadingActivities,
	setCount,
} = engagementsSlice.actions;
export default engagementsSlice.reducer;