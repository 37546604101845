import React from 'react';
import { Button } from '@mui/material';
import { InsightIcon } from '@/components/icons/InsightIcon';
import { IS_DEV } from '@/utils/environment';

export const NavigateInsightButton = ({ledgerName, insightId}) => {
	
	const ledger = ledgerName ?? (IS_DEV ? 'tstfolio' : 'folio');

	const navigateToInsight = () => {
		const ledgerUrl =  `https://${ledger}.insightbroking.co.nz`; 
		const url = `${ledgerUrl}/#/client/${insightId}/policy-transaction?isCoClientParam=false&isEditModeParam=&showFilter=ShowAll`;
		window.open(url, '_blank');
	};
	
	return (
		<Button
			component="label"
			sx={{ borderRadius: '10px' }}
			variant="outlined"
			onClick={navigateToInsight}
			startIcon={<InsightIcon />}
		>
			{'Open in INSIGHT'}
		</Button>
	);
};
