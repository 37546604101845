import React from 'react';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { inputProps } from './clientDetailData';
import { ResetButton } from './resetButton';

export const DrawNumeric = ({ clickHandler, isLoading, ...props }) => <NumericFormat
	{...props}
	fullWidth
	customInput={TextField}
	thousandSeparator
	decimalScale={0}
	fixedDecimalScale
	allowNegative={false}
	// sx={inputProps}
	// variant="standard"
	// className="minimal-input"
	InputProps={{
		endAdornment: <ResetButton isLoading={isLoading} onMouseDown={clickHandler}/>,
	}}
	disabled={isLoading}
/>;